import React, { useEffect, useState } from "react";
import {
  Flex,
  SkeletonText,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "../../axios";
import BackButton from "../../components/button/BackButton";

const UserTtrDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState({});
  const location = useLocation();
  const color = useColorModeValue("green.200", "green.700");
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "test";

  // ------------------- get Tap to run Detail ----------------------
  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/user_taptorun_detail", {
        params: {
          _id: location.state.e._id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log(result.data.data);
        setDetail(result.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
        setIsLoading(false);
      });
    return () => {
      cancel = true;
    };
  }, []);

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "full", md: "xl", lg: "8xl" }}
    >
      {" "}
      <BackButton />
      <VStack
        boxShadow={"base"}
        borderRadius={"md"}
        p={{ base: "2", md: "6" }}
        w={{ base: "full", md: "3xl", lg: "6xl" }}
        bg={"#fff"}
        spacing={{ base: 2, md: 4 }}
      >
        <VStack w={"full"}>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={"space-between"}
            w="full"
            borderWidth={1}
            borderColor={"#EFEFEF"}
            borderRadius={"md"}
            py={"2"}
            px={"6"}
            bg={"#F8F8F8"}
          >
            <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
              Багц үйлдлийн нэр:
            </Text>
            {isLoading ? (
              <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                -
              </Text>
            ) : (
              <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                {detail.name}
              </Text>
            )}
          </Stack>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={"space-between"}
            w="full"
            borderWidth={1}
            borderColor={"#EFEFEF"}
            borderRadius={"md"}
            py={"2"}
            px={"6"}
            bg={"#F8F8F8"}
          >
            <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
              Үүссэн цаг:
            </Text>
            <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
              -
            </Text>
            {/* <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
              {location.state.e.atTime.slice(0, 10) + " / "}
              {location.state.e.atTime.slice(11, 19)}
            </Text> */}
          </Stack>
        </VStack>
        <VStack
          borderWidth={1}
          borderColor={"#EFEFEF"}
          w="full"
          borderRadius={"md"}
          bg={"#fff"}
          p={{ base: "2", md: "6" }}
          spacing={{ base: 2, md: 4 }}
        >
          <Flex
            align={"center"}
            w="full"
            _before={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: color,
              flexGrow: 0.3,
              mr: { base: 4, md: 8 },
            }}
            _after={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: color,
              flexGrow: 1.7,
              ml: { base: 4, md: 8 },
            }}
          >
            <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
              Үйлдэл
            </Text>
          </Flex>
          <TableContainer w={{ base: "72", md: "full" }}>
            <Table>
              <Thead bg={"#F8F8F8"}>
                <Tr>
                  <Th color={"#000"} fontSize={14} fontWeight={"medium"}>
                    ID
                  </Th>
                  <Th color={"#000"} fontSize={12} fontWeight={"medium"}>
                    Нэр
                  </Th>
                  <Th color={"#000"} fontSize={12} fontWeight={"medium"}>
                    Төрөл
                  </Th>
                  <Th color={"#000"} fontSize={12} fontWeight={"medium"}>
                    Өрөө
                  </Th>
                  <Th
                    color={"#000"}
                    fontSize={12}
                    fontWeight={"medium"}
                    isNumeric
                  >
                    комманд
                  </Th>
                </Tr>
              </Thead>
              {isLoading ? (
                <Tbody>
                  <Tr>
                    <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                      <SkeletonText noOfLines={1} />
                    </Td>
                    <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                      <SkeletonText noOfLines={1} />
                    </Td>
                    <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                      <SkeletonText noOfLines={1} />
                    </Td>
                    <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                      <SkeletonText noOfLines={1} />
                    </Td>
                    <Td
                      color={"#000"}
                      fontSize={14}
                      fontWeight={"normal"}
                      isNumeric
                    >
                      <SkeletonText noOfLines={1} />
                    </Td>
                  </Tr>
                </Tbody>
              ) : detail.actions ? (
                detail.actions.map((e, index) => (
                  <Tbody key={index} bg={index % 2 == 0 ? "white" : "#FCFCFC"}>
                    <Tr>
                      <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                        {e._id}
                      </Td>
                      <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                        {e.for ? e.for : e.name}
                      </Td>
                      <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                        {e.type}
                      </Td>
                      <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                        {e.room ? e.room : "-"}
                      </Td>
                      <Td
                        color={"#000"}
                        fontSize={14}
                        fontWeight={"normal"}
                        isNumeric
                      >
                        {e.actionType} - {e.command}
                      </Td>
                    </Tr>
                  </Tbody>
                ))
              ) : null}
            </Table>
          </TableContainer>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default UserTtrDetail;
