import React, { useState } from "react";
import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Textarea,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { TiThumbsOk } from "react-icons/ti";
import { RiPhoneLine } from "react-icons/ri";
import axios from "../../axios";
import Cookies from "js-cookie";

const SendSms = ({ setTrigger }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [desc, setDesc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const toast = useToast();
  const id = "test";
  const token = Cookies.get("accessToken");

  const { isOpen, onOpen, onClose } = useDisclosure();

  // --------------------------- Send SMS --------------------------------
  const sendHandler = () => {
    if (phoneNumber && desc) {
      setDisableBtn(true);
      setIsLoading(true);
      onClose();
      axios
        .post(
          "/admin/send_message",
          {
            phoneNo: phoneNumber,
            message: desc,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log("Done ---", result);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "success",
              duration: 2000,
              position: "top",
              description: "Амжилттай илгээлээ.",
            });
          }
          setPhoneNumber("");
          setDesc("");
          setTrigger(true);
          setIsLoading(false);
          setDisableBtn(false);
        })
        .catch((err) => {
          // console.log("error", err);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "error",
              duration: 2000,
              position: "top",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
          setIsLoading(false);
          setDisableBtn(false);
        });
    }
  };

  return (
    <>
      <VStack
        bg={"#fff"}
        rounded={"lg"}
        px={{ base: "6", md: "8" }}
        py={{ base: "6", md: "8" }}
        w={"full"}
        // h={"72"}
        // shadow={"md"}
        spacing={3}
        justify={"center"}
      >
        <VStack alignSelf={"flex-start"} spacing={1}>
          <Box
            w={"16"}
            h={"0.5"}
            bg={"#45A735"}
            rounded={"full"}
            alignSelf={"flex-start"}
          />
          <HStack>
            <Text fontSize={15} fontWeight={"medium"}>
              Хэрэглэгч рүү мессеж илгээх
            </Text>
            <Icon as={TiThumbsOk} w={5} h={5} color="#45A735" />
          </HStack>
        </VStack>

        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<Icon as={RiPhoneLine} w={5} h={5} color="#45A735" />}
          />
          <Input
            type="text"
            placeholder="Утасны дугаар"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            fontSize={14}
            fontWeight={"normal"}
            _hover={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            _focus={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            w={"full"}
            bg={"#f9f9f9"}
            rounded={"md"}
          />
        </InputGroup>
        <Textarea
          type="text"
          placeholder="Дэлгэрэнгүй"
          w={"full"}
          bg={"#f9f9f9"}
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
          fontSize={14}
          fontWeight={"normal"}
          rounded={"md"}
          _hover={{
            borderColor: "#45A735",
            borderWidth: 1,
          }}
          _focus={{
            borderColor: "#45A735",
            borderWidth: 1,
          }}
          overflowY="auto"
          sx={{
            "&::-webkit-scrollbar": {
              width: "6px",
              borderRadius: "8px",
              backgroundColor: `rgba(249, 249, 249)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `rgba(69, 167, 53, 0.6)`,
              borderRadius: "8px",
            },
          }}
          onKeyPress={(e) => {
            if (phoneNumber && desc) {
              if (e.key === "Enter") {
                sendHandler();
              }
            }
          }}
        />
        <Button
          bg={"#45A735"}
          w={"full"}
          rounded={"md"}
          py={"2"}
          cursor={"pointer"}
          onClick={onOpen}
          _hover={{
            bg: "#5AB54B",
          }}
          _focus={{
            outline: "none",
          }}
          fontWeight={"normal"}
          disabled={phoneNumber && desc && !isLoading ? false : true}
        >
          {isLoading ? <Spinner color="#fff" mr={2} size="sm" /> : null}
          <Text fontSize={14} color={"#fff"}>
            Илгээх
          </Text>
        </Button>
      </VStack>

      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader
            fontSize={17}
            color={"#000"}
            fontWeight={"medium"}
            w={"xs"}
          >
            Та мессеж илгээхдээ итгэлтэй байна уу?
          </ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          <ModalFooter>
            <Button colorScheme="gray" mr={3} px={"8"} onClick={onClose}>
              Үгүй
            </Button>
            <Button
              bg={"#45A735"}
              color={"#fff"}
              px={"8"}
              _hover={{ bg: "#58B948" }}
              onClick={sendHandler}
              disabled={disableBtn}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SendSms;
