import React from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  keyframes,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SkeletonText,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { FiInfo, FiTrash2, FiUser } from "react-icons/fi";
import { CgLock } from "react-icons/cg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BsArrowRightShort } from "react-icons/bs";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import axios from "../../axios";
import minus from "../../Images/minus.png";
import ErrorBoundary from "../../utils/error/ErrorBoundary";

const ApiService = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [deleteUser, setDeleteUser] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState("");
  const token = Cookies.get("accessToken");
  const history = useHistory();
  const toast = useToast();
  const id = "test";
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get(
        "/admin/remote_api_service/account",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
      .then((result) => {
        if (cancel) return;
        // console.log(result.data.data);
        setUserList(result.data.data);
        setTrigger(false);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response.status);
        if (err.response.status === 403) {
          setError(err.response);
        } else {
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        }
        setIsLoading(false);
      });
    return () => {
      cancel = true;
    };
  }, [trigger]);

  const createHandler = () => {
    if (username && password) {
      axios
        .post(
          "/admin/remote_api_service/account",
          {
            username: username,
            password: password,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result);
          setTrigger(true);
          setUsername("");
          setPassword("");
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
        })
        .catch((err) => {
          // console.log(err.response);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const deleteModal = (e) => {
    setDeleteUserData(e);
    onOpen();
  };

  const deleteHandler = () => {
    onClose();
    axios
      .delete("/admin/remote_api_service/account", {
        data: {
          username: deleteUserData,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setTrigger(true);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "success",
            description: "Амжилттай устлаа!",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); }
  30% { transform: scale(1.02) rotate(-25deg); }
  60% { transform: scale(1) rotate(25deg); }
  100% { transform: scale(1) rotate(0); }
`;

  const animation = `${animationKeyframes} 0.8s ease`;

  if (isLoading)
    return (
      <Center>
        <Spinner />
      </Center>
    );

  if (error) return <ErrorBoundary error={error} />;

  return (
    <Stack
      direction={{ base: "column", md: "column", lg: "row" }}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <VStack
        bg={"#fff"}
        p={"4"}
        shadow={"md"}
        roundedBottom={"md"}
        w={"xs"}
        borderTopWidth={"medium"}
        borderTopColor={"#45A735"}
      >
        <Flex
          align={"center"}
          w="full"
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 0.3,
            mr: { base: 4, md: 4 },
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 1.7,
            ml: { base: 4, md: 4 },
          }}
        >
          <Text
            color={"#000"}
            fontSize={15}
            fontWeight={"medium"}
            letterSpacing={0.3}
          >
            Харилцагч үүсгэх
          </Text>
        </Flex>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<Icon as={FiUser} w={4} h={4} color="#45A735" />}
          />
          <Input
            placeholder="Харилцагчийн нэр"
            color={"#000"}
            fontSize={15}
            fontWeight={"normal"}
            maxLength={30}
            _hover={{
              borderColor: "#45A735",
            }}
            _focus={{
              outline: "none",
              borderColor: "#45A735",
            }}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </InputGroup>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<Icon as={CgLock} w={4} h={4} color="#45A735" />}
          />
          <Input
            placeholder="Нууц үг"
            color={"#000"}
            fontSize={15}
            fontWeight={"normal"}
            _hover={{
              borderColor: "#45A735",
            }}
            _focus={{
              outline: "none",
              borderColor: "#45A735",
            }}
            type={show ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={(e) => {
              if (username && password) {
                if (e.key === "Enter") {
                  createHandler();
                }
              }
            }}
          />
          <InputRightElement>
            <Icon
              as={show ? AiOutlineEyeInvisible : AiOutlineEye}
              w={5}
              h={5}
              color="#45A735"
              onClick={() => setShow(!show)}
              cursor={"pointer"}
            />
          </InputRightElement>
        </InputGroup>
        <Button
          bg={"#45A735"}
          color={"#fff"}
          fontSize={15}
          fontWeight={"normal"}
          w={"full"}
          size={"md"}
          _hover={{
            bg: "#5AB54B",
          }}
          _focus={{
            outline: "none",
          }}
          disabled={username && password ? false : true}
          onClick={createHandler}
        >
          Бүртгэх
        </Button>
      </VStack>
      <VStack
        w={{ base: "full", md: "full", lg: "4xl" }}
        spacing={4}
        bg={"#fff"}
        p={"4"}
        rounded={"md"}
        shadow={"md"}
      >
        <Box
          bg={"#f9f9f9"}
          align={"flex-start"}
          py={"3"}
          px={{ base: "4", md: "6" }}
          spacing={1}
          w={"full"}
          borderLeftWidth={"medium"}
          borderColor={"#45A735"}
          pos="relative"
        >
          <Text color={"#000"} fontWeight={"medium"} fontSize={15}>
            Харилцагчийн жагсаалт
          </Text>
          <Flex
            p={2}
            w="max-content"
            color="white"
            bgGradient="linear(to-br, #2E931F, #45A735)"
            rounded="md"
            marginInline="auto"
            pos="absolute"
            right={6}
            top="-0.5rem"
            boxShadow="lg"
            cursor={"pointer"}
            onClick={() => setDeleteUser(!deleteUser)}
            _hover={{ animation: animation }}
          >
            <Icon as={FiTrash2} color="#fff" />
          </Flex>
        </Box>
        <TableContainer
          borderLeftWidth={"thin"}
          borderRightWidth={"thin"}
          rounded={"md"}
          w={"full"}
          borderColor={"#F4F4F4"}
        >
          <Table size="md">
            <Thead bg={"#45A735"}>
              <Tr>
                {listTitle.map((e, index) => (
                  <Th
                    key={index}
                    color={"#fff"}
                    fontWeight={"medium"}
                    fontSize={14}
                    textTransform={"initial"}
                    isNumeric={index === 2 ? true : false}
                  >
                    {e.title}
                  </Th>
                ))}
              </Tr>
            </Thead>
            {isLoading ? (
              <Tbody>
                <Tr>
                  <Td>
                    <SkeletonText noOfLines={1} />
                  </Td>
                  <Td>
                    <SkeletonText noOfLines={1} />
                  </Td>
                  <Td isNumeric>
                    <SkeletonText noOfLines={1} />
                  </Td>
                </Tr>
              </Tbody>
            ) : userList.length > 0 ? (
              userList.map((e, index) => (
                <Tbody key={index} bg={index % 2 === 0 ? "#fff" : "#FCFCFC"}>
                  <Tr>
                    <Td color={"#000"} fontWeight={"normal"} fontSize={14}>
                      {index + 1}
                    </Td>
                    <Td color={"#000"} fontWeight={"normal"} fontSize={14}>
                      {e.username}
                    </Td>
                    <Td
                      color={"#000"}
                      fontWeight={"normal"}
                      fontSize={14}
                      py={0}
                      pr={2}
                      isNumeric
                    >
                      {deleteUser ? (
                        <Button
                          justifyContent={"center"}
                          alignItems={"center"}
                          size={"xs"}
                          bg={"transparent"}
                          mr={"2"}
                          _hover={{
                            bg: "#F5F5F5",
                          }}
                          _focus={{
                            outline: "none",
                          }}
                          onClick={() => deleteModal(e.username)}
                        >
                          <Image
                            src={minus}
                            objectFit="contain"
                            w={"4"}
                            h={"4"}
                          />
                        </Button>
                      ) : (
                        <Button
                          justifyContent={"center"}
                          alignItems={"center"}
                          size={"xs"}
                          py={4}
                          pl={4}
                          mr={0}
                          bg={"transparent"}
                          _hover={{
                            bg: "#F5F5F5",
                          }}
                          _focus={{
                            outline: "none",
                          }}
                          onClick={() =>
                            history.push("/api/shared-device-list", {
                              username: e.username,
                            })
                          }
                        >
                          <Text
                            color={"#000"}
                            fontSize={13}
                            fontWeight={"normal"}
                          >
                            Хэрэглэгчид хуваалцах
                          </Text>
                          <Icon
                            as={BsArrowRightShort}
                            w={5}
                            h={5}
                            color="#45A735"
                          />
                        </Button>
                      )}
                    </Td>
                  </Tr>
                </Tbody>
              ))
            ) : (
              <VStack
                justifyContent="center"
                alignItems="center"
                color="gray.600"
                w={{ base: "72", md: "xs", lg: "3xl" }}
                pt={{ base: "6", md: "12", lg: "24" }}
                spacing={1}
                pos={{ base: "initial", md: "absolute" }}
              >
                <Icon as={FiInfo} w={7} h={7} />
                <Text fontSize={15} fontWeight={"normal"}>
                  Мэдээлэл олдсонгүй.
                </Text>
              </VStack>
            )}
          </Table>
        </TableContainer>
      </VStack>
      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={onClose}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={deleteHandler}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

const listTitle = [
  { title: "№" },
  { title: "Харилцагчийн нэр" },
  { title: "Хуваалцах" },
];

export default ApiService;
