import React from "react";
import {
  HStack,
  IconButton,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { IoIosCloseCircleOutline } from "react-icons/io";

const AdditionalPaymentCard = ({ index, data, removeDataHandler }) => {
  return (
    <HStack
      w={"full"}
      bg={"#FCFCFC"}
      borderColor={"#EFEFEF"}
      borderWidth={1}
      borderRadius={4}
      px={5}
      py={2}
      align={"center"}
      justify={"space-between"}
      divider={<StackDivider borderColor="#EFEFEF" />}
      gap={4}
    >
      <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
        {index}
      </Text>
      <HStack w={"full"} align={"center"} justify={"space-between"} gap={2}>
        <VStack align={"flex-start"} spacing={0}>
          <Text color={"#000"} fontSize={13} fontWeight={"medium"}>
            {data?.name ? data.name : data.deviceName}
          </Text>
          <HStack spacing={10}>
            <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
              {"Нийт үнэ: "}
              <Text as={"span"} fontWeight={"medium"}>
                {data.price.toLocaleString() + "₮"}
              </Text>
            </Text>
            {data?.quantity && (
              <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
                {"Нийт нэгж: "}
                <Text as={"span"} fontWeight={"medium"}>
                  {data.quantity}
                </Text>
              </Text>
            )}
          </HStack>
        </VStack>
      </HStack>
      <IconButton
        transition={"ease-in-out .3s"}
        bg={"transparent"}
        color={"#f00"}
        borderWidth={1}
        borderColor={"transparent"}
        _hover={{
          borderColor: "#f00",
          bg: "#ffdcdc",
          color: "#f00",
        }}
        _focus={{
          outline: "none",
        }}
        icon={<IoIosCloseCircleOutline size={20} />}
        onClick={() => removeDataHandler(data)}
      />
    </HStack>
  );
};

export default AdditionalPaymentCard;
