import React, { useContext, useState } from "react";
import {
  Box,
  Center,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useToast,
  VStack,
  Image,
  Link,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { FiUser } from "react-icons/fi";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import UserContext from "../../UserContext";
import axios from "../../axios";
import { FaFacebookF } from "react-icons/fa";
import { CgLock } from "react-icons/cg";
import { BsTwitter, BsInstagram } from "react-icons/bs";
import logo from "../../Images/circleLogo.png";
import Cookies from "js-cookie";

const Profile = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const history = useHistory();
  const context = useContext(UserContext);
  const toast = useToast();
  const id = "test";
  const username = Cookies.get("username");
  const token = Cookies.get("accessToken");

  const saveHandler = () => {
    // console.log(data);
    if (oldPassword && newPassword && newPassword === confirmPassword) {
      const data = {
        username: context.username,
        password: oldPassword,
        newPassword: newPassword,
      };
      axios
        .post("/admin/user_change_info", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "success",
              duration: 2000,
              position: "top",
              description: "Амжилттай",
            });
          }
          history.push("/dashboard");
        })
        .catch((err) => {
          // console.log(err.response);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    } else {
      if (!toast.isActive(id)) {
        toast({
          id,
          duration: 2000,
          position: "top",
          status: "error",
          description: "Мэдээлэл дутуу байна!",
        });
      }
    }
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "xs", md: "xl", lg: "8xl" }}
    >
      <Box
        w={{ base: "72", md: "sm" }}
        h={"48"}
        bg={"#45A735"}
        rounded={"xl"}
        boxShadow={"lg"}
      >
        <Box
          w={{ base: "xs", md: "md" }}
          bg={"#fff"}
          rounded={"xl"}
          mt={{ base: "3", md: "8" }}
          ml={{ base: "3", md: "10" }}
          boxShadow={"lg"}
          p={{ base: "6", md: "12" }}
        >
          <Flex
            align={"center"}
            w="full"
            _before={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: "#45A735",
              flexGrow: 0.3,
              mr: { base: 4, md: 8 },
            }}
            _after={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: "#45A735",
              flexGrow: 1.7,
              ml: { base: 4, md: 8 },
            }}
          >
            <Text
              color={"#000"}
              fontSize={15}
              fontWeight={"medium"}
              letterSpacing={0.3}
            >
              Мэдээлэл засварлах
            </Text>
          </Flex>
          <InputGroup mt={"6"}>
            <InputLeftElement
              children={<Icon as={FiUser} w={4} h={4} color="#45A735" />}
            />
            <Input
              isReadOnly
              placeholder="Хэрэглэгчийн нэр"
              fontSize={"15"}
              type="text"
              bg={"#fff"}
              variant={"outline"}
              borderColor={"#eee"}
              borderWidth={"thin"}
              value={username}
              _focus={{ borderColor: "#45A735" }}
            />
          </InputGroup>
          <InputGroup mt={"2"}>
            <InputLeftElement
              children={<Icon as={CgLock} w={4} h={4} color="#45A735" />}
            />
            <Input
              placeholder="Хуучин нууц үг"
              fontSize={"15"}
              type={show ? "text" : "password"}
              bg={"#fff"}
              variant={"outline"}
              borderColor={"#eee"}
              borderWidth={"thin"}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              _focus={{ borderColor: "#45A735" }}
            />
            <InputRightElement>
              <Icon
                as={show ? AiOutlineEyeInvisible : AiOutlineEye}
                w={5}
                h={5}
                mr={"2"}
                color="#45A735"
                onClick={() => setShow(!show)}
                cursor={"pointer"}
              />
            </InputRightElement>
          </InputGroup>
          <InputGroup mt={"2"}>
            <InputLeftElement
              children={<Icon as={CgLock} w={4} h={4} color="#45A735" />}
            />
            <Input
              placeholder="Шинэ нууц үг"
              fontSize={"15"}
              type={show1 ? "text" : "password"}
              bg={"#fff"}
              variant={"outline"}
              borderColor={"#eee"}
              borderWidth={"thin"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              _focus={{ borderColor: "#45A735" }}
            />
            <InputRightElement>
              <Icon
                as={show1 ? AiOutlineEyeInvisible : AiOutlineEye}
                w={5}
                h={5}
                mr={"2"}
                color="#45A735"
                onClick={() => setShow1(!show1)}
                cursor={"pointer"}
              />
            </InputRightElement>
          </InputGroup>
          <InputGroup mt={"2"}>
            <InputLeftElement
              children={<Icon as={CgLock} w={4} h={4} color="#45A735" />}
            />
            <Input
              placeholder="Нууц үгээ давтана уу"
              fontSize={"15"}
              type={show2 ? "text" : "password"}
              bg={"#fff"}
              variant={"outline"}
              borderColor={"#eee"}
              borderWidth={"thin"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              _focus={{ borderColor: "#45A735" }}
            />
            <InputRightElement>
              <Icon
                as={show2 ? AiOutlineEyeInvisible : AiOutlineEye}
                w={5}
                h={5}
                mr={"2"}
                color="#45A735"
                onClick={() => setShow2(!show2)}
                cursor={"pointer"}
              />
            </InputRightElement>
          </InputGroup>

          <Center
            bg={"#45A735"}
            rounded={"md"}
            py={"2"}
            px={"6"}
            w={"full"}
            mt={"4"}
            cursor={"pointer"}
            onClick={saveHandler}
            _hover={{
              bg: "#5AB54B",
            }}
          >
            <Text color={"#fff"} fontWeight={"normal"} fontSize={"15"}>
              Хадгалах
            </Text>
          </Center>
        </Box>
      </Box>
      <Stack
        direction={{ base: "row", md: "column" }}
        pos={"absolute"}
        right={{ base: "22%", md: 4 }}
        top={{ base: null, md: "30%" }}
        bottom={{ base: "2%", md: null }}
        spacing={3}
      >
        <Tooltip label="Viot.mn" placement="left">
          <Link href="https://viot.mn/" isExternal>
            <Center bg={"#F0F0F0"} rounded={"lg"} shadow={"lg"} p={"3"}>
              <Image src={logo} w={4} h={4} color={"#fff"} />
            </Center>
          </Link>
        </Tooltip>

        <Tooltip label="facebook" placement="left">
          <Link href="https://www.facebook.com/viot.mon" isExternal>
            <Center bg={"#3B5998"} rounded={"lg"} shadow={"lg"} p={"3"}>
              <Icon as={FaFacebookF} color={"#fff"} />
            </Center>
          </Link>
        </Tooltip>

        <Tooltip label="instagram" placement="left">
          <Link href="https://www.facebook.com/viot.mon" isExternal>
            <Center
              bgGradient="linear(to-tl, #515BD4, #DD2A7B, #F58529)"
              rounded={"lg"}
              shadow={"lg"}
              p={"3"}
            >
              <Icon as={BsInstagram} color={"#fff"} />
            </Center>
          </Link>
        </Tooltip>

        <Tooltip label="twitter" placement="left">
          <Link href="https://www.facebook.com/viot.mon" isExternal>
            <Center bg={"#00ACEE"} rounded={"lg"} shadow={"lg"} p={"3"}>
              <Icon as={BsTwitter} color={"#fff"} />
            </Center>
          </Link>
        </Tooltip>
      </Stack>
    </VStack>
  );
};

export default Profile;
