import React, { useEffect, useState } from "react";
import {
  Center,
  HStack,
  Icon,
  Spinner,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import axios from "../../../axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
// import ReactPaginate from "react-paginate";
import { FiInfo } from "react-icons/fi";
import SysDevice from "../../../components/system/SysDevice";
import StockModal from "./utilities/StockModal";
import LandingModal from "./utilities/LandingModal";

const DeviceList = () => {
  const [trigger, setTrigger] = useState(true);
  const [deviceList, setDeviceList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const history = useHistory();

  useEffect(() => {
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get(
        "/v2/landin/get_list",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
      .then((result) => {
        if (cancel) return;
        // console.log(result.data);
        setDeviceList(result.data.data);
        setTrigger(false);
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: "Алдаа гарлаа!",
          });
        }
      });
    return () => {
      cancel = true;
    };
  }, [trigger]);

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={["xs", "full", "full", "full", "8xl"]}
    >
      <HStack w={"full"} align={"center"} justify={"space-between"}>
        <Center
          w={["full", "xs", "xs", "xs", "xs"]}
          bg={"#45A735"}
          rounded={"md"}
          py={"1"}
          onClick={() => history.push("/system/add-device")}
          cursor={"pointer"}
        >
          <Text fontSize={15} fontWeight={"normal"} color={"#fff"}>
            Төхөөрөмж нэмэх
          </Text>
        </Center>
      </HStack>

      <VStack
        w={["xl", "full", "full", "full", "full"]}
        bg={"#fff"}
        rounded={"md"}
        p={"4"}
        spacing={0}
      >
        <HStack w={"full"} bg={"#F5F5F5"} px={"1"} py={"2"} rounded={"md"}>
          {titleData.map((e, i) => (
            <Stack
              key={i}
              w={i === 0 ? "60" : i === 5 ? "sm" : "full"}
              align={i === 0 || i === 5 ? "center" : "flex-start"}
            >
              <Text fontSize={13} fontWeight={"medium"} color={"#000"}>
                {e.title}
              </Text>
            </Stack>
          ))}
        </HStack>
        {trigger ? (
          <Center w={"full"}>
            <Spinner size="md" color={"#45A735"} mt={"2"} />
          </Center>
        ) : deviceList.length > 0 ? (
          deviceList.map((e, i) => (
            <SysDevice
              key={i}
              data={e}
              index={i}
              setTrigger={setTrigger}
              setShowModal={setShowModal}
              setVisible={setVisible}
              setSelectedData={setSelectedData}
            />
          ))
        ) : (
          <VStack
            justifyContent="center"
            alignItems="center"
            color="gray.600"
            w={{ base: "full", md: "full", lg: "3xl" }}
            py={"2"}
            spacing={1}
          >
            <Icon as={FiInfo} w={7} h={7} />
            <Text fontSize={15} fontWeight={"normal"}>
              Мэдээлэл олдсонгүй.
            </Text>
          </VStack>
        )}
        {/* {deviceList.length > 0 ? (
          <ReactPaginate
            previousLabel={"< Prev"}
            nextLabel={"Next >"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            // pageCount={pageCount}
            // forcePage={currentPage}
            // onPageChange={handlePageClick}
            marginPagesDisplayed={2}
            pageRangeDisplayed={4}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        ) : null} */}
      </VStack>
      <StockModal
        data={selectedData}
        showModal={showModal}
        setShowModal={setShowModal}
        setTrigger={setTrigger}
      />
      <LandingModal
        data={selectedData}
        showModal={visible}
        setShowModal={setVisible}
        setTrigger={setTrigger}
      />
    </VStack>
  );
};

const titleData = [
  {
    title: "№",
  },
  {
    title: "Нэр",
  },
  {
    title: "Төрөл",
  },
  {
    title: "Нэгж үнэ",
  },
  {
    title: "Нийт үлдэгдэл / Хүлээгдэж буй",
  },
  {
    title: "...",
  },
];

export default DeviceList;
