import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  HStack,
  Icon,
  Skeleton,
  SkeletonText,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "../../axios";
import Cookies from "js-cookie";
import { FiInfo } from "react-icons/fi";

const NotificationList = () => {
  const [NotifList, setNotifList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLimit, setIsLimit] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const token = Cookies.get("accessToken");

  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get(
        "/admin/admin_notif",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
      .then((result) => {
        if (cancel) return;
        // console.log(result.data.data);
        setNotifList(result.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
    return () => {
      cancel = true;
    };
  }, []);

  // ----------------- Switch following log --------------------
  const getFollowingNotif = (Lastid) => {
    // setIsLoading(true);
    setLoadingMore(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/more_admin_notif", {
        params: {
          _id: Lastid,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        // console.log("follow data ===", result.data.data);
        const moreNotif = result.data.data;
        const clone = [...NotifList].concat(moreNotif);
        setNotifList(clone);

        // setIsLoading(false);
        if (result.data.data.length < 1) {
          setIsLimit(true);
        }
        setLoadingMore(false);
      })
      .catch((err) => {
        // console.log("follow err", err);
        // setIsLoading(false);
        setLoadingMore(false);
      });
  };

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loadingMore) return;
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          // console.log("islimit ----", isLimit);
          if (isLimit === false) {
            const lastIndex = NotifList.length - 1;
            const lastSwitchLog = NotifList[lastIndex];
            getFollowingNotif(lastSwitchLog._id);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, NotifList]
  );

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "xs", md: "xl", lg: "8xl" }}
    >
      {isLoading ? (
        <HStack spacing={"10"}>
          <Skeleton
            w={{ base: "full", md: "full", lg: "4xl" }}
            h={"12"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : NotifList.length > 0 ? (
        <Box
          w={{ base: "full", md: "full", lg: "4xl" }}
          bg={"#fff"}
          boxShadow={"lg"}
          p={"4"}
          borderRadius={"lg"}
        >
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={"space-between"}
            w={"full"}
            bg={"#F8F8F8"}
            borderTopRightRadius={"md"}
            borderBottomRightRadius={"md"}
            borderLeftWidth={"medium"}
            borderColor={"#45A735"}
            py={"2"}
            px={{ base: "4", md: "6" }}
            mb={"4"}
          >
            <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
              8 ба түүнээс дээш цагаар салсан төхөөрөмжийн мэдэгдэл
            </Text>
          </Stack>
          <TableContainer>
            <Table>
              <Thead bgGradient="linear(to-l, #45A735, #1ED600)">
                <Tr>
                  <Th
                    borderTopLeftRadius={"md"}
                    color={"#fff"}
                    fontSize={12}
                    fontWeight={"medium"}
                  >
                    Date
                  </Th>
                  <Th color={"#fff"} fontSize={12} fontWeight={"medium"}>
                    Time
                  </Th>
                  <Th
                    isNumeric
                    color={"#fff"}
                    fontSize={12}
                    fontWeight={"medium"}
                    borderTopRightRadius={"md"}
                  >
                    viewed By
                  </Th>
                </Tr>
              </Thead>

              <>
                {NotifList.map((e, index) => {
                  if (NotifList.length === index + 1) {
                    return (
                      <Tbody
                        ref={lastBookElementRef}
                        key={index}
                        bg={index % 2 === 0 ? "#fff" : "#FCFCFC"}
                        pos={"relative"}
                        cursor={"pointer"}
                        _hover={{
                          bg: "#EEEEEE",
                          transform: "scale(1.02)",
                        }}
                        onClick={() =>
                          history.push("/notification/detail", { e })
                        }
                      >
                        {e.viewed === false ? (
                          <Box
                            pos={"absolute"}
                            w={"2"}
                            h={"2"}
                            rounded={"full"}
                            bg={"red"}
                            top={3}
                            right={3}
                          />
                        ) : null}
                        <Tr>
                          <Td
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.atTime.slice(0, 10)}
                          </Td>
                          <Td
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.atTime.slice(11, 19)}
                          </Td>
                          <Td
                            isNumeric
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.viewedBy ? e.viewedBy : "-"}
                          </Td>
                        </Tr>
                      </Tbody>
                    );
                  } else {
                    return (
                      <Tbody
                        key={index}
                        bg={index % 2 === 0 ? "#fff" : "#FCFCFC"}
                        pos={"relative"}
                        cursor={"pointer"}
                        _hover={{
                          bg: "#EEEEEE",
                          transform: "scale(1.02)",
                        }}
                        onClick={() =>
                          history.push("/notification/detail", { e })
                        }
                      >
                        {e.viewed === false ? (
                          <Box
                            pos={"absolute"}
                            w={"2"}
                            h={"2"}
                            rounded={"full"}
                            bg={"red"}
                            top={3}
                            right={3}
                          />
                        ) : null}
                        <Tr>
                          <Td
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.atTime.slice(0, 10)}
                          </Td>
                          <Td
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.atTime.slice(11, 19)}
                          </Td>
                          <Td
                            isNumeric
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.viewedBy ? e.viewedBy : "-"}
                          </Td>
                        </Tr>
                      </Tbody>
                    );
                  }
                })}
              </>
              {loadingMore && (
                <Tbody>
                  <Tr>
                    <Td>
                      <SkeletonText
                        startColor="#eee"
                        endColor="#ddd"
                        noOfLines={1}
                      />
                    </Td>
                    <Td>
                      <SkeletonText
                        startColor="#eee"
                        endColor="#ddd"
                        noOfLines={1}
                      />
                    </Td>
                    <Td isNumeric>
                      <SkeletonText
                        startColor="#eee"
                        endColor="#ddd"
                        noOfLines={1}
                      />
                    </Td>
                  </Tr>
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <VStack
          justifyContent="center"
          alignItems="center"
          color="gray.600"
          w="full"
        >
          <Icon mt={"20"} as={FiInfo} w={7} h={7} />
          <Text fontSize={15}>Мэдээлэл олдсонгүй</Text>
        </VStack>
      )}
    </VStack>
  );
};

export default NotificationList;
