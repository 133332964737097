import React from "react";
import {
  Divider,
  Flex,
  Grid,
  HStack,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import BackButton from "../../../components/button/BackButton";

const UpdateLogDetail = () => {
  const location = useLocation();

  const color = useColorModeValue("green.200", "green.700");

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "full", md: "xl", lg: "8xl" }}
    >
      <BackButton />
      <Stack direction={{ base: "column", md: "row" }} align={"flex-start"}>
        {/* --------------------------- previous Version ----------------------------- */}
        <VStack
          boxShadow={"base"}
          borderRadius={"md"}
          p={"6"}
          w={{ base: "80", md: "2xl" }}
          bg={"#fff"}
          spacing={4}
        >
          <Flex
            align={"center"}
            w="full"
            _before={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: color,
              flexGrow: 1,
              mr: { base: 4, md: 8 },
            }}
            _after={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: color,
              flexGrow: 1,
              ml: { base: 4, md: 8 },
            }}
          >
            <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
              Хуучин автоматжуулалт
            </Text>
          </Flex>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={"space-between"}
            w="full"
            borderWidth={1}
            borderColor={"#EFEFEF"}
            borderRadius={"md"}
            py={"2"}
            px={"6"}
            bg={"#F8F8F8"}
          >
            <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
              Автоматжуулалтын нэр:
            </Text>
            <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
              {location.state.e.previousVersion.name}
            </Text>
          </Stack>
          <Flex
            align={"center"}
            w="full"
            _before={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: color,
              flexGrow: 0.3,
              mr: { base: 4, md: 8 },
            }}
            _after={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: color,
              flexGrow: 1.7,
              ml: { base: 4, md: 8 },
            }}
          >
            <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
              Нөхцөл
            </Text>
          </Flex>
          <Grid
            templateColumns="repeat(1, 1fr)"
            gap={4}
            alignItems={"flex-start"}
            w={"full"}
          >
            {location.state.e.previousVersion.triggers.map((e, index) =>
              e.automationTriggerType === "time" ? (
                <VStack
                  key={index}
                  bg={"#F8F8F8"}
                  borderRadius={"lg"}
                  w={"full"}
                  py={"4"}
                  px={"6"}
                  spacing={1}
                >
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Төрөл:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.automationTriggerType}
                    </Text>
                  </HStack>
                  <Divider />
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Цаг:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.condition}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Өдөр:
                    </Text>
                    <Text
                      color={"#000"}
                      fontSize={14}
                      fontWeight={"medium"}
                      align={"end"}
                    >
                      {e.days.join(", ")}
                    </Text>
                  </HStack>
                </VStack>
              ) : e.automationTriggerType === "state" ? (
                <VStack
                  key={index}
                  bg={"#F8F8F8"}
                  borderRadius={"lg"}
                  w={"full"}
                  py={"4"}
                  px={"6"}
                  spacing={1}
                >
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Төрөл:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.automationTriggerType}
                    </Text>
                  </HStack>
                  <Divider />
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      ID:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e._id}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Нэр:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.device}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Утга:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.condition}
                    </Text>
                  </HStack>
                </VStack>
              ) : e.automationTriggerType === "sun" ? (
                <VStack
                  key={index}
                  bg={"#F8F8F8"}
                  borderRadius={"lg"}
                  w={"full"}
                  py={"4"}
                  px={"6"}
                  spacing={1}
                >
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Төрөл:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.automationTriggerType}
                    </Text>
                  </HStack>
                  <Divider />
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Утга:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.condition}
                    </Text>
                  </HStack>
                </VStack>
              ) : e.automationTriggerType === "temperature" ||
                e.automationTriggerType === "humidity" ? (
                <VStack
                  key={index}
                  bg={"#F8F8F8"}
                  borderRadius={"lg"}
                  w={"full"}
                  py={"4"}
                  px={"6"}
                  spacing={1}
                >
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Төрөл:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.automationTriggerType}
                    </Text>
                  </HStack>
                  <Divider />
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      ID:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e._id}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Нэр:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.device}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Утга:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.compareType} {e.condition}
                    </Text>
                  </HStack>
                </VStack>
              ) : e.automationTriggerType === "co" ? (
                <VStack
                  key={index}
                  bg={"#F8F8F8"}
                  borderRadius={"lg"}
                  w={"full"}
                  py={"4"}
                  px={"6"}
                  spacing={1}
                >
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Төрөл:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.automationTriggerType}
                    </Text>
                  </HStack>
                  <Divider />
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      ID:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e._id}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Нэр:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.device}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Утга:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.condition}
                    </Text>
                  </HStack>
                </VStack>
              ) : null
            )}
          </Grid>
          <VStack
            borderWidth={1}
            borderColor={"#EFEFEF"}
            w="full"
            borderRadius={"md"}
            bg={"#fff"}
            p={"6"}
            spacing={4}
          >
            <Flex
              align={"center"}
              w="full"
              _before={{
                content: '""',
                borderBottom: "1px solid",
                borderColor: color,
                flexGrow: 0.3,
                mr: { base: 4, md: 8 },
              }}
              _after={{
                content: '""',
                borderBottom: "1px solid",
                borderColor: color,
                flexGrow: 1.7,
                ml: { base: 4, md: 8 },
              }}
            >
              <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
                Үйлдэл
              </Text>
            </Flex>
            <TableContainer w="full">
              <Table>
                <Thead bg={"#F8F8F8"}>
                  <Tr>
                    <Th color={"#000"} fontSize={14} fontWeight={"medium"}>
                      ID
                    </Th>
                    <Th color={"#000"} fontSize={12} fontWeight={"medium"}>
                      Нэр
                    </Th>
                    <Th
                      color={"#000"}
                      fontSize={12}
                      fontWeight={"medium"}
                      isNumeric
                    >
                      комманд
                    </Th>
                  </Tr>
                </Thead>
                {location.state.e.previousVersion.actions.map((e, index) => (
                  <Tbody key={index} bg={index % 2 === 0 ? "white" : "#FCFCFC"}>
                    {e.actionType === "switch" ||
                    e.actionType === "knx_switch" ? (
                      <Tr>
                        <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                          {e._id}
                        </Td>
                        <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                          {e.device}
                        </Td>
                        <Td
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"normal"}
                          isNumeric
                        >
                          {e.command}
                        </Td>
                      </Tr>
                    ) : e.actionType === "delay" ? (
                      <Tr>
                        <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                          -
                        </Td>
                        <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                          {e.actionType}
                        </Td>
                        <Td
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"normal"}
                          isNumeric
                          align="right"
                        >
                          {e.time}&nbsp;цаг&nbsp;{e.minute}&nbsp;минут<br></br>
                          {e.second}&nbsp;секунт
                        </Td>
                      </Tr>
                    ) : null}
                  </Tbody>
                ))}
              </Table>
            </TableContainer>
          </VStack>
        </VStack>

        {/* --------------------------- Updated Version ----------------------------- */}
        <VStack
          boxShadow={"base"}
          borderRadius={"md"}
          p={"6"}
          w={{ base: "80", md: "2xl" }}
          bg={"#fff"}
          spacing={4}
        >
          <Flex
            align={"center"}
            w="full"
            _before={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: color,
              flexGrow: 1,
              mr: { base: 4, md: 8 },
            }}
            _after={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: color,
              flexGrow: 1,
              ml: { base: 4, md: 8 },
            }}
          >
            <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
              Засагдсан автоматжуулалт
            </Text>
          </Flex>

          <Stack
            direction={{ base: "column", md: "row" }}
            justify={"space-between"}
            w="full"
            borderWidth={1}
            borderColor={"#EFEFEF"}
            borderRadius={"md"}
            py={"2"}
            px={"6"}
            bg={"#F8F8F8"}
          >
            <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
              Автоматжуулалтын нэр:
            </Text>
            <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
              {location.state.e.updatedVersion.name}
            </Text>
          </Stack>
          <Flex
            align={"center"}
            w="full"
            _before={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: color,
              flexGrow: 0.3,
              mr: { base: 4, md: 8 },
            }}
            _after={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: color,
              flexGrow: 1.7,
              ml: { base: 4, md: 8 },
            }}
          >
            <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
              Нөхцөл
            </Text>
          </Flex>
          <Grid
            templateColumns="repeat(1, 1fr)"
            gap={4}
            alignItems={"flex-start"}
            w={"full"}
          >
            {location.state.e.updatedVersion.triggers.map((e, index) =>
              e.automationTriggerType === "time" ? (
                <VStack
                  key={index}
                  bg={"#F8F8F8"}
                  borderRadius={"lg"}
                  w={"full"}
                  py={"4"}
                  px={"6"}
                  spacing={1}
                >
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Төрөл:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.automationTriggerType}
                    </Text>
                  </HStack>
                  <Divider />
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Цаг:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.condition}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Өдөр:
                    </Text>
                    <Text
                      color={"#000"}
                      fontSize={14}
                      fontWeight={"medium"}
                      align={"end"}
                    >
                      {e.days.join(", ")}
                    </Text>
                  </HStack>
                </VStack>
              ) : e.automationTriggerType === "state" ? (
                <VStack
                  key={index}
                  bg={"#F8F8F8"}
                  borderRadius={"lg"}
                  w={"full"}
                  py={"4"}
                  px={"6"}
                  spacing={1}
                >
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Төрөл:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.automationTriggerType}
                    </Text>
                  </HStack>
                  <Divider />
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      ID:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e._id}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Нэр:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.device}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Утга:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.condition}
                    </Text>
                  </HStack>
                </VStack>
              ) : e.automationTriggerType === "sun" ? (
                <VStack
                  key={index}
                  bg={"#F8F8F8"}
                  borderRadius={"lg"}
                  w={"full"}
                  py={"4"}
                  px={"6"}
                  spacing={1}
                >
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Төрөл:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.automationTriggerType}
                    </Text>
                  </HStack>
                  <Divider />
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Утга:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.condition}
                    </Text>
                  </HStack>
                </VStack>
              ) : e.automationTriggerType === "temperature" ||
                e.automationTriggerType === "humidity" ? (
                <VStack
                  key={index}
                  bg={"#F8F8F8"}
                  borderRadius={"lg"}
                  w={"full"}
                  py={"4"}
                  px={"6"}
                  spacing={1}
                >
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Төрөл:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.automationTriggerType}
                    </Text>
                  </HStack>
                  <Divider />
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      ID:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e._id}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Нэр:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.device}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Утга:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.compareType} {e.condition}
                    </Text>
                  </HStack>
                </VStack>
              ) : e.automationTriggerType === "co" ? (
                <VStack
                  key={index}
                  bg={"#F8F8F8"}
                  borderRadius={"lg"}
                  w={"full"}
                  py={"4"}
                  px={"6"}
                  spacing={1}
                >
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Төрөл:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.automationTriggerType}
                    </Text>
                  </HStack>
                  <Divider />
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      ID:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e._id}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Нэр:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.device}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      Утга:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.condition}
                    </Text>
                  </HStack>
                </VStack>
              ) : null
            )}
          </Grid>
          <VStack
            borderWidth={1}
            borderColor={"#EFEFEF"}
            w="full"
            borderRadius={"md"}
            bg={"#fff"}
            p={"6"}
            spacing={4}
          >
            <Flex
              align={"center"}
              w="full"
              _before={{
                content: '""',
                borderBottom: "1px solid",
                borderColor: color,
                flexGrow: 0.3,
                mr: { base: 4, md: 8 },
              }}
              _after={{
                content: '""',
                borderBottom: "1px solid",
                borderColor: color,
                flexGrow: 1.7,
                ml: { base: 4, md: 8 },
              }}
            >
              <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
                Үйлдэл
              </Text>
            </Flex>
            <TableContainer w="full">
              <Table>
                <Thead bg={"#F8F8F8"}>
                  <Tr>
                    <Th color={"#000"} fontSize={14} fontWeight={"medium"}>
                      ID
                    </Th>
                    <Th color={"#000"} fontSize={12} fontWeight={"medium"}>
                      Нэр
                    </Th>
                    <Th
                      color={"#000"}
                      fontSize={12}
                      fontWeight={"medium"}
                      isNumeric
                    >
                      комманд
                    </Th>
                  </Tr>
                </Thead>
                {location.state.e.updatedVersion.actions.map((e, index) => (
                  <Tbody key={index} bg={index % 2 === 0 ? "white" : "#FCFCFC"}>
                    {e.actionType === "switch" ||
                    e.actionType === "knx_switch" ? (
                      <Tr>
                        <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                          {e._id}
                        </Td>
                        <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                          {e.device}
                        </Td>
                        <Td
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"normal"}
                          isNumeric
                        >
                          {e.command}
                        </Td>
                      </Tr>
                    ) : e.actionType === "delay" ? (
                      <Tr>
                        <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                          -
                        </Td>
                        <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                          {e.actionType}
                        </Td>
                        <Td
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"normal"}
                          isNumeric
                          align="right"
                        >
                          {e.time}&nbsp;цаг&nbsp;{e.minute}&nbsp;минут<br></br>
                          {e.second}&nbsp;секунт
                        </Td>
                      </Tr>
                    ) : null}
                  </Tbody>
                ))}
              </Table>
            </TableContainer>
          </VStack>
        </VStack>
      </Stack>
    </VStack>
  );
};

export default UpdateLogDetail;
