import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Skeleton,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "../../../axios";
import { FiEdit, FiInfo, FiPhone } from "react-icons/fi";
import BackButton from "../../../components/button/BackButton";

const Knx = () => {
  const [knxList, setKnxList] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [houses, setHouses] = useState([]);
  const [houseId, setHouseId] = useState("");
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "knx";

  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/knx_create", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log("Knx List ---", result.data.data);
        setKnxList(
          result.data.data.map((e) => {
            return {
              clientId: e.clientId,
              topic: e.topic,
              entityName: e.entityName,
              deviceType: e.deviceType,
              user: "",
              for: "",
              houseId: "",
            };
          })
        );
        setTrigger(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setTrigger(false);
        setIsLoading(false);
        if (err.response.status && err.response.status === 404) {
          setKnxList([]);
        }
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });

    return () => {
      cancel = true;
    };
  }, [trigger]);

  // --- input value ---
  const handleChange = (a, index) => {
    // setPhone(a);
    let source = axios.CancelToken.source();
    let cancel = false;

    const newInput = [...knxList];
    newInput[index].user = a;
    setKnxList(newInput);
    // --- get House ---
    if (a.split("").length === 8) {
      axios
        .get("admin/device_houses", {
          params: { username: a },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setHouses(result.data.data);
        })
        .catch((err) => {
          // console.log("error", err);
        });
      return () => {
        cancel = true;
      };
    } else {
      setHouses([]);
    }
  };

  // --- get Select ---
  const handleSelect = (e, index) => {
    setHouseId(e.target.value);
  };

  const nameHandler = (value, index) => {
    const newInput = [...knxList];
    newInput[index].for = value;
    setKnxList(newInput);
  };

  const handleSubmit = (value) => {
    if (value) {
      const data = {
        clientId: value.clientId,
        topic: value.topic,
        entityName: value.entityName,
        deviceType: value.deviceType,
        user: value.user,
        for: value.for,
        house: houseId,
      };
      // console.log("handle", data);
      axios
        .post("/admin/knx_create", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log("Done ---", result);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "success",
              duration: 2000,
              position: "top",
              description: "Амжилттай",
            });
          }
          setTrigger(true);
          // history.push("dashboard");
        })
        .catch((err) => {
          // console.log("error", err);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "error",
              duration: 2000,
              position: "top",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "full", md: "full", lg: "7xl" }}
    >
      <BackButton />
      {isLoading ? (
        <HStack spacing={"10"}>
          <Skeleton
            w={{ base: "xs", md: "xs", lg: "sm" }}
            h={"52"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            w={{ base: "xs", md: "xs", lg: "sm" }}
            display={{ base: "none", md: "none", lg: "flex" }}
            h={"52"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            w={{ base: "xs", md: "xs", lg: "sm" }}
            display={{ base: "none", md: "flex", lg: "flex" }}
            h={"52"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : knxList.length > 0 ? (
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(1, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          gap={{ base: 4, md: 4 }}
          w={"full"}
        >
          {knxList.map((e, index) => (
            <VStack
              key={index}
              w={{ base: "xs", md: "md" }}
              bg={"#fff"}
              p={"4"}
              shadow={"md"}
              rounded={"md"}
            >
              <HStack
                w={"full"}
                bg={"#EFEFEF"}
                borderWidth={1}
                borderColor={"#EFEFEF"}
                borderRadius={"md"}
                py={"2"}
                px={"4"}
                justify={"space-between"}
              >
                <Text color={"#000"} fontWeight={"normal"} fontSize={14}>
                  clientId:
                </Text>
                <Text color={"#000"} fontWeight={"medium"} fontSize={14}>
                  {e.clientId}
                </Text>
              </HStack>
              <HStack
                w={"full"}
                bg={"#EFEFEF"}
                borderWidth={1}
                borderColor={"#EFEFEF"}
                borderRadius={"md"}
                py={"2"}
                px={"4"}
                justify={"space-between"}
              >
                <Text color={"#000"} fontWeight={"normal"} fontSize={14}>
                  deviceType:
                </Text>
                <Text color={"#000"} fontWeight={"medium"} fontSize={14}>
                  {e.deviceType}
                </Text>
              </HStack>
              <HStack
                w={"full"}
                bg={"#EFEFEF"}
                borderWidth={1}
                borderColor={"#EFEFEF"}
                borderRadius={"md"}
                py={"2"}
                px={"4"}
                justify={"space-between"}
              >
                <Text color={"#000"} fontWeight={"normal"} fontSize={14}>
                  entityName:
                </Text>
                <Text color={"#000"} fontWeight={"medium"} fontSize={14}>
                  {e.entityName}
                </Text>
              </HStack>
              <HStack
                w={"full"}
                bg={"#EFEFEF"}
                borderWidth={1}
                borderColor={"#EFEFEF"}
                borderRadius={"md"}
                py={"2"}
                px={"4"}
                justify={"space-between"}
              >
                <Text color={"#000"} fontWeight={"normal"} fontSize={14}>
                  topic:
                </Text>
                <Text color={"#000"} fontWeight={"medium"} fontSize={14}>
                  {e.topic}
                </Text>
              </HStack>
              <Stack direction={{ base: "column", md: "row" }} w={"full"}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Icon as={FiPhone} color="#45A735" />}
                  />
                  <Input
                    variant="filled"
                    placeholder="Утасны дугаар"
                    onChange={(a) => handleChange(a.target.value, index)}
                    value={e.user}
                    bg={"#FCFCFC"}
                    borderColor={"#EFEFEF"}
                    borderWidth={1}
                    maxLength={8}
                    fontSize={"14"}
                    fontWeight={"normal"}
                    type={"tel"}
                    w={"full"}
                    _hover={{
                      borderColor: "#45A735",
                      borderWidth: 1,
                    }}
                    _focus={{
                      borderColor: "#45A735",
                      borderWidth: 1,
                    }}
                  />
                </InputGroup>

                <Select
                  isDisabled={e.user.length > 7 ? false : true}
                  placeholder="-Байшин сонгоно уу-"
                  onChange={(q) => handleSelect(q, index)}
                  multiple={false}
                  w={"full"}
                  fontSize={"14"}
                  fontWeight={"normal"}
                  _hover={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                  _focus={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                >
                  {houses.map((q, index) => (
                    <option key={index} value={q._id}>
                      {q.name}
                    </option>
                  ))}
                </Select>
              </Stack>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={FiEdit} color="#45A735" />}
                />
                <Input
                  variant="filled"
                  placeholder="Төхөөрөмжинд нэр өгөх"
                  value={e.for}
                  onChange={(a) => nameHandler(a.target.value, index)}
                  bg={"#FCFCFC"}
                  borderColor={"#EFEFEF"}
                  borderWidth={1}
                  fontSize={"14"}
                  fontWeight={"normal"}
                  type={"text"}
                  w={"full"}
                  _hover={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                  _focus={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                />
              </InputGroup>
              <Button
                w={"full"}
                bg={"#45A735"}
                fontSize={15}
                fontWeight={"normal"}
                color={"#fff"}
                _hover={{
                  bg: "#5AB54B",
                }}
                _focus={{
                  outline: "none",
                }}
                disabled={
                  e.user && houseId && e.for && houses.length > 0 ? false : true
                }
                onClick={() => handleSubmit(e)}
              >
                Хадгалах
              </Button>
            </VStack>
          ))}
        </Grid>
      ) : (
        <VStack
          justifyContent="center"
          alignItems="center"
          color="gray.600"
          w="full"
          pt={"52"}
        >
          <Icon as={FiInfo} w={7} h={7} />
          <Text fontSize={15}>Холбогдсон төхөөрөмж байхгүй байна.</Text>
        </VStack>
      )}
    </VStack>
  );
};

export default Knx;
