import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useToast,
  VStack,
  Center,
  Flex,
  Stack,
  Spinner,
  Divider,
  Checkbox,
} from "@chakra-ui/react";
import { FiInfo, FiSearch } from "react-icons/fi";
import { RiUserSearchLine } from "react-icons/ri";
import axios from "../../axios";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import ReactPaginate from "react-paginate";
import { useContext } from "react";
import UserContext from "../../UserContext";

const FindUser = () => {
  const [username, setUsername] = useState("");
  const [userData, setUserData] = useState([]);
  const toast = useToast();
  const id = "test-toast";
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allUser, setAllUser] = useState(true);
  const [noDeviceHide, setNoDeviceHide] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const token = Cookies.get("accessToken");
  const context = useContext(UserContext);

  // ------------- Search Handler -----------------
  const UserSearchHandler = () => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/user_search", {
        params: { startsWith: username },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log(result.data.data);
        if (noDeviceHide === true) {
          const data = result.data.data.filter((e) => {
            return e.devices !== 0;
          });
          setUserData(data);
        } else {
          setUserData(result.data.data);
        }
        setUsername("");
        setAllUser(false);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description:
              err.response.status === 404
                ? "Энэ дугаар бүртгэлгүй байна!"
                : "Алдаа гарлаа!",
          });
        }
        setIsLoading(false);
      });
    return () => {
      cancel = true;
    };
  };

  // ------------------ get User List initial --------------------
  useEffect(() => {
    // if (context.trigger) {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get(
        "/admin/user_list_initial",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
      .then((result) => {
        if (cancel) return;
        // console.log(result.data);
        if (noDeviceHide) {
          const data = result.data.userList.filter((e) => {
            return e.devices !== 0;
          });
          setUserList(data);
        } else {
          setUserList(result.data.userList);
        }
        setPageCount(Math.ceil(result.data.count / 20));
        setIsLoading(false);
        context.setTrigger(false);
        setTrigger(false);
      })
      .catch((err) => {
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
        // console.log(err);
        setIsLoading(false);
      });
    return () => {
      cancel = true;
    };
    // }
  }, [trigger]);

  // ------------------ get follow User List --------------------
  const receivedData = (e) => {
    const pageSelect = e.selected + 1;
    // setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get(
        "/admin/user_list_following",
        {
          params: {
            pageNumber: pageSelect,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
      .then((result) => {
        if (cancel) return;
        // console.log(result.data.userList)
        if (context.noDeviceHide) {
          const data = result.data.userList.filter((e) => {
            return e.devices !== 0;
          });
          setUserList(data);
        } else {
          setUserList(result.data.userList);
        }
        // setUserList(result.data.userList);
        setIsLoading(false);
      })
      .catch((err) => {
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
        setIsLoading(false);
      });
    return () => {
      cancel = true;
    };
  };

  const handlePageClick = (e) => {
    // console.log("select Page--", e);
    receivedData(e);
  };

  const checkHandler = () => {
    setTrigger(true);
    setNoDeviceHide(!noDeviceHide);
    // console.log('click')
  };

  return (
    <Stack
      direction={{ base: "column-reverse", md: "column-reverse", lg: "row" }}
      align={"flex-start"}
      spacing={"6"}
      w={{ base: "full", md: "full", lg: "6xl" }}
      mb={"10"}
      transition="all 1s ease-in-out"
      // bg={"red"}
    >
      <Stack w={{ base: "full", md: "2xl", lg: "5xl" }}>
        <VStack
          spacing={{ base: 3, md: 6 }}
          bg={"#F2F7F1"}
          p={{ base: "3", md: "6" }}
          rounded={"md"}
          w={"full"}
        >
          <Box
            w={"full"}
            bg="#fff"
            borderColor={"#45A735"}
            borderLeftWidth={"medium"}
            textAlign="start"
            pos="relative"
            py={"2"}
            px={"4"}
            shadow={"base"}
          >
            <Text color={"#000"} fontWeight={"medium"} fontSize={15}>
              Хэрэглэгчид
            </Text>
            <Flex
              p={2}
              w="max-content"
              color="white"
              bgGradient="linear(to-br, #2E931F, #45A735)"
              rounded="md"
              marginInline="auto"
              pos="absolute"
              right={6}
              top="-0.5rem"
              boxShadow="lg"
            >
              <Icon as={RiUserSearchLine} color="#fff" />
            </Flex>
          </Box>
          {isLoading ? (
            <Spinner
              thickness="2px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#45A735"
              size="lg"
            />
          ) : (
            <>
              {allUser ? (
                <>
                  <Grid
                    templateColumns={{
                      base: "repeat(2, 1fr)",
                      md: "repeat(4, 1fr)",
                      lg: "repeat(4, 1fr)",
                    }}
                    gap={{ base: 3, md: 4, lg: 4 }}
                    w={"full"}
                  >
                    {userList.map((e) => (
                      <Center
                        key={e._id}
                        w={{ base: "36", md: "36", lg: "full" }}
                        bg="#fff"
                        boxShadow={"base"}
                        py={"2"}
                        px={"3"}
                        borderRadius={"lg"}
                        color={"#000"}
                        _hover={{
                          // transform: "scale(1.04)",
                          // bg: "#45A735",
                          // color: "#fff",
                          boxShadow: "0px 0px 4px 0px green",
                        }}
                        onClick={() =>
                          history.push("/User/find/house-list", {
                            e,
                            username: e.username,
                          })
                        }
                        cursor="pointer"
                        flexDirection={"column"}
                      >
                        <HStack justify={"space-between"} w={"full"}>
                          <Text fontWeight={"normal"} fontSize={14}>
                            Нэр:
                          </Text>
                          <Text fontWeight={"medium"} fontSize={15}>
                            {e.username}
                          </Text>
                        </HStack>
                        <HStack justify={"space-between"} w={"full"}>
                          <Text fontWeight={"normal"} fontSize={14}>
                            Төхөөрөмж:
                          </Text>
                          <Text fontWeight={"medium"} fontSize={15}>
                            {e.devices}
                          </Text>
                        </HStack>
                      </Center>
                    ))}
                  </Grid>
                  <ReactPaginate
                    previousLabel={"< Prev"}
                    nextLabel={"Next >"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    // forcePage={currentPage}
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={4}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </>
              ) : (
                <>
                  {userData.length > 0 ? (
                    <Grid
                      templateColumns={{
                        base: "repeat(2, 1fr)",
                        md: "repeat(4, 1fr)",
                        lg: "repeat(4, 1fr)",
                      }}
                      gap={{ base: 3, md: 4, lg: 4 }}
                      w={"full"}
                    >
                      {userData.map((e) => (
                        <Center
                          key={e._id}
                          w={{ base: "36", md: "36", lg: "full" }}
                          bg="#fff"
                          color={"#000"}
                          boxShadow={"base"}
                          py={"2"}
                          px={"3"}
                          borderRadius={"lg"}
                          _hover={{
                            // transform: "scale(1.04)",
                            // bg: "#45A735",
                            // color: "#fff",
                            boxShadow: "0px 0px 4px 0px green",
                          }}
                          onClick={() =>
                            history.push("/User/find/house-list", {
                              e,
                              username: e.username,
                            })
                          }
                          cursor="pointer"
                          flexDirection={"column"}
                        >
                          <HStack justify={"space-between"} w={"full"}>
                            <Text fontWeight={"normal"} fontSize={14}>
                              Нэр:
                            </Text>
                            <Text fontWeight={"medium"} fontSize={15}>
                              {e.username}
                            </Text>
                          </HStack>
                          <HStack justify={"space-between"} w={"full"}>
                            <Text fontWeight={"normal"} fontSize={14}>
                              Төхөөрөмж:
                            </Text>
                            <Text fontWeight={"medium"} fontSize={15}>
                              {e.devices}
                            </Text>
                          </HStack>
                        </Center>
                      ))}
                    </Grid>
                  ) : (
                    <VStack
                      justifyContent="center"
                      alignItems="center"
                      color="gray.600"
                      w="full"
                      mt={"6"}
                      ml={{ base: "24", md: "28" }}
                    >
                      <Icon as={FiInfo} w={7} h={7} />
                      <Text fontSize={15}>Хэрэглэгч олдсонгүй.</Text>
                    </VStack>
                  )}
                </>
              )}
            </>
          )}
        </VStack>
      </Stack>
      <Stack
        w={{ base: "xs", md: "md" }}
        bg={"#fff"}
        rounded={"md"}
        shadow={"md"}
        py={"4"}
        px={{ base: "4", md: "6" }}
      >
        <VStack w={"full"} spacing={0} align={"flex-start"}>
          <Text fontSize={14} color="#000" fontWeight={"normal"}>
            Хэрэглэгчийн утасны дугаар:
          </Text>
          <HStack w={"full"}>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Icon as={FiSearch} color="gray.400" />}
              />
              <Input
                type="tel"
                placeholder="Утасны дугаар"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                maxLength={8}
                _hover={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
                _focus={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
                w={{ base: "full", md: "full", lg: "xs" }}
                bg={"#fff"}
                rounded={"md"}
                fontWeight={"normal"}
                fontSize={15}
                onKeyPress={(e) => {
                  if (username.length > 0) {
                    if (e.key === "Enter") {
                      UserSearchHandler();
                    }
                  }
                }}
              />
            </InputGroup>
          </HStack>
        </VStack>
        <HStack w={"full"}>
          <Center
            // w={{ base: "full", md: "24" }}
            w={"full"}
            py={"1"}
            borderRadius="md"
            boxShadow="md"
            bg={"#45A735"}
            cursor="pointer"
            onClick={() => setAllUser(true)}
            _hover={{
              // transform: "scale(1.02)",
              bg: "#5AB54B",
            }}
          >
            <Text fontSize={14} color={"#fff"} fontWeight={"normal"}>
              Бүгд
            </Text>
          </Center>
          <Button
            h="1.9rem"
            bg={"#45A735"}
            size={"sm"}
            w={"full"}
            color={"#fff"}
            _hover={{
              bg: "#5AB54B",
            }}
            _focus={{
              outline: "none",
            }}
            fontWeight={"normal"}
            fontSize={14}
            disabled={username.length > 0 ? false : true}
            onClick={UserSearchHandler}
          >
            Хайх
          </Button>
        </HStack>

        <Divider />
        <Checkbox
          colorScheme="green"
          size={"lg"}
          value={noDeviceHide}
          onChange={checkHandler}
          isChecked={noDeviceHide ? true : false}
          css={`
            > span:first-of-type {
              box-shadow: unset;
            }
          `}
        >
          <Text fontSize={14} color="#000" fontWeight={"normal"}>
            Төхөөрөмжгүй хэрэглэгч харуулахгүй
          </Text>
        </Checkbox>
      </Stack>
    </Stack>
  );
};

export default FindUser;
