import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  GridItem,
  HStack,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "../../axios";
import Cookies from "js-cookie";
import BackButton from "../../components/button/BackButton";

const DcDeviceDetail = () => {
  const location = useLocation();
  const history = useHistory();
  const [device, setDevice] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookies.get("accessToken");

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/admin/device_details", {
        params: {
          clientId: location.state.e.clientId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        // console.log(result.data.data);
        setDevice(result.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
  }, []);

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      w={{ base: "xs", md: "xl", lg: "8xl" }}
      mb={"10"}
    >
      <BackButton />
      {isLoading ? (
        <HStack alignSelf={"flex-start"} spacing={{ base: 0, md: "10" }}>
          <Skeleton
            w={{ base: "80", md: "72" }}
            h={"xs"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            display={{ base: "none", md: "flex" }}
            w={"72"}
            h={"xs"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            display={{ base: "none", md: "flex" }}
            w={"72"}
            h={"xs"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            display={{ base: "none", md: "flex" }}
            w={"72"}
            h={"xs"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : (
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap={{ base: 4, md: 6 }}
          w={{ base: "full", md: "full" }}
          alignSelf={"center"}
          alignItems={"center"}
        >
          {device
            ? device.map((e, index) => (
                <GridItem
                  key={index}
                  w="100%"
                  // w={"full"}
                  bg="#fff"
                  boxShadow={"md"}
                  py={"6"}
                  px={"8"}
                  borderRadius={"lg"}
                  borderTopWidth={"medium"}
                  borderTopColor={"#45A735"}
                >
                  <VStack
                    bg={"#fff"}
                    borderRadius={"md"}
                    align={"flex-start"}
                    spacing={4}
                    w={"full"}
                  >
                    <HStack justify={"space-between"} w={"full"}>
                      <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                        clientId:
                      </Text>
                      <Text fontSize={14} color="#000" fontWeight={"medium"}>
                        {e.clientId}
                      </Text>
                    </HStack>
                    <Divider />
                    <HStack justify={"space-between"} w={"full"}>
                      <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                        contractId:
                      </Text>
                      <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                        {e.contractId}
                      </Text>
                    </HStack>
                    <HStack justify={"space-between"} w={"full"}>
                      <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                        deviceType:
                      </Text>
                      <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                        {e.deviceType}
                      </Text>
                    </HStack>
                    <HStack justify={"space-between"} w={"full"}>
                      <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                        entityName:
                      </Text>
                      <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                        {e.entityName}
                      </Text>
                    </HStack>
                    <HStack
                      justify={"space-between"}
                      w={"full"}
                      align={"flex-start"}
                    >
                      <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                        for:
                      </Text>
                      <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                        {e.for}
                      </Text>
                    </HStack>
                    <HStack
                      justify={"space-between"}
                      w={"full"}
                      align={"flex-start"}
                    >
                      <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                        house:
                      </Text>
                      <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                        {e.house}
                      </Text>
                    </HStack>
                    <HStack
                      justify={"space-between"}
                      w={"full"}
                      align={"flex-start"}
                    >
                      <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                        room:
                      </Text>
                      <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                        {e.room ? e.room : "-"}
                      </Text>
                    </HStack>
                    <HStack justify={"space-between"} w={"full"}>
                      <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                        status:
                      </Text>
                      <Text
                        fontSize={14}
                        color={e.status === "active" ? "#000CFF" : "#ff0000"}
                        fontWeight={"medium"}
                      >
                        {e.status}
                      </Text>
                    </HStack>
                    <HStack justify={"space-between"} w={"full"}>
                      <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                        ID:
                      </Text>
                      <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                        {e._id}
                      </Text>
                    </HStack>
                  </VStack>
                </GridItem>
              ))
            : null}
        </Grid>
      )}
    </VStack>
  );
};

export default DcDeviceDetail;
