import React, { useState } from "react";
import {
  Button,
  Center,
  Flex,
  Grid,
  HStack,
  Icon,
  Input,
  Skeleton,
  Spinner,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "../../../../axios";
import { FiInfo } from "react-icons/fi";
import { useHistory } from "react-router-dom";

const SearchDevice = () => {
  const [cliendId, setClientId] = useState("");
  const [devices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [checkData, setCheckData] = useState(false);
  const token = Cookies.get("accessToken");
  const user = Cookies.get("username");
  const toast = useToast();
  const id = "test";
  const history = useHistory();

  const searchHandler = () => {
    setCheckData(false);
    if (cliendId) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .post(
          "/admin/search_clientid",
          {
            clientId: cliendId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          if (cancel) return;
          // console.log(result.data.data);
          setDevices(result.data.data);
          setIsLoading(false);
          setCheckData(true);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
          setIsLoading(false);
        });
      return () => {
        cancel = true;
      };
    }
  };

  const masterHandler = () => {
    let source = axios.CancelToken.source();
    axios
      .get("/admin/master_password", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        // console.log("Master ---", result.data);
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      w={{ base: "full", md: "full", lg: "8xl" }}
      mb={"10"}
    >
      <HStack w={"full"} align={"center"} justify={"space-between"}>
        <VStack
          bg={"#fff"}
          shadow={"md"}
          rounded={"lg"}
          p={"4"}
          w={{ base: "xs", md: "lg" }}
          borderTopColor={"#45A735"}
          borderTopWidth={"medium"}
        >
          <Flex
            align={"center"}
            w="full"
            _before={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: "#45A735",
              flexGrow: 0.3,
              mr: { base: 4, md: 8 },
            }}
            _after={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: "#45A735",
              flexGrow: 1.7,
              ml: { base: 4, md: 8 },
            }}
          >
            <Text
              color={"#000"}
              fontSize={15}
              fontWeight={"medium"}
              letterSpacing={0.3}
            >
              Төхөөрөмж хайх
            </Text>
          </Flex>
          <Stack direction={{ base: "column", md: "row" }}>
            <Input
              w={"full"}
              fontSize={14}
              placeholder={"clientId оруулна уу!"}
              type={"text"}
              value={cliendId}
              onChange={(e) => setClientId(e.target.value)}
              _hover={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
              _focus={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
              onKeyPress={(e) => {
                if (cliendId) {
                  if (e.key === "Enter") {
                    searchHandler();
                  }
                }
              }}
            />
            <Button
              bg={"#45A735"}
              rounded={"md"}
              w={{ base: "72", md: "xs" }}
              onClick={searchHandler}
              disabled={isLoading ? true : false}
              _hover={{
                bg: "#5AB54B",
              }}
              _focus={{
                outline: "none",
              }}
            >
              {isLoading ? <Spinner mr={2} size="sm" color="#fff" /> : null}
              <Text fontSize={15} color={"#fff"} fontWeight={"normal"}>
                Хайх
              </Text>
            </Button>
          </Stack>
        </VStack>
        {user === "toomoo" ? (
          <Button
            w={"sm"}
            onClick={() => masterHandler()}
            _focus={{
              outline: "none",
            }}
          >
            Click
          </Button>
        ) : null}
      </HStack>
      {isLoading ? (
        <HStack spacing={{ base: "2", md: "10" }}>
          <Skeleton
            w={{ base: "xs", md: "2xs" }}
            h={"10"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            w={{ base: "40", md: "2xs" }}
            display={{ base: "none", md: "flex", lg: "flex" }}
            h={"10"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            display={{ base: "none", md: "none", lg: "flex" }}
            w={"2xs"}
            h={"10"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : (
        <Stack>
          {devices.length === 0 && checkData ? (
            <VStack
              justifyContent="center"
              alignItems="center"
              color="gray.600"
              w="full"
              mt={"6"}
              ml={{ base: "24", md: "28" }}
            >
              <Icon as={FiInfo} w={7} h={7} />
              <Text fontSize={15} fontWeight={"normal"}>
                Мэдээлэл олдсонгүй.
              </Text>
            </VStack>
          ) : devices.length > 0 ? (
            <VStack
              bg="#fff"
              shadow={"md"}
              rounded={"md"}
              p={"4"}
              borderTopWidth={"medium"}
              borderColor={"#45A735"}
              spacing={4}
              w={{ base: "xs", md: "full" }}
            >
              <Flex
                align={"center"}
                w="full"
                _before={{
                  content: '""',
                  borderBottom: "1px solid",
                  borderColor: "#45A735",
                  flexGrow: 0.3,
                  mr: { base: 4, md: 8 },
                }}
                _after={{
                  content: '""',
                  borderBottom: "1px solid",
                  borderColor: "#45A735",
                  flexGrow: 1.7,
                  ml: { base: 4, md: 8 },
                }}
              >
                <Text
                  color={"#000"}
                  fontSize={15}
                  fontWeight={"medium"}
                  letterSpacing={0.3}
                >
                  Төхөөрөмжийн жагсаалт
                </Text>
              </Flex>
              <Grid
                templateColumns={{
                  base: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                  lg: "repeat(6, 1fr)",
                }}
                gap={{ base: 2, md: 5 }}
                w={{ base: "full", md: "full" }}
                alignSelf={"center"}
                alignItems={"center"}
              >
                {devices.map((e, index) => (
                  <Center
                    key={index}
                    w={{ base: "36", md: "40" }}
                    bg="#f3f3f3"
                    // boxShadow={"base"}
                    py={"2"}
                    borderRadius={"lg"}
                    _hover={{
                      transform: "scale(1.06)",
                    }}
                    onClick={() =>
                      history.push("/device/search/device-detail", {
                        e,
                      })
                    }
                    cursor="pointer"
                  >
                    <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                      {e}
                    </Text>
                  </Center>
                ))}
              </Grid>
            </VStack>
          ) : null}
        </Stack>
      )}
    </VStack>
  );
};

export default SearchDevice;
