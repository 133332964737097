import React, { useEffect, useState } from "react";
import {
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  Grid,
  HStack,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "../../axios";
import Cookies from "js-cookie";
import { useHistory, useLocation } from "react-router-dom";
import { FiInfo, FiTrash2 } from "react-icons/fi";
import { RiShareForward2Fill } from "react-icons/ri";
import BackButton from "../../components/button/BackButton";

const SharedDeviceList = () => {
  const [trigger, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [selectData, setSelectData] = useState([]);
  const toast = useToast();
  const id = "test-toast";
  const history = useHistory();
  const location = useLocation();
  const token = Cookies.get("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();

  // --- get Device List---
  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/remote_api_service/account_device", {
        params: { remoteUser: location.state.username },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log(result.data.data);
        setDeviceList(result.data.data);
        setTrigger(false);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
        setIsLoading(false);
      });
    return () => {
      cancel = true;
    };
  }, [trigger]);

  const selectHandler = (e) => {
    // console.log("_id shdee ", e);
    if (selectData.find((a) => a === e)) {
      setSelectData((prevState) => prevState.filter((r) => r !== e));
    } else {
      const select = [...selectData];
      const allSelect = select.concat(e);
      setSelectData(allSelect);
    }
  };

  // ----------------- DElETE alert --------------------
  const deleteHandler = () => {
    // console.log("DElETE data ---", selectData);
    onClose();
    axios
      .delete("/admin/remote_api_service/account_device", {
        data: {
          devicesToBeRemoved: selectData,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setTrigger(true);
        setSelectData([]);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "success",
            description: "Амжилттай устлаа!",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "xs", md: "2xl", lg: "8xl" }}
    >
      <HStack w={"full"} justify={"space-between"}>
        <BackButton />
        <Center
          py={{ base: "1", md: "1.5" }}
          px={{ base: "4", md: "8" }}
          borderRadius="md"
          bg={"#45A735"}
          cursor="pointer"
          boxShadow={"md"}
          _hover={{
            bg: "#5AB54B",
          }}
          onClick={() =>
            history.push("/api/share-device", {
              username: location.state.username,
            })
          }
        >
          <Icon
            as={RiShareForward2Fill}
            color={"#fff"}
            w={{ base: 4, md: 5 }}
            h={{ base: 4, md: 5 }}
          />
          <Text fontSize={14} color={"#fff"} fontWeight={"normal"} mx={"1"}>
            Төхөөрөмж хуваалцах
          </Text>
        </Center>
      </HStack>
      <Stack direction={{ base: "column", md: "row" }} w={"full"}>
        <Flex
          align={"center"}
          w="full"
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 0.3,
            mr: { base: 4, md: 8 },
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 1.7,
            ml: { base: 4, md: 8 },
          }}
        >
          <Text
            color={"#000"}
            fontSize={15}
            fontWeight={"medium"}
            letterSpacing={0.3}
          >
            Хуваалцсан төхөөрөмжийн жагсаалт
          </Text>
        </Flex>
        {selectData.length > 0 ? (
          <Center
            onClick={() => onOpen()}
            shadow={"sm"}
            px={"5"}
            py={"0.5"}
            bg={"#ff0000"}
            rounded={"md"}
            cursor={"pointer"}
          >
            <Icon as={FiTrash2} w={"4"} h={"4"} color={"#fff"} mr={"1"} />
            <Text fontSize={"14"} color={"#fff"} fontWeight={"medium"}>
              Устгах
            </Text>
          </Center>
        ) : null}
      </Stack>

      <Grid
        transition="0.5s ease"
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(4, 1fr)",
        }}
        gap={{ base: 2, md: 4, lg: 4 }}
        w={"full"}
      >
        {isLoading ? (
          <HStack alignSelf={"flex-start"} spacing={{ base: 0, md: "10" }}>
            <Skeleton
              w={{ base: "80", md: "80" }}
              h={"xs"}
              borderRadius={"md"}
              startColor="blackAlpha.100"
              endColor="blackAlpha.300"
            />
            <Skeleton
              display={{ base: "none", md: "flex", lg: "flex" }}
              w={"80"}
              h={"xs"}
              borderRadius={"md"}
              startColor="blackAlpha.100"
              endColor="blackAlpha.300"
            />
            <Skeleton
              display={{ base: "none", md: "none", lg: "flex" }}
              w={"80"}
              h={"xs"}
              borderRadius={"md"}
              startColor="blackAlpha.100"
              endColor="blackAlpha.300"
            />
            <Skeleton
              display={{ base: "none", md: "none", lg: "flex" }}
              w={"80"}
              h={"xs"}
              borderRadius={"md"}
              startColor="blackAlpha.100"
              endColor="blackAlpha.300"
            />
          </HStack>
        ) : deviceList.length > 0 ? (
          deviceList.map((e, index) => (
            <VStack
              key={index}
              w={"full"}
              bg={"#fff"}
              rounded={"md"}
              shadow={"md"}
              p={"4"}
              borderTopWidth={"medium"}
              borderColor={"#45A735"}
            >
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  origin:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.origin}
                </Text>
              </HStack>
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  clientId:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.clientId}
                </Text>
              </HStack>
              <Divider />
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  deviceType:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.deviceType}
                </Text>
              </HStack>
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  entityName:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.entityName}
                </Text>
              </HStack>
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  deviceName:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.deviceName}
                </Text>
              </HStack>
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  id:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e._id}
                </Text>
              </HStack>
              <Checkbox
                colorScheme="red"
                size={"lg"}
                alignSelf={"flex-start"}
                value={selectData}
                onChange={() => selectHandler(e._id)}
                isChecked={selectData.find((a) => a === e._id) ? true : false}
                css={`
                  > span:first-of-type {
                    box-shadow: unset;
                  }
                `}
              >
                <Text fontSize={"15"} color="#ff0000" fontWeight={"medium"}>
                  Устгах
                </Text>
              </Checkbox>
            </VStack>
          ))
        ) : (
          <VStack
            justifyContent="center"
            alignItems="center"
            color="gray.600"
            w={{ base: "xs", md: "2xl", lg: "8xl" }}
            pt={{ base: "6", md: "12", lg: "24" }}
            spacing={1}
          >
            <Icon as={FiInfo} w={7} h={7} />
            <Text fontSize={15} fontWeight={"normal"}>
              Мэдээлэл олдсонгүй.
            </Text>
          </VStack>
        )}
      </Grid>
      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              _focus={{ outline: "none" }}
              onClick={onClose}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              px={"8"}
              _focus={{ outline: "none" }}
              onClick={() => deleteHandler()}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default SharedDeviceList;
