import {
  Flex,
  SkeletonText,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "../../../axios";
import { useState } from "react";
import BackButton from "../../../components/button/BackButton";

const TtrUpdateLogDetail = () => {
  const [detail, setDetail] = useState({});
  const location = useLocation();
  const history = useHistory();
  const token = Cookies.get("accessToken");

  const color = useColorModeValue("green.200", "green.700");

  useEffect(() => {
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get(
        "/admin/user_taptorun_updatelog_detail",
        {
          params: {
            _id: location.state.id,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
      .then((result) => {
        if (cancel) return;
        // console.log(result.data);
        setDetail(result.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      cancel = true;
    };
  }, []);

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "full", md: "xl", lg: "8xl" }}
    >
      <BackButton />

      {detail ? (
        <VStack>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={"space-between"}
            w="full"
            borderLeftWidth={"medium"}
            borderLeftColor={"#45A735"}
            borderTopRightRadius={"md"}
            borderBottomRightRadius={"md"}
            py={"3"}
            px={"6"}
            bg={"#fff"}
            shadow={"base"}
            spacing={1}
          >
            <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
              Багц үйлдлийн ID:
            </Text>
            {detail.tapToRunId ? (
              <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                {detail.tapToRunId}
              </Text>
            ) : (
              <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                -
              </Text>
            )}
          </Stack>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={"space-between"}
            w="full"
            borderLeftWidth={"medium"}
            borderLeftColor={"#45A735"}
            borderTopRightRadius={"md"}
            borderBottomRightRadius={"md"}
            py={"3"}
            px={"6"}
            bg={"#fff"}
            shadow={"base"}
            spacing={1}
          >
            <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
              Засагдсан цаг:
            </Text>
            {detail.updatedAt ? (
              <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                {detail.updatedAt.slice(0, 10)} /{" "}
                {detail.updatedAt.slice(11, 19)}
              </Text>
            ) : (
              <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                -
              </Text>
            )}
          </Stack>
          <Stack
            direction={{ base: "column", md: "column", lg: "row" }}
            align={"flex-start"}
          >
            {/* --------------------------- previous Version ----------------------------- */}
            <VStack
              boxShadow={"base"}
              borderRadius={"md"}
              p={"6"}
              w={{ base: "80", md: "2xl" }}
              bg={"#fff"}
              spacing={4}
            >
              <Flex
                align={"center"}
                w="full"
                _before={{
                  content: '""',
                  borderBottom: "1px solid",
                  borderColor: color,
                  flexGrow: 1,
                  mr: { base: 4, md: 8 },
                }}
                _after={{
                  content: '""',
                  borderBottom: "1px solid",
                  borderColor: color,
                  flexGrow: 1,
                  ml: { base: 4, md: 8 },
                }}
              >
                <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
                  Хуучин багц үйлдэл
                </Text>
              </Flex>
              <Stack
                direction={{ base: "column", md: "row" }}
                justify={"space-between"}
                w="full"
                borderWidth={1}
                borderColor={"#EFEFEF"}
                borderRadius={"md"}
                py={"2"}
                px={"6"}
                bg={"#F8F8F8"}
                spacing={1}
              >
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  Багц үйлдлийн нэр:
                </Text>
                {detail.previous ? (
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {detail.previous.name}
                  </Text>
                ) : (
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    -
                  </Text>
                )}
              </Stack>
              <VStack
                borderWidth={1}
                borderColor={"#EFEFEF"}
                w="full"
                borderRadius={"md"}
                bg={"#fff"}
                p={"6"}
                spacing={4}
              >
                <Flex
                  align={"center"}
                  w="full"
                  _before={{
                    content: '""',
                    borderBottom: "1px solid",
                    borderColor: color,
                    flexGrow: 0.3,
                    mr: { base: 4, md: 8 },
                  }}
                  _after={{
                    content: '""',
                    borderBottom: "1px solid",
                    borderColor: color,
                    flexGrow: 1.7,
                    ml: { base: 4, md: 8 },
                  }}
                >
                  <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
                    Үйлдэл
                  </Text>
                </Flex>
                <TableContainer
                  w="full"
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "4px",
                      height: "6px",
                    },
                    "&::-webkit-scrollbar-track": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#45A735",
                      borderRadius: "24px",
                    },
                  }}
                >
                  <Table>
                    <Thead bg={"#F8F8F8"}>
                      <Tr>
                        <Th color={"#000"} fontSize={14} fontWeight={"medium"}>
                          ID
                        </Th>
                        <Th color={"#000"} fontSize={12} fontWeight={"medium"}>
                          Нэр
                        </Th>
                        <Th color={"#000"} fontSize={12} fontWeight={"medium"}>
                          Өрөө
                        </Th>
                        <Th color={"#000"} fontSize={12} fontWeight={"medium"}>
                          Төрөл
                        </Th>
                        <Th
                          color={"#000"}
                          fontSize={12}
                          fontWeight={"medium"}
                          isNumeric
                        >
                          комманд
                        </Th>
                      </Tr>
                    </Thead>
                    {detail.previous ? (
                      detail.previous.actions.map((e, index) => (
                        <Tbody
                          key={index}
                          bg={index % 2 === 0 ? "white" : "#FCFCFC"}
                        >
                          <Tr>
                            <Td
                              color={"#000"}
                              fontSize={14}
                              fontWeight={"normal"}
                            >
                              {e._id}
                            </Td>
                            <Td
                              color={"#000"}
                              fontSize={14}
                              fontWeight={"normal"}
                            >
                              {e.for ? e.for : e.name}
                            </Td>
                            <Td
                              color={"#000"}
                              fontSize={14}
                              fontWeight={"normal"}
                            >
                              {e.room ? e.room : "-"}
                            </Td>

                            <Td
                              color={"#000"}
                              fontSize={14}
                              fontWeight={"normal"}
                            >
                              {e.type}
                            </Td>
                            <Td
                              color={"#000"}
                              fontSize={14}
                              fontWeight={"normal"}
                              isNumeric
                            >
                              {e.actionType} - {e.command}
                            </Td>
                          </Tr>
                        </Tbody>
                      ))
                    ) : (
                      <Tbody>
                        <Tr>
                          <Td>
                            <SkeletonText noOfLines={1} />
                          </Td>
                          <Td>
                            <SkeletonText noOfLines={1} />
                          </Td>
                          <Td>
                            <SkeletonText noOfLines={1} />
                          </Td>
                          <Td>
                            <SkeletonText noOfLines={1} />
                          </Td>
                          <Td isNumeric>
                            <SkeletonText noOfLines={1} />
                          </Td>
                        </Tr>
                      </Tbody>
                    )}
                  </Table>
                </TableContainer>
              </VStack>
            </VStack>

            {/* --------------------------- Updated Version ----------------------------- */}
            <VStack
              boxShadow={"base"}
              borderRadius={"md"}
              p={"6"}
              w={{ base: "80", md: "2xl" }}
              bg={"#fff"}
              spacing={4}
            >
              <Flex
                align={"center"}
                w="full"
                _before={{
                  content: '""',
                  borderBottom: "1px solid",
                  borderColor: color,
                  flexGrow: 1,
                  mr: { base: 4, md: 8 },
                }}
                _after={{
                  content: '""',
                  borderBottom: "1px solid",
                  borderColor: color,
                  flexGrow: 1,
                  ml: { base: 4, md: 8 },
                }}
              >
                <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
                  Засагдсан багц үйлдэл
                </Text>
              </Flex>

              <Stack
                direction={{ base: "column", md: "row" }}
                justify={"space-between"}
                w="full"
                borderWidth={1}
                borderColor={"#EFEFEF"}
                borderRadius={"md"}
                py={"2"}
                px={"6"}
                bg={"#F8F8F8"}
                spacing={1}
              >
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  Багц үйлдлийн нэр:
                </Text>
                {detail.updated ? (
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {detail.previous.name}
                  </Text>
                ) : (
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    -
                  </Text>
                )}
              </Stack>
              <VStack
                borderWidth={1}
                borderColor={"#EFEFEF"}
                w="full"
                borderRadius={"md"}
                bg={"#fff"}
                p={"6"}
                spacing={4}
              >
                <Flex
                  align={"center"}
                  w="full"
                  _before={{
                    content: '""',
                    borderBottom: "1px solid",
                    borderColor: color,
                    flexGrow: 0.3,
                    mr: { base: 4, md: 8 },
                  }}
                  _after={{
                    content: '""',
                    borderBottom: "1px solid",
                    borderColor: color,
                    flexGrow: 1.7,
                    ml: { base: 4, md: 8 },
                  }}
                >
                  <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
                    Үйлдэл
                  </Text>
                </Flex>
                <TableContainer
                  w="full"
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "4px",
                      height: "6px",
                    },
                    "&::-webkit-scrollbar-track": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#45A735",
                      borderRadius: "24px",
                    },
                  }}
                >
                  <Table>
                    <Thead bg={"#F8F8F8"}>
                      <Tr>
                        <Th color={"#000"} fontSize={14} fontWeight={"medium"}>
                          ID
                        </Th>
                        <Th color={"#000"} fontSize={12} fontWeight={"medium"}>
                          Нэр
                        </Th>
                        <Th color={"#000"} fontSize={12} fontWeight={"medium"}>
                          Өрөө
                        </Th>
                        <Th color={"#000"} fontSize={12} fontWeight={"medium"}>
                          Төрөл
                        </Th>
                        <Th
                          color={"#000"}
                          fontSize={12}
                          fontWeight={"medium"}
                          isNumeric
                        >
                          комманд
                        </Th>
                      </Tr>
                    </Thead>
                    {detail.updated ? (
                      detail.updated.actions.map((e, index) => (
                        <Tbody
                          key={index}
                          bg={index % 2 === 0 ? "white" : "#FCFCFC"}
                        >
                          <Tr>
                            <Td
                              color={"#000"}
                              fontSize={14}
                              fontWeight={"normal"}
                            >
                              {e._id}
                            </Td>
                            <Td
                              color={"#000"}
                              fontSize={14}
                              fontWeight={"normal"}
                            >
                              {e.for ? e.for : e.name}
                            </Td>
                            <Td
                              color={"#000"}
                              fontSize={14}
                              fontWeight={"normal"}
                            >
                              {e.room ? e.room : "-"}
                            </Td>

                            <Td
                              color={"#000"}
                              fontSize={14}
                              fontWeight={"normal"}
                            >
                              {e.type}
                            </Td>
                            <Td
                              color={"#000"}
                              fontSize={14}
                              fontWeight={"normal"}
                              isNumeric
                            >
                              {e.actionType} - {e.command}
                            </Td>
                          </Tr>
                        </Tbody>
                      ))
                    ) : (
                      <Tbody>
                        <Tr>
                          <Td>
                            <SkeletonText noOfLines={1} />
                          </Td>
                          <Td>
                            <SkeletonText noOfLines={1} />
                          </Td>
                          <Td>
                            <SkeletonText noOfLines={1} />
                          </Td>
                          <Td>
                            <SkeletonText noOfLines={1} />
                          </Td>
                          <Td isNumeric>
                            <SkeletonText noOfLines={1} />
                          </Td>
                        </Tr>
                      </Tbody>
                    )}
                  </Table>
                </TableContainer>
              </VStack>
            </VStack>
          </Stack>
        </VStack>
      ) : null}
    </VStack>
  );
};

export default TtrUpdateLogDetail;
