import React from "react";
import {
  Button,
  Divider,
  HStack,
  Icon,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "../../../axios";
import Cookies from "js-cookie";
import ComplainCard from "../../../components/complain/ComplainCard";
import { FiInfo, FiPlus } from "react-icons/fi";
import ReactPaginate from "react-paginate";
import CreateModal from "../../../components/complain/CreateModal";

const Complain = () => {
  const [complain, setComplain] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [trigger, setTrigger] = useState(true);
  const token = Cookies.get("accessToken");

  useEffect(() => {
    if (trigger) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/admin/complain", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          // console.log("result-->", result.data);
          setComplain(result.data.data);
          setPageCount(result.data.numberOfComplains / 10);
          setIsLoading(false);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log("get complain err", err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
      };
    }
  }, [trigger]);

  const handlePageClick = (e) => {
    // console.log("select Page--", e.selected + 1);
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("admin/get_more_complain", {
        params: {
          page: e.selected + 1,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log("result-->", result.data);
        setComplain(result.data.data);
        setIsLoading(false);
        setTrigger(false);
      })
      .catch((err) => {
        // console.log("get complain err", err);
        setIsLoading(false);
      });
    return () => {
      cancel = true;
    };
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"6"}
      w={{ base: "xs", md: "2xl", lg: "6xl" }}
    >
      <CreateModal
        showModal={showModal}
        setShowModal={setShowModal}
        setTrigger={setTrigger}
      />
      <VStack bg={"#fff"} w={"full"} shadow={"md"} rounded={"md"}>
        <Stack
          direction={["column", "column", "row", "row", "row"]}
          justify={"space-between"}
          align={"center"}
          w={"full"}
          pt={"4"}
          px={"4"}
          pb={"2"}
        >
          <Button
            leftIcon={<Icon as={FiPlus} w={5} h={5} />}
            fontWeight={"normal"}
            fontSize={15}
            w={{ base: "full", md: "32" }}
            size={"sm"}
            bg={"#45A735"}
            color={"#fff"}
            _hover={{
              bg: "#5AB54B",
            }}
            _focus={{
              outline: "none",
            }}
            onClick={() => setShowModal(true)}
          >
            Үүсгэх
          </Button>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(pageCount)}
            onPageChange={handlePageClick}
            marginPagesDisplayed={2}
            pageRangeDisplayed={4}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </Stack>
        <Divider />
        <VStack w={"full"} p={"4"}>
          {isLoading ? (
            <Spinner
              thickness="2px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#45A735"
              size="lg"
            />
          ) : complain.length > 0 ? (
            <VStack w={"full"} spacing={6}>
              {complain.map((e, index) => (
                <ComplainCard
                  key={index}
                  id={e._id}
                  title={e.title}
                  desc={e.text}
                  resolved={e.resolved}
                  comment={e.countComments}
                  time={e.atTime}
                  admin={e.admin}
                  keyData={index}
                  setTrigger={setTrigger}
                />
              ))}
            </VStack>
          ) : (
            <VStack
              justifyContent="center"
              alignItems="center"
              color="gray.600"
              w="full"
            >
              <Icon as={FiInfo} w={7} h={7} />
              <Text fontSize={15}>Мэдээлэл олдсонгүй</Text>
            </VStack>
          )}
        </VStack>
      </VStack>
    </VStack>
  );
};

export default Complain;
