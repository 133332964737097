import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Icon,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  useDisclosure,
  VStack,
  useToast,
  HStack,
  Skeleton,
  Center,
  Flex,
  keyframes,
} from "@chakra-ui/react";
import axios from "../../../axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { FiInfo } from "react-icons/fi";
import minus from "../../../Images/minus.png";
import deleteIcon from "../../../Images/delete.png";
import { motion } from "framer-motion";
import BackButton from "../../../components/button/BackButton";

const AddDevice = () => {
  const [deviceList, setDeviceList] = useState([]);
  const [showMinus, setShowMinus] = useState(1);
  const [clientId, setClientId] = useState();
  const [trigger, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();
  const toast = useToast();
  const id = "test-toast";
  const token = Cookies.get("accessToken");

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("admin/devicenew", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log("List ---", result.data.data);
        setDeviceList(result.data.data);
        setTrigger(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setTrigger(false);
        setIsLoading(false);
        if (err.response.status && err.response.status === 404) {
          setDeviceList([]);
        }
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });

    return () => {
      cancel = true;
    };
  }, [trigger]);

  const deleteHandler = (value) => {
    onClose();
    axios
      .delete("/admin/uncreated_devices", {
        data: {
          clientId: value,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setTrigger(true);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "success",
            description: "Амжилттай устлаа!",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  const deleteModal = (e) => {
    setClientId(e);
    onOpen();
  };

  const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); }
  30% { transform: scale(1.02) rotate(-25deg); }
  60% { transform: scale(1) rotate(25deg); }
  100% { transform: scale(1) rotate(0); }
`;

  const animation = `${animationKeyframes} 0.8s ease`;

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      // bg={"red"}
      w={{ base: "full", md: "xl", lg: "8xl" }}
      mb={"10"}
    >
      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          <ModalBody>{clientId}</ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} px={"8"} onClick={onClose}>
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              px={"8"}
              onClick={() => deleteHandler(clientId)}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <BackButton />
      {deviceList.length > 0 ? (
        <Flex
          align={"center"}
          w="full"
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 1.8,
            mr: { base: 4, md: 8 },
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 0.2,
            ml: { base: 4, md: 8 },
          }}
        >
          <Center
            as={motion.div}
            bg={"#fff"}
            rounded="md"
            p={"3"}
            boxShadow={"md"}
            borderTopWidth={"medium"}
            borderColor="#45A735"
            onClick={() => setShowMinus((e) => e + 1)}
            cursor="pointer"
            _hover={{ animation: animation }}
          >
            <Image src={deleteIcon} objectFit="contain" w={"4"} h={"4"} />
          </Center>
        </Flex>
      ) : null}

      {!isLoading ? (
        deviceList.length > 0 ? (
          <Grid
            templateColumns={{
              base: "repeat(2, 1fr)",
              md: "repeat(4, 1fr)",
              lg: "repeat(8, 1fr)",
            }}
            gap={6}
          >
            {deviceList.map((e, index) => (
              <Button
                key={index}
                bg={"#fff"}
                boxShadow={"md"}
                _hover={{
                  transform: showMinus % 2 === 0 ? "scale(1)" : "scale(1.06)",
                  transition: "0s ease",
                  // bg: "#F8F8F8",
                }}
                onClick={() => {
                  showMinus % 2 === 0
                    ? deleteModal(e)
                    : history.push("/device/type/add-device/device-detail", {
                        clientId: e,
                      });
                }}
                // disabled={showMinus % 2 === 0 ? true : false}
                _disabled={{
                  bg: "#fff",
                }}
                _focus={{
                  outline: "none",
                }}
              >
                <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
                  {e}
                </Text>
                {showMinus % 2 === 0 ? (
                  <Image
                    src={minus}
                    objectFit="contain"
                    w={"4"}
                    h={"4"}
                    ml={"2"}
                  />
                ) : null}
              </Button>
            ))}
          </Grid>
        ) : (
          <VStack
            justifyContent="center"
            alignItems="center"
            color="gray.600"
            w="full"
            pt={"52"}
          >
            <Icon as={FiInfo} w={7} h={7} />
            <Text fontSize={15}>Холбогдсон төхөөрөмж байхгүй байна.</Text>
          </VStack>
        )
      ) : (
        <HStack spacing={"10"}>
          <Skeleton
            w={{ base: "xs", md: "2xs" }}
            h={"10"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            w={"2xs"}
            h={"10"}
            display={{ base: "none", md: "none", lg: "flex" }}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            w={"2xs"}
            h={"10"}
            display={{ base: "none", md: "flex", lg: "flex" }}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      )}
    </VStack>
  );
};

export default AddDevice;
