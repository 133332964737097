import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import BackButton from "../../../components/button/BackButton";
import axios from "../../../axios";
import upload from "../../../Images/upload.png";
import Cookies from "js-cookie";
import TypeBox from "../../../components/system/addDevice/TypeBox";
import { useHistory, useLocation } from "react-router-dom";

const AddDeviceSys = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [editId, setEditId] = useState("");
  const [thumbnail, setThumbnail] = useState(upload);
  const [picture, setPicture] = useState();
  const [deviceName, setDeviceName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [deviceType, setDeviceType] = useState([]);
  const [connection, setConnection] = useState("");
  const [input, setInput] = useState("");
  const [current, setCurrent] = useState("");
  const [size, setSize] = useState("");
  const [infrared, setInfrared] = useState("");
  const [temperature, setTemperature] = useState("");
  const [humidity, setHumidity] = useState("");
  const [angle, setAngle] = useState("");
  const [range, setRange] = useState("");
  const [protection, setProtection] = useState("");
  const [noise, setNoise] = useState("");
  const [smokeArea, setSmokeArea] = useState("");
  const [lux, setLux] = useState("");
  const [electronTransfer, setElectronTransfer] = useState("");
  const [carbonDioxide, setCarbonDioxide] = useState("");
  const [voc, setVoc] = useState("");
  const [formaldehyde, setFormaldehyde] = useState("");
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.id) {
      setEditId(location.state.id);
      setIsLoading(true);
      axios
        .get("/v2/landin/update_device_about", {
          params: {
            _id: location.state.id,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            const data = result.data.data;
            const cacheBustedUrl = `${data.imgUrl[0]}?timestamp=${Date.now()}`;
            setThumbnail(cacheBustedUrl);
            setPicture(data.imgUrl[0]);
            setDeviceName(data.deviceName ? data.deviceName : "");
            setDescription(data.description ? data.description : "");
            setPrice(data.price ? data.price : "");
            setDeviceType(data.category ? data.category : []);
            setConnection(data.connection ? data.connection : "");
            setInput(data.in ? data.in : "");
            setCurrent(data.out ? data.out : "");
            setSize(data.size ? data.size : "");
            setInfrared(data.infraredDegree ? data.infraredDegree : "");
            setTemperature(data.temperature ? data.temperature : "");
            setHumidity(data.humidity ? data.humidity : "");
            setAngle(data.sensingDegree ? data.sensingDegree : "");
            setRange(data.sensingRange ? data.sensingRange : "");
            setProtection(data.resistantTo ? data.resistantTo : "");
            setNoise(data.noiseAlarm ? data.noiseAlarm : "");
            setSmokeArea(data.smokeSensingArea ? data.smokeSensingArea : "");
            setLux(data.lux ? data.lux : "");
            setElectronTransfer(
              data.electronTransference ? data.electronTransference : ""
            );
            setCarbonDioxide(data.co ? data.co : "");
            setVoc(data.VOC ? data.VOC : "");
            setFormaldehyde(data.formaldehyde ? data.formaldehyde : "");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
    }
  }, [location]);

  const handleFileChange = (e) => {
    if (e.target.files) {
      // console.log("file", e.target.files[0]);
      setPicture(e.target.files[0]);
      document.getElementById("frame").src = URL.createObjectURL(
        e.target.files[0]
      );
    }
  };

  const submitHandler = () => {
    if (editId.length > 0) {
      if (
        (picture &&
          deviceName &&
          description &&
          price &&
          deviceType.length > 0) ||
        (thumbnail &&
          deviceName &&
          description &&
          price &&
          deviceType.length > 0)
      ) {
        setIsDisable(true);
        const formData = new FormData();
        formData.append("_id", editId);
        formData.append("picture", picture ? picture : thumbnail);
        formData.append("name", deviceName);
        formData.append("description", description);
        formData.append("price", price);
        formData.append("category", deviceType);
        formData.append("connection", connection);
        formData.append("in", input);
        formData.append("size", size);
        formData.append(
          "payload",
          JSON.stringify({
            out: current,
            infraredDegree: infrared,
            temperature: temperature,
            humidity: humidity,
            sensingDegree: angle,
            sensingRange: range,
            resistantTo: protection,
            noiseAlarm: noise,
            smokeSensingArea: smokeArea,
            lux: lux,
            electronTransference: electronTransfer,
            co: carbonDioxide,
            VOC: voc,
            formaldehyde: formaldehyde,
          })
        );
        axios
          .post("/v2/landin/update_device_about", formData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((result) => {
            // console.log(result.data);
            setThumbnail(null);
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            history.push("/system/device-list");
            setIsDisable(false);
          })
          .catch((err) => {
            // console.log("err", err);
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "error",
                description: "Алдаа гарлаа!",
              });
            }
            setIsDisable(false);
          });
      } else {
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: "Талбарыг бөглөнө үү!",
          });
        }
      }
    } else {
      if (
        picture &&
        deviceName &&
        description &&
        price &&
        deviceType.length > 0
      ) {
        setIsDisable(true);
        const formData = new FormData();
        formData.append("picture", picture);
        formData.append("name", deviceName);
        formData.append("description", description);
        formData.append("price", price);
        formData.append("category", deviceType);
        formData.append("connection", connection);
        formData.append("in", input);
        formData.append("size", size);
        formData.append(
          "payload",
          JSON.stringify({
            out: current,
            infraredDegree: infrared,
            temperature: temperature,
            humidity: humidity,
            sensingDegree: angle,
            sensingRange: range,
            resistantTo: protection,
            noiseAlarm: noise,
            smokeSensingArea: smokeArea,
            lux: lux,
            electronTransference: electronTransfer,
            co: carbonDioxide,
            VOC: voc,
            formaldehyde: formaldehyde,
          })
        );
        // console.log(Object.fromEntries(formData));
        // const fileReader = new FileReader();
        // fileReader.onloadend = () => {
        //   console.log("first", fileReader.result);
        // };
        // fileReader.readAsDataURL(file);
        axios
          .post("/v2/landin/create_device", formData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((result) => {
            // console.log(result.data);
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            history.push("/system/device-list");
            setIsDisable(false);
          })
          .catch((err) => {
            // console.log("err", err);
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "error",
                description: "Алдаа гарлаа!",
              });
            }
            setIsDisable(false);
          });
      } else {
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: "Талбарыг бөглөнө үү!",
          });
        }
      }
    }
  };

  return isLoading ? (
    <Center w={"full"}>
      <Spinner size="md" color="#45A735" />
    </Center>
  ) : (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={["xs", "full", "full", "full", "8xl"]}
    >
      <BackButton />
      <VStack
        align={"flex-start"}
        justify={"flex-start"}
        w={"full"}
        spacing={8}
      >
        <Stack
          w={"full"}
          direction={["column", "column", "column", "column", "row"]}
          align={"flex-start"}
          justify={"flex-start"}
          spacing={10}
        >
          <VStack spacing={2}>
            <Box
              w={"xs"}
              h={"44"}
              borderColor="gray.300"
              borderStyle="dashed"
              borderWidth="2px"
              rounded="md"
              shadow="sm"
            >
              <Image
                id="frame"
                src={thumbnail}
                height="100%"
                width="100%"
                objectFit={"contain"}
              />
            </Box>

            <Center bg={"#D7FCD0"} w={"xs"} p={"3"} rounded={"md"}>
              <Text
                as={"i"}
                color={"#565656"}
                fontSize={13}
                fontWeight={"normal"}
              >
                PNG өргөтгөлтэй 100x100px зураг оруулна уу!
              </Text>
            </Center>

            <Center
              transition={"ease-in-out .3s"}
              w={"xs"}
              bg={"#45A735"}
              rounded={"md"}
              py={"2"}
              _hover={{
                shadow: "md",
              }}
              position={"relative"}
            >
              <Input
                type="file"
                height="100%"
                width="100%"
                position="absolute"
                top="0"
                left="0"
                opacity="0"
                aria-hidden="true"
                accept="image/*"
                onChange={handleFileChange}
              />
              <Text color={"#fff"} fontSize={15} fontWeight={"medium"}>
                Зураг оруулах
              </Text>
            </Center>
          </VStack>
          <Stack
            w={"full"}
            direction={["column", "column", "column", "row", "row"]}
            align={"flex-start"}
            justify={"flex-start"}
            spacing={10}
          >
            <VStack w={"full"} align={"flex-start"} justify={"flex-start"}>
              <VStack
                w={"full"}
                align={"flex-start"}
                justify={"flex-start"}
                spacing={0}
              >
                <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
                  <Text as={"span"} color={"#f00"}>
                    *
                  </Text>
                  Төхөөрөмжийн нэр
                </Text>
                <Input
                  placeholder="-"
                  w={["xs", "xs", "2xl", "full", "md"]}
                  bg={"#fff"}
                  fontSize={15}
                  fontWeight={"normal"}
                  color={"#000"}
                  value={deviceName}
                  onChange={(e) => setDeviceName(e.target.value)}
                  _focus={{
                    outline: "none",
                    borderColor: "#45A735",
                  }}
                />
              </VStack>
              <VStack
                w={"full"}
                align={"flex-start"}
                justify={"flex-start"}
                spacing={0}
              >
                <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
                  <Text as={"span"} color={"#f00"}>
                    *
                  </Text>
                  Дэлгэрэнгүй
                </Text>
                <Textarea
                  placeholder="-"
                  w={["xs", "xs", "2xl", "full", "md"]}
                  height={"44"}
                  bg={"#fff"}
                  fontSize={15}
                  fontWeight={"normal"}
                  borderColor={"#f2f2f2"}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  _focus={{
                    outline: "none",
                    borderColor: "#45A735",
                  }}
                />
              </VStack>
            </VStack>
            <VStack w={"full"} align={"flex-start"} justify={"flex-start"}>
              <VStack
                w={"full"}
                align={"flex-start"}
                justify={"flex-start"}
                spacing={0}
              >
                <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
                  <Text as={"span"} color={"#f00"}>
                    *
                  </Text>
                  Үнэ
                </Text>
                <InputGroup w={["xs", "xs", "2xl", "full", "md"]}>
                  <Input
                    placeholder="-"
                    bg={"#fff"}
                    fontSize={15}
                    fontWeight={"normal"}
                    color={"#000"}
                    value={price}
                    type={"number"}
                    onChange={(e) => setPrice(e.target.value)}
                    _focus={{
                      outline: "none",
                      borderColor: "#45A735",
                    }}
                  />

                  <InputRightElement
                    pointerEvents="none"
                    color="gray.400"
                    fontSize="13"
                    mr={"1"}
                    children={<Text>MNT</Text>}
                  />
                </InputGroup>
              </VStack>
              <VStack
                w={["xs", "xs", "2xl", "full", "md"]}
                align={"flex-start"}
                justify={"flex-start"}
                spacing={0}
              >
                <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
                  <Text as={"span"} color={"#f00"}>
                    *
                  </Text>
                  Төрөл
                </Text>
                {/* <Divider /> */}
                <SimpleGrid
                  w={"full"}
                  columns={[2, 2, 2, 2, 2]}
                  gap={"2"}
                  bg={"#fff"}
                  rounded={"md"}
                  py={"5"}
                  px={"5"}
                  borderWidth={"1px"}
                  borderColor={"#eee"}
                >
                  {typeData.map((e, i) => (
                    <TypeBox
                      key={i}
                      title={e.title}
                      deviceType={deviceType}
                      setDeviceType={setDeviceType}
                    />
                  ))}
                </SimpleGrid>
                {/* <Select
                placeholder="-"
                w={"md"}
                bg={"#fff"}
                fontSize={15}
                fontWeight={"normal"}
                color={"#000"}
                value={deviceType}
                onChange={(e) => setDeviceType(e.target.value)}
                _focus={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
              >
                <option value="option1">Мэдрэгч</option>
                <option value="option2">Унтраалга</option>
                <option value="option3">Бридж</option>
                <option value="option3">Удирдлага</option>
              </Select> */}
              </VStack>
            </VStack>
          </Stack>
        </Stack>
        <Divider />
        <SimpleGrid w={"full"} columns={[1, 2, 2, 2, 5]} spacing={6}>
          <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
            <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
              <Text as={"span"} color={"#f00"}>
                *
              </Text>
              Холболт
            </Text>
            <Input
              placeholder="-"
              w={"full"}
              bg={"#fff"}
              fontSize={15}
              fontWeight={"normal"}
              color={"#000"}
              value={connection}
              onChange={(e) => setConnection(e.target.value)}
              _focus={{
                outline: "none",
                borderColor: "#45A735",
              }}
            />
          </VStack>
          <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
            <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
              <Text as={"span"} color={"#f00"}>
                *
              </Text>
              Оролт
            </Text>
            <Input
              placeholder="-"
              w={"full"}
              bg={"#fff"}
              fontSize={15}
              fontWeight={"normal"}
              color={"#000"}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              _focus={{
                outline: "none",
                borderColor: "#45A735",
              }}
            />
          </VStack>
          <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
            <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
              Гүйдэл
            </Text>
            <InputGroup>
              <Input
                placeholder="-"
                w={"full"}
                bg={"#fff"}
                fontSize={15}
                fontWeight={"normal"}
                color={"#000"}
                value={current}
                onChange={(e) => setCurrent(e.target.value)}
                _focus={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
              />
              <InputRightElement
                pointerEvents="none"
                color="gray.400"
                fontSize="13"
                mr={"1"}
                children={<Text>A</Text>}
              />
            </InputGroup>
          </VStack>
          <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
            <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
              <Text as={"span"} color={"#f00"}>
                *
              </Text>
              Хэмжээ
            </Text>
            <InputGroup>
              <Input
                placeholder="-"
                w={"full"}
                bg={"#fff"}
                fontSize={15}
                fontWeight={"normal"}
                color={"#000"}
                value={size}
                onChange={(e) => setSize(e.target.value)}
                _focus={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
              />

              <InputRightElement
                pointerEvents="none"
                color="gray.400"
                fontSize="13"
                mr={"1"}
                children={<Text>mm</Text>}
              />
            </InputGroup>
          </VStack>
          <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
            <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
              Температур хэмжих
            </Text>
            <InputGroup>
              <Input
                placeholder="-"
                w={"full"}
                bg={"#fff"}
                fontSize={15}
                fontWeight={"normal"}
                color={"#000"}
                value={temperature}
                onChange={(e) => setTemperature(e.target.value)}
                _focus={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
              />

              <InputRightElement
                pointerEvents="none"
                color="gray.400"
                fontSize="13"
                mr={"1"}
                children={<Text>°C</Text>}
              />
            </InputGroup>
          </VStack>
          <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
            <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
              Чийгшил хэмжих
            </Text>
            <InputGroup>
              <Input
                placeholder="-"
                w={"full"}
                bg={"#fff"}
                fontSize={15}
                fontWeight={"normal"}
                color={"#000"}
                value={humidity}
                onChange={(e) => setHumidity(e.target.value)}
                _focus={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
              />
              <InputRightElement
                pointerEvents="none"
                color="gray.400"
                fontSize="13"
                mr={"1"}
                children={<Text>%</Text>}
              />
            </InputGroup>
          </VStack>
          <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
            <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
              Infrared дамжуулах өнцөг
            </Text>
            <InputGroup>
              <Input
                placeholder="-"
                w={"full"}
                bg={"#fff"}
                fontSize={15}
                fontWeight={"normal"}
                color={"#000"}
                value={infrared}
                onChange={(e) => setInfrared(e.target.value)}
                _focus={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
              />
              <InputRightElement
                pointerEvents="none"
                color="gray.400"
                fontSize="13"
                mr={"1"}
                children={<Text>°</Text>}
              />
            </InputGroup>
          </VStack>
          <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
            <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
              Мэдрэх өнцөг
            </Text>
            <InputGroup>
              <Input
                placeholder="-"
                w={"full"}
                bg={"#fff"}
                fontSize={15}
                fontWeight={"normal"}
                color={"#000"}
                value={angle}
                onChange={(e) => setAngle(e.target.value)}
                _focus={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
              />
              <InputRightElement
                pointerEvents="none"
                color="gray.400"
                fontSize="13"
                mr={"1"}
                children={<Text>°</Text>}
              />
            </InputGroup>
          </VStack>
          <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
            <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
              Мэдрэх зай
            </Text>
            <Input
              placeholder="-"
              w={"full"}
              bg={"#fff"}
              fontSize={15}
              fontWeight={"normal"}
              color={"#000"}
              value={range}
              onChange={(e) => setRange(e.target.value)}
              _focus={{
                outline: "none",
                borderColor: "#45A735",
              }}
            />
          </VStack>
          <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
            <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
              Хамгаалалт
            </Text>
            <Input
              placeholder="-"
              w={"full"}
              bg={"#fff"}
              fontSize={15}
              fontWeight={"normal"}
              color={"#000"}
              value={protection}
              onChange={(e) => setProtection(e.target.value)}
              _focus={{
                outline: "none",
                borderColor: "#45A735",
              }}
            />
          </VStack>
          <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
            <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
              Дуут дохио
            </Text>

            <InputGroup>
              <Input
                placeholder="-"
                w={"full"}
                bg={"#fff"}
                fontSize={15}
                fontWeight={"normal"}
                color={"#000"}
                value={noise}
                onChange={(e) => setNoise(e.target.value)}
                _focus={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
              />
              <InputRightElement
                pointerEvents="none"
                color="gray.400"
                fontSize="13"
                mr={"1"}
                children={<Text>dB</Text>}
              />
            </InputGroup>
          </VStack>
          <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
            <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
              Утаа мэдрэх талбай
            </Text>
            <InputGroup>
              <Input
                placeholder="-"
                w={"full"}
                bg={"#fff"}
                fontSize={15}
                fontWeight={"normal"}
                color={"#000"}
                value={smokeArea}
                onChange={(e) => setSmokeArea(e.target.value)}
                _focus={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
              />
              <InputRightElement
                pointerEvents="none"
                color="gray.400"
                fontSize="13"
                mr={"1"}
                children={<Text>м.кв</Text>}
              />
            </InputGroup>
          </VStack>
          <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
            <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
              Гэрэл хэмжих
            </Text>
            <InputGroup>
              <Input
                placeholder="-"
                w={"full"}
                bg={"#fff"}
                fontSize={15}
                fontWeight={"normal"}
                color={"#000"}
                value={lux}
                onChange={(e) => setLux(e.target.value)}
                _focus={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
              />
              <InputRightElement
                pointerEvents="none"
                color="gray.400"
                fontSize="13"
                mr={"1"}
                children={<Text>LUX</Text>}
              />
            </InputGroup>
          </VStack>
          <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
            <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
              Электрон дамжуулах
            </Text>

            <InputGroup>
              <Input
                placeholder="-"
                w={"full"}
                bg={"#fff"}
                fontSize={15}
                fontWeight={"normal"}
                color={"#000"}
                value={electronTransfer}
                onChange={(e) => setElectronTransfer(e.target.value)}
                _focus={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
              />
              <InputRightElement
                pointerEvents="none"
                color="gray.400"
                fontSize="13"
                mr={"1"}
                children={<Text>µS/cm</Text>}
              />
            </InputGroup>
          </VStack>
          <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
            <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
              Carbon dioxide
            </Text>
            <InputGroup>
              <Input
                placeholder="-"
                w={"full"}
                bg={"#fff"}
                fontSize={15}
                fontWeight={"normal"}
                color={"#000"}
                value={carbonDioxide}
                onChange={(e) => setCarbonDioxide(e.target.value)}
                _focus={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
              />
              <InputRightElement
                pointerEvents="none"
                color="gray.400"
                fontSize="13"
                mr={"1"}
                children={<Text>ppm</Text>}
              />
            </InputGroup>
          </VStack>
          <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
            <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
              VOC
            </Text>
            <InputGroup>
              <Input
                placeholder="-"
                w={"full"}
                bg={"#fff"}
                fontSize={15}
                fontWeight={"normal"}
                color={"#000"}
                value={voc}
                onChange={(e) => setVoc(e.target.value)}
                _focus={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
              />
              <InputRightElement
                pointerEvents="none"
                color="gray.400"
                fontSize="13"
                mr={"1"}
                children={<Text>ppm</Text>}
              />
            </InputGroup>
          </VStack>
          <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
            <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
              Formaldehyde
            </Text>
            <InputGroup>
              <Input
                placeholder="-"
                w={"full"}
                bg={"#fff"}
                fontSize={15}
                fontWeight={"normal"}
                color={"#000"}
                value={formaldehyde}
                onChange={(e) => setFormaldehyde(e.target.value)}
                _focus={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
              />
              <InputRightElement
                pointerEvents="none"
                color="gray.400"
                fontSize="13"
                mr={"1"}
                children={<Text>mg/m3</Text>}
              />
            </InputGroup>
          </VStack>
        </SimpleGrid>
      </VStack>
      <Button
        transition={"ease-in-out .3s"}
        w={"xs"}
        bg={"#45A735"}
        color={"#fff"}
        fontSize={15}
        fontWeight={"medium"}
        rounded={"md"}
        alignSelf={"flex-end"}
        onClick={() => submitHandler()}
        _focus={{
          outline: "none",
        }}
        _hover={{
          shadow: "md",
        }}
        disabled={isDisable}
      >
        {isDisable ? <Spinner mr={2} size="sm" /> : null}
        Хадгалах
      </Button>
    </VStack>
  );
};

const typeData = [
  {
    title: "Мэдрэгч",
  },
  {
    title: "Унтраалга",
  },
  {
    title: "Бридж",
  },
  {
    title: "Удирдлага",
  },
];

export default AddDeviceSys;
