import React, { useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "../../axios";
import Cookies from "js-cookie";

const CreateModal = ({ showModal, setShowModal, setTrigger }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const toast = useToast();
  const token = Cookies.get("accessToken");

  const createHandler = () => {
    if (title && description) {
      axios
        .post(
          "/admin/complain",
          {
            title: title,
            text: description,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          toast({
            position: "top",
            status: "success",
            duration: 2000,
            description: "Амжилттай!",
          });
          setShowModal(false);
          setTitle("");
          setDescription("");
          setTrigger(true);
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      toast({
        position: "top",
        status: "error",
        duration: 2000,
        description: "Талбарыг бүрэн бөглөнө үү!",
      });
    }
  };

  return (
    <Modal
      isCentered
      onClose={!showModal}
      isOpen={showModal}
      motionPreset="scale"
    >
      <ModalOverlay />
      <ModalContent w={{ base: "xs", md: "md" }}>
        <ModalHeader>Үүсгэх</ModalHeader>
        <ModalCloseButton
          _focus={{ outline: "none" }}
          onClick={() => setShowModal(false)}
        />
        <ModalBody>
          <VStack w={"full"}>
            <VStack
              w={"full"}
              align={"flex-start"}
              justify={"flex-start"}
              spacing={0}
            >
              <Text color={"#000"} fontWeight={"medium"} fontSize={15}>
                Гарчиг
              </Text>
              <Input
                color={"#000"}
                fontSize={15}
                fontWeight={"normal"}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                _hover={{
                  borderColor: "#45A735",
                }}
                _focus={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
              />
            </VStack>
            <VStack
              w={"full"}
              align={"flex-start"}
              justify={"flex-start"}
              spacing={0}
            >
              <Text color={"#000"} fontWeight={"medium"} fontSize={15}>
                Дэлгэрэнгүй
              </Text>
              <Textarea
                color={"#000"}
                fontSize={15}
                fontWeight={"normal"}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                _hover={{
                  borderColor: "#45A735",
                }}
                _focus={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
                onKeyPress={(e) => {
                  if (title && description) {
                    if (e.key === "Enter") {
                      createHandler();
                    }
                  }
                }}
              />
            </VStack>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="gray"
            fontSize={15}
            mr={3}
            px={"8"}
            onClick={() => setShowModal(false)}
            _focus={{
              outline: "none",
            }}
          >
            Болих
          </Button>
          <Button
            colorScheme="green"
            fontSize={15}
            px={"8"}
            _focus={{
              outline: "none",
            }}
            onClick={createHandler}
          >
            Хадгалах
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateModal;
