import React from "react";
import { Text, VStack } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import BackButton from "../../../components/button/BackButton";

const AddRS485 = () => {
  const history = useHistory();

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "full", md: "full", lg: "8xl" }}
    >
      <BackButton />
      <Text>AddRS485</Text>
    </VStack>
  );
};

export default AddRS485;
