import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "../../../axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { FiInfo } from "react-icons/fi";
import AddModel from "../../../components/zigbee/AddModel";
import { FiTrash } from "react-icons/fi";
import BackButton from "../../../components/button/BackButton";

const ZigBee = () => {
  const [zigBee, setZigBee] = useState([]);
  const [houseId, setHouseId] = useState();
  const [trigger, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [delClient, setDelClient] = useState("");
  const toast = useToast();
  const id = "test-toast";
  const history = useHistory();
  const token = Cookies.get("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();

  // ----------------- get Devices ---------------------
  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/zigbees", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log("List ---", result.data.data);
        setZigBee(
          result.data.data.map((e) => {
            return {
              clientId: e.clientId,
              deviceId: e.deviceId,
              phone: "",
              houseList: [],
              for: "",
            };
          })
        );
        setTrigger(false);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setTrigger(false);
        setIsLoading(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });

    return () => {
      cancel = true;
    };
  }, [trigger]);

  // ----------------- get contractId ---------------------
  const handleChange = (a, index) => {
    let source = axios.CancelToken.source();
    let cancel = false;

    const newInput = [...zigBee];
    newInput[index].phone = a;
    setZigBee(newInput);
    // --------- get House ---------
    if (a.split("").length === 8) {
      axios
        .get("admin/device_houses", {
          params: { username: a },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          const house = [...zigBee];
          house[index].houseList = result.data.data;
          setZigBee(house);
        })
        .catch((err) => {
          // console.log("error", err);
        });
      return () => {
        cancel = true;
      };
    }
  };

  // ----------------- get Select House ---------------------
  const handleSelect = (e, index) => {
    // console.log(e);
    setHouseId(e);
  };

  const nameHandler = (value, index) => {
    // console.log("for --->", value);
    const clone = [...zigBee];
    clone[index].for = value;
    setZigBee(clone);
  };

  const handleSubmit = (e) => {
    const data = {
      clientId: e.clientId,
      username: e.phone,
      deviceId: e.deviceId,
      house: {
        _id: houseId,
        name: e.houseList.find((a) => a._id === houseId)
          ? e.houseList.find((a) => a._id === houseId).name
          : null,
      },
      for: e.for,
    };
    // console.log(data);
    axios
      .post("/admin/zigbee_create", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        // console.log("Done ---", result);
        if (!toast.isActive(id)) {
          toast({
            id,
            status: "success",
            duration: 2000,
            position: "top",
            description: "Амжилттай",
          });
        }
        // history.push("dashboard");
        setTrigger(true);
      })
      .catch((err) => {
        // console.log("error", err);
        if (!toast.isActive(id)) {
          toast({
            id,
            status: "error",
            duration: 2000,
            position: "top",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  const deleteHandler = () => {
    if (delClient) {
      onClose();
      axios
        .delete("/admin/delete_uncreated_zbridge", {
          data: {
            clientId: delClient,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result);
          setTrigger(true);
          setDelClient("");
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай устлаа!",
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <VStack
      align={"flex-start"}
      w={{ base: "full", md: "full", lg: "8xl" }}
      mb={"10"}
      spacing={4}
    >
      <BackButton />
      {isLoading ? (
        <HStack spacing={"10"}>
          <Skeleton
            w={{ base: "xs", md: "2xs" }}
            h={"10"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            w={"2xs"}
            h={"10"}
            display={{ base: "none", md: "none", lg: "flex" }}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            w={"2xs"}
            h={"10"}
            display={{ base: "none", md: "flex", lg: "flex" }}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : zigBee.length > 0 ? (
        <Grid templateColumns="repeat(3, 1fr)" rowGap={6} w={"full"} mt={"10"}>
          {zigBee.map((e, index) => (
            <VStack
              key={index}
              bg={"#fff"}
              boxShadow={"md"}
              borderRadius={"md"}
              p={"4"}
              w={{ base: "xs", md: "md", lg: "full" }}
            >
              <HStack
                w={"full"}
                bg={"#EFEFEF"}
                borderWidth={1}
                borderColor={"#EFEFEF"}
                borderRadius={"md"}
                py={"2"}
                px={"4"}
                justify={"space-between"}
              >
                <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                  clientId:
                </Text>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  {e.clientId}
                </Text>
              </HStack>
              <Divider />
              <Stack w={"full"} direction={{ base: "column", md: "row" }}>
                <Input
                  variant="filled"
                  placeholder="Утасны дугаар"
                  onChange={(a) => handleChange(a.target.value, index)}
                  value={e.phone}
                  bg={"#FCFCFC"}
                  fontSize={15}
                  fontWeight={"normal"}
                  borderColor={"#EFEFEF"}
                  borderWidth={1}
                  maxLength={8}
                  type={"tel"}
                  _hover={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                  _focus={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                />

                <Select
                  isDisabled={e.phone.length > 7 ? false : true}
                  placeholder="-Байшин сонгоно уу-"
                  onChange={(q) => handleSelect(q.target.value, index)}
                  multiple={false}
                  fontSize={15}
                  fontWeight={"normal"}
                  _hover={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                  _focus={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                >
                  {e.houseList.map((q, index) => (
                    <option key={index} value={q._id}>
                      {q.name}
                    </option>
                  ))}
                </Select>
              </Stack>
              <Input
                variant="filled"
                placeholder="Төхөөрөмжинд нэр өгөх"
                value={e.for}
                onChange={(a) => nameHandler(a.target.value, index)}
                bg={"#FCFCFC"}
                borderColor={"#EFEFEF"}
                borderWidth={1}
                fontSize={"14"}
                maxLength={30}
                fontWeight={"normal"}
                type={"text"}
                w={"full"}
                _hover={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
                _focus={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
              />
              <HStack w={"full"}>
                <IconButton
                  colorScheme="red"
                  size="md"
                  icon={<Icon as={FiTrash} w={"4"} h={"4"} />}
                  _focus={{
                    outline: "none",
                  }}
                  onClick={() => {
                    onOpen();
                    setDelClient(e.clientId);
                  }}
                />
                <Button
                  w={"full"}
                  bg={"#45A735"}
                  _hover={{
                    bg: "#5AB54B",
                  }}
                  _focus={{
                    outline: "none",
                  }}
                  onClick={() => handleSubmit(e)}
                  disabled={e.for && e.phone && houseId ? false : true}
                >
                  <Text color={"#fff"} fontWeight="normal">
                    Хадгалах
                  </Text>
                </Button>
              </HStack>
            </VStack>
          ))}
        </Grid>
      ) : (
        <VStack
          justifyContent="center"
          alignItems="center"
          color="gray.600"
          w="full"
          pt={"52"}
        >
          <Icon as={FiInfo} w={7} h={7} />
          <Text fontSize={15}>Холбогдсон төхөөрөмж байхгүй байна.</Text>
        </VStack>
      )}
      <AddModel />

      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          <ModalBody>
            <Text color={"#000"} fontWeight={"normal"} fontSize={15}>
              {delClient}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={onClose}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={deleteHandler}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default ZigBee;
