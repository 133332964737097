import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  HStack,
  Icon,
  Skeleton,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "../../../axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { FiInfo } from "react-icons/fi";
import BackButton from "../../../components/button/BackButton";

const AQsensor = () => {
  const [device, setDevice] = useState([]);
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const id = "test-toast";
  const history = useHistory();
  const token = Cookies.get("accessToken");

  // ----------------- get Devices ---------------------
  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/air_quality_sensor", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log("List ---", result.data.data);
        setDevice(result.data.data);
        setTrigger(false);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setTrigger(false);
        setIsLoading(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });

    return () => {
      cancel = true;
    };
  }, [trigger]);

  return (
    <VStack
      align={"flex-start"}
      w={{ base: "xs", md: "2xl", lg: "8xl" }}
      mb={"10"}
      spacing={4}
    >
      <BackButton />
      {isLoading ? (
        <HStack spacing={"10"}>
          <Skeleton
            w={{ base: "xs", md: "2xs" }}
            h={"10"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            w={"2xs"}
            h={"10"}
            borderRadius={"md"}
            display={{ base: "none", md: "none", lg: "flex" }}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            w={"2xs"}
            h={"10"}
            borderRadius={"md"}
            display={{ base: "none", md: "flex", lg: "flex" }}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : device.length > 0 ? (
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          gap={{ base: 4, md: 6 }}
          w={"full"}
        >
          {device.map((e, index) => (
            <VStack
              key={index}
              bg={"#fff"}
              boxShadow={"md"}
              borderRadius={"md"}
              p={"4"}
              w={"full"}
            >
              <HStack
                w={"full"}
                bg={"#F3F3F3"}
                borderWidth={1}
                borderColor={"#EFEFEF"}
                borderRadius={"md"}
                py={"2"}
                px={"4"}
                justify={"space-between"}
              >
                <Text
                  fontSize={14}
                  color={"#000"}
                  fontWeight={"normal"}
                  alignSelf={"flex-start"}
                >
                  clientId:
                </Text>
                <Text
                  fontSize={14}
                  color={"#000"}
                  fontWeight={"medium"}
                  align={"end"}
                >
                  {e.clientId}
                </Text>
              </HStack>
              <Divider />
              <HStack w={"full"} justify={"space-between"}>
                <Button
                  w={"full"}
                  bg={"#E61616"}
                  _hover={{
                    bg: "#F00000",
                  }}
                  _focus={{
                    outline: "none",
                  }}
                  //   onClick={() => handleSubmit(e)}
                  disabled={true}
                >
                  <Text color={"#fff"} fontWeight="normal">
                    Устгах
                  </Text>
                </Button>
                <Button
                  w={"full"}
                  bg={"#45A735"}
                  _hover={{
                    bg: "#5AB54B",
                  }}
                  _focus={{
                    outline: "none",
                  }}
                  onClick={() =>
                    history.push("/device/type/aq-sensor", {
                      data: e,
                    })
                  }
                >
                  <Text color={"#fff"} fontWeight="normal">
                    Хэрэглэгчид нэмэх
                  </Text>
                </Button>
              </HStack>
            </VStack>
          ))}
        </Grid>
      ) : (
        <VStack
          justifyContent="center"
          alignItems="center"
          color="gray.600"
          w="full"
          pt={"40"}
        >
          <Icon as={FiInfo} w={7} h={7} />
          <Text fontSize={15}>Холбогдсон төхөөрөмж байхгүй байна.</Text>
        </VStack>
      )}
    </VStack>
  );
};

export default AQsensor;
