import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { UserStore } from "./UserContext";
import axios from "./axios";
import Cookies from "js-cookie";

// axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

// axios.interceptors.request.use(
//   (config) => {
//     console.log("config ---", config.headers);
//     if (config.headers.Authorization) {
//       const token = Cookies.get("accessToken");
//       console.log("first", token);

//       if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//         console.log("token dotor");
//       }
//     }

//     return config;
//   },
//   (error) => {
//     Promise.reject(error);
//     console.log(error);
//   }
// );

ReactDOM.render(
  // <React.StrictMode>
  <ChakraProvider>
    <UserStore>
      <App />
    </UserStore>
  </ChakraProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
