import { Input, Text, VStack } from "@chakra-ui/react";
import React from "react";

const CustomInput = ({
  placeholder,
  title,
  value,
  onChange,
  type,
  required,
  readOnly,
  onKeyDown,
}) => {
  return (
    <VStack w={"full"} align={"flex-start"} justify={"flex-start"} spacing={0}>
      {title && (
        <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
          {required && (
            <Text as={"span"} color={"#f00"}>
              *
            </Text>
          )}
          {title + ":"}
        </Text>
      )}
      <Input
        readOnly={readOnly}
        variant="filled"
        placeholder={placeholder || ""}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        bg={"#FCFCFC"}
        borderColor={"#EFEFEF"}
        borderWidth={1}
        // maxLength={8}
        fontSize={"14"}
        fontWeight={"normal"}
        type={type || "text"}
        w={"full"}
        _hover={{
          borderColor: "#45A735",
          borderWidth: 1,
        }}
        _focus={{
          borderColor: "#45A735",
          borderWidth: 1,
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && onKeyDown) {
            onKeyDown(e);
          }
        }}
      />
    </VStack>
  );
};

export default CustomInput;
