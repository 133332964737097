import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  Input,
  Select,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import BackButton from "../../../components/button/BackButton";
import axios from "../../../axios";
import Cookies from "js-cookie";

const G4 = () => {
  const [username, setUsername] = useState("");
  const [house, setHouse] = useState("");
  const [houseList, setHouseList] = useState([]);
  const [room, setRoom] = useState("");
  const [roomList, setRoomList] = useState([]);
  const [deviceName, setDeviceName] = useState("");
  const [onCommand, setOnCommand] = useState("");
  const [offCommand, setOffCommand] = useState("");
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");

  const changeHandler = (value) => {
    let source = axios.CancelToken.source();
    let cancel = false;
    setUsername(value);
    // -------------- get House -------------------
    if (value.length === 8) {
      axios
        .get("admin/device_houses", {
          params: { username: value },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          //   console.log("house List", result.data.data);
          setHouseList(result.data.data);
        })
        .catch((err) => {
          // console.log("error", err);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "error",
              duration: 2000,
              position: "top",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
      return () => {
        cancel = true;
      };
    } else {
      setHouseList([]);
      setRoomList([]);
    }
  };

  useEffect(() => {
    if (house) {
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/v2/user/create_device", {
          params: { houseId: house },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          //   console.log("room List", result.data.data);
          setRoomList(result.data.data);
        })
        .catch((err) => {
          // console.log("error", err);
        });
    }
  }, [house]);

  const submitHandler = () => {
    if (username && house && deviceName && onCommand && offCommand) {
      //   console.log("create", {
      //     username: username,
      //     houseId: house,
      //     roomId: room,
      //     deviceName: deviceName,
      //     onCommand: onCommand,
      //     offCommand: offCommand,
      //   });
      axios
        .post(
          "/v2/g4/create_g4",
          {
            username: username,
            houseId: house,
            roomId: room,
            deviceName: deviceName,
            onCommand: onCommand,
            offCommand: offCommand,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          //   console.log(result.data);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "success",
              duration: 2000,
              position: "top",
              description: "Амжилттай",
            });
          }
          setUsername("");
          setHouse("");
          setHouseList([]);
          setRoom("");
          setRoomList([]);
          setDeviceName("");
          setOnCommand("");
          setOffCommand("");
        })
        .catch((err) => {
          //   console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "error",
              duration: 2000,
              position: "top",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <VStack
      align={"flex-start"}
      w={{ base: "xs", md: "2xl", lg: "8xl" }}
      mb={"10"}
      spacing={4}
    >
      <BackButton />

      <VStack w={"sm"} p={4} bg={"#fff"} rounded={"md"} shadow={"md"}>
        <Divider />
        <VStack w={"full"} spacing={0} align={"flex-start"}>
          <Text fontSize={14} fontWeight={"medium"}>
            Хэрэглэгчийн утасны дугаар
          </Text>
          <Input
            variant="filled"
            placeholder="Утасны дугаар"
            bg={"#FCFCFC"}
            w={"full"}
            fontSize={14}
            borderColor={"#EFEFEF"}
            borderWidth={1}
            maxLength={8}
            type={"tel"}
            value={username}
            onChange={(a) => changeHandler(a.target.value)}
            _hover={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            _focus={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
          />
        </VStack>

        <VStack w={"full"} spacing={0} align={"flex-start"}>
          <Text fontSize={14} fontWeight={"medium"}>
            Байшин
          </Text>
          <Select
            variant={"filled"}
            isDisabled={houseList.length > 0 ? false : true}
            placeholder="-Байшин сонгоно уу-"
            onChange={(q) => setHouse(q.target.value)}
            multiple={false}
            fontSize={14}
            bg={"#FCFCFC"}
            w={"full"}
            borderColor={"#EFEFEF"}
            borderWidth={1}
            _hover={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            _focus={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
          >
            {houseList.map((q, index) => (
              <option key={index} value={q._id}>
                {q.name}
              </option>
            ))}
          </Select>
        </VStack>

        <VStack w={"full"} spacing={0} align={"flex-start"}>
          <Text fontSize={14} fontWeight={"medium"}>
            Өрөө
          </Text>
          <Select
            variant={"filled"}
            isDisabled={roomList.length > 0 ? false : true}
            placeholder="-Өрөө сонгоно уу-"
            onChange={(q) => setRoom(q.target.value)}
            multiple={false}
            fontSize={14}
            bg={"#FCFCFC"}
            w={"full"}
            borderColor={"#EFEFEF"}
            borderWidth={1}
            _hover={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            _focus={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
          >
            {roomList.map((q, index) => (
              <option key={index} value={q._id}>
                {q.name}
              </option>
            ))}
          </Select>
        </VStack>

        <VStack w={"full"} spacing={0} align={"flex-start"}>
          <Text fontSize={14} fontWeight={"medium"}>
            Төхөөрөмжийн нэр
          </Text>
          <Input
            variant="filled"
            placeholder="-"
            bg={"#FCFCFC"}
            w={"full"}
            fontSize={14}
            borderColor={"#EFEFEF"}
            borderWidth={1}
            value={deviceName}
            onChange={(e) => setDeviceName(e.target.value)}
            _hover={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            _focus={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
          />
        </VStack>

        <VStack w={"full"} spacing={0} align={"flex-start"}>
          <Text fontSize={14} fontWeight={"medium"}>
            Асаах
          </Text>
          <Input
            variant="filled"
            placeholder="-"
            bg={"#FCFCFC"}
            w={"full"}
            fontSize={14}
            borderColor={"#EFEFEF"}
            borderWidth={1}
            value={onCommand}
            onChange={(e) => setOnCommand(e.target.value)}
            _hover={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            _focus={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
          />
        </VStack>

        <VStack w={"full"} spacing={0} align={"flex-start"}>
          <Text fontSize={14} fontWeight={"medium"}>
            Унтраах
          </Text>
          <Input
            variant="filled"
            placeholder="-"
            bg={"#FCFCFC"}
            w={"full"}
            fontSize={14}
            borderColor={"#EFEFEF"}
            borderWidth={1}
            value={offCommand}
            onChange={(e) => setOffCommand(e.target.value)}
            _hover={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            _focus={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
          />
        </VStack>

        <Button
          w={"full"}
          bg={"#45A735"}
          _hover={{
            bg: "#5AB54B",
          }}
          _focus={{
            outline: "none",
          }}
          onClick={() => submitHandler()}
          disabled={
            username && house && deviceName && onCommand && offCommand
              ? false
              : true
          }
        >
          <Text color={"#fff"} fontSize={15} fontWeight="normal">
            Хадгалах
          </Text>
        </Button>
      </VStack>
    </VStack>
  );
};

export default G4;
