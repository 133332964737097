import React, { useState } from "react";
import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Textarea,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { TiThumbsOk } from "react-icons/ti";
import { MdOutlineEditNotifications } from "react-icons/md";
import axios from "../../axios";
import Cookies from "js-cookie";
import Select from "react-select";
import { useEffect } from "react";

const SelectUser = ({ setTrigger }) => {
  const [allUser, setAllUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const toast = useToast();
  const id = "test";
  const token = Cookies.get("accessToken");

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/user_get_all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log("all user ---", result.data.data);
        setAllUser(result.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
        setTrigger(false);
        setIsLoading(false);
      });
    return () => {
      cancel = true;
    };
  }, []);

  // --------------------------- Send Notification --------------------------------
  const sendHandler = () => {
    if (title && desc && selectedUser.length > 0) {
      setDisableBtn(true);
      setIsLoading(true);
      onClose();
      axios
        .post(
          "/admin/user_notif_people",
          {
            username: selectedUser.map((e) => e.value),
            title: title,
            message: desc,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log("Done ---", result);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "success",
              duration: 2000,
              position: "top",
              description: "Амжилттай илгээлээ.",
            });
          }
          setSelectedUser([]);
          setTitle("");
          setDesc("");
          setTrigger(true);
          setIsLoading(false);
          setDisableBtn(false);
        })
        .catch((err) => {
          // console.log("error", err);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "error",
              duration: 2000,
              position: "top",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
          setIsLoading(false);
          setDisableBtn(false);
        });
    }
  };

  const colourStyles = {
    control: (styles, isFocused) => ({
      ...styles,
      backgroundColor: "#f9f9f9",
      color: "#000",
      width: 350,
      zIndex: 999,
      borderRadius: 6,
      fontSize: 14,
      fontWeight: "normal",
      borderColor: isFocused ? "#45A735" : "red",
      // border: "none",
    }),
  };

  return (
    <>
      <VStack
        bg={"#fff"}
        rounded={"lg"}
        px={{ base: "6", md: "8" }}
        py={{ base: "6", md: "8" }}
        w={"full"}
        // h={"72"}
        // shadow={"md"}
        spacing={3}
        justify={"center"}
      >
        <VStack alignSelf={"flex-start"} spacing={1}>
          <Box
            w={"16"}
            h={"0.5"}
            bg={"#45A735"}
            rounded={"full"}
            alignSelf={"flex-start"}
          />
          <HStack>
            <Text fontSize={15} fontWeight={"medium"}>
              Хэрэглэгч сонгож мэдэгдэл илгээх
            </Text>
            <Icon as={TiThumbsOk} w={5} h={5} color="#45A735" />
          </HStack>
        </VStack>

        <Select
          placeholder={"Хэрэглэгч сонгоно уу!"}
          styles={colourStyles}
          closeMenuOnSelect={false}
          defaultValue={selectedUser}
          onChange={setSelectedUser}
          isMulti
          options={allUser.map((e) => {
            return {
              value: e.username,
              label: e.username,
            };
          })}
        />
        <InputGroup>
          {/* <InputLeftElement
            pointerEvents="none"
            children={
              <Icon
                as={MdOutlineEditNotifications}
                w={5}
                h={5}
                color="#45A735"
              />
            }
          /> */}
          <Input
            type="text"
            placeholder="Гарчиг"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fontSize={14}
            fontWeight={"normal"}
            _hover={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            _focus={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            w={"full"}
            bg={"#f9f9f9"}
            rounded={"md"}
          />
        </InputGroup>
        <Textarea
          type="text"
          placeholder="Дэлгэрэнгүй"
          w={"full"}
          bg={"#f9f9f9"}
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
          fontSize={14}
          fontWeight={"normal"}
          rounded={"md"}
          _hover={{
            borderColor: "#45A735",
            borderWidth: 1,
          }}
          _focus={{
            borderColor: "#45A735",
            borderWidth: 1,
          }}
          overflowY="auto"
          sx={{
            "&::-webkit-scrollbar": {
              width: "6px",
              borderRadius: "8px",
              backgroundColor: `rgba(249, 249, 249)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `rgba(69, 167, 53, 0.6)`,
              borderRadius: "8px",
            },
          }}
          onKeyPress={(e) => {
            if (title && desc) {
              if (e.key === "Enter") {
                sendHandler();
              }
            }
          }}
        />
        <Button
          bg={"#45A735"}
          w={"full"}
          rounded={"md"}
          py={"2"}
          cursor={"pointer"}
          onClick={onOpen}
          _hover={{
            bg: "#5AB54B",
          }}
          _focus={{
            outline: "none",
          }}
          fontWeight={"normal"}
          disabled={
            title && desc && selectedUser.length > 0 && !isLoading
              ? false
              : true
          }
        >
          {isLoading ? <Spinner color="#fff" mr={2} size="sm" /> : null}
          <Text fontSize={14} color={"#fff"}>
            Илгээх
          </Text>
        </Button>
      </VStack>

      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader fontSize={17} color={"#000"} fontWeight={"medium"}>
            Та илгээхдээ итгэлтэй байна уу?
          </ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          <ModalFooter>
            <Button colorScheme="gray" mr={3} px={"8"} onClick={onClose}>
              Үгүй
            </Button>
            <Button
              bg={"#45A735"}
              color={"#fff"}
              px={"8"}
              _hover={{ bg: "#58B948" }}
              onClick={sendHandler}
              disabled={disableBtn}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SelectUser;
