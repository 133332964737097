import React from "react";
import {
  Box,
  Icon,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useEffect } from "react";
import Cookies from "js-cookie";
import axios from "../../../axios";
import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdOutlineSubdirectoryArrowRight } from "react-icons/md";

const Analyze = () => {
  const [userInitialList, setUserInitialList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookies.get("accessToken");

  // --------------------------- Get Initial List --------------------------------
  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/admin/user_list_table_initial", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        // console.log("Result ---", result.data);
        setUserInitialList(
          result.data.data.map((e) => {
            return {
              username: e.username,
              atTime: e.atTime,
              numberOfHouses: e.numberOfHouses,
              numberOfRooms: e.numberOfRooms,
              numberOfDevices: e.numberOfTheDevices,
              numberOfPhysicalDevices: e.numberOfPhysicalDevices,
              arrowIcon: false,
              sharedUserList: [],
            };
          })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
      });
  }, []);

  const handleArrow = (value, i) => {
    // console.log("arrow", value, i);
    if (value.arrowIcon === true) {
      const list = [...userInitialList];
      list[i].sharedUserList = [];
      setUserInitialList(list);
    } else {
      axios
        .get("/admin/user_list_table_detail", {
          params: {
            username: value.username,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log("Result ---", result.data);
          const list = [...userInitialList];
          list[i].sharedUserList = result.data.data;
          setUserInitialList(list);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
    const list = [...userInitialList];
    list[i].arrowIcon = !list[i].arrowIcon;
    setUserInitialList(list);
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"6"}
      w={{ base: "xs", md: "2xl", lg: "8xl" }}
    >
      <VStack bg={"#fff"} rounded={"md"} p={"6"} shadow={"md"}>
        <Box
          w={"full"}
          bg={"#F8F8F8"}
          borderTopRightRadius={"md"}
          borderBottomRightRadius={"md"}
          borderLeftWidth={"medium"}
          borderColor={"#45A735"}
          py={"2"}
          px={{ base: "4", md: "6" }}
          mb={"4"}
        >
          <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
            Хэрэглэгчдийн мэдээлэл
          </Text>
        </Box>
        <TableContainer w={"5xl"}>
          <Table>
            <Thead bgGradient="linear(to-l, #45A735, #1ED600)">
              <Tr>
                <Th
                  borderTopLeftRadius={"md"}
                  color={"#fff"}
                  fontSize={12}
                  fontWeight={"medium"}
                >
                  №
                </Th>
                <Th color={"#fff"} fontSize={12} fontWeight={"medium"}>
                  Хэрэглэгч
                </Th>
                <Th color={"#fff"} fontSize={12} fontWeight={"medium"}>
                  Төхөөрөмж
                </Th>
                <Th color={"#fff"} fontSize={12} fontWeight={"medium"}>
                  Нэгж төхөөрөмж
                </Th>
                <Th color={"#fff"} fontSize={12} fontWeight={"medium"}>
                  Байшин
                </Th>
                <Th color={"#fff"} fontSize={12} fontWeight={"medium"}>
                  Өрөө
                </Th>
                <Th color={"#fff"} fontSize={12} fontWeight={"medium"}>
                  Хугацаа
                </Th>
                <Th
                  isNumeric
                  color={"#fff"}
                  fontSize={12}
                  fontWeight={"medium"}
                  borderTopRightRadius={"md"}
                ></Th>
              </Tr>
            </Thead>

            {isLoading ? (
              <Tbody>
                <Tr>
                  <Td>
                    <SkeletonText noOfLines={1} />
                  </Td>
                  <Td>
                    <SkeletonText noOfLines={1} />
                  </Td>
                  <Td>
                    <SkeletonText noOfLines={1} />
                  </Td>
                  <Td>
                    <SkeletonText noOfLines={1} />
                  </Td>
                  <Td>
                    <SkeletonText noOfLines={1} />
                  </Td>
                  <Td>
                    <SkeletonText noOfLines={1} />
                  </Td>
                  <Td>
                    <SkeletonText noOfLines={1} />
                  </Td>
                  <Td isNumeric></Td>
                </Tr>
              </Tbody>
            ) : userInitialList.length > 0 ? (
              userInitialList.map((e, index) => (
                <Tbody key={index}>
                  <Tr
                    bg={index % 2 === 0 ? "#FBFBFB" : "#F4F4F4"}
                    onClick={() => handleArrow(e, index)}
                    cursor={"pointer"}
                  >
                    <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                      {index + 1}
                    </Td>
                    <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                      {e.username}
                    </Td>
                    <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                      {e.numberOfPhysicalDevices}
                    </Td>
                    <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                      {e.numberOfDevices}
                    </Td>
                    <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                      {e.numberOfHouses}
                    </Td>
                    <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                      {e.numberOfRooms}
                    </Td>
                    <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                      {e.atTime
                        ? e.atTime.slice(0, 10) + " / " + e.atTime.slice(11, 19)
                        : "-"}
                    </Td>
                    <Td
                      isNumeric
                      color={"#000"}
                      fontSize={14}
                      fontWeight={"normal"}
                    >
                      <Icon
                        as={e.arrowIcon ? IoIosArrowUp : IoIosArrowDown}
                        color={e.arrowIcon ? "#45A735" : "#000"}
                      />
                    </Td>
                  </Tr>

                  {e.sharedUserList.length > 0
                    ? e.sharedUserList.map((a, index) => (
                        <Tr key={index}>
                          <Td
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            -
                          </Td>
                          <Td
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            <Icon
                              as={MdOutlineSubdirectoryArrowRight}
                              color={"#45A735"}
                            />{" "}
                            {a.username}
                          </Td>
                          <Td
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {a.number}
                          </Td>
                          <Td
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            -
                          </Td>
                          <Td
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            -
                          </Td>
                          <Td
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            -
                          </Td>
                          <Td
                            isNumeric
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          ></Td>
                        </Tr>
                      ))
                    : null}
                </Tbody>
              ))
            ) : null}
          </Table>
        </TableContainer>
      </VStack>
    </VStack>
  );
};

export default Analyze;
