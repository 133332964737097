import React, { useEffect, useState } from "react";
import {
  Button,
  HStack,
  Spinner,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import axios from "../../../axios";
import Cookies from "js-cookie";
import InvoiceTable from "./InvoiceTable";
import InvoiceModal from "./utilities/InvoiceModal";
import DeviceModal from "./utilities/DeviceModal";
import QuoteDrawer from "./utilities/QuoteDrawer";
import CustomPagination from "../../../components/pagination/CustomPagination";

const Invoice = () => {
  const [trigger, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const history = useHistory();
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");
  // ------------------------ Modal -----------------------------
  const [selectedData, setSelectedData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  // ------------------------ Pagination -----------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(0);

  useEffect(() => {
    // if (context.trigger) {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get(
        "/v2/quote/get",
        {
          params: {
            page: currentPage,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
      .then((result) => {
        if (cancel) return;
        // console.log(result.data);
        setData(result.data.quote);
        setCurrentPage(result.data.currentPage);
        setTotalCount(result.data.quoteLength);
        setPageSize(result.data.pageSize);
        setTrigger(false);
        setIsLoading(false);
      })
      .catch((err) => {
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
        // console.log(err);
        setIsLoading(false);
      });
    return () => {
      cancel = true;
    };
  }, [trigger]);

  const headerData = [
    "№",
    "Огноо",
    "Байгууллагын нэр",
    "Нэхэмжлэх",
    "Үнийн санал",
  ];

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "xs", md: "xl", lg: "8xl" }}
    >
      <HStack w={"full"} align={"center"} justify={"space-between"}>
        <Button
          bg={"#35A735"}
          boxShadow={"md"}
          _hover={{
            transform: "scale(1.01)",
            transition: "0.3s ease",
            // bg: "#F8F8F8",
          }}
          onClick={() => history.push("/system/invoice/create")}
          // disabled={showMinus % 2 === 0 ? true : false}
          _disabled={{
            bg: "#fff",
          }}
          _focus={{
            outline: "none",
          }}
        >
          <Text color={"#fff"} fontSize={15} fontWeight={"medium"}>
            Үнийн санал үүсгэх
          </Text>
        </Button>
      </HStack>
      <InvoiceTable
        headerData={headerData}
        bodyData={data}
        filter={false}
        initialLoading={isLoading}
        setSelectedData={setSelectedData}
        setShowModal={setShowModal}
        setVisible={setVisible}
        setShowDrawer={onOpen}
        currentPage={currentPage}
        pageSize={pageSize}
      />
      <CustomPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTrigger={setTrigger}
        totalCount={totalCount}
        pageSize={pageSize}
      />

      {/* <DeviceModal
        data={selectedData}
        showModal={visible}
        setShowModal={setVisible}
      /> */}

      <InvoiceModal
        data={selectedData}
        showModal={showModal}
        setShowModal={setShowModal}
        setTrigger={setTrigger}
      />
      <QuoteDrawer
        data={selectedData}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        setTrigger={setTrigger}
      />
    </VStack>
  );
};

export default Invoice;
