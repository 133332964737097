import React, { useState } from "react";
import {
  Button,
  Center,
  Collapse,
  Divider,
  Icon,
  Input,
  Select,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "../../axios";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Cookies from "js-cookie";

const AddModel = () => {
  const [modelId, setModelId] = useState();
  const [deviceType, setDeviceType] = useState();
  const [zoom, setZoom] = useState(false);
  const { isOpen, onToggle } = useDisclosure();
  const toast = useToast();
  const id = "test";
  const token = Cookies.get("accessToken");

  const handleZoom = () => {
    setZoom(!zoom);
    onToggle();
  };

  const handleSubmit = () => {
    axios
      .post(
        "/admin/zigbee_model_id",
        {
          modelId: modelId,
          deviceType: deviceType,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        // console.log(result);
        setModelId("");
        setDeviceType("");
        if (!toast.isActive(id)) {
          toast({
            id,
            status: "success",
            duration: 2000,
            position: "top",
            description: "Амжилттай",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  return (
    <Center
      transition="all 1s ease-in-out"
      bg={"#fff"}
      boxShadow={"lg"}
      rounded={"lg"}
      pos={"absolute"}
      right={{ base: "4", md: "10" }}
      borderTopWidth={"medium"}
      borderColor={"#45A735"}
      w={zoom ? { base: "xs", md: "sm" } : "14"}
      height={zoom ? "xs" : "14"}
    >
      <Icon
        as={zoom ? IoIosArrowUp : IoIosArrowDown}
        pos={"absolute"}
        top={"4"}
        w={"5"}
        h={"5"}
        onClick={handleZoom}
        cursor={"pointer"}
      />
      <Collapse
        className="addModel"
        in={isOpen}
        display={zoom ? "block" : "none"}
        animateOpacity
      >
        <VStack>
          <Text fontSize={"md"} color={"#000"} fontWeight="medium">
            Төхөөрөмжийн төрөл нэмэх
          </Text>
          <Divider />
          <Input
            variant="filled"
            placeholder="model id"
            onChange={(e) => setModelId(e.target.value)}
            value={modelId}
            bg={"#FCFCFC"}
            borderColor={"#EFEFEF"}
            borderWidth={1}
            w={"2xs"}
            type={"text"}
            _hover={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            _focus={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
          />
          <Select
            variant="filled"
            placeholder="-"
            bg={"#FCFCFC"}
            borderColor={"#EFEFEF"}
            borderWidth={1}
            onChange={(q) => setDeviceType(q.target.value)}
            value={deviceType}
            multiple={false}
            _hover={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            _focus={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
          >
            {devices.map((e, index) => (
              <option key={index} value={e.type}>
                {e.type}
              </option>
            ))}
          </Select>
          <Button
            w={"full"}
            bg={"#45A735"}
            _hover={{
              bg: "#5AB54B",
            }}
            _focus={{
              outline: "none",
            }}
            onClick={() => handleSubmit()}
            disabled={modelId && deviceType ? false : true}
          >
            <Text color={"#fff"} fontWeight="normal">
              Хадгалах
            </Text>
          </Button>
        </VStack>
      </Collapse>
    </Center>
  );
};

export default AddModel;

const devices = [
  { type: "Water" },
  { type: "zigbeeTh" },
  { type: "Occupancy" },
  { type: "Smoke" },
  { type: "zigbeeSwitch" },
  { type: "Contact" },
  { type: "RGB" },
];
