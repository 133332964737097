import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  HStack,
  Icon,
  Skeleton,
  Spinner,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import axios from "../../axios";
import Cookies from "js-cookie";
import { FiInfo } from "react-icons/fi";
import BackButton from "../../components/button/BackButton";

const NotificationDetail = () => {
  const [device, setDevice] = useState();
  const [disDetail, setDisDetail] = useState([]);
  const [reDetail, setReDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [disLoading, setDisLoading] = useState(false);
  const [reLoading, setReLoading] = useState(false);
  const [ignoreData, setIgnoreData] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const location = useLocation();
  const toast = useToast();
  const id = "test";
  const token = Cookies.get("accessToken");

  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get(
        "/admin/connection_detail",
        {
          params: {
            _id: location.state.e._id,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
      .then((result) => {
        if (cancel) return;
        // console.log("detail", result.data.data);
        setDevice(result.data.data);
        setIsLoading(false);
        setTrigger(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
    return () => {
      cancel = true;
    };
  }, [trigger]);

  const DisBtnHandler = (e) => {
    setDisLoading(true);
    axios
      .get("/admin/device_details", {
        params: {
          clientId: e.clientId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        // console.log(result.data.data);
        setDisDetail(result.data.data);
        setDisLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setDisLoading(false);
      });
  };
  const ReBtnHandler = (e) => {
    setReLoading(true);
    axios
      .get("/admin/device_details", {
        params: {
          clientId: e.clientid,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        // console.log(result.data.data);
        setReDetail(result.data.data);
        setReLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setReLoading(false);
      });
  };

  const ignoreSelectHandler = (e) => {
    // console.log("_id shdee ", e);
    if (ignoreData.find((a) => a === e)) {
      setIgnoreData((prevState) => prevState.filter((r) => r !== e));
    } else {
      const select = [...ignoreData];
      const allSelect = select.concat(e);
      setIgnoreData(allSelect);
    }
  };

  const ignoreHandler = () => {
    // console.log("ignore hiilee", ignoreData);
    if (ignoreData.length > 0) {
      axios
        .post(
          "/admin/ignore_device",
          {
            clientId: ignoreData,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result);
          setTrigger(true);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      // bg={"red"}
      mb={"10"}
      w={{ base: "full", md: "full", lg: "8xl" }}
    >
      <HStack
        w={{ base: "full", md: "full", lg: "3xl" }}
        justify={"space-between"}
      >
        {" "}
        <BackButton />
        <Button
          px={"4"}
          borderRadius="md"
          cursor="pointer"
          boxShadow={"md"}
          size={"sm"}
          bg={"#45A735"}
          _hover={{
            bg: "#5AB54B",
          }}
          _focus={{
            outline: "none",
          }}
          onClick={ignoreHandler}
          disabled={ignoreData.length > 0 ? false : true}
        >
          <Text fontSize={14} color={"#fff"} fontWeight={"normal"}>
            Ignore
          </Text>
        </Button>
      </HStack>
      <VStack
        bg={"#fff"}
        shadow={"md"}
        rounded={"md"}
        p={"4"}
        w={{ base: "full", md: "full", lg: "3xl" }}
      >
        <Stack
          direction={{ base: "row", md: "row" }}
          justify={"space-between"}
          w={"full"}
          bg={"#F8F8F8"}
          borderLeftWidth={"medium"}
          borderColor={"#45A735"}
          py={"2"}
          px={{ base: "4", md: "6" }}
          mb={"4"}
        >
          <Text color={"#000"} fontWeight={"normal"} fontSize={14}>
            Цаг:
          </Text>
          <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
            {device ? device.atTime.slice(0, 10) : "-"}
            {" / "}
            {device ? device.atTime.slice(11, 19) : "-"}
          </Text>
        </Stack>
        {isLoading ? (
          <HStack justify={"space-between"} w={"full"} spacing={4}>
            <Skeleton
              display={{ base: "flex", md: "flex", lg: "flex" }}
              w={"full"}
              h={"10"}
              borderRadius={"md"}
              startColor="blackAlpha.100"
              endColor="blackAlpha.300"
            />
            <Skeleton
              display={{ base: "none", md: "none", lg: "flex" }}
              w={"full"}
              h={"10"}
              borderRadius={"md"}
              startColor="blackAlpha.100"
              endColor="blackAlpha.300"
            />
          </HStack>
        ) : device ? (
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={4}
            w={"full"}
            justify={"space-between"}
          >
            <VStack w={"full"} spacing={3}>
              <VStack spacing={0}>
                <Text color={"#45A735"} fontWeight={"medium"} fontSize={15}>
                  Disconnect
                </Text>
                <Box w={"8"} h={"0.5"} bg={"#45A735"} />
              </VStack>
              {device.disconnectedDevices.length > 0 ? (
                <Accordion allowToggle={true} w={"full"}>
                  {device.disconnectedDevices.map((e, index) => {
                    return (
                      <HStack mt={"2"} key={index}>
                        <Checkbox
                          colorScheme="red"
                          size={"lg"}
                          value={ignoreData}
                          onChange={() => ignoreSelectHandler(e.clientId)}
                          css={`
                            > span:first-of-type {
                              box-shadow: unset;
                            }
                          `}
                        />
                        <AccordionItem
                          w={"full"}
                          border="none"
                          bg={"#eee"}
                          rounded={"md"}
                        >
                          <h2>
                            <AccordionButton
                              rounded={"md"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              _focus={{
                                outline: "none",
                              }}
                              _expanded={{ bg: "#45A735", color: "#fff" }}
                              onClick={() => DisBtnHandler(e)}
                            >
                              <Text
                                fontWeight={"normal"}
                                fontSize={14}
                                textAlign="left"
                              >
                                {e.clientId}
                              </Text>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            {disLoading ? (
                              <Center width={"full"} my={"4"}>
                                <Spinner
                                  thickness="2px"
                                  speed="0.65s"
                                  emptyColor="gray.200"
                                  color="#45A735"
                                  size="md"
                                />
                              </Center>
                            ) : disDetail.length > 0 ? (
                              disDetail.map((e, index) => (
                                <VStack
                                  key={index}
                                  mt={index === 0 ? 0 : 4}
                                  bg={"#fff"}
                                  rounded={"md"}
                                  p={"4"}
                                  spacing={1}
                                >
                                  <HStack justify={"space-between"} w={"full"}>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"normal"}
                                    >
                                      contractId
                                    </Text>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"medium"}
                                    >
                                      {e.contractId}
                                    </Text>
                                  </HStack>
                                  <HStack justify={"space-between"} w={"full"}>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"normal"}
                                    >
                                      for
                                    </Text>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"medium"}
                                    >
                                      {e.for}
                                    </Text>
                                  </HStack>
                                  <Divider />
                                  <HStack justify={"space-between"} w={"full"}>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"normal"}
                                    >
                                      deviceType
                                    </Text>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"medium"}
                                    >
                                      {e.deviceType}
                                    </Text>
                                  </HStack>
                                  <HStack justify={"space-between"} w={"full"}>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"normal"}
                                    >
                                      entityName
                                    </Text>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"medium"}
                                    >
                                      {e.entityName}
                                    </Text>
                                  </HStack>
                                  <HStack justify={"space-between"} w={"full"}>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"normal"}
                                    >
                                      house
                                    </Text>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"medium"}
                                    >
                                      {e.house}
                                    </Text>
                                  </HStack>
                                  <HStack justify={"space-between"} w={"full"}>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"normal"}
                                    >
                                      room
                                    </Text>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"medium"}
                                    >
                                      {e.room ? e.room : "-"}
                                    </Text>
                                  </HStack>
                                  <HStack justify={"space-between"} w={"full"}>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"normal"}
                                    >
                                      _id
                                    </Text>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"medium"}
                                    >
                                      {e._id}
                                    </Text>
                                  </HStack>
                                </VStack>
                              ))
                            ) : (
                              <VStack
                                justifyContent="center"
                                alignItems="center"
                                alignSelf={"center"}
                                color="gray.600"
                                w="full"
                                mt={"2"}
                                spacing={1}
                              >
                                <Icon as={FiInfo} w={6} h={6} />
                                <Text fontSize={15}>Хоосон байна.</Text>
                              </VStack>
                            )}
                          </AccordionPanel>
                        </AccordionItem>
                      </HStack>
                    );
                  })}
                </Accordion>
              ) : (
                <VStack
                  justifyContent="center"
                  alignItems="center"
                  alignSelf={"center"}
                  color="gray.600"
                  w="full"
                  pt={"10"}
                >
                  <Icon as={FiInfo} w={7} h={7} />
                  <Text fontSize={15}>Хоосон байна.</Text>
                </VStack>
              )}
            </VStack>
            <VStack w={"full"} spacing={3}>
              <VStack spacing={0}>
                <Text color={"#45A735"} fontWeight={"medium"} fontSize={15}>
                  Reconnect
                </Text>
                <Box w={"8"} h={"0.5"} bg={"#45A735"} />
              </VStack>
              {device.reconnectedDevices.length > 0 ? (
                <Accordion allowToggle={true} w={"full"}>
                  {device.reconnectedDevices.map((e, index) => {
                    return (
                      <HStack mt={"2"}>
                        <Checkbox
                          colorScheme="red"
                          size={"lg"}
                          value={ignoreData}
                          onChange={() => ignoreSelectHandler(e.clientid)}
                          _focus={{
                            outline: "none",
                          }}
                        />
                        <AccordionItem
                          key={index}
                          w={"full"}
                          border="none"
                          bg={"#eee"}
                          rounded={"md"}
                        >
                          <h2>
                            <AccordionButton
                              rounded={"md"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              _focus={{
                                outline: "none",
                              }}
                              _expanded={{ bg: "#45A735", color: "#fff" }}
                              onClick={() => ReBtnHandler(e)}
                            >
                              <Text
                                fontWeight={"normal"}
                                fontSize={14}
                                textAlign="left"
                              >
                                {e.clientid}
                              </Text>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            {reLoading ? (
                              <Center width={"full"} my={"4"}>
                                <Spinner
                                  thickness="2px"
                                  speed="0.65s"
                                  emptyColor="gray.200"
                                  color="#45A735"
                                  size="md"
                                />
                              </Center>
                            ) : reDetail.length > 0 ? (
                              reDetail.map((e, index) => (
                                <VStack
                                  key={index}
                                  mt={index === 0 ? 0 : 4}
                                  bg={"#fff"}
                                  rounded={"md"}
                                  p={"4"}
                                  spacing={1}
                                >
                                  <HStack justify={"space-between"} w={"full"}>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"normal"}
                                    >
                                      contractId
                                    </Text>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"medium"}
                                    >
                                      {e.contractId}
                                    </Text>
                                  </HStack>
                                  <HStack justify={"space-between"} w={"full"}>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"normal"}
                                    >
                                      for
                                    </Text>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"medium"}
                                    >
                                      {e.for}
                                    </Text>
                                  </HStack>
                                  <Divider />
                                  <HStack justify={"space-between"} w={"full"}>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"normal"}
                                    >
                                      deviceType
                                    </Text>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"medium"}
                                    >
                                      {e.deviceType}
                                    </Text>
                                  </HStack>
                                  <HStack justify={"space-between"} w={"full"}>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"normal"}
                                    >
                                      entityName
                                    </Text>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"medium"}
                                    >
                                      {e.entityName}
                                    </Text>
                                  </HStack>
                                  <HStack justify={"space-between"} w={"full"}>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"normal"}
                                    >
                                      house
                                    </Text>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"medium"}
                                    >
                                      {e.house}
                                    </Text>
                                  </HStack>
                                  <HStack justify={"space-between"} w={"full"}>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"normal"}
                                    >
                                      room
                                    </Text>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"medium"}
                                    >
                                      {e.room ? e.room : "-"}
                                    </Text>
                                  </HStack>
                                  <HStack justify={"space-between"} w={"full"}>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"normal"}
                                    >
                                      _id
                                    </Text>
                                    <Text
                                      color={"#000"}
                                      fontSize={14}
                                      fontWeight={"medium"}
                                    >
                                      {e._id}
                                    </Text>
                                  </HStack>
                                </VStack>
                              ))
                            ) : (
                              <VStack
                                justifyContent="center"
                                alignItems="center"
                                alignSelf={"center"}
                                color="gray.600"
                                w="full"
                                mt={"2"}
                                spacing={1}
                              >
                                <Icon as={FiInfo} w={6} h={6} />
                                <Text fontSize={15}>Хоосон байна.</Text>
                              </VStack>
                            )}
                          </AccordionPanel>
                        </AccordionItem>
                      </HStack>
                    );
                  })}
                </Accordion>
              ) : (
                <VStack
                  justifyContent="center"
                  alignItems="center"
                  alignSelf={"center"}
                  color="gray.600"
                  w="full"
                  pt={"10"}
                >
                  <Icon as={FiInfo} w={7} h={7} />
                  <Text fontSize={15}>Хоосон байна.</Text>
                </VStack>
              )}
            </VStack>
          </Stack>
        ) : null}
      </VStack>
    </VStack>
  );
};

export default NotificationDetail;
