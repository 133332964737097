import React from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";

const BreadCrumb = () => {
  const location = useLocation();
  const { pathname } = location;
  const { id } = useParams();

  // console.log(location);

  const pathSegments = pathname.split("/").filter((segment) => segment !== "");
  const breadcrumbs = pathSegments.map((segment, index) => {
    const url = `/${pathSegments.slice(0, index + 1).join("/")}`;
    return (
      <BreadcrumbItem
        key={segment}
        isCurrentPage={segment === "settings" ? true : false}
      >
        <BreadcrumbLink
          href={url}
          _focus={{
            outline: "none",
          }}
        >
          {segment === "dashboard"
            ? "Хяналтын самбар"
            : segment === "settings"
            ? "Тохиргоо"
            : segment === "device"
            ? "Төхөөрөмж"
            : segment === "type"
            ? "Төрөл"
            : segment === "interval"
            ? "Интервал"
            : segment === "search"
            ? "Хайх"
            : segment === "profile"
            ? "Профайл"
            : segment === "User"
            ? "Хэрэглэгч"
            : segment === "find"
            ? "Хэрэглэгч хайх"
            : segment === "device-list"
            ? "Төхөөрөмжийн жагсаалт"
            : segment === "notification"
            ? "Мэдэгдэл"
            : segment === "analyze"
            ? "Төлбөрийн мэдээлэл"
            : segment === "complain"
            ? "Гомдол"
            : segment === "location"
            ? "Байршил"
            : segment === "invoice"
            ? "Нэхэмжлэх"
            : segment === "api"
            ? "API service"
            : segment === "system"
            ? "Систем"
            : segment === "calculator"
            ? "Тооцоолуур"
            : segment === "create"
            ? "Үүсгэх"
            : segment}
        </BreadcrumbLink>
      </BreadcrumbItem>
    );
  });

  return (
    <Breadcrumb
      spacing={2}
      separator={<FiChevronRight color="gray.100" />}
      fontSize={13}
      fontWeight={"normal"}
    >
      <BreadcrumbItem isCurrentPage>
        <BreadcrumbLink>Хуудас</BreadcrumbLink>
      </BreadcrumbItem>
      {breadcrumbs.length === 0 ? (
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Хяналтын самбар</BreadcrumbLink>
        </BreadcrumbItem>
      ) : (
        breadcrumbs
      )}
    </Breadcrumb>
  );
};

export default BreadCrumb;
