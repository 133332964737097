import React, { useState } from "react";
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import CustomInput from "../../../../components/input/CustomInput";
import axios from "../../../../axios";
import Cookies from "js-cookie";

const LandingModal = ({ data, showModal, setShowModal, setTrigger }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");

  const submitHandler = () => {
    if (data) {
      setIsDisabled(true);
      axios
        .post(
          "/v2/landin/check_visibility",
          {
            _id: data._id,
            visibleInLanding: data.visibleInLanding ? false : true,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log("sumbit result: --->", result);
          setTrigger(true);
          setShowModal(false);
        })
        .catch((err) => {
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        })
        .finally(() => setIsDisabled(false));
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      size={"sm"}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color={"#000"} fontSize={17} fontWeight={"medium"}>
          Итгэлтэй байна уу?
        </ModalHeader>
        <ModalBody>
          {data?.visibleInLanding ? (
            <Text as="i" color={"#000"} fontSize={13} fontWeight={"normal"}>
              <Text
                as="span"
                color={"#f00"}
                fontSize={13}
                fontWeight={"medium"}
              >
                "Хэрэглэгчээс нуух"
              </Text>{" "}
              товчийг дарснаар танилцуулга веб сайт дээр тухайн төхөөрөмж
              харагдахгүй болохыг анхаарна уу!
            </Text>
          ) : (
            <Text as="i" color={"#000"} fontSize={13} fontWeight={"normal"}>
              <Text
                as="span"
                color={"#45A735"}
                fontSize={13}
                fontWeight={"medium"}
              >
                "Хэрэглэгчид харуулах"
              </Text>{" "}
              товчийг дарснаар танилцуулга веб сайт дээр тухайн төхөөрөмж
              харагдахыг анхаарна уу!
            </Text>
          )}
        </ModalBody>
        <ModalFooter gap={2}>
          <Button
            w={"full"}
            color={"#000"}
            fontWeight={"medium"}
            fontSize={13}
            onClick={() => setShowModal(false)}
          >
            Болих
          </Button>
          <Button
            w={"full"}
            bg={"#45A735"}
            color={"#fff"}
            fontWeight={"medium"}
            fontSize={13}
            _hover={{
              bg: "#3E9330",
            }}
            _disabled={{
              bg: "#8cd97f",
            }}
            _focus={{
              outline: "none",
            }}
            onClick={submitHandler}
            isDisabled={isDisabled}
          >
            {isDisabled ? <Spinner color="#fff" mr={2} size="sm" /> : null}
            Тийм
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LandingModal;
