import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { RiDraftLine, RiTimeLine } from "react-icons/ri";
import { GoCommentDiscussion } from "react-icons/go";
import axios from "../../axios";
import Cookies from "js-cookie";
import Comment from "./Comment";

const ComplainCard = ({
  id,
  title,
  desc,
  resolved,
  comment,
  time,
  admin,
  keyData,
  setTrigger,
}) => {
  const [showCmt, setShowCmt] = useState(false);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const resolvedHandler = () => {
    if (!resolved) {
      axios
        .post(
          "/admin/change_complain_status",
          {
            _id: id,
            status: true,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          toast({
            position: "top",
            status: "success",
            duration: 2000,
            description: "Амжилттай!",
          });
          setTrigger(true);
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      toast({
        position: "top",
        status: "error",
        duration: 2000,
        description: "Засагдсан байна!",
      });
    }
  };

  return (
    <VStack
      w={"full"}
      pos={"relative"}
      rounded={"md"}
      borderWidth={"thin"}
      // borderColor={"#ECECEC"}
      borderColor={"#45A735"}
      px={"4"}
      py={"4"}
      align={"flex-start"}
    >
      <Center pos={"absolute"} top={-3} left={4} bg={"#fff"} px={"2"}>
        <Text fontSize={17} color={"#000"} fontWeight={"medium"}>
          {keyData < 9 ? 0 : null}
          {keyData + 1}
        </Text>
      </Center>
      <Center pos={"absolute"} top={-6} right={4} bg={"#fff"} px={"2"}>
        <Button
          bg={"#fff"}
          borderWidth={"thin"}
          borderColor={resolved ? "#45A735" : "#ff0000"}
          size={"sm"}
          fontSize={15}
          color={"#000"}
          fontWeight={"medium"}
          transition={"all .3s"}
          onClick={() => {
            resolved
              ? toast({
                  position: "top",
                  status: "error",
                  duration: 2000,
                  description: "Засагдсан байна!",
                })
              : onOpen();
          }}
          _hover={{
            bg: resolved ? "#45A735" : "#ff0000",
            color: "#fff",
          }}
          _focus={{
            outline: "none",
          }}
        >
          {resolved ? "Засагдсан" : "Засагдаагүй"}
        </Button>
      </Center>
      <Box
        w={"full"}
        bg={"#F8F8F8"}
        rounded={"md"}
        borderColor={"#45A735"}
        py={"2"}
        px={{ base: "4", md: "6" }}
        mb={"4"}
      >
        <Text fontSize={15} color={"#000"} fontWeight={"medium"}>
          {title}
        </Text>
      </Box>
      <Text
        fontSize={15}
        color={"#000"}
        fontWeight={"normal"}
        textAlign={"justify"}
      >
        {desc}
      </Text>

      <Stack
        w={"full"}
        direction={{ base: "column-reverse", md: "row", lg: "row" }}
        justify={"space-between"}
        pt={"4"}
      >
        <HStack spacing={4}>
          <HStack>
            <Icon as={RiDraftLine} w={5} h={5} />
            <Text
              fontSize={15}
              color={"#000"}
              // color={"#7E7E7E"}
              fontWeight={"normal"}
            >
              {admin}
            </Text>
          </HStack>
          <HStack
            transition={"all .3s"}
            spacing={2}
            color={"#000"}
            _hover={{
              color: "#45A735",
            }}
            cursor={"pointer"}
            onClick={() => setShowCmt(!showCmt)}
          >
            <Icon as={GoCommentDiscussion} w={5} h={5} />
            <Text fontSize={15} fontWeight={"normal"}>
              {comment}
            </Text>
          </HStack>
        </HStack>

        <HStack>
          <Icon as={RiTimeLine} w={5} h={5} />
          <Text fontSize={15} color={"#000"} fontWeight={"normal"}>
            {time ? time.slice(0, 10) + " / " + time.slice(11, 19) : null}
          </Text>
        </HStack>
      </Stack>
      <Comment showCmt={showCmt} id={id} />

      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Итгэлтэй байна уу?</ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={onClose}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="green"
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={resolvedHandler}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default ComplainCard;
