import React from "react";
import {
  Center,
  Divider,
  Flex,
  Grid,
  Icon,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { SiZigbee } from "react-icons/si";
import { HiArrowSmRight } from "react-icons/hi";

const DeviceCategory = () => {
  const history = useHistory();

  return (
    <VStack
      alignSelf={{ base: "center", md: "center", lg: "flex-start" }}
      spacing={"6"}
      w={{ base: "xs", md: "md", lg: "4xl" }}
      mt={{ base: "4", md: "4" }}
      mb={"10"}
    >
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
        }}
        gap={8}
        justifyContent={{ base: "center", md: "flex-start" }}
        alignItems={{ base: "center", md: "flex-start" }}
        // alignSelf={"center"}
      >
        {data.map((e, index) => (
          <VStack
            transition={"ease-in-out .3s"}
            key={index}
            bgGradient="linear(to-br, #fff, gray.200)"
            w={"72"}
            h={"32"}
            rounded="lg"
            shadow={"base"}
            alignItems={"center"}
            justifyContent={"center"}
            pos="relative"
            _hover={{
              transform: "scale(1.02)",
            }}
            cursor={"pointer"}
            onClick={() => history.push(e.toLink)}
          >
            <Flex
              p={2}
              w="max-content"
              color="white"
              bgGradient="linear(to-br, #99E48D, #45A735)"
              rounded="md"
              marginInline="auto"
              pos="absolute"
              left={5}
              // right={0}
              top="-0.8rem"
              boxShadow="lg"
            >
              <Image src={e.image} objectFit={"contain"} w={"5"} h={"5"} />
            </Flex>
            <Text
              fontSize="14"
              fontWeight={"bold"}
              letterSpacing={"wide"}
              color={"#000"}
              textTransform={"uppercase"}
            >
              {e.title}
            </Text>
            <Divider w={"20"} borderColor={"#ddd"} />
            <Center pos="absolute" right={4} bottom={4}>
              <Icon as={HiArrowSmRight} w={"6"} h={"6"} color={"#45A735"} />
            </Center>
          </VStack>
        ))}
      </Grid>
    </VStack>
  );
};

const data = [
  {
    title: "regular",
    toLink: "/device/type/add-device",
    image: require("../Images/tasmota.png"),
  },
  {
    title: "zigbee",
    toLink: "/device/type/zigbee",
    icon: SiZigbee,
    image: require("../Images/zigbeeLogo.png"),
  },
  {
    title: "zlan",
    toLink: "/device/type/zlan",
    image: require("../Images/zlan.png"),
  },
  {
    title: "lora",
    toLink: "/device/type/lora",
    image: require("../Images/loraLogo.png"),
  },
  {
    title: "knx",
    toLink: "/device/type/knx",
    image: require("../Images/knx.png"),
  },
  {
    title: "IR",
    toLink: "/device/type/ir",
    image: require("../Images/IR.png"),
  },
  {
    title: "G4 - single",
    toLink: "/device/type/g4",
    image: require("../Images/IR.png"),
  },
  {
    title: "G4 - multi",
    toLink: "/device/type/g4-multi",
    image: require("../Images/IR.png"),
  },
  {
    title: "Air quality sensor",
    toLink: "/device/type/aq",
    image: require("../Images/tasmota.png"),
  },
];
export default DeviceCategory;
