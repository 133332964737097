import React, { useEffect, useState } from "react";
import {
  HStack,
  Icon,
  Skeleton,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "../../../axios";
import { useHistory, useLocation } from "react-router-dom";
import { FiInfo } from "react-icons/fi";
import Cookies from "js-cookie";
import BackButton from "../../../components/button/BackButton";

const TtrUpdateLog = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [updateLog, setUpdateLog] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "test";

  // ----------------- Tap to run update log --------------------
  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/user_taptorun_updatelog", {
        params: {
          _id: location.state.e._id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log(result.data.data);
        setUpdateLog(result.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
        setIsLoading(false);
      });
    return () => {
      cancel = true;
    };
  }, []);

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "xs", md: "full", lg: "8xl" }}
    >
      <BackButton />
      {isLoading ? (
        <HStack spacing={"10"}>
          <Skeleton
            w={{ base: "full", md: "xl" }}
            h={"12"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : updateLog.length > 0 ? (
        <VStack
          w={{ base: "full", md: "full", lg: "xl" }}
          bg={"#fff"}
          boxShadow={"lg"}
          p={{ base: "2", md: "4" }}
          borderRadius={"lg"}
          spacing={6}
        >
          <VStack w={"full"}>
            <Stack
              direction={{ base: "column", md: "row" }}
              justify={"space-between"}
              w={"full"}
              bg={"#F8F8F8"}
              borderTopRightRadius={"md"}
              borderBottomRightRadius={"md"}
              py={"2"}
              px={"6"}
              borderLeftWidth={"medium"}
              borderLeftColor={"#45A735"}
              spacing={1}
            >
              <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                Багц үйлдлийн нэр:
              </Text>
              <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                {location.state.e.name}
              </Text>
            </Stack>
            <Stack
              direction={{ base: "column", md: "row" }}
              justify={"space-between"}
              w={"full"}
              bg={"#F8F8F8"}
              borderTopRightRadius={"md"}
              borderBottomRightRadius={"md"}
              py={"2"}
              px={"6"}
              borderLeftWidth={"medium"}
              borderLeftColor={"#45A735"}
              spacing={1}
            >
              <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                Багц үйлдлийн ID:
              </Text>
              <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                {location.state.e._id}
              </Text>
            </Stack>
          </VStack>
          <Stack flexDirection={"row"} flexWrap={"wrap"} spacing={2} w={"full"}>
            {updateLog.map((e, index) => (
              <HStack
                key={index}
                w={"full"}
                justify={"space-between"}
                bg={"#F9F9F9"}
                borderColor={"#eee"}
                borderWidth={"thin"}
                p={"4"}
                rounded={"md"}
                _hover={{
                  bg: "#fff",
                  shadow: "base",
                }}
                cursor={"pointer"}
                onClick={() =>
                  history.push("/User/find/ttr-update-log-detail", {
                    id: e._id,
                    contractId: location.state.contractId,
                  })
                }
              >
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {index + 1}.
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  Шинэчилсэн цаг:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.updatedAt.slice(0, 10)} / {e.updatedAt.slice(11, 19)}
                </Text>
              </HStack>
            ))}
          </Stack>
        </VStack>
      ) : (
        <VStack
          justifyContent="center"
          alignItems="center"
          color="gray.600"
          w="full"
        >
          <Icon mt={"20"} as={FiInfo} w={7} h={7} />
          <Text fontSize={15}>Мэдээлэл олдсонгүй</Text>
        </VStack>
      )}
    </VStack>
  );
};

export default TtrUpdateLog;
