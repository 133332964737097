import React, { useEffect, useState } from "react";
import {
  Center,
  Icon,
  Text,
  VStack,
  Avatar,
  Flex,
  HStack,
  GridItem,
  Grid,
  Skeleton,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  useDisclosure,
  Button,
  useToast,
  Divider,
  StackDivider,
} from "@chakra-ui/react";
import { AiFillStar } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import axios from "../../axios";
import { FiInfo } from "react-icons/fi";
import Cookies from "js-cookie";
import BackButton from "../../components/button/BackButton";

const FeedBack = () => {
  const [feedBack, setFeedBack] = useState([]);
  // const [monitorFeedBack, setMonitorFeedBack] = useState([]);
  const [guestFb, setGuestFb] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectData, setSelectData] = useState([]);
  const [activeBtn, setActiveBtn] = useState("Хэрэглэгч");
  const history = useHistory();
  const toast = useToast();
  const id = "test-toast";
  const token = Cookies.get("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get(
        "/admin/feedback",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
      .then((result) => {
        // console.log(result.data);
        setFeedBack(result.data.data);
        setGuestFb(result.data.landingPageData);
        // setMonitorFeedBack(result.data);
        setTrigger(false);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
  }, [trigger]);

  // --------------------- Select Data ------------------------
  const selectHandler = (e) => {
    if (selectData.find((a) => a === e)) {
      setSelectData((prevState) => prevState.filter((r) => r !== e));
    } else {
      const select = [...selectData];
      const allSelect = select.concat(e);
      setSelectData(allSelect);
    }
  };

  // --------------------- delete Data ------------------------
  const deleteHandler = () => {
    onClose();
    // console.log("selectData --", selectData);
    axios
      .delete("/admin/feedback", {
        data: selectData,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        // console.log(result);
        setTrigger(true);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "success",
            description: "Амжилттай устлаа!",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "full", md: "full", lg: "8xl" }}
    >
      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} px={"8"} onClick={onClose}>
              Үгүй
            </Button>
            <Button colorScheme="red" px={"8"} onClick={deleteHandler}>
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <VStack w={"full"} spacing={3}>
        <HStack w={"full"} justify={"space-between"} align={"center"}>
          <BackButton />
          <Flex
            align={"center"}
            w="full"
            _before={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: "#45A735",
              flexGrow: 2,
              mr: 2,
            }}
          >
            <Center
              py={"1"}
              px={{ base: "6", md: "4" }}
              borderRadius="md"
              bg={"#ff0000"}
              cursor="pointer"
              boxShadow={"md"}
              onClick={onOpen}
            >
              <Text fontSize={14} color={"#fff"} fontWeight={"normal"}>
                Устгах
              </Text>
            </Center>
          </Flex>
        </HStack>
        <HStack
          divider={<StackDivider borderColor={"gray.200"} />}
          // w={"4xl"}
          w={"2xl"}
          py={"1.5"}
          align={"center"}
          justify={"center"}
          spacing={"6"}
        >
          <Center
            bg={"#fff"}
            rounded={"md"}
            borderWidth={"thin"}
            borderColor={activeBtn === "Хэрэглэгч" ? "#45A735" : "transparent"}
            w={"full"}
            py={"1.5"}
            cursor={"pointer"}
            _hover={{
              shadow: "rgba(99, 99, 99, 0.1) 0px 1px 3px 2px",
            }}
            onClick={() => setActiveBtn("Хэрэглэгч")}
          >
            <Text color={"#000"} fontWeight={"normal"} fontSize={15}>
              Хэрэглэгч
            </Text>
          </Center>

          {/* <Center
            bg={"#fff"}
            rounded={"md"}
            borderWidth={"thin"}
            borderColor={
              activeBtn === "Хяналтын систем" ? "#45A735" : "transparent"
            }
            w={"full"}
            py={"1.5"}
            cursor={"pointer"}
            _hover={{
              shadow: "rgba(99, 99, 99, 0.1) 0px 1px 3px 2px",
            }}
            onClick={() => setActiveBtn("Хяналтын систем")}
          >
            <Text color={"#000"} fontWeight={"normal"} fontSize={15}>
              Хяналтын систем
            </Text>
          </Center> */}

          <Center
            transition={"ease-in-out .3s"}
            bg={"#fff"}
            rounded={"md"}
            borderWidth={"thin"}
            borderColor={activeBtn === "Зочин" ? "#45A735" : "transparent"}
            w={"full"}
            py={"1.5"}
            cursor={"pointer"}
            _hover={{
              shadow: "rgba(99, 99, 99, 0.1) 0px 1px 3px 2px",
            }}
            onClick={() => setActiveBtn("Зочин")}
          >
            <Text color={"#000"} fontWeight={"normal"} fontSize={15}>
              Зочин
            </Text>
          </Center>
        </HStack>
      </VStack>
      {isLoading ? (
        <HStack spacing={"10"}>
          <Skeleton
            w={{ base: "xs", md: "xs", lg: "md" }}
            h={"36"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            display={{ base: "none", md: "flex" }}
            w={{ base: "xs", md: "xs", lg: "md" }}
            h={"36"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            display={{ base: "none", md: "none", lg: "flex" }}
            w={"md"}
            h={"36"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : feedBack.length > 0 || guestFb.length > 0 ? (
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          gap={6}
          w="full"
          pos={"relative"}
        >
          {activeBtn === "Хэрэглэгч" ? (
            feedBack.length > 0 ? (
              feedBack.map((e, index) => (
                <GridItem key={index}>
                  <Flex
                    boxShadow={"lg"}
                    maxW={"640px"}
                    width={"full"}
                    rounded={"xl"}
                    p={6}
                    justifyContent={"space-between"}
                    position={"relative"}
                    bg={"white"}
                  >
                    <Checkbox
                      colorScheme="green"
                      size={"lg"}
                      pos={"absolute"}
                      top={2}
                      right={2}
                      value={selectData}
                      onChange={() => selectHandler(e._id)}
                      css={`
                        > span:first-of-type {
                          box-shadow: unset;
                        }
                      `}
                    />

                    <VStack align={"flex-start"} spacing={"2"} w="full">
                      <HStack justify={"space-between"} w={"full"}>
                        <HStack>
                          <Avatar w={"10"} h={"10"} />
                          <VStack align={"flex-start"} spacing={"0"}>
                            <Text
                              fontWeight={"medium"}
                              fontSize={"sm"}
                              color={"#000"}
                            >
                              {e.contractId}
                            </Text>
                            <Text
                              fontWeight={"normal"}
                              fontSize={"sm"}
                              color={"#818181"}
                              maxW={{ base: "none", md: "36", lg: "none" }}
                            >
                              {e.mail}
                            </Text>
                          </VStack>
                        </HStack>
                        <HStack>
                          <Icon as={AiFillStar} color={"orange"} w={5} h={5} />
                          <Text
                            fontWeight={"medium"}
                            fontSize={"sm"}
                            color={"#000"}
                          >
                            {e.rate} / 5
                          </Text>
                        </HStack>
                      </HStack>

                      <Flex
                        align={"center"}
                        w="full"
                        _before={{
                          content: '""',
                          borderBottom: "1px solid",
                          borderColor: "#45A735",
                          flexGrow: 1.8,
                          mr: 2,
                        }}
                        _after={{
                          content: '""',
                          borderBottom: "1px solid",
                          borderColor: "#45A735",
                          flexGrow: 0.2,
                          ml: 2,
                        }}
                      >
                        {e.atTime ? (
                          <Text
                            fontWeight={"medium"}
                            fontSize={"sm"}
                            color={"#000"}
                          >
                            {e.atTime.slice(0, 10)}
                            {" / "}
                            {e.atTime.slice(11, 19)}
                          </Text>
                        ) : (
                          <Text
                            fontWeight={"medium"}
                            fontSize={"sm"}
                            color={"#000"}
                          >
                            Хугацаагүй
                          </Text>
                        )}
                      </Flex>
                      <Text fontWeight={"normal"} fontSize={"sm"}>
                        {e.description}
                      </Text>
                    </VStack>
                  </Flex>
                </GridItem>
              ))
            ) : (
              <VStack
                justifyContent="center"
                alignItems="center"
                color="gray.600"
                w="full"
                mt={"52"}
                pos={"absolute"}
              >
                <Icon as={FiInfo} w={7} h={7} />
                <Text fontSize={15}>Санал хүсэлт байхгүй байна.</Text>
              </VStack>
            )
          ) : // : activeBtn === "Хяналтын систем" ? (
          //   monitorFeedBack.length > 0 ? (
          //     monitorFeedBack.map((e, index) => <Text>asd</Text>)
          //   ) : (
          //     <VStack
          //       justifyContent="center"
          //       alignItems="center"
          //       color="gray.600"
          //       w="full"
          //       mt={"52"}
          //       pos={"absolute"}
          //     >
          //       <Icon as={FiInfo} w={7} h={7} />
          //       <Text fontSize={15}>Санал хүсэлт байхгүй байна.</Text>
          //     </VStack>
          //   )
          // )
          guestFb.length > 0 ? (
            guestFb.map((e, index) => (
              <GridItem key={index}>
                <Flex
                  boxShadow={"lg"}
                  maxW={"640px"}
                  width={"full"}
                  rounded={"xl"}
                  p={6}
                  justifyContent={"space-between"}
                  position={"relative"}
                  bg={"white"}
                >
                  <Checkbox
                    colorScheme="green"
                    size={"lg"}
                    pos={"absolute"}
                    top={2}
                    right={2}
                    value={selectData}
                    onChange={() => selectHandler(e._id)}
                    css={`
                      > span:first-of-type {
                        box-shadow: unset;
                      }
                    `}
                  />

                  <VStack align={"flex-start"} spacing={"2"} w="full">
                    <HStack justify={"space-between"} w={"full"}>
                      <HStack>
                        <Avatar w={"10"} h={"10"} />
                        <VStack align={"flex-start"} spacing={"0"}>
                          <Text
                            fontWeight={"medium"}
                            fontSize={"sm"}
                            color={"#000"}
                          >
                            {e.name}
                          </Text>
                          <Text
                            fontWeight={"normal"}
                            fontSize={"sm"}
                            color={"#818181"}
                            maxW={{ base: "none", md: "36", lg: "none" }}
                          >
                            {e.email}
                          </Text>
                        </VStack>
                      </HStack>
                      <HStack>
                        <Icon as={AiFillStar} color={"orange"} w={5} h={5} />
                        <Text
                          fontWeight={"medium"}
                          fontSize={"sm"}
                          color={"#000"}
                        >
                          5 / 5
                        </Text>
                      </HStack>
                    </HStack>

                    <Flex
                      align={"center"}
                      w="full"
                      _before={{
                        content: '""',
                        borderBottom: "1px solid",
                        borderColor: "#45A735",
                        flexGrow: 1.8,
                        mr: 2,
                      }}
                      _after={{
                        content: '""',
                        borderBottom: "1px solid",
                        borderColor: "#45A735",
                        flexGrow: 0.2,
                        ml: 2,
                      }}
                    >
                      {e.atTime ? (
                        <Text
                          fontWeight={"medium"}
                          fontSize={"sm"}
                          color={"#000"}
                        >
                          {e.atTime.slice(0, 10)}
                          {" / "}
                          {e.atTime.slice(11, 19)}
                        </Text>
                      ) : (
                        <Text
                          fontWeight={"medium"}
                          fontSize={"sm"}
                          color={"#000"}
                        >
                          Хугацаагүй
                        </Text>
                      )}
                    </Flex>
                    <Text fontWeight={"normal"} fontSize={"sm"}>
                      {e.message}
                    </Text>
                  </VStack>
                </Flex>
              </GridItem>
            ))
          ) : (
            <VStack
              justifyContent="center"
              alignItems="center"
              color="gray.600"
              w="full"
              mt={"52"}
              pos={"absolute"}
            >
              <Icon as={FiInfo} w={7} h={7} />
              <Text fontSize={15}>Санал хүсэлт байхгүй байна.</Text>
            </VStack>
          )}
        </Grid>
      ) : // <VStack
      //   justifyContent="center"
      //   alignItems="center"
      //   color="gray.600"
      //   w="full"
      //   mt={"52"}
      // >
      //   <Icon as={FiInfo} w={7} h={7} />
      //   <Text fontSize={15}>Санал хүсэлт байхгүй байна.</Text>
      // </VStack>
      null}
    </VStack>
  );
};

export default FeedBack;
