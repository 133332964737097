import React, { useState } from "react";
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import CustomInput from "../../../../components/input/CustomInput";
import axios from "../../../../axios";
import Cookies from "js-cookie";

const StockModal = ({ data, showModal, setShowModal, setTrigger }) => {
  const [quantity, setQuantity] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");

  const submitHandler = () => {
    if (quantity) {
      setIsDisabled(true);
      axios
        .post(
          "/v2/landin/add_stock",
          {
            _id: data._id,
            quantity: quantity,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log("sumbit result: --->", result);
          setTrigger(true);
          setShowModal(false);
          setQuantity("");
        })
        .catch((err) => {
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        })
        .finally(() => setIsDisabled(false));
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      size={"sm"}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color={"#000"} fontSize={17} fontWeight={"medium"}>
          Stock нэмэх
        </ModalHeader>
        <ModalBody>
          <VStack>
            <CustomInput
              title={"Төхөөрөмжийн тоо"}
              type={"number"}
              value={quantity}
              onChange={(e) => setQuantity(e)}
              onKeyDown={(e) => {
                if (quantity) {
                  submitHandler();
                }
              }}
            />
          </VStack>
        </ModalBody>
        <ModalFooter gap={2}>
          <Button
            w={"full"}
            color={"#000"}
            fontWeight={"medium"}
            fontSize={13}
            onClick={() => setShowModal(false)}
          >
            Болих
          </Button>
          <Button
            w={"full"}
            bg={"#45A735"}
            color={"#fff"}
            fontWeight={"medium"}
            fontSize={13}
            _hover={{
              bg: "#3E9330",
            }}
            _disabled={{
              bg: "#8cd97f",
            }}
            _focus={{
              outline: "none",
            }}
            onClick={submitHandler}
            isDisabled={!isDisabled && quantity ? false : true}
          >
            {isDisabled ? <Spinner color="#fff" mr={2} size="sm" /> : null}
            Хадгалах
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default StockModal;
