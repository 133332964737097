// import React, { useState } from "react";
// import "rc-pagination/assets/index.css";
// import "./custom-pagination.css"; // Import your custom styles
// import localeInfo from "./lang";
// import Pagination from "rc-pagination";

// const CustomPagination = ({
//   currentPage,
//   setCurrentPage,
//   totalCount,
//   setTrigger,
//   pageSize,
// }) => {
//   const changePage = (current, pageSize) => {
//     // console.log("onChange:current=", current);
//     setCurrentPage(current);
//     setTrigger(true);
//   };

//   return (
//     <Pagination
//       // showQuickJumper
//       pageSize={pageSize || 30}
//       current={currentPage}
//       onChange={changePage}
//       total={totalCount}
//       locale={localeInfo}
//       style={{
//         alignSelf: "flex-end",
//         color: "#f00",
//         borderColor: "#f00",
//       }}
//     />
//   );
// };

// export default CustomPagination;

import React from "react";
import { Button, HStack, IconButton } from "@chakra-ui/react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const CustomPagination = ({
  currentPage,
  setCurrentPage,
  totalCount,
  setTrigger,
  pageSize,
}) => {
  // const totalPages = Math.ceil(totalCount / pageSize);

  const validTotalCount =
    Number.isNaN(totalCount) || totalCount < 0 ? 0 : totalCount;
  const validPageSize = Number.isNaN(pageSize) || pageSize <= 0 ? 10 : pageSize;

  const totalPages = Math.ceil(validTotalCount / validPageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setTrigger(true);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  return (
    <HStack alignSelf={"flex-end"}>
      <IconButton
        aria-label="Previous Page"
        icon={<IoIosArrowBack />}
        onClick={handlePrevPage}
        isDisabled={currentPage === 1}
        size={"sm"}
        fontSize={11}
        borderWidth={1}
        borderColor={"#e0e0e0"}
      />
      {/* Render page numbers */}
      {Array.from({ length: totalPages }, (_, index) => (
        <Button
          key={index + 1}
          onClick={() => handlePageChange(index + 1)}
          variant={currentPage === index + 1 ? "solid" : "outline"}
          borderColor={currentPage === index + 1 ? "#45A735" : "#e0e0e0"}
          borderWidth={1}
          size={"sm"}
          fontSize={13}
          color={currentPage === index + 1 ? "#45A735" : "#000"}
          fontWeight={currentPage === index + 1 ? "bold" : "medium"}
        >
          {index + 1}
        </Button>
      ))}
      <IconButton
        aria-label="Next Page"
        icon={<IoIosArrowForward />}
        onClick={handleNextPage}
        isDisabled={currentPage === totalPages}
        size={"sm"}
        fontSize={11}
        borderWidth={1}
        borderColor={"#e0e0e0"}
      />
    </HStack>
  );
};

export default CustomPagination;
