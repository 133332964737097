import {
  Button,
  Icon,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { FiInfo } from "react-icons/fi";

const InvoiceTable = ({
  headerData,
  bodyData,
  filter,
  initialLoading,
  setSelectedData,
  setShowModal,
  setVisible,
  setShowDrawer,
  currentPage,
  pageSize,
}) => {
  const renderSkeletonRow = () => (
    <Tbody>
      <Tr>
        {headerData.map((_, i) => (
          <Td key={i} isNumeric={i === headerData.length - 1}>
            <SkeletonText noOfLines={1} />
          </Td>
        ))}
      </Tr>
    </Tbody>
  );

  const renderEmptyState = () => (
    <VStack
      justifyContent="center"
      alignItems="center"
      color="gray.600"
      w={{ base: "72", md: "xs", lg: "8xl" }}
      pt={{ base: "6", md: "12", lg: "24" }}
      spacing={1}
      pos={"absolute"}
    >
      <Icon as={FiInfo} w={6} h={6} />
      <Text fontSize={13} fontWeight="normal">
        Мэдээлэл олдсонгүй.
      </Text>
    </VStack>
  );

  // console.log("currentPage: " + currentPage);
  const [indexArr, setIndexArr] = React.useState([]);

  // Function to generate an ascending array
  const generateAscendingArray = (startingNumber, length) =>
    Array.from({ length }, (_, i) => startingNumber + i + 1);

  React.useEffect(() => {
    const startingNumber = (currentPage - 1) * pageSize;
    setIndexArr(generateAscendingArray(startingNumber, pageSize));
  }, [bodyData, currentPage, pageSize]);

  const renderBodyRows = () =>
    bodyData.map((e, index) => (
      <Tr key={index}>
        <Td color="#000" fontWeight="normal" fontSize={13}>
          {indexArr[index]}
          {/* {index + 1} */}
          {/* {index + 1 + (currentPage - 1) * 30} */}
          {/* {(currentPage - 1) * pageSize + i + 1} */}
        </Td>
        <Td color="#000" fontWeight="normal" fontSize={13}>
          {e.time}
        </Td>
        <Td color="#000" fontWeight="normal" fontSize={13}>
          <Tooltip
            hasArrow
            // label={`${e.orgPhone}\n${e.orgAddress}`}
            label={
              <span>
                <Text color="#000" fontWeight="normal" fontSize={13}>
                  {"Утас: "}
                  <Text as="span" fontWeight={"medium"}>
                    {e.orgPhone || "-"}
                  </Text>
                </Text>
                {/* <br /> */}
                <Text color="#000" fontWeight="normal" fontSize={13}>
                  {"Хаяг: "}
                  <Text as="span" fontWeight={"medium"}>
                    {e.orgAddress || "-"}
                  </Text>
                </Text>
              </span>
            }
            bg="gray.300"
            color="black"
          >
            {e.orgName || "-"}
          </Tooltip>
        </Td>
        <Td color="#000" fontWeight="normal" fontSize={13}>
          <Button
            variant="link"
            as="u"
            fontWeight="medium"
            fontSize={13}
            color={e.invoiceData?.isPaid ? "#45A735" : "#f00"}
            onClick={() => {
              setSelectedData(e);
              setShowModal(true);
            }}
            cursor="pointer"
          >
            {e.invoiceNo}
          </Button>
        </Td>
        <Td isNumeric color="#000" fontWeight="normal" fontSize={13}>
          <Button
            variant="link"
            as="u"
            fontWeight="medium"
            fontSize={13}
            onClick={() => {
              setSelectedData(e);
              setShowDrawer();
            }}
            cursor="pointer"
          >
            {e.number}
          </Button>
        </Td>
      </Tr>
    ));

  return (
    <TableContainer
      borderLeftWidth="thin"
      borderRightWidth="thin"
      rounded="md"
      w="full"
      borderColor="#F4F4F4"
    >
      <Table size="md" variant="simple">
        <Thead bg="#45A735">
          <Tr>
            {headerData.map((e, index) => (
              <Th
                key={index}
                color="#fff"
                fontWeight="medium"
                fontSize={14}
                textTransform="initial"
                isNumeric={index === headerData.length - 1}
              >
                {e}
              </Th>
            ))}
          </Tr>
        </Thead>
        {initialLoading
          ? renderSkeletonRow()
          : bodyData.length > 0
          ? renderBodyRows()
          : renderEmptyState()}
      </Table>
    </TableContainer>
  );
};

export default InvoiceTable;
