import React, { useContext, useState } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  useColorModeValue,
  Spinner,
  Image,
  InputGroup,
  InputRightElement,
  Icon,
} from "@chakra-ui/react";
import UserContext from "../../UserContext";
import bg from "../../Images/bg.png";
import logo from "../../Images/logo.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Login = () => {
  const context = useContext(UserContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);

  return (
    <Flex
      minH={"100vh"}
      // w={"100vw"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
      backgroundImage={{ base: null, lg: bg }}
      backgroundRepeat="no-repeat"
      backgroundSize={"contain"}
      backgroundPosition={"right"}
    >
      <Image
        src={logo}
        objectFit="contain"
        w={"3xs"}
        pos={"absolute"}
        top={"12"}
        left={"16"}
      />
      <Stack spacing={4} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={"12"}
        >
          <Stack spacing={4}>
            <FormControl id="text">
              <FormLabel>Хэрэглэгчийн нэр</FormLabel>
              <Input
                type="text"
                placeholder="Хэрэглэгчийн нэр"
                fontWeight={"normal"}
                fontSize={"15"}
                _hover={{
                  borderColor: "#45A735",
                }}
                _focus={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Нууц үг</FormLabel>
              <InputGroup>
                <Input
                  type={show ? "text" : "password"}
                  placeholder="Нууц үг"
                  fontWeight={"normal"}
                  fontSize={"15"}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  _hover={{
                    borderColor: "#45A735",
                  }}
                  _focus={{
                    outline: "none",
                    borderColor: "#45A735",
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      context.loginHandler(username, password);
                    }
                  }}
                />
                <InputRightElement>
                  <Icon
                    as={show ? AiOutlineEyeInvisible : AiOutlineEye}
                    w={5}
                    h={5}
                    mr={"2"}
                    color="#45A735"
                    onClick={() => setShow(!show)}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Stack spacing={10}>
              <Button
                bg={"#45A735"}
                color={"#fff"}
                fontSize={"15"}
                _hover={{
                  bg: "#5AB54B",
                }}
                _focus={{
                  outline: "none",
                }}
                fontWeight={"normal"}
                disabled={
                  username && password && !context.isLoading ? false : true
                }
                onClick={() => context.loginHandler(username, password)}
              >
                {context.isLoading ? <Spinner mr={2} size="sm" /> : null}
                Нэвтрэх
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Login;
