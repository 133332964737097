import React from "react";
import { Image, Text, VStack } from "@chakra-ui/react";
import errImage from "../../Images/403.png";

const ErrorBoundary = ({ error }) => {
  return (
    <VStack>
      <Image src={errImage} w={"xs"} />
      <Text fontSize={15} fontWeight={"medium"}>
        {error.data.message}
      </Text>
    </VStack>
  );
};

export default ErrorBoundary;
