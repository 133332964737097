import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  HStack,
  Icon,
  IconButton,
  Select,
  Stack,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import { Chart, Line } from "react-chartjs-2";
import axios from "../../../axios";
import Cookies from "js-cookie";
import { FiInfo } from "react-icons/fi";
import { GrPowerReset } from "react-icons/gr";
import BackButton from "../../../components/button/BackButton";

const SensorStateLog = () => {
  const location = useLocation();
  const history = useHistory();
  const [logData, setLogData] = useState([]);
  const [date, setDate] = useState("1 өдөр");
  const [realDates, setRealDate] = useState({
    time: 86400000,
  });

  const token = Cookies.get("accessToken");

  useEffect(() => {
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/device_logs_state_sensor", {
        params: {
          _id: location.state.e._id,
          time: realDates.time,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        // console.log(result.data.data);
        setLogData(result.data.data.reverse());
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [date]);

  const selectDate = (e) => {
    setDate(e.target.value);
    setRealDate(
      e.target.value === "1 өдөр"
        ? { time: 86400000 }
        : e.target.value === "7 хоног"
        ? { time: 604800000 }
        : e.target.value === "1 сар"
        ? { time: 2629800000 }
        : e.target.value === "3 сар"
        ? { time: 7889400000 }
        : e.target.value === "6 сар"
        ? { time: 15778800000 }
        : e.target.value === "1 жил"
        ? { time: 31557600000 }
        : null
    );
  };

  const labelAdjusted = logData.map((e) => {
    return (
      e.time.toString().slice(5, 10) + "--" + e.time.toString().slice(11, 16)
    );
  });

  const data = {
    labels: labelAdjusted.map((e) => e.split("--")),
    datasets: [
      {
        fill: false,
        borderColor: "#45A735",
        backgroundColor: "#45A735",
        borderCapStyle: "butt",
        borderJoinStyle: "#45A735",
        pointBorderColor: "#45A735",
        pointBackgroundColor: "#45A735",
        borderWidth: 1,
        pointBorderWidth: 1,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: "#45A735",
        pointHoverBorderColor: "#45A735",
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: logData.map((data) => data.status).reverse(),
        spanGaps: false,
        tension: 0.4,
        stepped: true,
        yAxisID: "y",
      },
    ],
  };

  const chartRef = useRef(null);
  const resetZoom = () => {
    if (logData.length > 1) {
      chartRef.current.resetZoom();
    }
  };

  const options = {
    spanGaps: true,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          mode: "x",
          pinch: {
            enabled: true,
          },
        },
        pan: {
          enabled: true,
          mode: "x",
        },
      },
    },

    scales: {
      x: {
        beginAtZero: false,
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: 6,
          color: "#707070",
        },
        grid: {
          borderDash: [3, 3],
          color: "#DBDBDB",
          drawBorder: false,
          drawTicks: false,
        },
      },
      y: {
        type: "category",
        labels: ["connected", "disconnected"],
        offset: true,
        position: "left",
        beginAtZero: false,
        grid: {
          borderDash: [3, 3],
          color: "#DBDBDB",
          drawTicks: false,
          drawBorder: false,
        },
        ticks: {
          mirror: true,
          labelOffset: -10,
          padding: 5,
          count: 3,
          color: "#707070",
        },
      },
    },
  };
  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "xs", md: "xl", lg: "7xl" }}
    >
      <HStack w={"full"} justify={"space-between"}>
        <BackButton />
        <Stack
          direction={{ base: "column", md: "row" }}
          justify={"center"}
          align={"center"}
        >
          <Select
            value={date}
            onChange={(e) => {
              selectDate(e);
            }}
            size="sm"
            maxW="40"
            variant="filled"
            rounded="md"
            bg={"#45A735"}
            color={"#fff"}
            boxShadow={"base"}
            cursor="pointer"
            _hover={{
              bg: "#45A735",
            }}
            _focus={{
              bg: "#45A735",
              color: "#000",
            }}
          >
            <option value="1 өдөр">1 өдөр</option>
            <option value="7 хоног">7 хоног</option>
            <option value="1 сар">1 сар</option>
            <option value="3 сар">3 сар</option>
            <option value="6 сар">6 сар</option>
            <option value="1 жил">1 жил</option>
          </Select>
          <Tooltip label="Шинэчлэх" placement="right" hasArrow>
            <IconButton
              aria-label="Reset"
              icon={<GrPowerReset />}
              colorScheme="gray"
              onClick={resetZoom}
              w={{ base: "full", md: "min-content" }}
              size="sm"
              _focus={{
                outline: "none",
              }}
              disabled={logData.length > 0 ? false : true}
            />
          </Tooltip>
        </Stack>
      </HStack>
      <Box bg={"#fff"} w={"full"} h={"xs"} p={"4"} rounded={"md"} shadow={"md"}>
        {logData.length > 1 ? (
          <Line data={data} options={options} ref={chartRef} />
        ) : (
          <VStack
            justifyContent="center"
            alignItems="center"
            color="gray.600"
            w="full"
            mt={"24"}
          >
            <Icon as={FiInfo} w={7} h={7} />
            <Text fontSize={15} fontWeight={"normal"}>
              Мэдээлэл олдсонгүй.
            </Text>
          </VStack>
        )}
      </Box>
    </VStack>
  );
};

export default SensorStateLog;
