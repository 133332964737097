import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  SkeletonText,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import axios from "../../../axios";
import { useHistory, useLocation } from "react-router-dom";
import { RiFileExcel2Line } from "react-icons/ri";
import { FiInfo } from "react-icons/fi";
import { ExportReactCSV } from "../../../components/export/ExportReactCSV";
import Cookies from "js-cookie";
import moment from "moment-timezone";
import BackButton from "../../../components/button/BackButton";

const SwitchActivityLog = () => {
  const location = useLocation();
  const history = useHistory();
  const [switchLog, setSwitchLog] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLimit, setIsLimit] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [date, setDate] = useState("1 өдөр");
  const [realDates, setRealDate] = useState({
    time: 86400000,
  });

  const [startDay, setStartDay] = useState(
    moment().tz("Asia/Ulaanbaatar").format("YYYY-MM-DD")
  );
  const [endDay, setEndDay] = useState(
    moment().tz("Asia/Ulaanbaatar").format("YYYY-MM-DD")
  );

  const [data, setData] = React.useState([]);
  const [customData, setCustomData] = React.useState([]);
  const fileName = "VIOT-" + location.state.e.for;
  const token = Cookies.get("accessToken");

  // ----------------- Switch initial log --------------------
  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/device_logs", {
        params: {
          _id: location.state.e._id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        // console.log(result.data.data);
        setSwitchLog(result.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
  }, []);

  // ----------------- Switch following log --------------------
  const getFollowingLog = (Lastid) => {
    // console.log("Lastid data ----", Lastid);
    // setIsLoading(true);
    setLoadingMore(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/device_logs_more", {
        params: {
          lastLogId: Lastid,
          _id: location.state.e._id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        // console.log("follow data ===", result.data.data);
        const moreLog = result.data.data;
        const clone = [...switchLog].concat(moreLog);
        setSwitchLog(clone);

        // setIsLoading(false);
        if (result.data.data.length < 1) {
          setIsLimit(true);
        }
        setLoadingMore(false);
      })
      .catch((err) => {
        // console.log("follow err", err);
        // setIsLoading(false);
        setLoadingMore(false);
      });
  };

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loadingMore) return;
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          // console.log("islimit ----", isLimit);
          if (isLimit === false) {
            const lastIndex = switchLog.length - 1;
            const lastSwitchLog = switchLog[lastIndex];
            getFollowingLog(lastSwitchLog._id);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, switchLog]
  );

  const selectDate = (e) => {
    setDate(e.target.value);
    setRealDate(
      e.target.value === "1 өдөр"
        ? { time: 86400000 }
        : e.target.value === "7 хоног"
        ? { time: 604800000 }
        : e.target.value === "1 сар"
        ? { time: 2629800000 }
        : e.target.value === "3 сар"
        ? { time: 7889400000 }
        : e.target.value === "6 сар"
        ? { time: 15778800000 }
        : e.target.value === "1 жил"
        ? { time: 31557600000 }
        : null
    );
  };

  useEffect(() => {
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/device_logs_excel", {
        params: { _id: location.state.e._id, time: realDates.time },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        // console.log(result.data);
        setData(
          result.data.data.length === 0
            ? "Хоосон байна"
            : result.data.data.map((e) => {
                return {
                  date: e.atTime.slice(0, 10),
                  time: e.atTime.slice(11, 19),
                  source: e.source,
                  user: e.user,
                  commandReceived: e.commandReceived,
                };
              })
        );
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [date]);

  useEffect(() => {
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/device_logs_excel_custom", {
        params: {
          _id: location.state.e._id,
          startsAtDate: startDay + "T00:00:00",
          endsAtDate: endDay + "T23:59:00",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        // console.log(result.data.data);
        setCustomData(
          result.data.data.length === 0
            ? "Хоосон байна"
            : result.data.data.map((e) => {
                return {
                  date: e.atTime.slice(0, 10),
                  time: e.atTime.slice(11, 19),
                  source: e.source,
                  user: e.user,
                  commandReceived: e.commandReceived,
                };
              })
        );
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [startDay, endDay]);

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      // bg={"red"}
      mb={"10"}
      w={{ base: "xs", md: "xl", lg: "8xl" }}
    >
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader fontSize={"16"}>Хугацаагаа сонгоно уу!</ModalHeader>
          <ModalBody pb={6}>
            <VStack w={"full"} spacing={6}>
              <FormControl>
                <Select
                  value={date}
                  onChange={(e) => {
                    selectDate(e);
                  }}
                  size="md"
                  maxW="sm"
                  variant="filled"
                  rounded="md"
                  _focus={{
                    outline: "none",
                  }}
                  _hover={{
                    borderColor: "#45A735",
                  }}
                >
                  <option value="1 өдөр">1 өдөр</option>
                  <option value="7 хоног">7 хоног</option>
                  <option value="1 сар">1 сар</option>
                  <option value="3 сар">3 сар</option>
                  <option value="6 сар">6 сар</option>
                  <option value="1 жил">1 жил</option>
                </Select>

                <Center mt={"2"} onClick={onClose}>
                  <ExportReactCSV csvData={data} fileName={fileName} />
                </Center>
              </FormControl>
              <VStack w={"full"}>
                <Flex
                  align={"center"}
                  w="full"
                  _before={{
                    content: '""',
                    borderBottom: "1px solid",
                    borderColor: "#45A735",
                    flexGrow: 1,
                    mr: 4,
                  }}
                  _after={{
                    content: '""',
                    borderBottom: "1px solid",
                    borderColor: "#45A735",
                    flexGrow: 1,
                    ml: 4,
                  }}
                >
                  <Text
                    color={"#000"}
                    fontSize={15}
                    fontWeight={"medium"}
                    letterSpacing={0.3}
                  >
                    Custom
                  </Text>
                </Flex>
                <Stack direction={{ base: "column", md: "row" }} w={"full"}>
                  <VStack spacing={0} w={"full"} align={"flex-start"}>
                    <Text color={"#000"} fontSize={14} fontWeight="normal">
                      Эхлэх
                    </Text>
                    <Input
                      type={"date"}
                      value={startDay}
                      onChange={(e) => setStartDay(e.target.value)}
                      bg={"#FCFCFC"}
                      borderColor={"#EFEFEF"}
                      borderWidth={1}
                      w={"full"}
                      size={"sm"}
                      rounded={"md"}
                      fontSize={14}
                      _hover={{
                        borderColor: "#45A735",
                        borderWidth: 1,
                      }}
                      _focus={{
                        borderColor: "#45A735",
                        borderWidth: 1,
                      }}
                    />
                  </VStack>
                  <VStack spacing={0} w={"full"} align={"flex-start"}>
                    <Text color={"#000"} fontSize={14} fontWeight="normal">
                      Дуусах
                    </Text>
                    <Input
                      type={"date"}
                      value={endDay}
                      onChange={(e) => setEndDay(e.target.value)}
                      bg={"#FCFCFC"}
                      borderColor={"#EFEFEF"}
                      borderWidth={1}
                      w={"full"}
                      size={"sm"}
                      rounded={"md"}
                      fontSize={14}
                      _hover={{
                        borderColor: "#45A735",
                        borderWidth: 1,
                      }}
                      _focus={{
                        borderColor: "#45A735",
                        borderWidth: 1,
                      }}
                    />
                  </VStack>
                </Stack>
              </VStack>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} px={"10"} mr={3}>
              Үгүй
            </Button>
            <Center px={"0"} onClick={onClose}>
              <ExportReactCSV csvData={customData} fileName={fileName} />
            </Center>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <HStack
        w={{ base: "full", md: "full", lg: "4xl" }}
        justify={"space-between"}
      >
        <BackButton />
        {switchLog.length > 0 ? (
          <Center
            py={"1"}
            px={"4"}
            borderRadius="md"
            bg={"#45A735"}
            cursor="pointer"
            boxShadow={"md"}
            onClick={onOpen}
          >
            <Icon as={RiFileExcel2Line} color={"#fff"} w={5} h={5} mr={"1"} />
            <Text fontSize={14} color={"#fff"} fontWeight={"normal"}>
              Export
            </Text>
          </Center>
        ) : null}
      </HStack>

      {isLoading ? (
        <HStack spacing={"10"}>
          <Skeleton
            w={{ base: "72", md: "4xl" }}
            h={"20"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : switchLog.length > 0 ? (
        <Box
          w={{ base: "80", md: "4xl" }}
          bg={"#fff"}
          boxShadow={"lg"}
          p={"4"}
          borderRadius={"lg"}
        >
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={"space-between"}
            w={"full"}
            bg={"#F8F8F8"}
            borderRadius={"md"}
            py={"2"}
            px={"6"}
            mb={"4"}
          >
            <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
              Төхөөрөмжийн ID:
            </Text>
            <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
              {location.state.e._id}
            </Text>
          </Stack>
          <TableContainer>
            <Table>
              <Thead bgGradient="linear(to-l, #45A735, #1ED600)">
                <Tr>
                  <Th
                    borderTopLeftRadius={"md"}
                    color={"#fff"}
                    fontSize={12}
                    fontWeight={"medium"}
                  >
                    Date
                  </Th>
                  <Th color={"#fff"} fontSize={12} fontWeight={"medium"}>
                    Time
                  </Th>
                  <Th color={"#fff"} fontSize={12} fontWeight={"medium"}>
                    Source
                  </Th>
                  <Th color={"#fff"} fontSize={12} fontWeight={"medium"}>
                    User
                  </Th>
                  <Th
                    isNumeric
                    color={"#fff"}
                    fontSize={12}
                    fontWeight={"medium"}
                    borderTopRightRadius={"md"}
                  >
                    Command
                  </Th>
                </Tr>
              </Thead>

              <>
                {switchLog.map((e, index) => {
                  if (switchLog.length === index + 1) {
                    return (
                      <Tbody
                        ref={lastBookElementRef}
                        key={index}
                        bg={index % 2 === 0 ? "#fff" : "#FCFCFC"}
                      >
                        <Tr>
                          <Td
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.atTime.slice(0, 10)}
                          </Td>
                          <Td
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.atTime.slice(11, 19)}
                          </Td>
                          <Td
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.source}
                          </Td>
                          <Td
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.user}
                          </Td>
                          <Td
                            isNumeric
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.commandReceived}
                          </Td>
                        </Tr>
                      </Tbody>
                    );
                  } else {
                    return (
                      <Tbody
                        key={index}
                        bg={index % 2 === 0 ? "#fff" : "#FCFCFC"}
                      >
                        <Tr>
                          <Td
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.atTime.slice(0, 10)}
                          </Td>
                          <Td
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.atTime.slice(11, 19)}
                          </Td>
                          <Td
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.source}
                          </Td>
                          <Td
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.user}
                          </Td>
                          <Td
                            isNumeric
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.commandReceived}
                          </Td>
                        </Tr>
                      </Tbody>
                    );
                  }
                })}
              </>
              {loadingMore && (
                <Tbody>
                  <Tr>
                    <Td>
                      <SkeletonText
                        startColor="#eee"
                        endColor="#ddd"
                        noOfLines={1}
                      />
                    </Td>
                    <Td>
                      <SkeletonText
                        startColor="#eee"
                        endColor="#ddd"
                        noOfLines={1}
                      />
                    </Td>
                    <Td>
                      <SkeletonText
                        startColor="#eee"
                        endColor="#ddd"
                        noOfLines={1}
                      />
                    </Td>
                    <Td isNumeric>
                      <SkeletonText
                        startColor="#eee"
                        endColor="#ddd"
                        noOfLines={1}
                      />
                    </Td>
                  </Tr>
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <VStack
          justifyContent="center"
          alignItems="center"
          color="gray.600"
          w="full"
        >
          <Icon mt={"20"} as={FiInfo} w={7} h={7} />
          <Text fontSize={15}>Мэдээлэл олдсонгүй</Text>
        </VStack>
      )}
    </VStack>
  );
};

export default SwitchActivityLog;
