import React, { useEffect, useState } from "react";
import {
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "../../axios";
import Cookies from "js-cookie";
import { FiInfo } from "react-icons/fi";
import { IoIosStats } from "react-icons/io";
import { BiChevronDown } from "react-icons/bi";
import { GrUpdate } from "react-icons/gr";
import BackButton from "../../components/button/BackButton";

const UserTtrList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [ttrList, setTtrList] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const toast = useToast();
  const id = "test-toast";
  const token = Cookies.get("accessToken");

  const color = useColorModeValue("#45A735");

  // ------------------- get Tap to run List ----------------------
  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/user_taptorun", {
        params: {
          houseId: location.state.e._id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log(result.data.data);
        setTtrList(result.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
        setIsLoading(false);
      });
    return () => {
      cancel = true;
    };
  }, []);

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "xs", md: "full", lg: "full" }}
    >
      <BackButton />
      {isLoading ? (
        <HStack spacing={"10"}>
          <Skeleton
            w={{ base: "80", md: "xs", lg: "sm" }}
            h={"32"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            display={{ base: "none", md: "flex", lg: "flex" }}
            w={{ base: "80", md: "xs", lg: "sm" }}
            h={"32"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            display={{ base: "none", md: "none", lg: "flex" }}
            w={{ base: "80", md: "xs", lg: "sm" }}
            h={"32"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : ttrList.length ? (
        <VStack
          align={"flex-start"}
          spacing={{ base: 4, md: 6 }}
          w={{ base: "full", md: "full" }}
        >
          {ttrList.length > 0 ? (
            <>
              <Flex
                align={"center"}
                w="full"
                _before={{
                  content: '""',
                  borderBottom: "1px solid",
                  borderColor: color,
                  flexGrow: 0.3,
                  mr: { base: 4, md: 8 },
                }}
                _after={{
                  content: '""',
                  borderBottom: "1px solid",
                  borderColor: color,
                  flexGrow: 1.7,
                  ml: { base: 4, md: 8 },
                }}
              >
                <Text
                  color={"#000"}
                  fontSize={15}
                  fontWeight={"medium"}
                  letterSpacing={0.3}
                >
                  Багц үйлдэл
                </Text>
              </Flex>
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                  lg: "repeat(3, 1fr)",
                }}
                gap={{ base: 4, md: 6 }}
                w={"full"}
              >
                {ttrList.map((e) => (
                  <VStack
                    key={e._id}
                    w={{ base: "full", md: "xs", lg: "md" }}
                    bg="#fff"
                    boxShadow={"base"}
                    borderRadius={"lg"}
                    pb={"4"}
                    py={"6"}
                    px={"8"}
                    spacing={4}
                  >
                    <VStack
                      w={"full"}
                      cursor="pointer"
                      onClick={() =>
                        history.push("/User/find/ttr-detail", {
                          e,
                          contractId: location.state.contractId,
                        })
                      }
                    >
                      <HStack justify={"space-between"} w="full">
                        <Text
                          fontSize={"sm"}
                          color="#000"
                          fontWeight={"normal"}
                          alignSelf={"flex-start"}
                        >
                          Багц үйлдлийн нэр:
                        </Text>
                        <Text
                          fontSize={"sm"}
                          color="#000"
                          fontWeight={"medium"}
                          maxW={"44"}
                          align={"end"}
                        >
                          {e.name}
                        </Text>
                      </HStack>
                      <Divider />
                      <HStack justify={"space-between"} w="full">
                        <Text
                          fontSize={"sm"}
                          color="#000"
                          fontWeight={"normal"}
                        >
                          Төхөөрөмжийн тоо:
                        </Text>
                        <Text
                          fontSize={"sm"}
                          color="#000"
                          fontWeight={"medium"}
                        >
                          {e.actionNumber}
                        </Text>
                      </HStack>
                    </VStack>
                    <Menu>
                      {({ isOpen }) => (
                        <>
                          <MenuButton
                            isActive={isOpen}
                            as={Button}
                            rightIcon={<BiChevronDown />}
                            w={{ base: "full", md: "72", lg: "96" }}
                            bg={"#fff"}
                            color={"#45A735"}
                            borderWidth={1}
                            borderColor={"#eee"}
                            _expanded={{ bg: "#5AB54B", color: "#fff" }}
                            _hover={{
                              bg: "#5AB54B",
                              color: "#fff",
                            }}
                            _focus={{
                              outline: "none",
                            }}
                            textAlign="left"
                            fontWeight={"medium"}
                            fontSize={"sm"}
                          >
                            Log харах
                          </MenuButton>
                          <MenuList w={{ base: "64", md: "72", lg: "80" }}>
                            <MenuItem
                              icon={<IoIosStats />}
                              fontSize={"sm"}
                              color="#000"
                              fontWeight={"normal"}
                              onClick={() =>
                                history.push("/User/find/ttr-status-log", {
                                  e,
                                  contractId: location.state.contractId,
                                })
                              }
                            >
                              Status Log
                            </MenuItem>
                            <MenuItem
                              icon={<GrUpdate />}
                              fontSize={"sm"}
                              color="#000"
                              fontWeight={"normal"}
                              onClick={() =>
                                history.push("/User/find/ttr-update-log", {
                                  e,
                                  contractId: location.state.contractId,
                                })
                              }
                            >
                              Update Log
                            </MenuItem>
                          </MenuList>
                        </>
                      )}
                    </Menu>
                  </VStack>
                ))}
              </Grid>
            </>
          ) : null}
        </VStack>
      ) : (
        <VStack
          justifyContent="center"
          alignItems="center"
          color="gray.600"
          w="full"
        >
          <Icon mt={"20"} as={FiInfo} w={7} h={7} />
          <Text fontSize={15}>Мэдээлэл олдсонгүй</Text>
        </VStack>
      )}
    </VStack>
  );
};

export default UserTtrList;
