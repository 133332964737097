import React, { useEffect, useState } from "react";
import { generatePDF } from "./GeneratePDF";
import {
  Button,
  Checkbox,
  Divider,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  StackDivider,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import CustomInput from "../../../../components/input/CustomInput";
import CustomTextarea from "../../../../components/input/CustomTextarea";
import axios from "../../../../axios";
import Cookies from "js-cookie";
import DeviceCard from "../utilities/DeviceCard";
import { FiInfo } from "react-icons/fi";
import AdditionalPaymentCard from "../utilities/AdditionalPaymentCard";
import { useHistory, useLocation } from "react-router-dom";
import SummaryRow from "../utilities/SummaryRow";

const DeviceInvoice = () => {
  const location = useLocation();
  const history = useHistory();
  const params = location?.state?.data;
  const [editId, setEditId] = useState(params?._id || "");
  const [trigger, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [data, setData] = useState([]);
  // --------------------------- Төлөгчийн мэдээлэл -------------------------------
  const [formData, setFormData] = useState({
    number: params?.number || "",
    companyName: params?.orgName || "",
    regNumber: params?.orgId || "",
    address: params?.orgAddress || "",
    phoneNo: params?.orgPhone || "",
  });

  // --------------------------- Нэмэлт мэдээлэл -------------------------------
  const [additionalInfo, setAdditionalInfo] = useState(
    params?.additionalInfo || []
  );
  // --------------------------- Төхөөрөмж -------------------------------
  const [bodyData, setBodyData] = useState(params?.devices || []);
  const [deviceData, setDeviceData] = useState(params?.devices || []);
  const [tax, setTax] = useState(params?.tax > 0 ? true : false);
  // --------------------------- Modal -------------------------------
  const [showModal, setShowModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showAskModal, setShowAskModal] = useState(false);
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");
  // --------------------------- Нэмэлт төлбөр -------------------------------
  const [desc, setDesc] = useState("");
  const [count, setCount] = useState("");
  const [price, setPrice] = useState("");

  const additionalInformation = [
    "Ажлын хөлсийг хүн цагаар тооцон нэмж нэхэмжилнэ.",
    "Ажлын нөхцөл болон шаардлагаас хамаарч үнийн дүнд өөрчлөлт орж болно.",
    "Хүчинтэй хугацаа ажлын 10 хоног.",
  ];

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  useEffect(() => {
    setIsLoading(true);
    const source = axios.CancelToken.source();

    axios
      .get("/v2/landin/get_list", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        setData(result.data.data || []);
        setTrigger(false);
      })
      .catch(() => {
        if (!toast.isActive("fetch-error")) {
          toast({
            id: "fetch-error",
            duration: 2000,
            position: "top",
            status: "error",
            description: "Алдаа гарлаа!",
          });
        }
      })
      .finally(() => setIsLoading(false));

    return () => source.cancel();
  }, [trigger]);

  const handleDeviceSelection = (value) => {
    if (value.stock > 0) {
      setBodyData((prev) =>
        prev.some((item) => item._id === value._id)
          ? prev.filter((item) => item._id !== value._id)
          : [
              ...prev,
              {
                ...value,
                quantity: value.quantity || 1,
              },
            ]
      );
    }
  };
  const deviceHandler = () => {
    setDeviceData(bodyData);
    setShowModal(false);
  };

  const handleFooterSelection = (value) => {
    setAdditionalInfo((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  const updateDeviceQuantity = (id, increment = true) => {
    const updateQuantity = (data) =>
      data.map((device) =>
        device._id === id
          ? { ...device, quantity: device.quantity + (increment ? 1 : -1) }
          : device
      );

    setDeviceData(updateQuantity);
    setBodyData(updateQuantity);
  };

  const removeDevice = (value) => {
    // console.log("removeDevice -->", value);
    const filterDevices = (prev) => {
      return prev.filter((device) =>
        value._id ? device._id !== value._id : device !== value
      );
    };

    setDeviceData(filterDevices);
    setBodyData(filterDevices);
  };

  const submitHandler = () => {
    // setIsDisabled(true);
    // const totalAmount = deviceData.reduce(
    //   (sum, item) => sum + item.price * item?.quantity,
    //   0
    // );
    const totalAmount = deviceData.reduce((sum, item) => {
      const quantity =
        isNaN(item.quantity) || typeof item.quantity === "string"
          ? 1
          : Number(item.quantity);
      return sum + item.price * quantity;
    }, 0);
    const nout = totalAmount * 0.1;

    let requestData = {
      orgId: formData.regNumber,
      orgName: formData.companyName,
      orgAddress: formData.address,
      orgPhone: formData.phoneNo,
      devices: deviceData.map((e) =>
        e._id
          ? {
              _id: e._id,
              name: e.name ? e.name : e.deviceName,
              quantity: e.quantity,
              price: e.price,
            }
          : {
              name: e.name ? e.name : e.deviceName,
              quantity: e.quantity || "",
              price: e.price,
            }
      ),
      additionalInfo: additionalInfo,
      amount: totalAmount,
      tax: tax ? nout : 0,
    };
    if (editId) {
      requestData._id = editId;
    }
    // console.log(requestData);
    if (editId) {
      axios
        .post("/v2/quote/update", requestData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log("update result: --->", result);
          setFormData((prevData) => ({
            ...prevData,
            number: result.data.quoteNumber || "",
          }));
          history.push("/system/invoice");
          setShowAskModal(false);
          setTrigger(true);
        })
        .catch((err) => {
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        })
        .finally(() => setIsDisabled(false));
    } else {
      axios
        .post("/v2/quote/create", requestData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log("sumbit result: --->", result);
          setFormData((prevData) => ({
            ...prevData,
            number: result.data.quoteNumber || "",
          }));
          history.push("/system/invoice");
          setShowAskModal(false);
          setTrigger(true);
        })
        .catch((err) => {
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        })
        .finally(() => setIsDisabled(false));
    }
  };

  const additionalPaymentHandler = () => {
    if (desc && price) {
      const clone = [
        ...deviceData,
        { deviceName: desc, price: parseInt(price), quantity: count || "" },
      ];

      setDeviceData(clone);
      setBodyData(clone);
      setVisible(false);
      setDesc("");
      setPrice("");
      setCount("");
    }
  };

  const totalAmount = deviceData.reduce((sum, item) => {
    const quantity =
      isNaN(item.quantity) || typeof item.quantity === "string"
        ? 1
        : Number(item.quantity);
    return sum + item.price * quantity;
  }, 0);
  const totalTax = totalAmount * 0.1;

  // console.log(totalTax, totalAmount);

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "xs", md: "xl", lg: "8xl" }}
    >
      <HStack w={"full"} align={"flex-start"} gap={2}>
        <VStack w={"max-content"}>
          {/* ----------------------------------- Төлөгчийн мэдээлэл ------------------------------------------------ */}
          <VStack
            w={"sm"}
            pt={5}
            px={8}
            pb={8}
            bg={"#fff"}
            borderRadius={"md"}
            borderWidth={1}
            borderColor={"#eee"}
          >
            <VStack w={"full"} mb={3} align={"flex-start"}>
              <Text fontWeight={"medium"} color={"#000"} fontSize={15}>
                Төлөгчийн мэдээлэл
              </Text>
              <Divider />
            </VStack>
            <CustomInput
              title={"Төлөгчийн нэр"}
              value={formData.companyName}
              onChange={(value) => handleInputChange("companyName", value)}
            />
            <CustomInput
              title={"Регистрийн дугаар"}
              value={formData.regNumber}
              onChange={(value) => handleInputChange("regNumber", value)}
            />
            <CustomTextarea
              title={"Хаяг"}
              value={formData.address}
              onChange={(value) => handleInputChange("address", value)}
            />
            <CustomInput
              title={"Утас"}
              type={"number"}
              value={formData.phoneNo}
              onChange={(value) => handleInputChange("phoneNo", value)}
            />
          </VStack>
          {/* ----------------------------------- Төхөөрөмж сонгох ------------------------------------------------ */}
          <VStack
            w={"sm"}
            pt={5}
            px={8}
            pb={8}
            bg={"#fff"}
            borderRadius={"md"}
            borderWidth={1}
            borderColor={"#eee"}
            align={"flex-start"}
          >
            <VStack w={"full"} mb={3} align={"flex-start"}>
              <HStack w={"full"} align={"center"} justify={"space-between"}>
                <Text fontWeight={"medium"} color={"#000"} fontSize={15}>
                  Төхөөрөмж сонгох{" "}
                  <Text as={"span"} color={"#f00"}>
                    *
                  </Text>
                </Text>

                <Checkbox
                  colorScheme="green"
                  size={"md"}
                  fontSize={11}
                  isChecked={tax}
                  value={tax}
                  onChange={() => setTax(!tax)}
                  css={`
                    > span:first-of-type {
                      box-shadow: unset;
                    }
                  `}
                >
                  <Text color={"#000"} fontSize={13} fontWeight={"medium"}>
                    НӨАТ-тэй
                  </Text>
                </Checkbox>
              </HStack>
              <Divider />
            </VStack>
            <HStack w={"full"}>
              <VStack
                transition={"ease-in-out .3s"}
                w={"full"}
                h={"28"}
                p={4}
                borderRadius={"md"}
                borderWidth={1}
                borderColor={"#eee"}
                align={"center"}
                justify={"center"}
                spacing={1}
                _hover={{
                  shadow: "md",
                  borderColor: "#45A735",
                }}
                cursor={"pointer"}
                onClick={() => setShowModal(true)}
              >
                <Text color={"#000"} fontSize={13} fontWeight={"medium"}>
                  Төхөөрөмж
                </Text>
              </VStack>
              <VStack
                transition={"ease-in-out .3s"}
                w={"full"}
                h={"28"}
                p={4}
                borderRadius={"md"}
                borderWidth={1}
                borderColor={"#eee"}
                align={"center"}
                justify={"center"}
                spacing={1}
                cursor={"pointer"}
                onClick={() => setVisible(true)}
                _hover={{
                  shadow: "md",
                  borderColor: "#45A735",
                }}
              >
                <Text
                  color={"#000"}
                  fontSize={13}
                  fontWeight={"medium"}
                  textAlign={"center"}
                >
                  Нэмэлт төлбөр шивэх
                </Text>
              </VStack>
            </HStack>
          </VStack>
          {/* ----------------------------------- Нэмэлт мэдээлэл ------------------------------------------------ */}
          <VStack
            w={"sm"}
            pt={5}
            px={8}
            pb={8}
            bg={"#fff"}
            borderRadius={"md"}
            borderWidth={1}
            borderColor={"#eee"}
            align={"flex-start"}
            gap={1}
          >
            <VStack w={"full"} mb={3} align={"flex-start"}>
              <Text fontWeight={"medium"} color={"#000"} fontSize={15}>
                Нэмэлт мэдээлэл
              </Text>
              <Divider />
            </VStack>
            {additionalInformation.map((info, i) => (
              <Checkbox
                key={i}
                colorScheme="green"
                size={"md"}
                fontSize={11}
                isChecked={additionalInfo.includes(info)}
                value={additionalInfo}
                onChange={() => handleFooterSelection(info)}
                css={`
                  > span:first-of-type {
                    box-shadow: unset;
                  }
                `}
              >
                <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
                  {info}
                </Text>
              </Checkbox>
            ))}
          </VStack>
        </VStack>
        {/* ----------------------------------- Сонгогдсон төхөөрөмж ------------------------------------------------ */}
        <VStack
          w={"full"}
          h={"full"}
          pt={5}
          px={8}
          pb={8}
          bg={"#fff"}
          borderRadius={"md"}
          borderWidth={1}
          borderColor={"#eee"}
          align={"flex-start"}
        >
          <VStack w={"full"} mb={3} align={"flex-start"}>
            <Text fontWeight={"medium"} color={"#000"} fontSize={15}>
              Төхөөрөмж болон нэмэлт төлбөр{" "}
              <Text as={"span"} color={"#f00"}>
                *
              </Text>
            </Text>
            <Divider />
          </VStack>
          {deviceData.length > 0 ? (
            <VStack w={"full"}>
              {deviceData.map((device, i) =>
                device._id ? (
                  <DeviceCard
                    key={i}
                    index={i + 1}
                    data={device}
                    incrementHandler={() =>
                      updateDeviceQuantity(device._id, true)
                    }
                    decrementHandler={() =>
                      updateDeviceQuantity(device._id, false)
                    }
                    removeDataHandler={() => removeDevice(device)}
                  />
                ) : (
                  <AdditionalPaymentCard
                    key={i}
                    index={i + 1}
                    data={device}
                    removeDataHandler={() => removeDevice(device)}
                  />
                )
              )}
              <VStack alignSelf={"flex-end"} minW={"72"}>
                {tax ? (
                  <>
                    <SummaryRow
                      label={"НӨАТ-гүй дүн:"}
                      value={totalAmount.toLocaleString() + "₮"}
                    />
                    <SummaryRow
                      label={"НӨАТ:"}
                      value={totalTax.toLocaleString() + "₮"}
                    />
                    <SummaryRow
                      label={"Нийт дүн:"}
                      value={(totalAmount + totalTax).toLocaleString() + "₮"}
                    />
                  </>
                ) : (
                  <SummaryRow
                    label={"Нийт дүн:"}
                    value={totalAmount.toLocaleString() + "₮"}
                  />
                )}
              </VStack>
            </VStack>
          ) : (
            <VStack
              justifyContent="center"
              alignItems="center"
              alignSelf={"center"}
              justifySelf={"center"}
              color="gray.600"
              pt={"30%"}
              spacing={1}
            >
              <Icon as={FiInfo} w={5} h={5} />
              <Text fontSize={13} fontWeight={"normal"}>
                Мэдээлэл олдсонгүй.
              </Text>
            </VStack>
          )}
        </VStack>
      </HStack>
      <Divider />
      <HStack w={"xs"} gap={4} alignSelf={"flex-end"}>
        {/* <Button
          w={"full"}
          bg={"#CBCBCB"}
          boxShadow={"md"}
          onClick={() =>
            generatePDF({
              invoice: false,
              tax: tax,
              deviceData: deviceData,
              footerData: additionalInfo,
              orgInfo: {
                number: formData?.number || "",
                orgName: formData?.companyName || "",
                orgId: formData?.regNumber || "",
                orgAddress: formData?.address || "",
                orgPhone: formData?.phoneNo || "",
              },
            })
          }
          isDisabled={!isDisabled && formData?.number ? false : true}
          color={"#000"}
          _hover={{
            bg: "#C1C1C1",
          }}
          _disabled={{
            bg: "#ddd",
            color: "#8e8e8e",
            cursor: "not-allowed",
          }}
          _focus={{
            outline: "none",
          }}
        >
          <Text fontSize={15} fontWeight={"medium"}>
            Export PDF
          </Text>
        </Button> */}
        <Button
          w={"full"}
          bg={"#45A735"}
          boxShadow={"md"}
          onClick={() => setShowAskModal(true)}
          isDisabled={!isDisabled && deviceData.length > 0 ? false : true}
          _hover={{
            bg: "#3E9330",
          }}
          _disabled={{
            bg: "#8cd97f",
          }}
          _focus={{
            outline: "none",
          }}
        >
          <Text color={"#fff"} fontSize={15} fontWeight={"medium"}>
            Хадгалах
          </Text>
        </Button>
      </HStack>

      <Modal
        isOpen={showModal}
        onClose={!showModal}
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={"#000"} fontSize={17} fontWeight={"medium"}>
            Төхөөрөмж
          </ModalHeader>
          <ModalBody
            maxH={"xl"}
            overflow={"auto"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "10px",
                borderRadius: "8px",
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
                borderRadius: "8px",
              },
            }}
          >
            <VStack w={"full"} spacing={2}>
              {data.map((e, i) => (
                <HStack
                  key={i}
                  w={"full"}
                  bg={"#FCFCFC"}
                  borderColor={"#EFEFEF"}
                  borderWidth={1}
                  borderRadius={4}
                  px={5}
                  py={2}
                  align={"center"}
                  justify={"space-between"}
                  cursor={e.stock ? "pointer" : "not-allowed"}
                  // pointerEvents={e.stock ? "all" : "none"}
                  onClick={(a) => {
                    a.preventDefault();
                    handleDeviceSelection(e);
                  }}
                >
                  <HStack
                    w={"full"}
                    align={"center"}
                    gap={2}
                    divider={<StackDivider borderColor="#EFEFEF" />}
                  >
                    <Checkbox
                      readOnly
                      colorScheme="green"
                      size={"md"}
                      fontSize={11}
                      isChecked={
                        bodyData.find((a) => a._id === e._id) ? true : false
                      }
                      css={`
                        > span:first-of-type {
                          box-shadow: unset;
                        }
                      `}
                    ></Checkbox>
                    <VStack w={"full"} align={"flex-start"} spacing={0}>
                      <Text color={"#000"} fontSize={13} fontWeight={"medium"}>
                        {e.deviceName}
                      </Text>
                      <HStack w={"full"} justify={"space-between"}>
                        <Text
                          color={"#000"}
                          fontSize={13}
                          fontWeight={"medium"}
                        >
                          {e.price.toLocaleString() + "₮"}
                        </Text>
                        <Text
                          color={"#000"}
                          fontSize={13}
                          fontWeight={"normal"}
                        >
                          {"Нийт үлдэгдэл: " + (e.stock ? e.stock : 0) + "ш"}
                        </Text>
                      </HStack>
                    </VStack>
                  </HStack>
                </HStack>
              ))}
            </VStack>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button
              w={"full"}
              bg={"#FCFCFC"}
              borderColor={"#EFEFEF"}
              borderWidth={1}
              fontSize={15}
              onClick={() => setShowModal(false)}
            >
              Болих
            </Button>
            <Button
              w={"full"}
              bg={"#45A735"}
              fontSize={13}
              onClick={deviceHandler}
              // isDisabled={showMinus % 2 === 0 ? true : false}
              _hover={{
                bg: "#3E9330",
              }}
              _disabled={{
                bg: "#8cd97f",
              }}
              _focusVisible={{
                outline: "none",
              }}
            >
              <Text color={"#fff"} fontSize={15} fontWeight={"medium"}>
                Хадгалах
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={visible}
        onClose={!visible}
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={"#000"} fontSize={17} fontWeight={"medium"}>
            Нэмэлт төлбөр шивэх
          </ModalHeader>
          <ModalBody>
            <VStack w={"full"} spacing={2}>
              <CustomInput
                required={true}
                title={"Утга"}
                value={desc}
                onChange={setDesc}
              />
              <HStack w={"full"}>
                <CustomInput
                  title={"Тоо ширхэг"}
                  value={count}
                  onChange={setCount}
                />
                <CustomInput
                  required={true}
                  title={"Нийт үнэ"}
                  type={"number"}
                  value={price}
                  onChange={setPrice}
                />
              </HStack>
              <VStack
                w={"full"}
                bg={"#fce5bc"}
                borderWidth={1}
                borderColor={"#ffa200"}
                borderRadius={"md"}
                p={4}
                mt={4}
                align={"flex-start"}
                spacing={1}
              >
                <Text as="i" color={"#000"} fontSize={13} fontWeight={"normal"}>
                  Тоо ширхгийн хэсгийг бөглөх үед хэмжих нэгжийг заавал бичнэ
                  үү!
                </Text>
                <Text as="i" color={"#000"} fontSize={13} fontWeight={"normal"}>
                  Жишээ нь: 10 метр, 5 цаг
                </Text>
              </VStack>
            </VStack>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button
              w={"full"}
              bg={"#FCFCFC"}
              borderColor={"#EFEFEF"}
              borderWidth={1}
              fontSize={15}
              onClick={() => setVisible(false)}
            >
              Болих
            </Button>
            <Button
              w={"full"}
              bg={"#45A735"}
              fontSize={13}
              onClick={additionalPaymentHandler}
              isDisabled={desc && price ? false : true}
              _hover={{
                bg: "#3E9330",
              }}
              _disabled={{
                bg: "#8cd97f",
              }}
              _focusVisible={{
                outline: "none",
              }}
            >
              <Text color={"#fff"} fontSize={15} fontWeight={"medium"}>
                Хадгалах
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={showAskModal}
        onClose={() => setShowAskModal(false)}
        size={"sm"}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={"#000"} fontSize={17} fontWeight={"medium"}>
            Итгэлтэй байна уу?
          </ModalHeader>
          <ModalBody>
            <VStack>
              <Text as="i" color={"#000"} fontSize={13} fontWeight={"normal"}>
                <Text
                  as="span"
                  color={"#45A735"}
                  fontSize={13}
                  fontWeight={"medium"}
                >
                  "Хадгалах"
                </Text>{" "}
                товчийг дарснаар үүссэн{" "}
                <Text
                  as="span"
                  color={"#45A735"}
                  fontSize={13}
                  fontWeight={"medium"}
                >
                  "үнийн санал"
                </Text>
                -г устгах боломжгүйг анхаарна уу!
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter gap={2}>
            <Button
              w={!data?.invoiceData?.isPaid ? "full" : "50%"}
              color={"#000"}
              fontWeight={"medium"}
              fontSize={13}
              onClick={() => setShowAskModal(false)}
            >
              Болих
            </Button>
            <Button
              w={"full"}
              bg={"#45A735"}
              color={"#fff"}
              fontWeight={"medium"}
              fontSize={13}
              _hover={{
                bg: "#3E9330",
              }}
              _disabled={{
                bg: "#8cd97f",
              }}
              _focus={{
                outline: "none",
              }}
              onClick={submitHandler}
              isDisabled={isDisabled}
            >
              {isDisabled ? <Spinner color="#fff" mr={2} size="sm" /> : null}
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default DeviceInvoice;
