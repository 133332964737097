import React from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Image,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import Cookies from "js-cookie";
import axios from "../../axios";
import { FiInfo } from "react-icons/fi";

const Banner = () => {
  const [bannerDark, setBannerDark] = useState([]);
  const [bannerLight, setBannerLight] = useState([]);
  const [bannerType, setBannerType] = useState("mobileBanner");
  const [addBanner, setAddBanner] = useState("");
  const [deleteBanner, setDeleteBanner] = useState("");
  const [trigger, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "test";

  const { isOpen, onClose, onOpen } = useDisclosure();

  // ---------------------------- Get Banner -----------------------------
  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get(
        "/admin/pictures/mobile_banner",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
      .then((result) => {
        // console.log("banner get ", result.data.data);
        const dark = result.data.data.filter((e) => {
          return e.pictureType !== "mobileBanner";
        });
        setBannerDark(dark);
        const light = result.data.data.filter((e) => {
          return e.pictureType !== "mobileBannerDark";
        });
        setBannerLight(light);
        setTrigger(false);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
  }, [trigger]);

  // ---------------------------- Add Banner -----------------------------
  const addHandler = () => {
    if (addBanner && bannerType) {
      axios
        .post(
          "/admin/pictures/mobile_banner",
          {
            url: addBanner,
            type: bannerType,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "success",
              duration: 2000,
              position: "top",
              description: "Амжилттай",
            });
          }
          setAddBanner("");
          setTrigger(true);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  // ---------------------------- Delete Banner -----------------------------
  const selectHandler = (e) => {
    setDeleteBanner(e);
    onOpen();
  };

  const DeleteHandler = () => {
    if (deleteBanner) {
      axios
        .delete("/admin/pictures/mobile_banner", {
          data: {
            _id: deleteBanner,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log("Done ---", result);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "success",
              duration: 2000,
              position: "top",
              description: "Амжилттай",
            });
          }
          onClose();
          setTrigger(true);
        })
        .catch((err) => {
          // console.log("Delete error", err);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "error",
              duration: 2000,
              position: "top",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "full", md: "full", lg: "8xl" }}
    >
      <VStack
        bg={"#fff"}
        shadow={"md"}
        rounded={"md"}
        p={"4"}
        w={{ base: "xs", md: "sm" }}
      >
        <Flex
          align={"center"}
          w="full"
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 0.3,
            mr: { base: 4, md: 8 },
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 1.7,
            ml: { base: 4, md: 8 },
          }}
        >
          <Text
            color={"#000"}
            fontSize={15}
            fontWeight={"medium"}
            letterSpacing={0.3}
          >
            Баннер нэмэх
          </Text>
        </Flex>
        <Input
          bg={"#fff"}
          fontSize={14}
          fontWeight={"normal"}
          w={"full"}
          placeholder="URL оруулна уу!"
          value={addBanner}
          onChange={(e) => setAddBanner(e.target.value)}
          onKeyPress={(e) => {
            if (addBanner) {
              addHandler();
            }
          }}
        />
        <VStack w={"full"} spacing={0} align={"flex-start"}>
          <Text color={"#000"} fontWeight={"medium"} fontSize={13}>
            Горим:
          </Text>
          <Select
            variant="filled"
            // placeholder="-"
            bg={"#FCFCFC"}
            borderColor={"#EFEFEF"}
            borderWidth={1}
            fontSize={14}
            w={"full"}
            onChange={(q) => setBannerType(q.target.value)}
            value={bannerType}
            multiple={false}
            _hover={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            _focus={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
          >
            <option value={"mobileBanner"}>Light</option>
            <option value={"mobileBannerDark"}>Dark</option>
          </Select>
        </VStack>
        <Text
          color={"#000"}
          fontWeight={"normal"}
          fontSize={13}
          alignSelf={"flex-start"}
        >
          Санамж: 1920x1080px хэмжээтэй зураг оруулна уу!
        </Text>
        <Button
          bg={"#45A735"}
          w={"full"}
          rounded={"md"}
          py={"2"}
          cursor={"pointer"}
          _hover={{ bg: "#58B948" }}
          onClick={addHandler}
          disabled={addBanner ? false : true}
        >
          <Text fontSize={14} color={"#fff"} fontWeight={"normal"}>
            Хадгалах
          </Text>
        </Button>
      </VStack>

      <VStack
        bg={"#fff"}
        p={"4"}
        rounded={"md"}
        spacing={4}
        maxW={"8xl"}
        overflowX={"scroll"}
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
            height: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#45A735",
            borderRadius: "24px",
          },
        }}
      >
        <Box
          direction={{ base: "column", md: "row" }}
          justify={"space-between"}
          w={"full"}
          bg={"#F8F8F8"}
          borderTopRightRadius={"md"}
          borderBottomRightRadius={"md"}
          borderLeftWidth={"medium"}
          borderColor={"#45A735"}
          py={"2"}
          px={{ base: "4", md: "6" }}
        >
          <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
            Баннер - Гэрлийн горим
          </Text>
        </Box>
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap={{ base: 4, md: 6 }}
          w={{ base: "full", md: "full" }}
          alignSelf={"center"}
          alignItems={"center"}
        >
          {bannerLight.length === 0 && isLoading === false ? (
            <VStack
              justifyContent="center"
              alignItems="center"
              color="gray.600"
              w="full"
            >
              <Icon as={FiInfo} w={7} h={7} />
              <Text fontSize={15}>Баннер байхгүй байна.</Text>
            </VStack>
          ) : (
            bannerLight.map((e, index) => (
              <GridItem
                key={index}
                w="100%"
                bg="#fff"
                boxShadow={"md"}
                borderTopWidth={"medium"}
                borderTopColor={"#45A735"}
              >
                <Image src={e.url} w={"full"} h={"full"} resize={"contain"} />
                <Center
                  bg={"#ff0000"}
                  py={"0.5"}
                  boxShadow={"md"}
                  rounded={"md"}
                  m={"2"}
                  cursor={"pointer"}
                  onClick={() => selectHandler(e._id)}
                >
                  <Text color={"#fff"} fontWeight={"normal"} fontSize={14}>
                    Устгах
                  </Text>
                </Center>
              </GridItem>
            ))
          )}
        </Grid>
      </VStack>
      <VStack
        bg={"#fff"}
        p={"4"}
        rounded={"md"}
        w={"full"}
        spacing={4}
        align={"center"}
        justify={"center"}
      >
        <Box
          direction={{ base: "column", md: "row" }}
          justify={"space-between"}
          w={"full"}
          bg={"#F8F8F8"}
          borderTopRightRadius={"md"}
          borderBottomRightRadius={"md"}
          borderLeftWidth={"medium"}
          borderColor={"#45A735"}
          py={"2"}
          px={{ base: "4", md: "6" }}
        >
          <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
            Баннер - Харанхуйн горим
          </Text>
        </Box>
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap={{ base: 4, md: 6 }}
          w={{ base: "full", md: "full" }}
          alignSelf={"center"}
          alignItems={"center"}
        >
          {bannerDark.length === 0 && isLoading === false ? (
            <VStack
              justifyContent="center"
              alignItems="center"
              color="gray.600"
              w="full"
            >
              <Icon as={FiInfo} w={7} h={7} />
              <Text fontSize={15}>Баннер байхгүй байна.</Text>
            </VStack>
          ) : (
            bannerDark.map((e, index) => (
              <GridItem
                key={index}
                w="100%"
                bg="#fff"
                boxShadow={"md"}
                borderTopWidth={"medium"}
                borderTopColor={"#45A735"}
              >
                <Image src={e.url} w={"full"} h={"full"} resize={"contain"} />
                <Center
                  bg={"#ff0000"}
                  py={"0.5"}
                  boxShadow={"md"}
                  rounded={"md"}
                  m={"2"}
                  cursor={"pointer"}
                  onClick={() => selectHandler(e._id)}
                >
                  <Text color={"#fff"} fontWeight={"normal"} fontSize={14}>
                    Устгах
                  </Text>
                </Center>
              </GridItem>
            ))
          )}
        </Grid>
      </VStack>
      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} px={"8"} onClick={onClose}>
              Үгүй
            </Button>
            <Button colorScheme="red" px={"8"} onClick={DeleteHandler}>
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Banner;
