import React, { useContext, useEffect } from "react";
import Cookie from "js-cookie";
import UserContext from "./UserContext";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Main from "./pages/Main";
import Dashboard from "./pages/Dashboard/Dashboard";
import AddDevice from "./pages/Device/AddDevice/AddDevice";
import DeviceDetail from "./pages/Device/AddDevice/DeviceDetail";
import FindUser from "./pages/User/FindUser";
import Login from "./pages/Auth/Login";
import UserHouseList from "./pages/User/UserHouseList";
import UserDeviceList from "./pages/User/UserDeviceList";
import UserAutomationList from "./pages/User/UserAutomationList";
import UserGroupDetail from "./pages/User/UserGroupDetail";
import AutomationDetail from "./pages/User/AutomationDetail";
import SwitchActivityLog from "./pages/User/logs/SwitchActivityLog";
import AutomationStatusLog from "./pages/User/logs/AutomationStatusLog";
import AutomationUpdateLog from "./pages/User/logs/AutomationUpdateLog";
import UpdateLogDetail from "./pages/User/logs/UpdateLogDetail";
import SwitchStateLog from "./pages/User/logs/SwitchStateLog";
import DcDeviceDetail from "./pages/Dashboard/DcDeviceDetail";
import ZigBee from "./pages/Device/AddDeviceZigBee/ZigBee";
import ThChart from "./components/chart/ThChart";
import ZlanType from "./pages/Device/AddZlan/ZlanType";
import AddmA from "./pages/Device/AddZlan/AddmA";
import AddRS485 from "./pages/Device/AddZlan/AddRS485";
import FeedBack from "./pages/Dashboard/FeedBack";
import AdminList from "./pages/CreateAdmin/AdminList";
import Lora from "./pages/Device/AddLora/Lora";
import AddInterval from "./pages/Device/AddDevice/AddInterval";
import Profile from "./pages/Settings/Profile";
import BlockUser from "./pages/Dashboard/BlockUser";
import Notification from "./pages/User/notification/Notification";
import Command from "./pages/User/command/Command";
import ZigbeeThActivityLog from "./pages/User/logs/ZigbeeThActivityLog";
import SearchDevice from "./pages/Device/AddDevice/SearchByDevice/SearchDevice";
import DeletedDevicesDetail from "./pages/Dashboard/DeletedDevicesDetail";
import Banner from "./pages/Settings/Banner";
import SearchDetail from "./pages/Device/AddDevice/SearchByDevice/SearchDetail";
import ShareDevice from "./pages/User/ShareDevice";
import DeviceCategory from "./pages/DeviceCategory";
import NotificationList from "./pages/Notification/NotificationList";
import NotificationDetail from "./pages/Notification/NotificationDetail";
import ZlanChart from "./components/chart/ZlanChart";
import SensorStateLog from "./pages/User/logs/SensorStateLog";
import CreateInvoice from "./pages/Invoice/CreateInvoice";
import UserTtrList from "./pages/User/UserTtrList";
import UserTtrDetail from "./pages/User/UserTtrDetail";
import TtrStatusLog from "./pages/User/logs/TtrStateLog";
import TtrUpdateLog from "./pages/User/logs/TtrUpdateLog";
import TtrStateLogDetail from "./pages/User/logs/TtrStateLogDetail";
import TtrUpdateLogDetail from "./pages/User/logs/TtrUpdateLogDetail";
import UserRestrict from "./pages/Invoice/UserRestrict";
import LoraSmtcChart from "./components/chart/LoraSmtcChart";
import Knx from "./pages/Device/knx/Knx";
import Ir from "./pages/Device/IR/Ir";
import LoraLgtChart from "./components/chart/LoraLgtChart";
import ApiService from "./pages/Api-service/ApiService";
import SharedDeviceList from "./pages/Api-service/SharedDeviceList";
import ApiShareDevice from "./pages/Api-service/ApiShareDevice";
import ShareDeviceZlan from "./pages/User/ShareDeviceZlan";
import IrSubDevices from "./pages/Device/IR/IrSubDevices";
import Analyze from "./pages/User/analyze/Analyze";
import UserLocation from "./pages/User/map/UserLocation";
import Complain from "./pages/User/complain/Complain";
import DeviceList from "./pages/System/Devices/DeviceList";
import Instruction from "./pages/System/Instruction/Instruction";
import AddDeviceSys from "./pages/System/Devices/AddDeviceSys";
import G4 from "./pages/Device/G4/G4";
import G4Multi from "./pages/Device/G4/G4Multi";
import LoraThChart from "./components/chart/LoraThChart";
import ZbAqiChart from "./components/chart/ZbAqiChart";
import AQsensor from "./pages/Device/AQsensor/AQsensor";
import AddAQ from "./pages/Device/AQsensor/AddAQ";
import G4Detail from "./pages/User/g4/G4Detail";
import Calculator from "./pages/System/calculator/Calculator";
import Invoice from "./pages/System/invoice/Invoice";
import DeviceInvoice from "./pages/System/invoice/create/DeviceInvoice";

const App = () => {
  const context = useContext(UserContext);
  const accessToken = Cookie.get("accessToken");
  const isLog = Cookie.get("isLoggedIn");
  const role = Cookie.get("role");

  return (
    <Router>
      {isLog && accessToken ? (
        <Main>
          <Switch>
            <Route exact path="/dashboard">
              <Dashboard />
            </Route>
            <Route exact path="/profile">
              <Profile />
            </Route>
            <Route exact path="/banner">
              <Banner />
            </Route>
            <Route exact path="/dashboard/feedback">
              <FeedBack />
            </Route>
            <Route exact path="/dashboard/dcdevice-detail">
              <DcDeviceDetail />
            </Route>
            <Route exact path="/dashboard/deleted-devices">
              <DeletedDevicesDetail />
            </Route>
            <Route exact path="/device/type">
              <DeviceCategory />
            </Route>
            <Route exact path="/device/type/add-device">
              <AddDevice />
            </Route>
            <Route exact path="/device/type/add-device/device-detail">
              <DeviceDetail />
            </Route>
            <Route exact path="/device/search">
              <SearchDevice />
            </Route>
            <Route exact path="/device/search/device-detail">
              <SearchDetail />
            </Route>
            <Route exact path="/device/interval">
              <AddInterval />
            </Route>
            <Route exact path="/device/type/zigbee">
              <ZigBee />
            </Route>
            <Route exact path="/device/type/zlan">
              <ZlanType />
            </Route>
            <Route exact path="/device/type/zlan/add-zlan-ma">
              <AddmA />
            </Route>
            <Route exact path="/device/type/zlan/add-zlan-rs">
              <AddRS485 />
            </Route>
            <Route exact path="/device/type/lora">
              <Lora />
            </Route>
            <Route exact path="/device/type/knx">
              <Knx />
            </Route>
            <Route exact path="/device/type/ir">
              <Ir />
            </Route>
            <Route exact path="/device/type/g4">
              <G4 />
            </Route>
            <Route exact path="/device/type/g4-multi">
              <G4Multi />
            </Route>
            <Route exact path="/device/type/aq">
              <AQsensor />
            </Route>
            <Route exact path="/device/type/aq-sensor">
              <AddAQ />
            </Route>
            <Route exact path="/User/find">
              <FindUser />
            </Route>
            <Route exact path="/User/notification">
              <Notification />
            </Route>
            <Route exact path="/User/block">
              <BlockUser />
            </Route>
            <Route exact path="/User/analyze">
              <Analyze />
            </Route>
            <Route exact path="/User/location">
              <UserLocation />
            </Route>
            <Route exact path="/User/complain">
              <Complain />
            </Route>
            <Route path="/User/find/house-list">
              <UserHouseList />
            </Route>
            <Route path="/User/find/device-list">
              <UserDeviceList />
            </Route>
            <Route path="/User/find/ir-detail">
              <IrSubDevices />
            </Route>

            <Route path="/User/find/g4-detail">
              <G4Detail />
            </Route>
            <Route path="/User/find/automation-list">
              <UserAutomationList />
            </Route>
            <Route path="/User/find/automation-detail">
              <AutomationDetail />
            </Route>
            <Route path="/User/find/group-detail">
              <UserGroupDetail />
            </Route>
            <Route path="/User/find/ttr-list">
              <UserTtrList />
            </Route>
            <Route path="/User/find/ttr-detail">
              <UserTtrDetail />
            </Route>
            <Route path="/User/find/ttr-status-log">
              <TtrStatusLog />
            </Route>
            <Route path="/User/find/ttr-status-log-detail">
              <TtrStateLogDetail />
            </Route>
            <Route path="/User/find/ttr-update-log">
              <TtrUpdateLog />
            </Route>
            <Route path="/User/find/ttr-update-log-detail">
              <TtrUpdateLogDetail />
            </Route>
            <Route path="/User/find/switch-state-log">
              <SwitchStateLog />
            </Route>
            <Route path="/User/find/sensor-state-log">
              <SensorStateLog />
            </Route>
            <Route path="/User/find/switch-activity-log">
              <SwitchActivityLog />
            </Route>
            <Route path="/User/find/zigbeeTh-activity-log">
              <ZigbeeThActivityLog />
            </Route>
            <Route path="/User/find/chart">
              <ThChart />
            </Route>
            <Route path="/User/find/Zlan-chart">
              <ZlanChart />
            </Route>
            <Route path="/User/find/zbAqi-chart">
              <ZbAqiChart />
            </Route>
            <Route path="/User/find/LoraSmtc-chart">
              <LoraSmtcChart />
            </Route>
            <Route path="/User/find/LoraLgt-chart">
              <LoraLgtChart />
            </Route>
            <Route path="/User/find/LoraTh-chart">
              <LoraThChart />
            </Route>
            <Route path="/User/find/automation-status-log">
              <AutomationStatusLog />
            </Route>
            <Route path="/User/find/automation-update-log">
              <AutomationUpdateLog />
            </Route>
            <Route path="/User/find/update-log-detail">
              <UpdateLogDetail />
            </Route>
            <Route path="/User/find/share-device">
              <ShareDevice />
            </Route>
            <Route path="/User/find/share-device-zlan">
              <ShareDeviceZlan />
            </Route>
            <Route path="/User/find/command">
              <Command />
            </Route>
            <Route path="/admin-list">
              <AdminList />
            </Route>
            <Route exact path="/notification">
              <NotificationList />
            </Route>
            <Route path="/notification/detail">
              <NotificationDetail />
            </Route>
            <Route exact path="/invoice">
              <CreateInvoice />
            </Route>
            <Route path="/invoice/restrict">
              <UserRestrict />
            </Route>
            <Route exact path="/api">
              <ApiService />
            </Route>
            <Route path="/api/shared-device-list">
              <SharedDeviceList />
            </Route>
            <Route path="/api/share-device">
              <ApiShareDevice />
            </Route>
            <Route exact path="/system/device-list">
              <DeviceList />
            </Route>
            <Route path="/system/add-device">
              <AddDeviceSys />
            </Route>
            <Route exact path="/system/instruction">
              <Instruction />
            </Route>
            <Route exact path="/system/calculator">
              <Calculator />
            </Route>
            <Route exact path="/system/invoice">
              <Invoice />
            </Route>
            <Route exact path="/system/invoice/create">
              <DeviceInvoice />
            </Route>
            <Redirect
              to={
                role === "techsub" || role === "techmonitor"
                  ? "/device/type"
                  : "/dashboard"
              }
            />
          </Switch>
        </Main>
      ) : (
        <>
          <Route exact path="/login">
            <Login />
          </Route>
          <Redirect to="/login" />
        </>
      )}
    </Router>
  );
};

export default App;
