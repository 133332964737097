import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import BackButton from "../../../components/button/BackButton";
import axios from "../../../axios";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import delIcon from "../../../Images/delete.png";

const G4Detail = () => {
  const [data, setData] = useState([]);
  const [subnetId, setSubnetId] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [deviceData, setDeviceData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [trigger, setTrigger] = useState(true);
  const location = useLocation();
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { isOpen, onClose, onOpen } = useDisclosure();

  // ----------------- get Device detail --------------------
  useEffect(() => {
    if (trigger && location?.state?.data?._id) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/v2/new_g4/channels", {
          params: {
            _id: location.state.data._id,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          //   console.log("g4 DEtail -->", result.data.data);
          setData(result.data.data);
          setSubnetId(result.data.data[0].subnetId);
          setDeviceId(result.data.data[0].deviceId);
          setIsLoading(false);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
          setTrigger(false);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
      };
    }
  }, [trigger]);

  const deleteHandler = () => {
    if (deviceData) {
      setIsDisabled(true);
      axios
        .delete("/v2/new_g4/channels", {
          data: {
            channelId: deviceData._id,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result);
          setTrigger(true);
          setDeviceData({});
          onClose();
          setIsDisabled(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай устлаа!",
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          setIsDisabled(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "xs", md: "full", lg: "8xl" }}
    >
      <BackButton />
      {isLoading ? (
        <Spinner alignSelf={"center"} color="green" />
      ) : data.length > 0 ? (
        <VStack
          w={"full"}
          align={"flex-start"}
          justify={"flex-start"}
          spacing={4}
        >
          <HStack
            w={"full"}
            bg={"#fff"}
            shadow={"md"}
            rounded={"md"}
            py={"3"}
            px={"4"}
            align={"flex-start"}
            justify={"space-between"}
            borderTopWidth={"medium"}
            borderTopColor={"#45A735"}
          >
            <Text fontSize={15} fontWeight={"medium"} color={"#000"}>
              {location.state.data.for}
            </Text>
            <HStack spacing={6}>
              <HStack>
                <Text fontSize={15} fontWeight={"normal"} color={"#000"}>
                  Subnet ID:
                </Text>
                <Text fontSize={15} fontWeight={"medium"} color={"#000"}>
                  {subnetId}
                </Text>
              </HStack>
              <HStack>
                <Text fontSize={15} fontWeight={"normal"} color={"#000"}>
                  Device ID:
                </Text>
                <Text fontSize={15} fontWeight={"medium"} color={"#000"}>
                  {deviceId}
                </Text>
              </HStack>
            </HStack>
          </HStack>
          <SimpleGrid w={"full"} columns={[1, 2, 3, 4, 5]} gap={4}>
            {data.map((e, i) => (
              <VStack
                key={e._id}
                w={"full"}
                bg={"#fff"}
                shadow={"md"}
                rounded={"md"}
                py={"2"}
                px={"4"}
                spacing={2}
              >
                <HStack
                  w={"full"}
                  align={"flex-start"}
                  justify={"space-between"}
                >
                  <Text fontSize={15} fontWeight={"medium"} color={"#000"}>
                    {"#" + e.channel}
                  </Text>
                  {/* {e.isOriginal === false ? null : ( */}
                  <Image
                    boxSize="18px"
                    objectFit="contain"
                    color={"red"}
                    src={delIcon}
                    alt="delete"
                    onClick={() => {
                      setDeviceData(e);
                      onOpen();
                    }}
                    cursor="pointer"
                    alignSelf={"flex-start"}
                  />
                  {/* )} */}
                </HStack>
                <Divider />
                <VStack w={"full"} spacing={0}>
                  <HStack
                    w={"full"}
                    align={"flex-start"}
                    justify={"space-between"}
                  >
                    <Text fontSize={15} fontWeight={"normal"} color={"#000"}>
                      Нэр:
                    </Text>
                    <Text fontSize={15} fontWeight={"medium"} color={"#000"}>
                      {e.name}
                    </Text>
                  </HStack>
                  <HStack
                    w={"full"}
                    align={"flex-start"}
                    justify={"space-between"}
                  >
                    <Text fontSize={15} fontWeight={"normal"} color={"#000"}>
                      Төлөв:
                    </Text>
                    <Text
                      fontSize={15}
                      fontWeight={"medium"}
                      color={e.isOn ? "#45A735" : "#ff0000"}
                    >
                      {e.isOn ? "Асаалттай" : "Унтраалттай"}
                    </Text>
                  </HStack>
                </VStack>
              </VStack>
            ))}
          </SimpleGrid>
        </VStack>
      ) : null}

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="scale"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalBody>{deviceData.name}</ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              _focus={{ outline: "none" }}
              onClick={() => {
                setDeviceData({});
                onClose();
              }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              px={"8"}
              _focus={{ outline: "none" }}
              onClick={() => deleteHandler()}
              disabled={isDisabled}
            >
              {isDisabled ? <Spinner color="#fff" mr={2} size="sm" /> : null}
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default G4Detail;
