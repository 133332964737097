import {
  Center,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React from "react";

function AddedUsers({ lastAddedUserLoading, lastUsers, day }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <VStack
      allowMultiple
      borderColor={"#eee"}
      bg="#fff"
      borderWidth={"thin"}
      borderRadius={"xl"}
      _hover={{
        bg: "#fff",
        shadow: "md",
      }}
      w="full"
      overflow={"hidden"}
      transition="0.5s ease"
      cursor={"pointer"}
      onClick={onOpen}
    >
      <VStack
        px={4}
        py={6}
        rounded={"md"}
        _focus={{
          border: "none",
        }}
        _hover={{
          bg: "#fff",
          rounded: "md",
        }}
        align={"center"}
        spacing={1}
        w={"md"}
      >
        <Text
          fontSize={15}
          color={"#000"}
          fontWeight={"normal"}
          align={"start"}
          textAlign={"center"}
        >
          Сүүлийн {day ? day : "-"} хоногт нэмэгдсэн хэрэглэгчид
        </Text>
        {lastAddedUserLoading ? (
          <Center w="full" h="26px">
            <Skeleton h="4" w="30%" rounded={"md"} />
          </Center>
        ) : (
          <Text fontSize={17} color={"#000"} fontWeight={"medium"}>
            {lastUsers.length}
          </Text>
        )}
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text
              fontSize={15}
              color={"#000"}
              fontWeight={"medium"}
              align={"start"}
            >
              Сүүлийн {day ? day : "-"} хоногт нэмэгдсэн хэрэглэгчид
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid
              templateColumns={["repeat(1, 1fr)"]}
              gap={3}
              w={"full"}
              pb={2}
            >
              {lastUsers.map((e, index) => (
                <GridItem
                  key={index}
                  bg={"#f9f9f9"}
                  py={2}
                  px={3}
                  rounded={"md"}
                >
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Text fontSize={15} color={"#000"} fontWeight={"normal"}>
                      contractId:
                    </Text>
                    <Text fontSize={15} color={"#000"} fontWeight={"medium"}>
                      {e.username}
                    </Text>
                  </Stack>
                </GridItem>
              ))}
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
}

export default AddedUsers;
