import { Button } from "@chakra-ui/react";
import React from "react";
import { CSVLink } from "react-csv";

export const ExportReactCSV = ({ csvData, fileName }) => {
  return (
    <Button
      bg="#45A735"
      color={"#fff"}
      px={"10"}
      _hover={{
        bg: "#5AB54B",
      }}
      _focus={{
        outline: 'none',
      }}
    >
      <CSVLink data={csvData} filename={fileName}>
        Татах
      </CSVLink>
    </Button>
  );
};
