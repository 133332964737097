import React from "react";
import { HStack, StackDivider, Text, VStack } from "@chakra-ui/react";

const QuoteDeviceCard = ({ device, index }) => {
  return (
    <HStack
      w={"full"}
      bg={"#FCFCFC"}
      borderColor={"#EFEFEF"}
      borderWidth={1}
      borderRadius={4}
      px={5}
      py={2}
      align={"center"}
      justify={"space-between"}
      divider={<StackDivider borderColor="#EFEFEF" />}
      gap={2}
    >
      <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
        {index + 1}
      </Text>
      <VStack w={"full"} align={"flex-start"}>
        <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
          {device.name}
        </Text>
        <HStack spacing={10}>
          <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
            {"Нэгж үнэ: "}
            <Text as={"span"} fontWeight={"medium"}>
              {device.price.toLocaleString() + "₮"}
            </Text>
          </Text>
          {device.quantity && (
            <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
              {"Нийт: "}
              <Text as={"span"} fontWeight={"medium"}>
                {typeof device.quantity === "string"
                  ? device.quantity
                  : device.quantity + " ш"}
                {/* {device.quantity + "ш"} */}
              </Text>
            </Text>
          )}
        </HStack>
      </VStack>
    </HStack>
  );
};

export default QuoteDeviceCard;
