import React, { useState } from "react";
import { Checkbox, Text } from "@chakra-ui/react";

const TypeBox = ({ title, deviceType, setDeviceType }) => {
  const [checkData, setCheckData] = useState(
    deviceType && deviceType.find((r) => r === title) ? true : false
  );
  const submitHandler = (value) => {
    if (!value) {
      setDeviceType((prevState) => prevState.filter((r) => r !== title));
    } else {
      const clone = [...deviceType];
      const allSelect = clone.concat(title);
      setDeviceType(allSelect);
    }
    setCheckData(value);
  };

  return (
    <Checkbox
      colorScheme="green"
      size={"lg"}
      value={checkData}
      onChange={() => submitHandler(!checkData)}
      isChecked={checkData}
      css={`
        > span:first-of-type {
          box-shadow: unset;
        }
        touch-action: none;
      `}
    >
      <Text fontSize={"15"} color="#000" fontWeight={"normal"}>
        {title}
      </Text>
    </Checkbox>
  );
};

export default TypeBox;
