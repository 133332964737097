import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  HStack,
  Icon,
  Skeleton,
  SkeletonText,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import axios from "../../../axios";
import { useLocation } from "react-router-dom";
import { FiInfo } from "react-icons/fi";
import Cookies from "js-cookie";
import BackButton from "../../../components/button/BackButton";

const AutomationStatusLog = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [automationLog, setAutomationLog] = useState([]);
  const [isLimit, setIsLimit] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const token = Cookies.get("accessToken");
  const location = useLocation();

  // ----------------- Automation status initial log --------------------
  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/user_automation_statuslog", {
        params: {
          automationId: location.state.e._id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        // console.log(result.data.data);
        setAutomationLog(result.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
  }, []);

  // ----------------- Automation status following log --------------------
  const getFollowingLog = (Lastid) => {
    // console.log("Lastid data ----", Lastid);
    setLoadingMore(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/user_automation_statuslog_more", {
        params: {
          lastLogId: Lastid,
          automationId: location.state.e._id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        const moreLog = result.data.data;
        const clone = [...automationLog].concat(moreLog);
        setAutomationLog(clone);

        if (result.data.data.length < 1) {
          setIsLimit(true);
        }
        setLoadingMore(false);
      })
      .catch((err) => {
        setLoadingMore(false);
      });
  };

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loadingMore) return;
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          // console.log("islimit ----", isLimit);
          if (isLimit === false) {
            const lastIndex = automationLog.length - 1;
            const lastAutomation = automationLog[lastIndex];
            getFollowingLog(lastAutomation._id);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, automationLog]
  );

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "xs", md: "xl", lg: "8xl" }}
    >
      <BackButton />
      {isLoading ? (
        <HStack spacing={"10"}>
          <Skeleton
            w={"72"}
            h={"xs"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : automationLog.length > 0 ? (
        <TableContainer
          w={{ base: "full", md: "4xl" }}
          bg={"#fff"}
          boxShadow={"lg"}
          p={{ base: "1", md: "4" }}
          borderRadius={"lg"}
        >
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={"space-between"}
            w={"full"}
            bg={"#F8F8F8"}
            borderRadius={"md"}
            py={"2"}
            px={"6"}
            mb={"4"}
          >
            <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
              Автоматжуулалтын ID:
            </Text>
            <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
              {location.state.e._id}
            </Text>
          </Stack>
          <Table>
            <Thead bgGradient="linear(to-l, #45A735, #1ED600)">
              <Tr>
                <Th
                  borderTopLeftRadius={"md"}
                  color={"#fff"}
                  fontSize={12}
                  fontWeight={"medium"}
                >
                  Date
                </Th>
                <Th color={"#fff"} fontSize={12} fontWeight={"medium"}>
                  Time
                </Th>
                <Th
                  isNumeric
                  color={"#fff"}
                  fontSize={12}
                  fontWeight={"medium"}
                  borderTopRightRadius={"md"}
                >
                  Command
                </Th>
              </Tr>
            </Thead>
            <>
              {automationLog.map((e, index) => {
                if (automationLog.length === index + 1) {
                  return (
                    <Tbody
                      ref={lastBookElementRef}
                      key={index}
                      bg={index % 2 === 0 ? "#fff" : "#FCFCFC"}
                    >
                      <Tr>
                        <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                          {e.time.slice(0, 10)}
                        </Td>
                        <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                          {e.time.slice(11, 19)}
                        </Td>
                        <Td
                          isNumeric
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"normal"}
                        >
                          {e.isActive === true ? "ON" : "OFF"}
                        </Td>
                      </Tr>
                    </Tbody>
                  );
                } else {
                  return (
                    <Tbody
                      key={index}
                      bg={index % 2 === 0 ? "#fff" : "#FCFCFC"}
                    >
                      <Tr>
                        <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                          {e.time.slice(0, 10)}
                        </Td>
                        <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                          {e.time.slice(11, 19)}
                        </Td>
                        <Td
                          isNumeric
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"normal"}
                        >
                          {e.isActive === true ? "ON" : "OFF"}
                        </Td>
                      </Tr>
                    </Tbody>
                  );
                }
              })}
            </>
            {loadingMore && (
              <Tbody>
                <Tr>
                  <Td>
                    <SkeletonText
                      startColor="#eee"
                      endColor="#ddd"
                      noOfLines={1}
                    />
                  </Td>
                  <Td>
                    <SkeletonText
                      startColor="#eee"
                      endColor="#ddd"
                      noOfLines={1}
                    />
                  </Td>
                  <Td isNumeric>
                    <SkeletonText
                      startColor="#eee"
                      endColor="#ddd"
                      noOfLines={1}
                    />
                  </Td>
                </Tr>
              </Tbody>
            )}
          </Table>
        </TableContainer>
      ) : (
        <VStack
          justifyContent="center"
          alignItems="center"
          color="gray.600"
          w="full"
        >
          <Icon mt={"20"} as={FiInfo} w={7} h={7} />
          <Text fontSize={15}>Мэдээлэл олдсонгүй</Text>
        </VStack>
      )}
    </VStack>
  );
};

export default AutomationStatusLog;
