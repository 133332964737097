import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  HStack,
  Icon,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import { useLocation, useParams } from "react-router-dom";
import { navData } from "./sidebar/SideBarData";
import { BiMenuAltLeft, BiTimeFive } from "react-icons/bi";
import { BsCalendar2Week } from "react-icons/bs";
import SideBarMobile from "./sidebar/SideBarMobile";
import moment from "moment-timezone";
import "moment/locale/mn";
import BreadCrumb from "./breadcrumb/BreadCrumb";
moment.locale("mn");

const Header = () => {
  const [showSideBar, setShowSidebar] = useState(false);
  const location = useLocation();
  const { id } = useParams();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toggleSidebar = () => {
    if (showSideBar === true) {
      setShowSidebar(onClose);
    } else {
      setShowSidebar(!isOpen);
    }
  };

  const [dateState, setDateState] = useState(new Date());
  useEffect(() => {
    setInterval(() => setDateState(new Date()), 1000);
  }, []);

  return (
    <>
      <SideBarMobile showSideBar={showSideBar} toggleSidebar={toggleSidebar} />
      <HStack
        // w="full"
        h={"16"}
        justify={"space-between"}
        px={["6", "10", "10", "10", "10"]}
        py={"2"}
        bg={"#fff"}
        boxShadow={"md"}
        rounded={"lg"}
        m={"2"}
      >
        <Flex display={["flex", "flex", "flex", "flex", "none"]}>
          <Icon
            as={BiMenuAltLeft}
            w={6}
            h={6}
            color="#000"
            onClick={toggleSidebar}
          />
        </Flex>
        <VStack
          alignItems={"flex-start"}
          spacing={0}
          display={["none", "none", "none", "none", "flex"]}
        >
          <BreadCrumb />
          <Text color={"#45A735"} fontWeight={"medium"} fontSize={13}>
            {location.pathname === "/dashboard"
              ? "Хяналтын самбар"
              : location.pathname === "/profile"
              ? "Профайл"
              : location.pathname === "/banner"
              ? "Баннер"
              : location.pathname === "/dashboard/feedback"
              ? "Хэрэглэгчийн санал хүсэлт"
              : location.pathname === "/dashboard/dcdevice-detail" ||
                location.pathname === "/User/find/g4-detail"
              ? "Төхөөрөмжийн дэлгэрэнгүй"
              : location.pathname === "/dashboard/deleted-devices"
              ? "Устгагдсан төхөөрөмжийн дэлгэрэнгүй"
              : location.pathname === "/User/block"
              ? "Хэрэглэгчийг хязгаарлах"
              : location.pathname === "/User/notification"
              ? "Хэрэглэгчид мэдэгдэл илгээх"
              : location.pathname === "/User/analyze"
              ? "Хэрэглэгчид мэдээлэл"
              : location.pathname === "/User/location"
              ? "Байршил"
              : location.pathname === "/User/complain"
              ? "Гомдол"
              : location.pathname === "/User/find"
              ? "Хэрэглэгчийн жагсаалт"
              : location.pathname === "/User/find/chart" ||
                location.pathname === "/User/find/Zlan-chart" ||
                location.pathname === "/User/find/LoraSmtc-chart" ||
                location.pathname === "/User/find/LoraLgt-chart" ||
                location.pathname === "/User/find/LoraTh-chart"
              ? "Төхөөрөмжийн дата график"
              : location.pathname === `/User/find/house-list`
              ? "Байшингийн жагсаалт"
              : location.pathname === `/User/find/device-list`
              ? "Төхөөрөмжийн жагсаалт"
              : location.pathname === "/User/find/automation-list"
              ? "Автоматжуулалтын жагсаалт"
              : location.pathname === "/User/find/automation-detail"
              ? "Автоматжуулалтын дэлгэрэнгүй"
              : location.pathname === "/User/find/group-detail"
              ? "Групп автоматжуулалтын дэлгэрэнгүй"
              : location.pathname === "/User/find/ttr-list"
              ? "Багц үйлдлийн жагсаалт"
              : location.pathname === "/User/find/ttr-detail"
              ? "Багц үйлдлийн дэлгэрэнгүй"
              : location.pathname === "/User/find/ttr-status-log"
              ? "Багц үйлдлийн статус"
              : location.pathname === "/User/find/ttr-status-log-detail"
              ? "Статус логийн дэлгэрэнгүй"
              : location.pathname === "/User/find/ttr-update-log"
              ? "Багц үйлдлийг шинэчилсэн түүх"
              : location.pathname === "/User/find/ttr-update-log-detail"
              ? "Шинэчилсэн түүхийн дэлгэрэнгүй"
              : location.pathname === "/User/find/switch-state-log"
              ? "Switch State log"
              : location.pathname === "/User/find/sensor-state-log"
              ? "Sensor State log"
              : location.pathname === "/User/find/switch-activity-log"
              ? "Switch Activity log"
              : location.pathname === "/User/find/zigbeeTh-activity-log"
              ? "zigbeeTh Activity log"
              : location.pathname === "/User/find/automation-status-log"
              ? "Автоматжуулалтын статус"
              : location.pathname === "/User/find/automation-update-log"
              ? "Автоматжуулалтыг шинэчилсэн түүх"
              : location.pathname === "/User/find/update-log-detail"
              ? "Автоматжуулалтыг шинэчилсэн түүхийн дэлгэрэнгүй"
              : location.pathname === "/User/find/command"
              ? "Төхөөрөмжийн комманд явуулах"
              : location.pathname === "/User/find/share-device"
              ? "Төхөөрөмж хуваалцах"
              : location.pathname === "/User/find/share-device-zlan"
              ? "Төхөөрөмж хуваалцах"
              : location.pathname === "/User/find/ir-detail"
              ? "IR төхөөрөмж нэмэх"
              : location.pathname === "/device/type"
              ? "Төхөөрөмж нэмэх"
              : location.pathname === "/device/type/add-device"
              ? "Төхөөрөмж нэмэх / regular /"
              : location.pathname === "/device/type/add-device/device-detail"
              ? "Төхөөрөмж нэмэх / regular /"
              : location.pathname === "/device/type/zigbee"
              ? "Төхөөрөмж нэмэх / zigbee /"
              : location.pathname === "/device/type/zigbee/zigbee-detail"
              ? "Төхөөрөмж нэмэх / zigbee /"
              : location.pathname === "/device/type/zlan"
              ? "Төхөөрөмж нэмэх / zlan /"
              : location.pathname === "/device/type/zlan/add-zlan-ma"
              ? "Төхөөрөмж нэмэх > 4-20mA / zlan /"
              : location.pathname === "/device/type/zlan/add-zlan-rs"
              ? "Төхөөрөмж нэмэх > RS485 / zlan /"
              : location.pathname === "/device/type/lora"
              ? "Төхөөрөмж нэмэх / lora /"
              : location.pathname === "/device/type/knx"
              ? "Төхөөрөмж нэмэх / knx /"
              : location.pathname === "/device/type/ir"
              ? "Төхөөрөмж нэмэх / IR /"
              : location.pathname === "/device/type/g4" ||
                location.pathname === "/device/type/g4-multi"
              ? "Төхөөрөмж нэмэх / G4 /"
              : location.pathname === "/device/type/aq" ||
                location.pathname === "/device/type/aq-sensor"
              ? "Төхөөрөмж нэмэх / Air quality /"
              : location.pathname === "/device/search"
              ? "Төхөөрөмж хайх"
              : location.pathname === "/device/search/device-detail"
              ? "Төхөөрөмжийн дэлгэрэнгүй"
              : location.pathname === "/device/interval"
              ? "Төхөөрөмжинд Interval өгөх"
              : location.pathname === "/admin-list"
              ? "Админы бүртгэл болон жагсаалт"
              : location.pathname === "/notification"
              ? "Төхөөрөмжийн мэдэгдэл"
              : location.pathname === "/notification/detail"
              ? "Мэдэгдлийн дэлгэрэнгүй"
              : location.pathname === "/invoice"
              ? "Нэхэмжлэх үүсгэх"
              : location.pathname === "/invoice/restrict"
              ? "Хэрэглэгчийг хязгаарлах"
              : location.pathname === "/api"
              ? "API service"
              : location.pathname === "/api/shared-device-list"
              ? "API service Төхөөрөмжийн жагсаалт"
              : location.pathname === "/api/share-device"
              ? "API service Төхөөрөмж хуваалцах"
              : location.pathname === "/system/device-list"
              ? "Төхөөрөмжийн жагсаалт"
              : location.pathname === "/system/instruction"
              ? "Системийн зааварчилгаа"
              : location.pathname === "/system/add-device"
              ? "Төхөөрөмж бүртгэх"
              : location.pathname === "/system/calculator"
              ? "Цалин тооцоолуур"
              : location.pathname === "/system/invoice"
              ? "Нэхэмжлэхийн жагсаалт"
              : location.pathname === "/system/invoice/create"
              ? "Нэхэмжлэх үүсгэх"
              : null}
          </Text>
        </VStack>

        <HStack spacing={4}>
          <VStack align={"flex-end"} spacing={0}>
            <HStack spacing={2}>
              <Icon as={BsCalendar2Week} w={4} h={4} color="#000" />
              <Text
                color={"#000"}
                fontWeight={"medium"}
                fontSize={14}
                align={"end"}
              >
                {moment().tz("Asia/Ulaanbaatar").format("LL")}
              </Text>
            </HStack>
            <HStack spacing={1}>
              <Icon as={BiTimeFive} w={4} h={4} color="#45A735" />
              <Text
                color={"#45A735"}
                fontWeight={"medium"}
                fontSize={14}
                align={"end"}
              >
                {moment().tz("Asia/Ulaanbaatar").format("LTS")}
              </Text>
            </HStack>
          </VStack>
        </HStack>
      </HStack>
    </>
  );
};

export default Header;
