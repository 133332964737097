import React, { useState, useEffect } from "react";
import {
  Button,
  Center,
  Divider,
  Grid,
  HStack,
  Icon,
  Input,
  Select,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import BackButton from "../../../components/button/BackButton";
import axios from "../../../axios";
import Cookies from "js-cookie";
import { FiPlusCircle } from "react-icons/fi";

const G4Multi = () => {
  const [clientId, setClientId] = useState("");
  const [username, setUsername] = useState("");
  const [house, setHouse] = useState("");
  const [houseList, setHouseList] = useState([]);
  const [room, setRoom] = useState("");
  const [roomList, setRoomList] = useState([]);
  const [deviceName, setDeviceName] = useState("");
  const [subnetId, setSubnetId] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [channelNo, setChannelNo] = useState("");
  const [channelName, setChannelName] = useState("");
  const [channel, setChannel] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");

  const changeHandler = (value) => {
    let source = axios.CancelToken.source();
    let cancel = false;
    setUsername(value);
    // -------------- get House -------------------
    if (value.length === 8) {
      axios
        .get("admin/device_houses", {
          params: { username: value },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          //   console.log("house List", result.data.data);
          setHouseList(result.data.data);
        })
        .catch((err) => {
          // console.log("error", err);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "error",
              duration: 2000,
              position: "top",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
      return () => {
        cancel = true;
      };
    } else {
      setHouseList([]);
      setRoomList([]);
    }
  };

  useEffect(() => {
    if (house) {
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/v2/user/create_device", {
          params: { houseId: house },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          //   console.log("room List", result.data.data);
          setRoomList(result.data.data);
        })
        .catch((err) => {
          // console.log("error", err);
        });
    }
  }, [house]);

  const submitHandler = () => {
    // console.log("create", {
    //   clientId: clientId,
    //   username: username,
    //   houseId: house,
    //   roomId: room,
    //   description: deviceName,
    //   subnetId: parseInt(subnetId),
    //   deviceId: parseInt(deviceId),
    //   channels: channel,
    // });
    if (
      username &&
      house &&
      deviceName &&
      clientId &&
      subnetId &&
      deviceId &&
      channel.length > 0
    ) {
      setIsLoading(true);
      axios
        .post(
          "/v2/new_g4/",
          {
            clientId: clientId,
            username: username,
            houseId: house,
            roomId: room,
            description: deviceName,
            subnetId: subnetId,
            deviceId: deviceId,
            channels: channel,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          //   console.log(result.data);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "success",
              duration: 2000,
              position: "top",
              description: "Амжилттай",
            });
          }
          setIsLoading(false);
          setClientId("");
          setUsername("");
          setSubnetId("");
          setDeviceId("");
          setDeviceName("");
          setHouse("");
          setHouseList([]);
          setRoom("");
          setRoomList([]);
          setChannelNo("");
          setChannelName("");
          setChannel([]);
        })
        .catch((err) => {
          //   console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "error",
              duration: 2000,
              position: "top",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
          setIsLoading(false);
        });
    }
  };

  const incSelect = () => {
    setChannel((prevState) => {
      return [...prevState, { channel: "", description: "" }];
    });
  };

  //   const deleteHandler = (index) => {
  //     console.log("clicked", index, offCommand);
  //     // setOffCommand(offCommand.filter((e, i) => i !== index));
  //   };

  const channelNameHandler = (value, i) => {
    const clone = [...channel];
    clone[i].description = value;
    setChannel(clone);
  };

  const channelNoHandler = (value, i) => {
    const clone = [...channel];
    clone[i].channel = value;
    setChannel(clone);
  };

  return (
    <VStack
      align={"flex-start"}
      w={{ base: "xs", md: "2xl", lg: "8xl" }}
      mb={"10"}
      spacing={4}
    >
      <BackButton />

      <VStack
        w={"6xl"}
        p={4}
        bg={"#fff"}
        rounded={"md"}
        shadow={"md"}
        spacing={5}
      >
        <Grid
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
            "repeat(2, 1fr)",
            "repeat(3, 1fr)",
            "repeat(3, 1fr)",
          ]}
          columnGap={6}
          rowGap={2}
          justifyContent={{ base: "center", md: "flex-start" }}
          alignItems={{ base: "center", md: "flex-start" }}
          w={"full"}
        >
          <VStack w={"full"} spacing={0} align={"flex-start"}>
            <Text fontSize={14} fontWeight={"medium"}>
              Хэрэглэгчийн утасны дугаар:
            </Text>
            <Input
              variant="filled"
              placeholder="Утасны дугаар"
              bg={"#FCFCFC"}
              w={"full"}
              fontSize={14}
              borderColor={"#EFEFEF"}
              borderWidth={1}
              maxLength={8}
              type={"tel"}
              value={username}
              onChange={(a) => changeHandler(a.target.value)}
              _hover={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
              _focus={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
            />
          </VStack>

          <VStack w={"full"} spacing={0} align={"flex-start"}>
            <Text fontSize={14} fontWeight={"medium"}>
              Байшин:
            </Text>
            <Select
              variant={"filled"}
              isDisabled={houseList.length > 0 ? false : true}
              placeholder="-Байшин сонгоно уу-"
              onChange={(q) => setHouse(q.target.value)}
              multiple={false}
              fontSize={14}
              bg={"#FCFCFC"}
              w={"full"}
              borderColor={"#EFEFEF"}
              borderWidth={1}
              _hover={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
              _focus={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
            >
              {houseList.map((q, index) => (
                <option key={index} value={q._id}>
                  {q.name}
                </option>
              ))}
            </Select>
          </VStack>

          <VStack w={"full"} spacing={0} align={"flex-start"}>
            <Text fontSize={14} fontWeight={"medium"}>
              Өрөө:
            </Text>
            <Select
              variant={"filled"}
              isDisabled={roomList.length > 0 ? false : true}
              placeholder="-Өрөө сонгоно уу-"
              onChange={(q) => setRoom(q.target.value)}
              multiple={false}
              fontSize={14}
              bg={"#FCFCFC"}
              w={"full"}
              borderColor={"#EFEFEF"}
              borderWidth={1}
              _hover={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
              _focus={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
            >
              {roomList.map((q, index) => (
                <option key={index} value={q._id}>
                  {q.name}
                </option>
              ))}
            </Select>
          </VStack>
          <VStack w={"full"} spacing={0} align={"flex-start"}>
            <Text fontSize={14} fontWeight={"medium"}>
              clientId:
            </Text>
            <Input
              variant="filled"
              placeholder="-"
              bg={"#FCFCFC"}
              w={"full"}
              fontSize={14}
              borderColor={"#EFEFEF"}
              borderWidth={1}
              value={clientId}
              onChange={(e) => setClientId(e.target.value)}
              _hover={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
              _focus={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
            />
          </VStack>
          <VStack w={"full"} spacing={0} align={"flex-start"}>
            <Text fontSize={14} fontWeight={"medium"}>
              Төхөөрөмжийн нэр:
            </Text>
            <Input
              variant="filled"
              placeholder="-"
              bg={"#FCFCFC"}
              w={"full"}
              fontSize={14}
              borderColor={"#EFEFEF"}
              borderWidth={1}
              value={deviceName}
              onChange={(e) => setDeviceName(e.target.value)}
              _hover={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
              _focus={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
            />
          </VStack>
          <HStack spacing={6}>
            <VStack w={"full"} spacing={0} align={"flex-start"}>
              <Text fontSize={14} fontWeight={"medium"}>
                Subnet id:
              </Text>
              <Input
                variant="filled"
                placeholder="-"
                bg={"#FCFCFC"}
                w={"full"}
                fontSize={14}
                borderColor={"#EFEFEF"}
                borderWidth={1}
                value={subnetId}
                onChange={(e) => setSubnetId(e.target.value)}
                _hover={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
                _focus={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
              />
            </VStack>
            <VStack w={"full"} spacing={0} align={"flex-start"}>
              <Text fontSize={14} fontWeight={"medium"}>
                Device id:
              </Text>
              <Input
                variant="filled"
                placeholder="-"
                bg={"#FCFCFC"}
                w={"full"}
                fontSize={14}
                borderColor={"#EFEFEF"}
                borderWidth={1}
                value={deviceId}
                onChange={(e) => setDeviceId(e.target.value)}
                _hover={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
                _focus={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
              />
            </VStack>
          </HStack>
        </Grid>

        <Divider />

        <Grid
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
            "repeat(2, 1fr)",
            "repeat(3, 1fr)",
            "repeat(3, 1fr)",
          ]}
          gap={6}
          justifyContent={{ base: "center", md: "flex-start" }}
          alignItems={{ base: "center", md: "flex-start" }}
          w={"full"}
        >
          {channel.map((e, i) => (
            <VStack
              key={i}
              w={"full"}
              bg={"#F6F6F6"}
              rounded={"md"}
              px={"3"}
              py={"2"}
              pos={"relative"}
              spacing={1}
            >
              <Center
                w={"6"}
                h={"6"}
                bg={"#45A735"}
                rounded={"md"}
                pos={"absolute"}
                top={-2}
                // right={10}
                right={3}
              >
                <Text fontSize={15} color={"#fff"} fontWeight={"medium"}>
                  {i + 1}
                </Text>
              </Center>

              {/* <Center
                w={"6"}
                h={"6"}
                bg={"#ff0000"}
                rounded={"md"}
                pos={"absolute"}
                top={-3}
                right={3}
                cursor={"pointer"}
                onClick={() => deleteHandler(i)}
                _hover={{
                  shadow: "md",
                }}
              >
                <Text as={FiMinusCircle} color={"#fff"} w={"4"} h={"4"} />
              </Center> */}
              <VStack w={"full"} spacing={0} align={"flex-start"}>
                <Text fontSize={14} fontWeight={"medium"}>
                  Дугаар:
                </Text>
                <Input
                  variant="filled"
                  placeholder="-"
                  bg={"#fff"}
                  w={"full"}
                  fontSize={14}
                  borderColor={"#EFEFEF"}
                  borderWidth={1}
                  value={channelNo[i]}
                  onChange={(e) => channelNoHandler(e.target.value, i)}
                  _hover={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                  _focus={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                />
              </VStack>

              <VStack w={"full"} spacing={0} align={"flex-start"}>
                <Text fontSize={14} fontWeight={"medium"}>
                  Нэр:
                </Text>
                <Input
                  variant="filled"
                  placeholder="-"
                  bg={"#fff"}
                  w={"full"}
                  fontSize={14}
                  borderColor={"#EFEFEF"}
                  borderWidth={1}
                  value={channelName[i]}
                  onChange={(e) => channelNameHandler(e.target.value, i)}
                  _hover={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                  _focus={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                />
              </VStack>
            </VStack>
          ))}

          <Button
            transition={"ease-in-out .3s"}
            w={"full"}
            h={"36"}
            bg={"#F6F6F6"}
            rounded={"md"}
            px={"3"}
            py={"2"}
            align={"center"}
            justify={"center"}
            cursor={"pointer"}
            _hover={{
              shadow: "md",
            }}
            _focus={{
              outline: "none",
            }}
            onClick={() => incSelect()}
            disabled={channel.length === 16 ? true : false}
          >
            <VStack>
              <Icon as={FiPlusCircle} w={"6"} h={"6"} />
              <Text fontSize={14} fontWeight={"medium"}>
                Channel нэмэх
              </Text>
            </VStack>
          </Button>
        </Grid>

        <Button
          w={"xs"}
          bg={"#45A735"}
          alignSelf={"flex-end"}
          _hover={{
            bg: "#5AB54B",
          }}
          _focus={{
            outline: "none",
          }}
          onClick={() => submitHandler()}
          disabled={
            !isLoading &&
            username &&
            house &&
            deviceName &&
            clientId &&
            subnetId &&
            deviceId &&
            channel.length > 0
              ? false
              : true
          }
        >
          <Text color={"#fff"} fontSize={15} fontWeight="normal">
            Хадгалах
          </Text>
        </Button>
      </VStack>
    </VStack>
  );
};

export default G4Multi;
