import React from "react";
import { Center, Icon, Text } from "@chakra-ui/react";
import { IoIosArrowBack } from "react-icons/io";
import { useHistory } from "react-router-dom";

const BackButton = () => {
  const history = useHistory();

  return (
    <Center
      py={"1"}
      px={"4"}
      borderRadius="md"
      bg={"#45A735"}
      cursor="pointer"
      onClick={() => history.goBack()}
    >
      <Icon as={IoIosArrowBack} color={"#fff"} w={5} h={5} />
      <Text fontSize={14} color={"#fff"} fontWeight={"normal"} mr={"2"}>
        Буцах
      </Text>
    </Center>
  );
};

export default BackButton;
