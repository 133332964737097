import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SkeletonText,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { AiOutlineSend } from "react-icons/ai";
import axios from "../../axios";
import Cookies from "js-cookie";
import { RiTimeLine } from "react-icons/ri";
import { BiTrashAlt } from "react-icons/bi";

const Comment = ({ showCmt, id }) => {
  const [data, setData] = useState([]);
  const [comment, setComment] = useState("");
  const [deleteCmt, setDeleteCmt] = useState("");
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (showCmt && trigger) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/admin/complain_comment", {
          params: {
            _id: id,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          //   console.log("cmt result-->", result.data);
          setData(result.data.data.reverse());
          setIsLoading(false);
          setTrigger(false);
        })
        .catch((err) => {
          //   console.log("comment err", err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
      };
    }
  }, [showCmt, trigger]);

  const sendHandler = () => {
    if (comment) {
      axios
        .post(
          "/admin/complain_comment",
          {
            _id: id,
            text: comment,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          toast({
            position: "top",
            status: "success",
            duration: 2000,
            description: "Илгээлээ!",
          });
          setComment("");
          setTrigger(true);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  const deleteHandler = () => {
    onClose();
    axios
      .delete("/admin/complain_comment", {
        data: {
          _id: deleteCmt,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setTrigger(true);
        setDeleteCmt("");
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "success",
            description: "Амжилттай устлаа!",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        setDeleteCmt("");
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  return (
    <VStack
      w={"full"}
      transition={"ease-in-out 2s"}
      display={showCmt ? "flex" : "none"}
    >
      <Divider />
      <InputGroup>
        <Input
          placeholder="Сэтгэгдэл бичих"
          fontSize={15}
          fontWeight={"normal"}
          color={"#000"}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          _hover={{
            borderColor: "#45A735",
          }}
          _focus={{
            outline: "none",
            borderColor: "#45A735",
          }}
          onKeyPress={(e) => {
            if (comment) {
              if (e.key === "Enter") {
                sendHandler();
              }
            }
          }}
        />
        <InputRightElement
          onClick={sendHandler}
          children={<Icon as={AiOutlineSend} w={5} h={5} />}
          cursor={"pointer"}
        />
      </InputGroup>

      {isLoading ? (
        <VStack
          bg={"#FAFAFA"}
          rounded={"md"}
          spacing={0}
          py={"2"}
          px={"4"}
          w={"full"}
          align={"flex-start"}
        >
          <SkeletonText
            w={"full"}
            noOfLines={2}
            spacing="3"
            skeletonHeight="2"
          />
        </VStack>
      ) : data.length > 0 ? (
        data.map((e, index) => (
          <VStack
            key={index}
            bg={"#FAFAFA"}
            rounded={"md"}
            spacing={{ base: 2, md: 0 }}
            py={"2"}
            px={"4"}
            w={"full"}
            align={"flex-start"}
          >
            <Stack
              w={"full"}
              justify={"space-between"}
              direction={{ base: "column", md: "row" }}
              spacing={0}
            >
              <Text
                fontSize={14}
                color={"#000"}
                fontWeight={"medium"}
                textTransform={"capitalize"}
              >
                {e.admin}
              </Text>
              <HStack
                w={{ base: "full", md: "40" }}
                justify={{ base: "space-between", md: "center" }}
              >
                <HStack align={"center"} justify={"center"}>
                  <Icon as={RiTimeLine} w={4} h={4} color={"#707070"} />
                  <Text fontSize={13} color={"#454545"} fontWeight={"normal"}>
                    {e.atTime
                      ? e.atTime.slice(0, 10) + " / " + e.atTime.slice(11, 16)
                      : null}
                  </Text>
                </HStack>
                <Icon
                  as={BiTrashAlt}
                  w={4}
                  h={4}
                  color={"#ff0000"}
                  cursor={"pointer"}
                  onClick={() => {
                    onOpen();
                    setDeleteCmt(e._id);
                  }}
                />
              </HStack>
            </Stack>
            <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
              {e.text}
            </Text>
          </VStack>
        ))
      ) : null}

      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={onClose}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={deleteHandler}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Comment;
