import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "../../../axios";
import Cookies from "js-cookie";
import { useHistory, useLocation } from "react-router-dom";
import { FiEdit, FiPhone } from "react-icons/fi";
import BackButton from "../../../components/button/BackButton";

const DeviceDetail = () => {
  const location = useLocation();
  const [username, setUsername] = useState("");
  const [device, setDevice] = useState([]);
  const [data, setData] = useState([]);
  const [checkData, setCheckData] = useState(false);
  const toast = useToast();
  const id = "test-toast";
  const history = useHistory();
  const token = Cookies.get("accessToken");

  // --- get Device List---
  useEffect(() => {
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("admin/device_entities", {
        params: { clientId: location.state.clientId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log(result.data.data);
        setDevice(
          result.data.data.map((e) => {
            return {
              entityName: e.entityName,
              deviceId: e.deviceId,
              deviceType: e.deviceType,
              for: "",
              phone: "",
              houseList: [],
              houseId: "",
            };
          })
        );
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      cancel = true;
    };
  }, []);

  // ------------------------ set Username -----------------------------
  const handleChange = (a, index) => {
    let source = axios.CancelToken.source();
    let cancel = false;

    const newInput = [...device];
    newInput[index].phone = a;
    // --------------------------- get Houses ---------------------------------
    if (a.split("").length > 7) {
      setUsername(a.split(" "));
      axios
        .get("admin/device_houses", {
          params: { username: a },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          // console.log(result.data.data);
          const house = [...device];
          house[index].houseList = result.data.data;
          setDevice(house);
        })
        .catch((err) => {
          // console.log("error", err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
      return () => {
        cancel = true;
      };
    } else {
      const house = [...device];
      house[index].houseList = [];
      setDevice(house);
    }
    setDevice(newInput);
  };

  // --- get Select ---
  const handleSelect = (e, index) => {
    const houseData = [...device];
    houseData[index].houseId = e.target.value;
    setDevice(houseData);
  };

  const nameHandler = (value, index) => {
    const name = [...device];
    name[index].for = value;
    setDevice(name);

    if (value !== "" && value !== " " && value !== null) {
      setData(
        device.map((e) => {
          return {
            clientId: location.state.clientId,
            entityName: e.entityName,
            deviceId: e.deviceId,
            deviceType: e.deviceType,
            username: e.phone,
            for: e.for,
            house: {
              _id: e.houseId ? e.houseId : null,
              name: e.houseList.find((a) => a._id === e.houseId)
                ? e.houseList.find((a) => a._id === e.houseId).name
                : null,
            },
          };
        })
      );
      setCheckData(true);
    }
  };

  const handleSubmit = () => {
    if (checkData) {
      axios
        .post(
          "admin/devicenew",
          {
            data,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log("Done ---", result);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "success",
              duration: 2000,
              position: "top",
              description: "Амжилттай",
            });
          }
          setCheckData(false);
          setData([]);
          history.push("dashboard");
        })
        .catch((err) => {
          // console.log("error", err);
          setCheckData(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "error",
              duration: 2000,
              position: "top",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      // bg={"red"}
      mb={"10"}
      w={{ base: "xs", md: "xl", lg: "8xl" }}
    >
      <BackButton />
      <Stack
        spacing={4}
        bg={"#fff"}
        boxShadow={"md"}
        borderRadius={"md"}
        p={"4"}
        w={{ base: "full", md: "xl" }}
      >
        <HStack
          w={"full"}
          bg={"#EFEFEF"}
          borderWidth={1}
          borderColor={"#EFEFEF"}
          borderRadius={"md"}
          py={"2"}
          px={"4"}
          justify={"space-between"}
        >
          <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
            clientId:
          </Text>
          <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
            {location.state.clientId}
          </Text>
        </HStack>
        <Divider />
        {device.map((e, index) => (
          <Stack direction={{ base: "column", md: "column" }} key={index}>
            <Stack direction={{ base: "column", md: "row" }}>
              <HStack
                w={{ base: "full", md: "lg" }}
                bg={"#FCFCFC"}
                borderColor={"#EFEFEF"}
                borderWidth={1}
                borderRadius={"md"}
                py={"2"}
                px={"4"}
                justify={"space-between"}
              >
                <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                  entityName:
                </Text>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  {e.entityName}
                </Text>
              </HStack>
              <HStack
                w={{ base: "full", md: "lg" }}
                bg={"#FCFCFC"}
                borderColor={"#EFEFEF"}
                borderWidth={1}
                borderRadius={"md"}
                py={"2"}
                px={"4"}
                justify={"space-between"}
              >
                <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                  deviceType:
                </Text>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  {e.deviceType}
                </Text>
              </HStack>
            </Stack>
            <Stack direction={{ base: "column", md: "row" }} w={"full"}>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={FiPhone} color="#45A735" />}
                />
                <Input
                  variant="filled"
                  placeholder="Утасны дугаар"
                  value={e.phone}
                  onChange={(a) => handleChange(a.target.value, index)}
                  bg={"#FCFCFC"}
                  borderColor={"#EFEFEF"}
                  borderWidth={1}
                  maxLength={8}
                  fontSize={"14"}
                  fontWeight={"normal"}
                  type={"tel"}
                  w={"full"}
                  _hover={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                  _focus={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                />
              </InputGroup>

              <Select
                isDisabled={
                  e.phone.length > 7 && e.houseList.length > 0 ? false : true
                }
                placeholder="-Байшин сонгоно уу-"
                onChange={(q) => handleSelect(q, index)}
                multiple={false}
                w={"full"}
                fontSize={"14"}
                fontWeight={"normal"}
                _hover={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
                _focus={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
              >
                {e.houseList.map((q, index) => (
                  <option key={index} value={q._id}>
                    {q.name}
                  </option>
                ))}
              </Select>
            </Stack>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Icon as={FiEdit} color="#45A735" />}
              />
              <Input
                variant="filled"
                placeholder="Төхөөрөмжинд нэр өгөх"
                value={e.for}
                onChange={(a) => nameHandler(a.target.value, index)}
                bg={"#FCFCFC"}
                borderColor={"#EFEFEF"}
                borderWidth={1}
                fontSize={"14"}
                maxLength={30}
                fontWeight={"normal"}
                type={"text"}
                w={"full"}
                _hover={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
                _focus={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
              />
            </InputGroup>
          </Stack>
        ))}
        <Button
          bg={"#45A735"}
          _hover={{
            bg: "#5AB54B",
          }}
          _focus={{
            outline: "none",
          }}
          onClick={handleSubmit}
          disabled={checkData ? false : true}
        >
          <Text color={"#fff"} fontWeight="normal">
            Хадгалах
          </Text>
        </Button>
      </Stack>
    </VStack>
  );
};

export default DeviceDetail;
