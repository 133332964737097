import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Input,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import QuoteDeviceCard from "./QuoteDeviceCard";
import { generatePDF } from "../create/GeneratePDF";
import axios from "../../../../axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

const QuoteDrawer = ({ data, isOpen, onClose, onOpen, setTrigger }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");
  const history = useHistory();
  // console.log("QuoteDrawer--->", data);

  const submitHandler = () => {
    setIsDisabled(true);
    axios
      .post(
        "/v2/device_invoice/create",
        { _id: data?._id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        // console.log("sumbit result: --->", result);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "success",
            description: "Амжилттай",
          });
        }
        onClose();
        setTrigger(true);
      })
      .catch((err) => {
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      })
      .finally(() => setIsDisabled(false));
  };

  return (
    <Drawer
      isOpen={isOpen}
      size={"sm"}
      placement="right"
      onClose={onClose}
      //   closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader color={"#45a735"} fontWeight={"medium"} fontSize={17}>
          Үнийн санал
        </DrawerHeader>

        <DrawerBody>
          <VStack w={"full"} spacing={4}>
            <VStack w={"full"} align={"flex-start"}>
              <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
                Дугаар №:{" "}
                <Text as={"span"} fontWeight={"medium"}>
                  {data?.number}
                </Text>
              </Text>
              <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
                Төлөгчийн нэр:{" "}
                <Text as={"span"} fontWeight={"medium"}>
                  {data?.orgName || "-"}
                </Text>
              </Text>
              <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
                Регистрийн дугаар:{" "}
                <Text as={"span"} fontWeight={"medium"}>
                  {data?.orgId || "-"}
                </Text>
              </Text>
              <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
                Хаяг:{" "}
                <Text as={"span"} fontWeight={"medium"}>
                  {data?.orgAddress || "-"}
                </Text>
              </Text>
              <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
                Утас:{" "}
                <Text as={"span"} fontWeight={"medium"}>
                  {data?.orgPhone || "-"}
                </Text>
              </Text>
              <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
                Үүсгэсэн хугацаа:{" "}
                <Text as={"span"} fontWeight={"medium"}>
                  {data?.time}
                </Text>
              </Text>
              <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
                Үүсгэсэн админ:{" "}
                <Text as={"span"} fontWeight={"medium"}>
                  {data?.admin}
                </Text>
              </Text>{" "}
              <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
                Нийт дүн:{" "}
                <Text as={"span"} fontWeight={"medium"}>
                  {data?.amount.toLocaleString() + "₮"}
                </Text>
              </Text>{" "}
              <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
                НӨАТ:{" "}
                <Text as={"span"} fontWeight={"medium"}>
                  {data?.tax.toLocaleString() + "₮"}
                </Text>
              </Text>
            </VStack>
            <VStack w={"full"} align={"flex-start"}>
              <VStack w={"full"} align={"flex-start"} spacing={1}>
                <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
                  Төхөөрөмж
                </Text>
                <Divider />
              </VStack>
              {data?.devices.map((device, i) => (
                <QuoteDeviceCard key={device._id} index={i} device={device} />
              ))}
            </VStack>
            {data?.additionalInfo.length > 0 && (
              <VStack w={"full"} align={"flex-start"}>
                <VStack w={"full"} align={"flex-start"} spacing={1}>
                  <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
                    Нэмэлт мэдээлэл
                  </Text>
                  <Divider />
                </VStack>
                {data?.additionalInfo.map((info, i) => (
                  <HStack key={i}>
                    <Box w={1} h={1} borderRadius={"full"} bg={"#000"} />
                    <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
                      {info}
                    </Text>
                  </HStack>
                ))}
              </VStack>
            )}
          </VStack>
        </DrawerBody>

        <DrawerFooter>
          {data?.invoiceNo ? (
            <Button
              variant="outline"
              w={"full"}
              fontSize={13}
              color={"#000"}
              fontWeight={"medium"}
              onClick={() =>
                generatePDF({
                  invoice: false,
                  tax: data?.tax > 0 ? true : false,
                  deviceData: data?.devices,
                  footerData: data?.additionalInfo,
                  orgInfo: {
                    number: data?.number || "",
                    orgName: data?.orgName || "",
                    orgId: data?.orgId || "",
                    orgAddress: data?.orgAddress || "",
                    orgPhone: data?.orgPhone || "",
                    time: data?.time || "",
                    admin: data?.admin || "",
                    amount: data?.amount || 0,
                    tax: data?.tax || 0,
                  },
                  invoiceNo: data?.number || "",
                })
              }
            >
              Export
            </Button>
          ) : (
            <VStack w={"full"} spacing={3}>
              <Button
                w={"full"}
                bg={"#45A735"}
                fontSize={13}
                color={"#fff"}
                fontWeight={"medium"}
                onClick={submitHandler}
                isDisabled={!isDisabled && !data?.invoiceNo ? false : true}
                _hover={{
                  bg: "#3E9330",
                }}
                _disabled={{
                  bg: "#8cd97f",
                }}
                _focusVisible={{
                  outline: "none",
                }}
              >
                Нэхэмжлэх үүсгэх
              </Button>
              <HStack w={"full"} spacing={3}>
                <Button
                  variant="outline"
                  w={"full"}
                  fontSize={13}
                  color={"#000"}
                  fontWeight={"medium"}
                  onClick={() =>
                    generatePDF({
                      invoice: false,
                      tax: data?.tax > 0 ? true : false,
                      deviceData: data?.devices,
                      footerData: data?.additionalInfo,
                      orgInfo: {
                        number: data?.number || "",
                        orgName: data?.orgName || "",
                        orgId: data?.orgId || "",
                        orgAddress: data?.orgAddress || "",
                        orgPhone: data?.orgPhone || "",
                        time: data?.time || "",
                        admin: data?.admin || "",
                        amount: data?.amount || 0,
                        tax: data?.tax || 0,
                      },
                      invoiceNo: data?.number || "",
                    })
                  }
                >
                  Export
                </Button>
                <Button
                  w={"full"}
                  bg={"#eb9e19"}
                  fontSize={13}
                  color={"#fff"}
                  fontWeight={"medium"}
                  // onClick={submitHandler}
                  onClick={() =>
                    history.push("/system/invoice/create", { data: data })
                  }
                  isDisabled={data?.invoiceNo ? true : false}
                  _hover={{
                    bg: "#cc9028",
                  }}
                  _disabled={{
                    bg: "#dab980",
                  }}
                  _focus={{
                    outline: "none",
                  }}
                >
                  Засах
                </Button>
              </HStack>
            </VStack>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default QuoteDrawer;
