import React, { useState, createContext } from "react";
import { Box, useToast } from "@chakra-ui/react";
import axios from "./axios";
import Cookies from "js-cookie";
import jwt from "jwt-decode";
import moment from "moment-timezone";
const UserContext = createContext();

export const UserStore = (props) => {
  const toast = useToast();
  const id = "test-toast";
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState();
  const [refreshToken, setRefreshToken] = useState();
  const [username, setUsername] = useState();
  const [role, setRole] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [trigger, setTrigger] = useState(true);

  // search filter
  const [noDeviceHide, setNoDeviceHide] = useState(false);
  // search filter

  const loginSuccess = (accToken, refToken, name, role) => {
    Cookies.set("accessToken", accToken);
    Cookies.set("refreshToken", refToken);
    Cookies.set("username", name);
    Cookies.set("role", role);
    Cookies.set("isLoggedIn", true);
    setAccessToken(accToken);
    setRefreshToken(refToken);
    setUsername(name);
    setRole(role);
    setIsLoggedIn(true);
  };

  const loginHandler = async (user, pass) => {
    setIsLoading(true);
    const loginData = {
      username: user,
      password: pass,
    };
    axios
      .post("/auth/login", { loginData })
      .then((result) => {
        // console.log(result.data);
        const role = jwt(result.data.accessToken);
        loginSuccess(
          result.data.accessToken,
          result.data.refreshToken,
          result.data.username,
          role.role
        );
        setIsLoading(false);

        const currentHour = moment().format("HH");
        if (currentHour >= 4 && currentHour < 11) {
          toast({
            duration: 3000,
            position: "top",
            render: () => (
              <Box
                mt={"10"}
                color="#000"
                py={3}
                px={6}
                bg="#F2F7F1"
                rounded={"md"}
                borderLeftWidth={"medium"}
                borderLeftColor={"#45A735"}
                shadow={"md"}
                fontSize={15}
                fontWeight={"medium"}
              >
                VIOT - Өглөөний мэнд! {result.data.username}
              </Box>
            ),
          });
        } else if (currentHour >= 11 && currentHour < 17) {
          toast({
            duration: 3000,
            position: "top",
            render: () => (
              <Box
                mt={"10"}
                color="#000"
                py={3}
                px={6}
                bg="#F2F7F1"
                rounded={"md"}
                borderLeftWidth={"medium"}
                borderLeftColor={"#45A735"}
                shadow={"md"}
                fontSize={15}
                fontWeight={"medium"}
              >
                VIOT - Өдрийн мэнд! {result.data.username}
              </Box>
            ),
          });
        } else if (currentHour >= 17 && currentHour < 22) {
          toast({
            duration: 3000,
            position: "top",
            render: () => (
              <Box
                mt={"10"}
                color="#000"
                py={3}
                px={6}
                bg="#F2F7F1"
                rounded={"md"}
                borderLeftWidth={"medium"}
                borderLeftColor={"#45A735"}
                shadow={"md"}
                fontSize={15}
                fontWeight={"medium"}
              >
                VIOT - Оройн мэнд! {result.data.username}
              </Box>
            ),
          });
        } else if (currentHour >= 22 && currentHour < 4) {
          toast({
            duration: 3000,
            position: "top",
            render: () => (
              <Box
                mt={"10"}
                color="#000"
                py={3}
                px={6}
                bg="#F2F7F1"
                rounded={"md"}
                borderLeftWidth={"medium"}
                borderLeftColor={"#45A735"}
                shadow={"md"}
                fontSize={15}
                fontWeight={"medium"}
              >
                VIOT - Шөнийн мэнд! {result.data.username}
              </Box>
            ),
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description:
              error.response.status === 400
                ? "Таны нэвтрэх нэр эсвэл нууц үг буруу байна!"
                : "Холболтын алдаа",
          });
        }
      });
  };

  const logoutHandler = async () => {
    const refToken = Cookies.get("refreshToken");
    setIsLoading(true);
    try {
      axios
        .post("/auth/logout", { TheRefreshToken: refToken })
        .then((result) => {
          removeCookies();
          setIsLoggedIn(false);
          setUsername("");
          setRole("");
          setAccessToken("");
          setRefreshToken("");
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } catch (e) {
      // console.log(e);
    }
  };

  const removeCookies = () => {
    Cookies.remove("username");
    Cookies.remove("role");
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    Cookies.remove("isLoggedIn");
  };

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        accessToken,
        refreshToken,
        username,
        role,
        loginHandler,
        removeCookies,
        isLoading,
        setIsLoading,
        logoutHandler,
        noDeviceHide,
        setNoDeviceHide,
        trigger,
        setTrigger,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
