import React, { useEffect, useState, useRef } from "react";
import {
  Flex,
  Spinner,
  IconButton,
  Tooltip,
  useBreakpointValue,
  VStack,
  Text,
  Icon,
  Center,
  Input,
  Button,
  Stack,
} from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import { Chart } from "chart.js";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import Cookies from "js-cookie";
import axios from "../../axios";
import { FiInfo } from "react-icons/fi";
import { GrPowerReset } from "react-icons/gr";
import moment from "moment-timezone";
import BackButton from "../button/BackButton";

Chart.register(zoomPlugin);
const ZbAqiChart = () => {
  let location = useLocation();
  let history = useHistory();
  const [datas, setDatas] = useState([]);
  const [spin, setSpin] = useState(false);
  const [startDay, setStartDay] = useState(
    moment().tz("Asia/Ulaanbaatar").format("YYYY-MM-DD")
  );
  const [endDay, setEndDay] = useState(
    moment().tz("Asia/Ulaanbaatar").format("YYYY-MM-DD")
  );
  const token = Cookies.get("accessToken");

  const variant = useBreakpointValue({ base: false, md: true });

  //Chart data, option set
  const labelAdjusted = datas.map((el) => {
    return (
      el.time.toString().slice(5, 10) + "--" + el.time.toString().slice(11, 16)
    );
  });

  const data = {
    labels: labelAdjusted.map((el) => el.split("--")),
    datasets: [
      {
        label: "Температур",
        fill: false,
        borderColor: "#ff0000",
        backgroundColor: "#ff0000",
        borderCapStyle: "butt",
        borderJoinStyle: "#ff0000",
        pointBorderColor: "#ff0000",
        pointBackgroundColor: "#ff0000",
        borderWidth: 1,
        pointBorderWidth: 1,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: "#ff0000",
        pointHoverBorderColor: "#ff0000",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        data: datas.map((el) => el.temperature),
        spanGaps: false,
        tension: 0.4,
      },

      {
        label: "Чийгшил",
        fill: false,
        borderColor: "#0074D9",
        backgroundColor: "#0074D9",
        borderCapStyle: "butt",
        borderJoinStyle: "#0074D9",
        pointBorderColor: "#0074D9",
        pointBackgroundColor: "#0074D9",
        borderWidth: 1,
        pointBorderWidth: 1,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: "#0074D9",
        pointHoverBorderColor: "#0074D9",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        data: datas.map((el) => el.humidity),
        spanGaps: false,
        tension: 0.4,
      },
      {
        label: "Нүүрстөрөгч",
        fill: false,
        borderColor: "#FFCD00",
        backgroundColor: "#FFCD00",
        borderCapStyle: "butt",
        borderJoinStyle: "#FFCD00",
        pointBorderColor: "#FFCD00",
        pointBackgroundColor: "#FFCD00",
        borderWidth: 1,
        pointBorderWidth: 1,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: "#FFCD00",
        pointHoverBorderColor: "#FFCD00",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        data: datas.map((el) => el.co2),
        spanGaps: false,
        tension: 0.4,
      },
      {
        label: "VOC",
        fill: false,
        borderColor: "#00D8FF",
        backgroundColor: "#00D8FF",
        borderCapStyle: "butt",
        borderJoinStyle: "#00D8FF",
        pointBorderColor: "#00D8FF",
        pointBackgroundColor: "#00D8FF",
        borderWidth: 1,
        pointBorderWidth: 1,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: "#00D8FF",
        pointHoverBorderColor: "#00D8FF",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        data: datas.map((el) => el.VOC),
        spanGaps: false,
        tension: 0.4,
      },
      {
        label: "Формальдегид",
        fill: false,
        borderColor: "#45A735",
        backgroundColor: "#45A735",
        borderCapStyle: "butt",
        borderJoinStyle: "#45A735",
        pointBorderColor: "#45A735",
        pointBackgroundColor: "#45A735",
        borderWidth: 1,
        pointBorderWidth: 1,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: "#45A735",
        pointHoverBorderColor: "#45A735",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        data: datas.map((el) => el.formalDehyde),
        spanGaps: false,
        tension: 0.4,
      },
    ],
  };

  const options = {
    spanGaps: true,
    animation: variant,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: false,
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: 6,
          callback: function (val, index) {
            return index != 0 ? this.getLabelForValue(val) : "";
          },
          color: "#707070",
        },
        grid: {
          // borderDash: [3, 3],
          color: "#DBDBDB",
          drawBorder: false,
          drawTicks: false,
        },
      },
      y: {
        display: variant,
        beginAtZero: false,
        grid: {
          borderDash: [3, 3],
          color: "#DBDBDB",
          drawTicks: false,
          drawBorder: false,
        },
        ticks: {
          // maxTicksLimit: 3,
          display: true,
          mirror: true,
          labelOffset: -10,
          padding: 5,
          count: 3,
          color: "#707070",
          callback: function (val, index) {
            return index === 0
              ? "Min " + this.getLabelForValue(val)
              : index === 1
              ? "Avg " + this.getLabelForValue(val)
              : "Max " + this.getLabelForValue(val);
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const def1 =
              context.dataset.label === "Чийгшил"
                ? "Чийгшил: "
                : context.dataset.label === "Температур"
                ? "Температур: "
                : context.dataset.label === "Нүүрстөрөгч"
                ? "Нүүрстөрөгч: "
                : context.dataset.label === "Формальдегид"
                ? "Формальдегид: "
                : context.dataset.label === "VOC"
                ? "VOC: "
                : null;

            const def2 =
              context.dataset.label === "Чийгшил"
                ? "%"
                : context.dataset.label === "Температур"
                ? "°C"
                : context.dataset.label === "Нүүрстөрөгч"
                ? " ppm"
                : context.dataset.label === "Формальдегид"
                ? " mg/m3"
                : context.dataset.label === "VOC"
                ? " ppm"
                : null;

            return def1 + context.raw + def2;
          },
        },
      },
      title: {
        display: true,
      },
      legend: {
        display: true,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          color: "#707070",
        },
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          mode: "x",
          pinch: {
            enabled: true,
          },
        },
        pan: {
          enabled: true,
          mode: "x",
        },
      },
      chartAreaBorder: {
        borderColor: "#DBDBDB",
        borderWidth: 1,
      },
    },
  };

  const chartAreaBorder = {
    id: "chartAreaBorder",
    beforeDatasetsDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { top, bottom, left, right, width, height },
      } = chart;
      ctx.save();
      ctx.beginPath();
      ctx.strokeStyle = options.borderColor;
      ctx.lineWidth = options.borderWidth;
      ctx.moveTo(right, top);
      ctx.lineTo(right, bottom);
      ctx.closePath();
      ctx.stroke();
    },
  };

  //Reset zoom chart usage
  const chartRef = useRef(null);
  const resetZoom = () => {
    if (datas.length > 0) {
      chartRef.current.resetZoom();
    }
  };

  useEffect(() => {
    if (location.state.e._id) {
      let source = axios.CancelToken.source();
      let cancel = false;
      setSpin(true);
      axios
        .get("/v2/log/chart_data", {
          params: {
            _id: location.state.e._id,
            startsAtDate: startDay + "T00:00:00",
            endsAtDate: endDay + "T23:59:00",
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          //   console.log(result.data.data);
          // setDatas(result.data.data.reverse());
          setDatas(result.data.data.reverse());
          setSpin(false);
        })
        .catch((err) => {
          // console.log(err);
          setSpin(false);
        });
      return () => {
        cancel = true;
      };
    }
  }, []);

  const dayHandler = () => {
    if (startDay && endDay) {
      setSpin(true);
      axios
        .get("/v2/log/chart_data", {
          params: {
            _id: location.state.e._id,
            startsAtDate: startDay + "T00:00:00",
            endsAtDate: endDay + "T23:59:00",
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result.data.data);
          setDatas(result.data.data.reverse());
          setSpin(false);
        })
        .catch((err) => {
          // console.log(err);
          setSpin(false);
        });
    }
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      // bg={"red"}
      mb={"10"}
      // w={{ base: "xs", md: "xl", lg: "8xl" }}
      w={{ base: "full", md: "full", lg: "8xl" }}
    >
      <BackButton />
      <Flex
        flexDir="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        w={"full"}
        maxH="36rem"
        minH={96}
        px={{ base: 4, md: 10 }}
        py={{ base: 6, md: 6 }}
        rounded="md"
        boxShadow="rgba(99, 99, 99, 0.1) 0px 1px 6px 2px"
        bg={"#FFF"}
      >
        <Stack
          direction={{ base: "column", md: "row" }}
          justify={"space-between"}
          alignItems="center"
          w={"full"}
        >
          <Stack
            direction={{ base: "column", md: "row" }}
            w={{ base: "full", md: "min-content" }}
            align={"flex-end"}
          >
            <VStack spacing={0} w={"full"} align={"flex-start"}>
              <Text color={"#000"} fontSize={14} fontWeight="normal">
                Эхлэх
              </Text>
              <Input
                type={"date"}
                value={startDay}
                onChange={(e) => setStartDay(e.target.value)}
                bg={"#FCFCFC"}
                borderColor={"#EFEFEF"}
                borderWidth={1}
                w={"full"}
                size={"sm"}
                rounded={"md"}
                fontSize={14}
                _hover={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
                _focus={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
              />
            </VStack>
            <VStack spacing={0} w={"full"} align={"flex-start"}>
              <Text color={"#000"} fontSize={14} fontWeight="normal">
                Дуусах
              </Text>
              <Input
                type={"date"}
                value={endDay}
                onChange={(e) => setEndDay(e.target.value)}
                bg={"#FCFCFC"}
                borderColor={"#EFEFEF"}
                borderWidth={1}
                w={"full"}
                size={"sm"}
                rounded={"md"}
                fontSize={14}
                _hover={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
                _focus={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
              />
            </VStack>
            <Button
              w={"full"}
              size={"sm"}
              bg={"#45A735"}
              _hover={{
                bg: "#5AB54B",
              }}
              onClick={dayHandler}
              _focus={{
                outline: "none",
              }}
            >
              <Text color={"#fff"} fontSize={14} fontWeight="normal">
                Харах
              </Text>
            </Button>
          </Stack>
          <Tooltip label="Шинэчлэх" placement="right" hasArrow>
            <IconButton
              aria-label="Reset"
              icon={<GrPowerReset />}
              colorScheme="gray"
              onClick={resetZoom}
              w={{ base: "full", md: "min-content" }}
              size="sm"
              disabled={datas.length > 0 ? false : true}
            />
          </Tooltip>
        </Stack>

        {spin ? (
          <Center w={"full"}>
            <Spinner size="lg" color="green" mt={{ base: "20", md: "32" }} />
          </Center>
        ) : datas.length > 0 &&
          // datas.some((e) => e.Temperature != null || e.Humidity != null) ? (
          datas.some((e) => e.temperature != null || e.humidity != null) ? (
          <Line
            data={data}
            options={options}
            ref={chartRef}
            plugins={[chartAreaBorder]}
            style={{
              maxHeight: "26rem",
            }}
          />
        ) : (
          <VStack
            justifyContent="center"
            alignItems="center"
            color="gray.600"
            w="full"
          >
            <Icon mt={"20"} as={FiInfo} w={7} h={7} />
            <Text fontSize={15}>Мэдээлэл олдсонгүй</Text>
          </VStack>
        )}
      </Flex>
    </VStack>
  );
};

export default ZbAqiChart;
