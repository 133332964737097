import React, { useContext } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  HStack,
  Stack,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Image,
  Center,
  Icon,
  Text,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Avatar,
  Box,
} from "@chakra-ui/react";
import { navData } from "./SideBarData";
import { NavLink, useLocation } from "react-router-dom";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { HiLogout } from "react-icons/hi";
import logoLight from "../../Images/logo.png";
import UserContext from "../../UserContext";
import Cookies from "js-cookie";

const SideBarMobile = ({ showSideBar, toggleSidebar }) => {
  const location = useLocation();
  const context = useContext(UserContext);
  const username = Cookies.get("username");
  const role = Cookies.get("role");

  return (
    <Drawer
      onClose={toggleSidebar}
      isOpen={showSideBar}
      size={"sm"}
      placement={"left"}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton _focus={{ outline: "none" }} />
        <DrawerHeader>
          <Image src={logoLight} objectFit="contain" w={"48"} />
        </DrawerHeader>
        <DrawerBody>
          <VStack align={"center"} justify={"space-between"} h={"full"}>
            <Stack spacing={2} w={"full"}>
              <Divider />
              <Accordion allowMultiple w="100%">
                <Stack spacing={1}>
                  {navData.map((e, index) =>
                    (role === "techsub" && e.toLink === "/invoice") ||
                    (role === "techsub" && e.toLink === "/api") ||
                    (role === "techsub" && e.toLink === "/User/find") ||
                    (role === "techsub" && e.toLink === "/dashboard") ||
                    (role === "techsub" &&
                      e.toLink === "/system/device-list") ||
                    (role === "techmonitor" && e.toLink === "/dashboard") ||
                    (role === "techmonitor" && e.toLink === "/api") ||
                    (role === "techmonitor" && e.toLink === "/invoice") ||
                    (role === "techmonitor" &&
                      e.toLink ===
                        "/system/device-list") ? null : e.subTitle ? (
                      <AccordionItem key={index} border="none">
                        <AccordionButton
                          _focus={{ border: "none" }}
                          justifyContent={"space-between"}
                          _hover={{
                            backgroundColor: "#F7F7F7",
                            borderRadius: 10,
                          }}
                          as={NavLink}
                          to={e.toLink}
                          m={0}
                          px={2}
                        >
                          <HStack align="center" cursor="pointer">
                            <Center
                              bg={
                                location.pathname.split("/")[1] ===
                                e.toLink.split("/")[1]
                                  ? "#45A735"
                                  : "#fff"
                              }
                              boxShadow={"base"}
                              borderRadius={"lg"}
                              p={2}
                            >
                              <Icon
                                fontSize="16"
                                color={
                                  location.pathname.split("/")[1] ===
                                  e.toLink.split("/")[1]
                                    ? "#fff"
                                    : "#45A735"
                                }
                                as={e.icon}
                              />
                            </Center>
                            <Text
                              w={"full"}
                              color={
                                location.pathname.split("/")[1] ===
                                e.toLink.split("/")[1]
                                  ? "#000"
                                  : "#A0A0A0"
                              }
                              fontSize={"sm"}
                              fontWeight={
                                location.pathname.split("/")[1] ===
                                e.toLink.split("/")[1]
                                  ? "medium"
                                  : "normal"
                              }
                              ml={"2"}
                            >
                              {e.title}
                            </Text>
                          </HStack>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel py={0} my={2}>
                          <VStack align="center" cursor="pointer" ml={2}>
                            {e.subTitle.map((e, i) => (
                              <HStack
                                key={i}
                                as={NavLink}
                                to={e.toLink}
                                onClick={toggleSidebar}
                                alignSelf="flex-start"
                                align={"center"}
                                w={"100%"}
                                p={2}
                                mt={e.title === "Interval" ? 4 : 0}
                                transition="all 0.3s"
                                _hover={{
                                  backgroundColor: "#F7F7F7",
                                  borderRadius: 10,
                                }}
                              >
                                <Icon
                                  fontSize="16"
                                  color={
                                    location.pathname.split("/")[2] ===
                                    e.toLink.split("/")[2]
                                      ? "#45A735"
                                      : "#A0A0A0"
                                  }
                                  as={e.icon}
                                />
                                <Text
                                  w={"full"}
                                  color={
                                    location.pathname.split("/")[2] ===
                                    e.toLink.split("/")[2]
                                      ? "#000"
                                      : "#A0A0A0"
                                  }
                                  fontSize={"sm"}
                                  fontWeight={
                                    location.pathname.split("/")[2] ===
                                    e.toLink.split("/")[2]
                                      ? "medium"
                                      : "normal"
                                  }
                                >
                                  {e.title}
                                </Text>
                              </HStack>
                            ))}
                          </VStack>
                        </AccordionPanel>
                      </AccordionItem>
                    ) : e.toLink === "/admin-list" ||
                      e.toLink === "/notification" ||
                      e.toLink === "/profile" ||
                      e.toLink === "/User/notification" ||
                      e.toLink === "/banner" ||
                      e.toLink === "/login" ? null : (
                      <NavLink
                        className={"navLink"}
                        key={index}
                        to={e.toLink}
                        onClick={toggleSidebar}
                        style={{
                          textDecoration: "none",
                          alignSelf: "center",
                          width: "100%",
                          padding: 8,
                        }}
                        activeStyle={{
                          backgroundColor: "#fff",
                          borderRadius: 10,
                        }}
                      >
                        <HStack align="center" cursor="pointer">
                          <Center
                            bg={
                              location.pathname.split("/")[1] ===
                              e.toLink.split("/")[1]
                                ? "#45A735"
                                : "#fff"
                            }
                            boxShadow={"base"}
                            borderRadius={"lg"}
                            p={2}
                          >
                            <Icon
                              fontSize="16"
                              color={
                                location.pathname.split("/")[1] ===
                                e.toLink.split("/")[1]
                                  ? "#fff"
                                  : "#45A735"
                              }
                              as={e.icon}
                            />
                          </Center>
                          <Text
                            w={"full"}
                            color={
                              location.pathname.split("/")[1] ===
                              e.toLink.split("/")[1]
                                ? "#000"
                                : "#A0A0A0"
                            }
                            fontSize={"sm"}
                            fontWeight={
                              location.pathname.split("/")[1] ===
                              e.toLink.split("/")[1]
                                ? "medium"
                                : "normal"
                            }
                            ml={"2"}
                          >
                            {e.title}
                          </Text>
                        </HStack>
                      </NavLink>
                    )
                  )}
                </Stack>
              </Accordion>
            </Stack>
            <Stack pb={"8"} align={"center"} width={"100%"}>
              {navData.map((e, index) =>
                role !== "techsub" &&
                role !== "techmonitor" &&
                e.toLink === "/notification" ? (
                  <Flex
                    key={index}
                    cursor="pointer"
                    width={"full"}
                    color={"#F7F7F7"}
                    as={NavLink}
                    to={e.toLink}
                    onClick={toggleSidebar}
                    style={{
                      textDecoration: "none",
                      alignSelf: "center",
                      width: "100%",
                      padding: 8,
                    }}
                    _hover={{
                      backgroundColor: "#F7F7F7",
                      borderRadius: 10,
                    }}
                  >
                    <HStack align="center" cursor="pointer">
                      <Center
                        bg={
                          location.pathname.split("/")[1] ===
                          e.toLink.split("/")[1]
                            ? "#45A735"
                            : "#fff"
                        }
                        boxShadow={"base"}
                        borderRadius={"lg"}
                        p={2}
                      >
                        <Icon
                          fontSize="16"
                          color={
                            location.pathname.split("/")[1] ===
                            e.toLink.split("/")[1]
                              ? "#fff"
                              : "#45A735"
                          }
                          as={e.icon}
                        />
                      </Center>
                      <Text
                        w={"full"}
                        color={
                          location.pathname.split("/")[1] ===
                          e.toLink.split("/")[1]
                            ? "#000"
                            : "#A0A0A0"
                        }
                        fontSize={"sm"}
                        fontWeight={
                          location.pathname.split("/")[1] ===
                          e.toLink.split("/")[1]
                            ? "medium"
                            : "normal"
                        }
                        ml={"2"}
                      >
                        {e.title}
                      </Text>
                    </HStack>
                  </Flex>
                ) : null
              )}
              <Divider />
              <Flex
                alignItems={"center"}
                align={"center"}
                justify={"center"}
                width={"100%"}
                px={"3"}
                _hover={{
                  backgroundColor: "#F7F7F7",
                  borderRadius: 10,
                }}
              >
                <Menu w={"full"} placement={"top-start"}>
                  {({ isOpen }) => (
                    <>
                      <MenuButton
                        py={2}
                        w={"full"}
                        transition="all 0.3s"
                        _focus={{ boxShadow: "none" }}
                      >
                        <HStack w={"full"} justifyContent={"space-between"}>
                          <HStack>
                            <Avatar size={"sm"} borderRadius={"lg"} />
                            <VStack
                              alignItems="flex-start"
                              spacing="1px"
                              ml="2"
                            >
                              <Text
                                fontSize={14}
                                color={"#000"}
                                fontWeight={"medium"}
                              >
                                {username}
                              </Text>
                              <Text
                                fontSize={12}
                                color="#9A9A9A"
                                fontWeight={"medium"}
                                textTransform={"capitalize"}
                              >
                                {role ? role : "-"}
                              </Text>
                            </VStack>
                          </HStack>
                          <Box>
                            {isOpen ? <FiChevronDown /> : <FiChevronUp />}
                          </Box>
                        </HStack>
                      </MenuButton>
                      <MenuList bg={"white"} borderColor={"gray.200"}>
                        {navData.map((e, index) =>
                          e.toLink === "/profile" ||
                          (role !== "techsub" &&
                            role !== "techmonitor" &&
                            e.toLink === "/banner") ||
                          (role !== "techsub" &&
                            role !== "techmonitor" &&
                            e.toLink === "/admin-list") ? (
                            <MenuItem
                              key={index}
                              as={NavLink}
                              to={e.toLink}
                              onClick={toggleSidebar}
                              _focus={{
                                backgroundColor: "#fff",
                                borderRadius: 10,
                              }}
                              _active={{
                                backgroundColor: "#fff",
                                borderRadius: 10,
                              }}
                            >
                              <Center borderRadius={"lg"} p={2} bg={"#F5F5F5"}>
                                <Icon
                                  fontSize="16"
                                  color={"#45A735"}
                                  as={e.icon}
                                />
                              </Center>
                              <Text
                                color={"#000"}
                                fontSize={"sm"}
                                fontWeight="normal"
                                ml={"2"}
                              >
                                {e.title}
                              </Text>
                            </MenuItem>
                          ) : null
                        )}
                        <MenuDivider />
                        <MenuItem
                          _focus={{
                            backgroundColor: "#fff",
                            borderRadius: 10,
                          }}
                          _active={{
                            backgroundColor: "#fff",
                            borderRadius: 10,
                          }}
                          onClick={() => context.logoutHandler()}
                        >
                          <Center borderRadius={"lg"} p={2} bg={"#F5F5F5"}>
                            <Icon
                              fontSize="16"
                              color={"#45A735"}
                              as={HiLogout}
                            />
                          </Center>
                          <Text
                            color={"#ff0000"}
                            fontSize={"sm"}
                            fontWeight="medium"
                            ml={"2"}
                          >
                            Гарах
                          </Text>
                        </MenuItem>
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Flex>
            </Stack>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default SideBarMobile;
