import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  HStack,
  Icon,
  Skeleton,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "../../axios";
import { FiInfo } from "react-icons/fi";
import BackButton from "../../components/button/BackButton";

const ShareDevice = () => {
  const [deviceList, setDeviceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "share";

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(
        "/admin/get_shared_devices",
        {
          _id: location.state.e._id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        // console.log(result.data.data);
        setDeviceList(result.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });
  }, []);

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      w={{ base: "full", md: "full", lg: "6xl" }}
      mb={"10"}
    >
      {" "}
      <BackButton />
      {isLoading ? (
        <HStack spacing={"10"}>
          <Skeleton
            w={{ base: "xs", md: "xs" }}
            h={"10"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            w={"xs"}
            h={"10"}
            borderRadius={"md"}
            display={{ base: "none", md: "none", lg: "flex" }}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            w={"xs"}
            h={"10"}
            borderRadius={"md"}
            display={{ base: "none", md: "none", lg: "flex" }}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            w={"xs"}
            h={"10"}
            borderRadius={"md"}
            display={{ base: "none", md: "flex", lg: "flex" }}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : deviceList.length > 0 ? (
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap={6}
        >
          {deviceList.map((e, index) => (
            <VStack
              key={index}
              bg={"#fff"}
              p={"4"}
              rounded={"md"}
              shadow={"md"}
              w={{ base: "xs", md: "xs" }}
              borderTopWidth={"medium"}
              borderTopColor={"#45A735"}
            >
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  contractId:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.contractId}
                </Text>
              </HStack>
              <Divider />
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  for:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.for}
                </Text>
              </HStack>
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  house:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.house}
                </Text>
              </HStack>
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  isOriginal:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.isOriginal ? "true" : "false"}
                </Text>
              </HStack>
            </VStack>
          ))}
        </Grid>
      ) : (
        <VStack
          justifyContent="center"
          alignItems="center"
          color="gray.600"
          w="full"
          pt={"52"}
        >
          <Icon as={FiInfo} w={7} h={7} />
          <Text fontSize={15}>Хуваалцсан төхөөрөмж байхгүй байна!</Text>
        </VStack>
      )}
    </VStack>
  );
};

export default ShareDevice;
