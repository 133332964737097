import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  keyframes,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SkeletonText,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "../../axios";
import { FiEdit, FiInfo, FiMinusCircle } from "react-icons/fi";
import BackButton from "../../components/button/BackButton";

const UserRestrict = () => {
  const [restrictedUserList, setRestrictedUserList] = useState([]);
  const [unRestrictUsername, setUnRestrictUsername] = useState("");
  const [restrictShowModal, setRestrictShowModal] = useState(false);
  const [username, setUsername] = useState("");
  const [trigger, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "test";

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get(
        "/v2/user_restriction",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
      .then((result) => {
        if (cancel) return;
        // console.log(result.data.data);
        setRestrictedUserList(result.data.data);
        setTrigger(false);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
    return () => {
      cancel = true;
    };
  }, [trigger]);

  const deleteModal = (e) => {
    // console.log(e);
    setUnRestrictUsername(e);
    onOpen();
  };

  const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); }
  30% { transform: scale(1) rotate(-25deg); }
  60% { transform: scale(1) rotate(25deg); }
  100% { transform: scale(1) rotate(0); }
`;

  const animation = `${animationKeyframes} 0.8s ease`;

  const restrictModal = () => {
    setRestrictShowModal(true);
  };

  const restrictHandler = () => {
    setRestrictShowModal(false);
    if (username) {
      axios
        .post(
          "/v2/user_restriction",
          {
            username: username,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result);
          setTrigger(true);
          setUsername("");
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
        })
        .catch((err) => {
          // console.log(err.response);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const unRestrictHandler = () => {
    onClose();
    if (unRestrictUsername) {
      axios
        .put(
          "/v2/user_restriction",
          {
            username: unRestrictUsername,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result);
          setTrigger(true);
          setUnRestrictUsername("");
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
        })
        .catch((err) => {
          // console.log(err.response);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      // bg={"red"}
      mb={"10"}
      w={{ base: "full", md: "full", lg: "5xl" }}
    >
      <BackButton />
      <Stack
        w={"full"}
        direction={{ base: "column", md: "column", lg: "row" }}
        spacing={3}
      >
        <VStack
          w={"xs"}
          bg={"#fff"}
          borderTopWidth={"medium"}
          borderTopColor={"#45A735"}
          px={"4"}
          pt={"4"}
          pb={"6"}
          rounded={"md"}
          h={"min-content"}
          shadow={"md"}
        >
          <VStack spacing={3} w={"full"}>
            <VStack spacing={0.5}>
              <Text fontWeight={"medium"} fontSize={14} color={"#000"}>
                Хэрэглэгчийг хязгаарлах
              </Text>
              <Box w={"10"} h={"0.5"} bg={"#45A735"} />
            </VStack>
            <InputGroup>
              <Input
                placeholder="Нэр"
                w={"full"}
                value={username}
                fontWeight={"normal"}
                fontSize={14}
                maxLength={8}
                onChange={(e) => setUsername(e.target.value)}
                _hover={{ borderColor: "#45A735" }}
                _focus={{ borderColor: "#45A735" }}
                onKeyPress={(e) => {
                  if (username) {
                    if (e.key === "Enter") {
                      restrictModal();
                    }
                  }
                }}
              />

              <InputRightElement
                pointerEvents="none"
                children={<Icon as={FiEdit} w={4} h={4} color="gray.400" />}
              />
            </InputGroup>
            <Button
              bg={"#45A735"}
              color={"#fff"}
              fontWeight={"normal"}
              fontSize={14}
              w={"full"}
              _focus={{
                outline: "none",
              }}
              _hover={{
                bg: "#5AB54B",
              }}
              //   onClick={restrictHandler}
              onClick={restrictModal}
              disabled={username ? false : true}
            >
              Хязгаарлах
            </Button>
          </VStack>
        </VStack>
        <VStack
          w={"full"}
          bg={"#fff"}
          rounded={"md"}
          shadow={"md"}
          p={"4"}
          borderTopWidth={"medium"}
          borderTopColor={"#45A735"}
          spacing={4}
        >
          <Flex
            align={"center"}
            w="full"
            _before={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: "#45A735",
              flexGrow: 1,
              mr: { base: 4, md: 8 },
            }}
            _after={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: "#45A735",
              flexGrow: 1,
              ml: { base: 4, md: 8 },
            }}
          >
            <Text
              color={"#000"}
              fontSize={15}
              fontWeight={"medium"}
              letterSpacing={0.3}
            >
              Хязгаарлагдсан хэрэглэгчийн жагсаалт
            </Text>
          </Flex>
          <TableContainer w={"full"}>
            <Table size="md" variant="simple">
              <Thead bgGradient="linear(to-l, #45A735, #1ED600)">
                <Tr>
                  <Th
                    color={"#fff"}
                    fontWeight={"medium"}
                    fontSize={14}
                    textTransform={"initial"}
                  >
                    №
                  </Th>
                  <Th
                    color={"#fff"}
                    fontWeight={"medium"}
                    fontSize={14}
                    textTransform={"initial"}
                  >
                    Хэрэглэгчийн нэр
                  </Th>
                  <Th
                    color={"#fff"}
                    fontWeight={"medium"}
                    fontSize={14}
                    textTransform={"initial"}
                  >
                    Хязгаарласан хугацаа
                  </Th>
                  <Th
                    color={"#fff"}
                    fontWeight={"medium"}
                    fontSize={14}
                    textTransform={"initial"}
                    isNumeric
                  >
                    Устгах
                  </Th>
                </Tr>
              </Thead>
              {isLoading ? (
                <Tbody>
                  <Tr>
                    <Td>
                      <SkeletonText noOfLines={1} />
                    </Td>
                    <Td>
                      <SkeletonText noOfLines={1} />
                    </Td>
                    <Td>
                      <SkeletonText noOfLines={1} />
                    </Td>

                    <Td isNumeric>
                      <SkeletonText noOfLines={1} />
                    </Td>
                  </Tr>
                </Tbody>
              ) : restrictedUserList.length > 0 ? (
                restrictedUserList.map((e, index) => (
                  <Tbody key={index}>
                    <Tr>
                      <Td color={"#000"} fontWeight={"normal"} fontSize={14}>
                        {index + 1}
                      </Td>
                      <Td color={"#000"} fontWeight={"normal"} fontSize={14}>
                        {e.username}
                      </Td>
                      <Td color={"#000"} fontWeight={"normal"} fontSize={14}>
                        {e.restrictedAt.slice(0, 10)} /{" "}
                        {e.restrictedAt.slice(11, 19)}
                      </Td>
                      <Td
                        color={"#000"}
                        fontWeight={"normal"}
                        fontSize={14}
                        py={0}
                        isNumeric
                      >
                        <Icon
                          color={"red"}
                          w={5}
                          h={5}
                          cursor="pointer"
                          as={FiMinusCircle}
                          _hover={{ animation: animation }}
                          onClick={() => deleteModal(e.username)}
                        />
                      </Td>
                    </Tr>
                  </Tbody>
                ))
              ) : (
                <VStack
                  justifyContent="center"
                  alignItems="center"
                  color="gray.600"
                  w={{ base: "full", md: "full", lg: "3xl" }}
                  pos={{ base: "initial", md: "absolute" }}
                  py={"2"}
                  spacing={1}
                >
                  <Icon as={FiInfo} w={7} h={7} />
                  <Text fontSize={15} fontWeight={"normal"}>
                    Мэдээлэл олдсонгүй.
                  </Text>
                </VStack>
              )}
            </Table>
          </TableContainer>
        </VStack>
      </Stack>
      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader fontSize={17}>
            {unRestrictUsername} гэрээний дугаартай хэрэглэгчийн хязгаарлахыг
            болиулах уу?
          </ModalHeader>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              onClick={onClose}
              _focus={{
                outline: "none",
              }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              px={"8"}
              bg={"#45A735"}
              _hover={{
                bg: "#5AB54B",
              }}
              _focus={{
                outline: "none",
              }}
              onClick={unRestrictHandler}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isCentered
        onClose={!restrictShowModal}
        isOpen={restrictShowModal}
        motionPreset="scale"
      >
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader fontSize={17}>
            {username} гэрээний дугаартай хэрэглэгчийг хязгаарлах уу?
          </ModalHeader>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              onClick={() => setRestrictShowModal(false)}
              _focus={{
                outline: "none",
              }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              px={"8"}
              bg={"#45A735"}
              _hover={{
                bg: "#5AB54B",
              }}
              _focus={{
                outline: "none",
              }}
              onClick={restrictHandler}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default UserRestrict;
