import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  HStack,
  Icon,
  Input,
  Select,
  Skeleton,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "../../../axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { FiInfo } from "react-icons/fi";
import BackButton from "../../../components/button/BackButton";

const Lora = () => {
  const [username, setUsername] = useState("");
  const [device, setDevice] = useState([]);
  const [houseList, setHouseList] = useState([]);
  const [selectHouse, setSelectHouse] = useState("");
  const [trigger, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const id = "test-toast";
  const history = useHistory();
  const token = Cookies.get("accessToken");

  // ----------------- get Devices ---------------------
  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/lora_device", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log("List ---", result.data.data);
        setDevice(
          result.data.data.map((e) => {
            return {
              clientId: e.clientId,
              deviceType: e.deviceType,
              for: "",
              contractId: "",
              houseId: "",
            };
          })
        );
        setTrigger(false);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setTrigger(false);
        setIsLoading(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });

    return () => {
      cancel = true;
    };
  }, [trigger]);

  const labelHandler = (b, index) => {
    const clone = [...device];
    clone[index].for = b;
    setDevice(clone);
  };

  // ---------------- input value -------------------
  const handleChange = (a, index) => {
    let source = axios.CancelToken.source();
    let cancel = false;

    const newInput = [...device];
    newInput[index].contractId = a;
    setDevice(newInput);
    // -------------- get House -------------------
    if (a.split("").length === 8) {
      setUsername(a.split(" "));
      axios
        .get("admin/device_houses", {
          params: { username: a },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          // console.log("house List", result.data.data);
          setHouseList(result.data.data);
        })
        .catch((err) => {
          // console.log("error", err);
        });
      return () => {
        cancel = true;
      };
    } else {
      setHouseList([]);
    }
  };

  const handleSubmit = (e) => {
    const data = {
      clientId: e.clientId,
      deviceType: e.deviceType,
      username: e.contractId,
      for: e.for,
      house: selectHouse,
    };
    axios
      .post("/admin/lora_device", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        // console.log("Done ---", result);
        if (!toast.isActive(id)) {
          toast({
            id,
            status: "success",
            duration: 2000,
            position: "top",
            description: "Амжилттай",
          });
        }
        setTrigger(true);
        // history.push("dashboard");
      })
      .catch((err) => {
        // console.log("error", err);
        if (!toast.isActive(id)) {
          toast({
            id,
            status: "error",
            duration: 2000,
            position: "top",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  return (
    <VStack
      align={"flex-start"}
      w={{ base: "xs", md: "2xl", lg: "8xl" }}
      mb={"10"}
      spacing={4}
    >
      <BackButton />
      {isLoading ? (
        <HStack spacing={"10"}>
          <Skeleton
            w={{ base: "xs", md: "2xs" }}
            h={"10"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            w={"2xs"}
            h={"10"}
            borderRadius={"md"}
            display={{ base: "none", md: "none", lg: "flex" }}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            w={"2xs"}
            h={"10"}
            borderRadius={"md"}
            display={{ base: "none", md: "flex", lg: "flex" }}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : device.length > 0 ? (
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          gap={{ base: 4, md: 6 }}
          w={"full"}
        >
          {device.map((e, index) => (
            <VStack
              key={index}
              bg={"#fff"}
              boxShadow={"md"}
              borderRadius={"md"}
              p={"4"}
              w={"full"}
            >
              <HStack
                w={"full"}
                bg={"#F3F3F3"}
                borderWidth={1}
                borderColor={"#EFEFEF"}
                borderRadius={"md"}
                py={"2"}
                px={"4"}
                justify={"space-between"}
              >
                <Text
                  fontSize={14}
                  color={"#000"}
                  fontWeight={"normal"}
                  alignSelf={"flex-start"}
                >
                  clientId:
                </Text>
                <Text
                  fontSize={14}
                  color={"#000"}
                  fontWeight={"medium"}
                  align={"end"}
                >
                  {e.clientId}
                </Text>
              </HStack>
              <HStack
                w={"full"}
                bg={"#F3F3F3"}
                borderWidth={1}
                borderColor={"#EFEFEF"}
                borderRadius={"md"}
                py={"2"}
                px={"4"}
                justify={"space-between"}
              >
                <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                  deviceType:
                </Text>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  {e.deviceType}
                </Text>
              </HStack>
              <Divider />
              <Input
                variant="filled"
                placeholder="Төхөөрөмжийн нэр"
                onChange={(a) => labelHandler(a.target.value, index)}
                value={e.for}
                bg={"#FCFCFC"}
                w={"full"}
                fontSize={14}
                borderColor={"#EFEFEF"}
                borderWidth={1}
                // type={"text"}
                _hover={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
                _focus={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
              />
              <Stack
                w={"full"}
                direction={{ base: "column", md: "column", lg: "row" }}
              >
                <Input
                  variant="filled"
                  placeholder="Утасны дугаар"
                  onChange={(a) => handleChange(a.target.value, index)}
                  value={e.contractId}
                  bg={"#FCFCFC"}
                  w={"full"}
                  fontSize={14}
                  borderColor={"#EFEFEF"}
                  borderWidth={1}
                  maxLength={8}
                  type={"tel"}
                  _hover={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                  _focus={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                />

                <Select
                  variant={"filled"}
                  isDisabled={houseList.length > 0 ? false : true}
                  placeholder="-Байшин сонгоно уу-"
                  onChange={(q) => setSelectHouse(q.target.value)}
                  multiple={false}
                  fontSize={14}
                  bg={"#FCFCFC"}
                  w={"full"}
                  borderColor={"#EFEFEF"}
                  borderWidth={1}
                  _hover={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                  _focus={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                >
                  {houseList.map((q, index) => (
                    <option key={index} value={q._id}>
                      {q.name}
                    </option>
                  ))}
                </Select>
              </Stack>
              <Button
                w={"full"}
                bg={"#45A735"}
                _hover={{
                  bg: "#5AB54B",
                }}
                _focus={{
                  outline: "none",
                }}
                onClick={() => handleSubmit(e)}
                disabled={
                  e.for === "" || e.contractId === "" || selectHouse === ""
                    ? true
                    : false
                }
              >
                <Text color={"#fff"} fontWeight="normal">
                  Хадгалах
                </Text>
              </Button>
            </VStack>
          ))}
        </Grid>
      ) : (
        <VStack
          justifyContent="center"
          alignItems="center"
          color="gray.600"
          w="full"
          pt={"40"}
        >
          <Icon as={FiInfo} w={7} h={7} />
          <Text fontSize={15}>Холбогдсон төхөөрөмж байхгүй байна.</Text>
        </VStack>
      )}
    </VStack>
  );
};

export default Lora;
