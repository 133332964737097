import React from "react";
import { HStack, Text, VStack } from "@chakra-ui/react";

const LocationList = ({ data, setPinCenter }) => {
  const centerHandler = () => {
    // console.log("data", data);
    setPinCenter({
      lng: data.longitude,
      lat: data.latitude,
    });
  };
  return (
    <VStack
      transition={"ease-in-out .3s"}
      w={"full"}
      bg={"#fff"}
      roundedTopRight={"md"}
      roundedBottomRight={"md"}
      shadow={"md"}
      py={"2"}
      px={"4"}
      align={"flex-start"}
      justify={"flex-start"}
      spacing={2}
      borderColor={"#fff"}
      borderWidth={"thin"}
      borderLeftWidth={"medium"}
      borderLeftColor={"#45A735"}
      _hover={{
        // borderColor: "#45A735",
        shadow: "lg",
      }}
      cursor={"pointer"}
      onClick={() => centerHandler()}
    >
      <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
        <Text color={"#000"} fontWeight={"medium"} fontSize={15}>
          {data.username}
        </Text>
        <Text color={"#000"} fontWeight={"normal"} fontSize={13}>
          {data.address}
        </Text>
      </VStack>
      <HStack
        w={"full"}
        bg={"#eee"}
        rounded={"md"}
        px={"2"}
        justify={"space-between"}
        align={"center"}
      >
        <Text color={"#000"} fontWeight={"normal"} fontSize={13}>
          Төхөөрөмж:
        </Text>
        <Text color={"#000"} fontWeight={"medium"} fontSize={15}>
          {data.deviceCount}
        </Text>
      </HStack>
    </VStack>
  );
};

export default LocationList;
