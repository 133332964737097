import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  GridItem,
  HStack,
  Icon,
  Skeleton,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "../../../../axios";
import Cookies from "js-cookie";
import { FiInfo } from "react-icons/fi";
import BackButton from "../../../../components/button/BackButton";

const SearchDetail = () => {
  const [deviceDetail, setDeviceDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const token = Cookies.get("accessToken");

  useEffect(() => {
    if (location.state.e) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/admin/device_datas", {
          params: {
            clientId: location.state.e,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          // console.log("detail ---", result.data.data);
          setDeviceDetail(result.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
      };
    }
  }, []);

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      // bg={"red"}
      mb={"10"}
      w={{ base: "full", md: "full", lg: "full" }}
    >
      <BackButton />
      <Box
        bg={"#fff"}
        align={"flex-start"}
        py={"3"}
        px={"6"}
        spacing={1}
        w={"full"}
        borderLeftWidth={"medium"}
        borderColor={"#45A735"}
        shadow={"sm"}
      >
        <Text color={"#000"} fontWeight={"medium"} fontSize={15}>
          {location.state.e}
        </Text>
      </Box>
      {isLoading ? (
        <HStack alignSelf={"flex-start"} spacing={{ base: 0, md: "10" }}>
          <Skeleton
            w={{ base: "80", md: "72" }}
            h={"xs"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            display={{ base: "none", md: "flex" }}
            w={"72"}
            h={"xs"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            display={{ base: "none", md: "none", lg: "flex" }}
            w={"72"}
            h={"xs"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : deviceDetail.length > 0 ? (
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap={4}
          w={{ base: "full", md: "full" }}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          {deviceDetail.map((e, index) => (
            <GridItem
              key={index}
              w={{ base: "xs", md: "xs", lg: "sm" }}
              bg="#fff"
              boxShadow={"md"}
              py={"6"}
              px={"8"}
              borderRadius={"lg"}
              borderTopWidth={"medium"}
              borderTopColor={"#45A735"}
            >
              <VStack
                bg={"#fff"}
                borderRadius={"md"}
                align={"flex-start"}
                spacing={2}
                w={"full"}
              >
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    contractId:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.contractId}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    for:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.for ? e.for : "-"}
                  </Text>
                </HStack>
                <Divider />
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    deviceType:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.deviceType}
                  </Text>
                </HStack>
                <Stack
                  direction={{ base: "column", md: "column", lg: "row" }}
                  justifyContent={"space-between"}
                  w={"full"}
                >
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    deviceId:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.deviceId}
                  </Text>
                </Stack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    entityName:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.entityName}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    house:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.house}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    room:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.room ? e.room : "-"}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    isOriginal:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.isOriginal ? "true" : "false"}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    origin:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.origin ? e.origin : "-"}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    originalDeviceId:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.originalDeviceId ? e.originalDeviceId : "-"}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    address:
                  </Text>
                  <Text
                    fontSize={14}
                    color={e.status === "active" ? "blue" : "red"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.address ? e.address : "-"}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    status:
                  </Text>
                  <Text
                    fontSize={14}
                    color={e.status === "active" ? "blue" : "red"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.status}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    subDeviceCount:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.subDeviceCount}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    timeInterval:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.timeInterval}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    Холбогдсон цаг:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.atTime.slice(0, 10)}
                    {" / "}
                    {e.atTime.slice(11, 19)}
                  </Text>
                </HStack>
              </VStack>
            </GridItem>
          ))}
        </Grid>
      ) : (
        <VStack
          justifyContent="center"
          alignItems="center"
          color="gray.600"
          w="full"
          mt={"6"}
          ml={{ base: "24", md: "28" }}
        >
          <Icon as={FiInfo} w={7} h={7} />
          <Text fontSize={15} fontWeight={"normal"}>
            Мэдээлэл олдсонгүй.
          </Text>
        </VStack>
      )}
    </VStack>
  );
};

export default SearchDetail;
