import React, { useEffect, useState } from "react";
import {
  VStack,
  Table,
  TableContainer,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Button,
  Text,
  Flex,
  useToast,
  Spinner,
  Stack,
  Select,
  HStack,
} from "@chakra-ui/react";
import axios from "../../../axios";
import Cookies from "js-cookie";
import { TiArrowUnsorted } from "react-icons/ti";

const AddInterval = () => {
  const [intList, setIntList] = useState([]);
  const [time, setTime] = useState();
  const [deviceType, setDeviceType] = useState();
  const [trigger, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const id = "test";
  const token = Cookies.get("accessToken");
  const [sortDir, setSortDir] = useState("ascend");

  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get(
        "/admin/device_interval",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
      .then((result) => {
        // console.log(result.data.data);
        setIntList(result.data.data);
        setTrigger(false);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
  }, [trigger]);

  const handleSubmit = () => {
    axios
      .post(
        "/admin/device_interval",
        {
          deviceType: deviceType,
          interval: time,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        // console.log(result.data);
        setTime("");
        setDeviceType("");
        setTrigger(true);
        if (!toast.isActive(id)) {
          toast({
            id,
            status: "success",
            duration: 2000,
            position: "top",
            description: "Амжилттай",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  const sortList = (sortType) => {
    if (sortDir === "ascend") {
      if (sortType === "deviceType") {
        setIntList((prev) => {
          const sortedDevices = [...prev].sort((a, b) => {
            if (a?.deviceType?.toUpperCase() < b.deviceType?.toUpperCase())
              return -1;
            if (a?.deviceType?.toUpperCase() > b.deviceType?.toUpperCase())
              return 1;
            return 0;
          });
          return sortedDevices;
        });
      }

      if (sortType === "type") {
        setIntList((prev) => {
          const sortedDevices = [...prev].sort((a, b) => {
            if (a?.type?.toUpperCase() < b.type?.toUpperCase()) return -1;
            if (a?.type?.toUpperCase() > b.type?.toUpperCase()) return 1;
            return 0;
          });
          return sortedDevices;
        });
      }

      if (sortType === "timeInterval") {
        setIntList((prev) => {
          const sortedDevices = [...prev].sort((a, b) => {
            if (a?.timeInterval < b.timeInterval) return -1;
            if (a?.timeInterval > b.timeInterval) return 1;
            return 0;
          });
          return sortedDevices;
        });
      }
    } else {
      if (sortType === "deviceType") {
        setIntList((prev) => {
          const sortedDevices = [...prev].sort((a, b) => {
            if (a?.deviceType?.toUpperCase() < b.deviceType?.toUpperCase())
              return 1;
            if (a?.deviceType?.toUpperCase() > b.deviceType?.toUpperCase())
              return -1;
            return 0;
          });
          return sortedDevices;
        });
      }

      if (sortType === "type") {
        setIntList((prev) => {
          const sortedDevices = [...prev].sort((a, b) => {
            if (a?.type?.toUpperCase() < b.type?.toUpperCase()) return 1;
            if (a?.type?.toUpperCase() > b.type?.toUpperCase()) return -1;
            return 0;
          });
          return sortedDevices;
        });
      }

      if (sortType === "timeInterval") {
        setIntList((prev) => {
          const sortedDevices = [...prev].sort((a, b) => {
            if (a?.timeInterval < b.timeInterval) return 1;
            if (a?.timeInterval > b.timeInterval) return -1;
            return 0;
          });
          return sortedDevices;
        });
      }
    }

    setSortDir((prev) => (prev === "ascend" ? "descend" : "ascend"));
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      w={{ base: "full", md: "full", lg: "8xl" }}
      mb={"10"}
    >
      <Stack
        direction={{ base: "column", md: "column" }}
        w={{ base: "full", md: "3xl" }}
        bg={"#fff"}
        shadow={"md"}
        rounded={"lg"}
        borderTopWidth={"medium"}
        borderColor={"#45A735"}
        p={"4"}
        align={"flex-start"}
      >
        <Flex
          align={"center"}
          w="full"
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 0.3,
            mr: { base: 4, md: 8 },
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 1.7,
            ml: { base: 4, md: 8 },
          }}
        >
          <Text
            color={"#000"}
            fontSize={15}
            fontWeight={"medium"}
            letterSpacing={0.3}
          >
            Төхөөрөмжинд interval өгөх
          </Text>
        </Flex>
        <Stack direction={{ base: "column", md: "row" }} w={"full"}>
          <Select
            bg={"#FCFCFC"}
            variant="filled"
            borderColor={"#EFEFEF"}
            borderWidth={1}
            onChange={(e) => setDeviceType(e.target.value)}
            fontSize={15}
            _hover={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            _focus={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            placeholder="Төхөөрөмжийн төрөл"
            value={deviceType}
            disabled={isLoading}
          >
            {intList.length > 0
              ? intList.map((e) => (
                  <option key={Math.random()} value={e?.deviceType}>
                    {e?.deviceType}
                  </option>
                ))
              : null}
          </Select>
          <Input
            variant="filled"
            placeholder="Хугацаа (second)"
            onChange={(e) => setTime(e.target.value)}
            value={time}
            bg={"#FCFCFC"}
            borderColor={"#EFEFEF"}
            borderWidth={1}
            w={{ base: "full", md: "50" }}
            fontSize={15}
            type={"number"}
            _hover={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            _focus={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter" && time && deviceType) {
                handleSubmit();
              }
            }}
          />
          <Button
            w={"full"}
            bg={"#45A735"}
            _hover={{
              bg: "#5AB54B",
            }}
            _focus={{
              outline: "none",
            }}
            onClick={() => handleSubmit()}
            disabled={time && deviceType ? false : true}
          >
            <Text color={"#fff"} fontSize={15} fontWeight="normal">
              Хадгалах
            </Text>
          </Button>
        </Stack>
      </Stack>
      <TableContainer
        bg={"#fff"}
        shadow={"md"}
        rounded={"lg"}
        h={{ base: "full", md: "xl" }}
        p={{ base: "2", md: "6" }}
        overflowY={"auto"}
        overflowX={"auto"}
        css={{
          "&::-webkit-scrollbar": {
            width: "2px",
            // height: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#45A735",
            borderRadius: "24px",
          },
        }}
      >
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th color={"#000"} fontSize={12} fontWeight={"medium"}>
                №
              </Th>
              <Th
                cursor={"pointer"}
                color={"#000"}
                fontSize={12}
                fontWeight={"medium"}
                onClick={() => sortList("deviceType")}
              >
                <HStack>
                  <Text>DeviceType</Text>
                  <TiArrowUnsorted />
                </HStack>
              </Th>
              <Th
                cursor={"pointer"}
                color={"#000"}
                fontSize={12}
                fontWeight={"medium"}
                onClick={() => sortList("type")}
              >
                <HStack>
                  <Text>төрөл</Text>
                  <TiArrowUnsorted alignmentBaseline="middle" />
                </HStack>
              </Th>
              <Th
                cursor={"pointer"}
                color={"#000"}
                fontSize={12}
                fontWeight={"medium"}
                isNumeric
                onClick={() => sortList("timeInterval")}
              >
                <HStack>
                  <Text>Time (second)</Text>
                  <TiArrowUnsorted />
                </HStack>
              </Th>
            </Tr>
          </Thead>
          {isLoading ? (
            <Spinner
              position={"absolute"}
              thickness="3px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#45A735"
              size="lg"
              mt={"36"}
              ml={"36"}
            />
          ) : (
            <Tbody>
              {intList.length > 0
                ? intList.map((e, index) => (
                    <Tr key={Math.random()}>
                      <Td color={"#000"} fontSize={15} fontWeight={"normal"}>
                        {index + 1}
                      </Td>
                      <Td color={"#000"} fontSize={15} fontWeight={"normal"}>
                        {e.deviceType}
                      </Td>
                      <Td color={"#000"} fontSize={15} fontWeight={"normal"}>
                        {e.type}
                      </Td>
                      <Td
                        color={"#000"}
                        fontSize={15}
                        fontWeight={"normal"}
                        isNumeric
                      >
                        {e.timeInterval}
                      </Td>
                    </Tr>
                  ))
                : null}
            </Tbody>
          )}
        </Table>
      </TableContainer>
    </VStack>
  );
};

export default AddInterval;
