import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import axios from "../../../axios";
import Cookies from "js-cookie";
import { MdOutlineEditNotifications } from "react-icons/md";
import { TiThumbsOk } from "react-icons/ti";
import { FiInfo } from "react-icons/fi";
import SendNotification from "../../../components/userNotif/SendNotification";

const Notification = () => {
  const [trigger, setTrigger] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [notifList, setNotifList] = useState([]);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "test";

  // --------------------------- Get Notification History --------------------------------
  useEffect(() => {
    setInitialLoading(true);
    axios
      .get("/admin/user_notification", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        // console.log("Notif ---", result.data.data);
        setNotifList(result.data.data.reverse());
        setTrigger(false);
        setInitialLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setTrigger(false);
        setInitialLoading(false);
      });
  }, [trigger]);

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"6"}
      w={{ base: "xs", md: "2xl", lg: "8xl" }}
    >
      <Stack
        direction={{ base: "column", md: "column", lg: "row" }}
        w={"full"}
        spacing={4}
        justify={"flex-start"}
        align={"flex-start"}
      >
        <SendNotification setTrigger={setTrigger} />

        <VStack
          w={"full"}
          bg={"#fff"}
          rounded={"lg"}
          p={{ base: "4", md: "8" }}
          shadow={"md"}
          align={"flex-start"}
          spacing={4}
        >
          <Box
            bg={"#f9f9f9"}
            align={"flex-start"}
            py={"3"}
            px={{ base: "4", md: "6" }}
            spacing={1}
            w={"full"}
            borderLeftWidth={"medium"}
            borderColor={"#45A735"}
          >
            <Text color={"#000"} fontWeight={"medium"} fontSize={15}>
              Мэдэгдлийн жагсаалт
            </Text>
          </Box>
          {initialLoading ? (
            <Center w={"full"}>
              <Spinner size="lg" color="green" />
            </Center>
          ) : notifList.length > 0 ? (
            <Grid
              w={"full"}
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(1, 1fr)",
              }}
              gap={{ base: 3, md: 4 }}
              overflowY="auto"
              maxH={"2xl"}
              sx={{
                "&::-webkit-scrollbar": {
                  width: "6px",
                  borderRadius: "8px",
                  backgroundColor: `rgba(249, 249, 249)`,
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: `rgba(69, 167, 53, 0.6)`,
                  borderRadius: "8px",
                },
              }}
            >
              {notifList.map((e, index) => (
                <GridItem
                  key={index}
                  rounded={"lg"}
                  bg={"#f9f9f9"}
                  align={"flex-start"}
                  py={{ base: "2", md: "3" }}
                  px={{ base: "3", md: "6" }}
                  w={"full"}
                >
                  <HStack w={"full"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {index < 9 ? 0 : null}
                      {index + 1}
                    </Text>
                    <Flex
                      align={"center"}
                      w="full"
                      _before={{
                        content: '""',
                        borderBottom: "1px solid",
                        borderColor: "#45A735",
                        flexGrow: 1.7,
                        mr: { base: 4, md: 8 },
                      }}
                      _after={{
                        content: '""',
                        borderBottom: "1px solid",
                        borderColor: "#45A735",
                        flexGrow: 0.3,
                        ml: { base: 4, md: 8 },
                      }}
                    >
                      <Text
                        color={"#000"}
                        fontSize={14}
                        fontWeight={"medium"}
                        letterSpacing={0.3}
                      >
                        {e.atTime.slice(0, 10)}
                        {" / "}
                        {e.atTime.slice(11, 19)}
                      </Text>
                    </Flex>
                  </HStack>
                  <HStack
                    bg={"#fff"}
                    py={"1"}
                    px={"3"}
                    rounded={"md"}
                    justify={"space-between"}
                    align={"flex-start"}
                    my={"2"}
                  >
                    <Text
                      fontSize={14}
                      fontWeight={"medium"}
                      color={"#000"}
                      w={"20"}
                    >
                      Хэн рүү:
                    </Text>
                    <Wrap w={"full"} align={"flex-end"} justify={"flex-end"}>
                      {e.receiver ? (
                        e.receiver.length > 0 ? (
                          e.receiver.map((data, i) => (
                            <Text
                              key={i}
                              fontSize={14}
                              fontWeight={"medium"}
                              color={"#000"}
                            >
                              {data},
                            </Text>
                          ))
                        ) : (
                          <Text
                            fontSize={14}
                            fontWeight={"medium"}
                            color={"#000"}
                          >
                            Бүх хэрэглэгчрүү
                          </Text>
                        )
                      ) : (
                        <Text
                          fontSize={14}
                          fontWeight={"medium"}
                          color={"#000"}
                        >
                          Бүх хэрэглэгчид
                        </Text>
                      )}
                    </Wrap>
                  </HStack>
                  <HStack px={"3"} align={"flex-start"} justify={"flex-start"}>
                    <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
                      Гарчиг:
                    </Text>
                    <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                      {e.title}
                    </Text>
                  </HStack>

                  <HStack px={"3"} align={"flex-start"} justify={"flex-start"}>
                    <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
                      Дэлгэрэнгүй:
                    </Text>
                    <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                      {e.message}
                    </Text>
                  </HStack>

                  <HStack
                    bg={"#fff"}
                    py={"1"}
                    rounded={"md"}
                    justify={"space-between"}
                    mt={"2"}
                  >
                    <Text
                      fontSize={14}
                      fontWeight={"medium"}
                      color={"#45A735"}
                      px={"3"}
                    >
                      Мэдэгдэл явуулсан админ:
                    </Text>
                    <Text
                      fontSize={14}
                      fontWeight={"medium"}
                      color={"#000"}
                      px={"3"}
                      align={"end"}
                    >
                      {e.admin ? e.admin : "-"}
                    </Text>
                  </HStack>
                </GridItem>
              ))}
            </Grid>
          ) : (
            <VStack
              justifyContent="center"
              alignItems="center"
              alignSelf={"center"}
              color="gray.600"
              w="full"
            >
              <Icon as={FiInfo} w={7} h={7} />
              <Text fontSize={15}>Мэдэгдэл байхгүй байна.</Text>
            </VStack>
          )}
        </VStack>
      </Stack>
    </VStack>
  );
};

export default Notification;
