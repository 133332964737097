import React, { useEffect, useState } from "react";
import {
  Center,
  Divider,
  HStack,
  Icon,
  Image,
  Spinner,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import GoogleMapReact from "google-map-react";
import { GrLocation } from "react-icons/gr";
import Cookies from "js-cookie";
import axios from "../../../axios";
import LocationList from "../../../components/location/LocationList";
import AddLocation from "../../../components/location/AddLocation";
import pin from "../../../Images/pin.png";

const PinComponent = () => <Image src={pin} h={"7"} />;

const UserLocation = () => {
  const [zoom, setZoom] = useState(13);
  const [pinCenter, setPinCenter] = useState({
    lat: 47.91948034963807,
    lng: 106.91772834219846,
  });
  const [trigger, setTrigger] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";

  useEffect(() => {
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get(
        "/v2/user_location/",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
      .then((result) => {
        if (cancel) return;
        // console.log(result.data);
        setLocationList(result.data.data);
        setTrigger(false);
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: "Алдаа гарлаа!",
          });
        }
      });
    return () => {
      cancel = true;
    };
  }, [trigger]);

  return (
    <HStack
      w={"full"}
      h={"80vh"}
      align={"flex-start"}
      justify={"flex-start"}
      spacing={4}
    >
      <VStack w={"xs"} spacing={4}>
        <AddLocation setTrigger={setTrigger} />
        <Divider />
        <VStack
          w={"full"}
          spacing={3}
          maxH={"72vh"}
          overflowY="auto"
          sx={{
            "&::-webkit-scrollbar": {
              width: "6px",
              borderRadius: "8px",
              backgroundColor: `rgba(249, 249, 249)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `rgba(69, 167, 53, 0.6)`,
              borderRadius: "8px",
            },
          }}
          px={"0.5"}
          pb={"2"}
        >
          {trigger ? (
            <Center>
              <Spinner size="md" color="#45A735" />
            </Center>
          ) : locationList.length > 0 ? (
            locationList.map((e, i) => (
              <LocationList key={i} data={e} setPinCenter={setPinCenter} />
            ))
          ) : null}
        </VStack>
      </VStack>
      <VStack
        w={"full"}
        h={"full"}
        bg={"#fff"}
        rounded={"md"}
        shadow={"md"}
        p={"2"}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyC0PZPzftfnrX4eu_O59XKZPYr7xeDHISg" }}
          // defaultCenter={{
          //   lat: 47.91948034963807,
          //   lng: 106.91772834219846,
          // }}
          // center={pinCenter}
          // center={{
          //   lat: 47.920745807656715,
          //   lng: 106.87927619376096,
          // }}
          center={{
            lat: pinCenter.lat,
            lng: pinCenter.lng,
          }}
          // defaultCenter={{
          //   lat: pinLat,
          //   lng: pinLng,
          // }}
          zoom={zoom}
          onChange={(e) => setZoom(e.zoom)}
          yesIWantToUseGoogleMapApiInternals
          // onGoogleApiLoaded={({ map }) => {
          //   mapRef.current = map;
          // }}
        >
          {zoom >= 13
            ? locationList.length > 0
              ? locationList.map((e, i) => (
                  <PinComponent
                    key={i}
                    lat={e.latitude}
                    lng={e.longitude}
                    icon={GrLocation}
                  />
                ))
              : null
            : null}
        </GoogleMapReact>
      </VStack>
    </HStack>
  );
};

export default UserLocation;
