import React, { useState } from "react";
import {
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  Grid,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "../../axios";
import Cookies from "js-cookie";
import { useHistory, useLocation } from "react-router-dom";
import { FiInfo, FiPhone, FiTrash2 } from "react-icons/fi";
import { RiShareForward2Fill } from "react-icons/ri";
import { useEffect } from "react";
import BackButton from "../../components/button/BackButton";

const ApiShareDevice = () => {
  const [contractId, setContractId] = useState("");
  const [houses, setHouses] = useState([]);
  const [house, setHouse] = useState("");
  const [trigger, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shareAbleDevices, setShareAbleDevices] = useState([]);
  const [selectData, setSelectData] = useState([]);
  const [alreadySharedDevices, setAlreadySharedDevices] = useState([]);
  const [removeData, setRemoveData] = useState([]);
  const [removeModal, setRemoveModal] = useState(false);
  const toast = useToast();
  const id = "test-toast";
  const history = useHistory();
  const location = useLocation();
  const token = Cookies.get("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();

  // --- input value ---
  const handleChange = (a) => {
    setContractId(a);
    let source = axios.CancelToken.source();
    let cancel = false;
    // --- get House ---
    if (a.split("").length === 8) {
      axios
        .get("admin/device_houses", {
          params: { username: a },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          // console.log(result.data.data);
          setHouses(result.data.data);
        })
        .catch((err) => {
          //   console.log("error", err);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "error",
              duration: 2000,
              position: "top",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
      return () => {
        cancel = true;
      };
    } else {
      setHouses([]);
      setTrigger(true);
      setHouse("");
    }
  };

  // --- get Select ---
  const handleSelect = (value) => {
    setHouse(value);
  };

  useEffect(() => {
    setIsLoading(true);
    if (contractId && house) {
      axios
        .get("/admin/remote_api_service/share", {
          params: {
            houseId: house,
            remoteUsername: location.state.username,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log("Done ---", result.data);
          setShareAbleDevices(result.data.shareAbleDevices);
          setAlreadySharedDevices(result.data.alreadySharedDevices);
          setTrigger(false);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log("error", err);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "error",
              duration: 2000,
              position: "top",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
          setIsLoading(false);
        });
    }
  }, [trigger, house]);

  // ----------------------- Share device -------------------------
  const selectHandler = (e) => {
    // console.log("_id shdee ", e);
    if (selectData.find((a) => a === e)) {
      setSelectData((prevState) => prevState.filter((r) => r !== e));
    } else {
      const select = [...selectData];
      const allSelect = select.concat(e);
      setSelectData(allSelect);
    }
  };

  // ----------------- share Handler --------------------
  const shareHandler = () => {
    onClose();
    axios
      .post(
        "/admin/remote_api_service/share",
        {
          devices: selectData,
          remoteUsername: location.state.username,
          originalUsername: contractId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setTrigger(true);
        setSelectData([]);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "success",
            description: "Амжилттай хуваалцлаа!",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "xs", md: "2xl", lg: "8xl" }}
    >
      <BackButton />
      <Stack
        spacing={2}
        bg={"#fff"}
        boxShadow={"md"}
        borderRadius={"md"}
        p={"4"}
        w={{ base: "full", md: "lg" }}
        borderTopWidth={"medium"}
        borderColor={"#45A735"}
      >
        <Flex
          align={"center"}
          w="full"
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 0.3,
            mr: { base: 4, md: 4 },
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 1.7,
            ml: { base: 4, md: 4 },
          }}
        >
          <Text
            color={"#000"}
            fontSize={15}
            fontWeight={"medium"}
            letterSpacing={0.3}
          >
            Төхөөрөмж хайх
          </Text>
        </Flex>
        <Stack direction={{ base: "column", md: "row" }} w={"full"}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<Icon as={FiPhone} color="#45A735" />}
            />
            <Input
              variant="filled"
              placeholder="Утасны дугаар"
              value={contractId}
              onChange={(e) => handleChange(e.target.value)}
              bg={"#FCFCFC"}
              borderColor={"#EFEFEF"}
              borderWidth={1}
              maxLength={8}
              fontSize={"14"}
              fontWeight={"normal"}
              type={"tel"}
              w={"full"}
              _hover={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
              _focus={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
            />
          </InputGroup>
          <Select
            isDisabled={contractId.length > 7 ? false : true}
            placeholder="-Байшин сонгоно уу-"
            onChange={(e) => handleSelect(e.target.value)}
            multiple={false}
            w={"full"}
            fontSize={"14"}
            fontWeight={"normal"}
            _hover={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            _focus={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
          >
            {houses.map((q, index) => (
              <option key={index} value={q._id}>
                {q.name}
              </option>
            ))}
          </Select>
        </Stack>
      </Stack>

      {/* ------------------------- shareAbleDevices list ----------------------------- */}
      <Stack direction={{ base: "column", md: "row" }} w={"full"}>
        <Flex
          align={"center"}
          w="full"
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 0.3,
            mr: { base: 4, md: 8 },
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 1.7,
            ml: { base: 4, md: 8 },
          }}
        >
          <Text
            color={"#000"}
            fontSize={15}
            fontWeight={"medium"}
            letterSpacing={0.3}
          >
            Хуваалцах боломжтой төхөөрөмжийн жагсаалт
          </Text>
        </Flex>
        {selectData.length > 0 ? (
          <Center
            onClick={() => onOpen()}
            shadow={"sm"}
            px={"5"}
            py={"0.5"}
            bg={"#45A735"}
            rounded={"md"}
            cursor={"pointer"}
          >
            <Icon
              as={RiShareForward2Fill}
              w={"4"}
              h={"4"}
              color={"#fff"}
              mr={"1"}
            />
            <Text fontSize={"14"} color={"#fff"} fontWeight={"normal"}>
              Хуваалцах
            </Text>
          </Center>
        ) : null}
      </Stack>

      <Grid
        transition="0.5s ease"
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(4, 1fr)",
        }}
        gap={{ base: 2, md: 4, lg: 4 }}
        w={"full"}
      >
        {isLoading ? (
          <HStack alignSelf={"flex-start"} spacing={{ base: 0, md: "10" }}>
            <Skeleton
              w={{ base: "80", md: "80" }}
              h={"64"}
              borderRadius={"md"}
              startColor="blackAlpha.100"
              endColor="blackAlpha.300"
            />
            <Skeleton
              display={{ base: "none", md: "flex", lg: "flex" }}
              w={"80"}
              h={"64"}
              borderRadius={"md"}
              startColor="blackAlpha.100"
              endColor="blackAlpha.300"
            />
            <Skeleton
              display={{ base: "none", md: "none", lg: "flex" }}
              w={"80"}
              h={"64"}
              borderRadius={"md"}
              startColor="blackAlpha.100"
              endColor="blackAlpha.300"
            />
            <Skeleton
              display={{ base: "none", md: "none", lg: "flex" }}
              w={"80"}
              h={"64"}
              borderRadius={"md"}
              startColor="blackAlpha.100"
              endColor="blackAlpha.300"
            />
          </HStack>
        ) : shareAbleDevices.length > 0 ? (
          shareAbleDevices.map((e, index) => (
            <VStack
              key={index}
              w={"full"}
              bg={"#fff"}
              rounded={"md"}
              shadow={"md"}
              p={"4"}
              borderTopWidth={"medium"}
              borderColor={"#45A735"}
            >
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  clientId:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.clientId}
                </Text>
              </HStack>
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  for:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.for}
                </Text>
              </HStack>
              <Divider />
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  deviceType:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.deviceType}
                </Text>
              </HStack>
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  entityName:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.entityName}
                </Text>
              </HStack>
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  room:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.room ? e.room : "-"}
                </Text>
              </HStack>
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  id:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e._id}
                </Text>
              </HStack>
              <Checkbox
                colorScheme="green"
                size={"lg"}
                alignSelf={"flex-start"}
                value={selectData}
                onChange={() => selectHandler(e._id)}
                isChecked={selectData.find((a) => a === e._id) ? true : false}
                css={`
                  > span:first-of-type {
                    box-shadow: unset;
                  }
                `}
              >
                <Text fontSize={"15"} color="#45A735" fontWeight={"medium"}>
                  Сонгох
                </Text>
              </Checkbox>
            </VStack>
          ))
        ) : (
          <VStack
            justifyContent="center"
            alignItems="center"
            color="gray.600"
            w={{ base: "xs", md: "2xl", lg: "8xl" }}
            py={{ base: "6", md: "12", lg: "16" }}
            spacing={1}
          >
            <Icon as={FiInfo} w={7} h={7} />
            <Text fontSize={15} fontWeight={"normal"}>
              Мэдээлэл олдсонгүй.
            </Text>
          </VStack>
        )}
      </Grid>

      {/* ------------------------- alreadySharedDevices list ----------------------------- */}
      <Stack direction={{ base: "column", md: "row" }} w={"full"}>
        <Flex
          align={"center"}
          w="full"
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 0.3,
            mr: { base: 4, md: 8 },
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 1.7,
            ml: { base: 4, md: 8 },
          }}
        >
          <Text
            color={"#000"}
            fontSize={15}
            fontWeight={"medium"}
            letterSpacing={0.3}
          >
            Хуваалцсан төхөөрөмжийн жагсаалт
          </Text>
        </Flex>
        {removeData.length > 0 ? (
          <Center
            onClick={() => setRemoveModal(true)}
            shadow={"sm"}
            px={"5"}
            py={"0.5"}
            bg={"#ff0000"}
            rounded={"md"}
            cursor={"pointer"}
          >
            <Icon as={FiTrash2} w={"4"} h={"4"} color={"#fff"} mr={"1"} />
            <Text fontSize={"14"} color={"#fff"} fontWeight={"medium"}>
              Устгах
            </Text>
          </Center>
        ) : null}
      </Stack>

      <Grid
        transition="0.5s ease"
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(4, 1fr)",
        }}
        gap={{ base: 2, md: 4, lg: 4 }}
        w={"full"}
      >
        {isLoading ? (
          <HStack alignSelf={"flex-start"} spacing={{ base: 0, md: "10" }}>
            <Skeleton
              w={{ base: "80", md: "80" }}
              h={"64"}
              borderRadius={"md"}
              startColor="blackAlpha.100"
              endColor="blackAlpha.300"
            />
            <Skeleton
              display={{ base: "none", md: "flex", lg: "flex" }}
              w={"80"}
              h={"64"}
              borderRadius={"md"}
              startColor="blackAlpha.100"
              endColor="blackAlpha.300"
            />
            <Skeleton
              display={{ base: "none", md: "none", lg: "flex" }}
              w={"80"}
              h={"64"}
              borderRadius={"md"}
              startColor="blackAlpha.100"
              endColor="blackAlpha.300"
            />
            <Skeleton
              display={{ base: "none", md: "none", lg: "flex" }}
              w={"80"}
              h={"64"}
              borderRadius={"md"}
              startColor="blackAlpha.100"
              endColor="blackAlpha.300"
            />
          </HStack>
        ) : alreadySharedDevices.length > 0 ? (
          alreadySharedDevices.map((e, index) => (
            <VStack
              key={index}
              w={"full"}
              bg={"#fff"}
              rounded={"md"}
              shadow={"md"}
              p={"4"}
              borderTopWidth={"medium"}
              borderColor={"#45A735"}
            >
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  clientId:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.clientId}
                </Text>
              </HStack>
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  for:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.for}
                </Text>
              </HStack>
              <Divider />
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  deviceType:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.deviceType}
                </Text>
              </HStack>
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  entityName:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.entityName}
                </Text>
              </HStack>
              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  room:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e.room ? e.room : "-"}
                </Text>
              </HStack>

              <HStack justify={"space-between"} w={"full"}>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  id:
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  {e._id}
                </Text>
              </HStack>
            </VStack>
          ))
        ) : (
          <VStack
            justifyContent="center"
            alignItems="center"
            color="gray.600"
            w={{ base: "xs", md: "2xl", lg: "8xl" }}
            py={{ base: "6", md: "12", lg: "16" }}
            spacing={1}
          >
            <Icon as={FiInfo} w={7} h={7} />
            <Text fontSize={15} fontWeight={"normal"}>
              Мэдээлэл олдсонгүй.
            </Text>
          </VStack>
        )}
      </Grid>
      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Хуваалцах уу?</ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              _focus={{ outline: "none" }}
              onClick={onClose}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="green"
              px={"8"}
              _focus={{ outline: "none" }}
              onClick={() => shareHandler()}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default ApiShareDevice;
