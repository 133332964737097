import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  HStack,
  Icon,
  Input,
  keyframes,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "../../../axios";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { FiInfo, FiTrash2 } from "react-icons/fi";
import { AiOutlineMinusCircle } from "react-icons/ai";
import BackButton from "../../../components/button/BackButton";

const IrSubDevices = () => {
  const [modelIdList, setModelIdList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectData, setSelectData] = useState([]);
  const [subDevices, setSubDevices] = useState([]);
  const location = useLocation();
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "alert";

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/ir_controller_sub", {
        params: {
          _id: location.state.e._id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log("Ir List ---", result.data.data);
        setDeviceList(result.data.data);
        setTrigger(false);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setTrigger(false);
        setIsLoading(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });

    return () => {
      cancel = true;
    };
  }, [trigger]);

  // ------------------------- get Air condition modelId ------------------------------
  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/ircontroller_create_models", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log("AC modelId", result.data.data);
        setModelIdList(result.data.data);
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });

    return () => {
      cancel = true;
    };
  }, []);

  // --------------------- Add Device Handler ------------------------
  const addHandler = () => {
    if (subDevices.length > 0) {
      // console.log("subDevices ---->", subDevices);
      setSubDevices([]);
      axios
        .post("/admin/ir_controller_sub", subDevices, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result);
          setTrigger(true);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
        })
        .catch((err) => {
          // console.log(err.response);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  // --------------------- Select Data ------------------------
  const selectHandler = (e) => {
    if (selectData.find((a) => a === e)) {
      setSelectData((prevState) => prevState.filter((r) => r !== e));
    } else {
      const select = [...selectData];
      const allSelect = select.concat(e);
      setSelectData(allSelect);
    }
  };

  // -------------------------- create SubDevices -------------------------
  const AddForm = () => {
    setSubDevices((prevState) => {
      return [
        ...prevState,
        {
          controllerId: location.state.e._id,
          model: "",
          type: "AC",
          config: {
            tempMin: "",
            tempMax: "",
            fanSpeedMax: "",
            fanSpeedMin: "",
          },
        },
      ];
    });
  };

  // ----------------------------- update SubDevices -----------------------------------
  const updateFormValue = (value, index) => {
    // console.log("update form value", value, index);
    const devices = [...subDevices];
    devices[index].model = value;
    setSubDevices(devices);
  };
  const updateTempMin = (value, index) => {
    // console.log("update tempMin", value, index);
    const devices = [...subDevices];
    devices[index].config.tempMin = parseInt(value);
    setSubDevices(devices);
  };
  const updateTempMax = (value, index) => {
    // console.log("update tempMax", value, index);
    const devices = [...subDevices];
    devices[index].config.tempMax = parseInt(value);
    setSubDevices(devices);
  };
  const updateSpeedMin = (value, index) => {
    // console.log("update tempMin", value, index);
    const devices = [...subDevices];
    devices[index].config.fanSpeedMin = parseInt(value);
    setSubDevices(devices);
  };
  const updateSpeedMax = (value, index) => {
    // console.log("update tempMax", value, index);
    const devices = [...subDevices];
    devices[index].config.fanSpeedMax = parseInt(value);
    setSubDevices(devices);
  };

  // ----------------------------- remove SubDevices -----------------------------------
  const removeForm = (index) => {
    setSubDevices(subDevices.filter((e, i) => i !== index));
  };

  // ------------------------- Delete subDevice -------------------------------
  const deleteHandler = () => {
    onClose();
    axios
      .delete("/admin/ir_controller_sub", {
        data: {
          _id: selectData,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setTrigger(true);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "success",
            description: "Амжилттай устлаа!",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); }
  30% { transform: scale(1.02) rotate(-25deg); }
  60% { transform: scale(1) rotate(25deg); }
  100% { transform: scale(1) rotate(0); }
`;

  const animation = `${animationKeyframes} 0.8s ease`;

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "xs", md: "xl", lg: "8xl" }}
    >
      <BackButton />

      <Stack
        direction={{ base: "column", md: "column", lg: "row" }}
        align={"flex-start"}
        justify={"flex-start"}
        spacing={4}
      >
        <VStack
          bg={"#fff"}
          w={{ base: "xs", md: "sm" }}
          borderColor={"#EFEFEF"}
          borderWidth={"thin"}
          p={"4"}
          rounded={"md"}
          shadow={"md"}
          spacing={4}
          borderTopWidth={"medium"}
          borderTopColor={"#45A735"}
        >
          <Button
            w={{ base: "72", md: "xs" }}
            bg={"#ddd"}
            color={"#000"}
            fontSize={15}
            fontWeight={"normal"}
            _focus={{
              outline: "none",
            }}
            onClick={() => AddForm()}
          >
            Air condition нэмэх
          </Button>
          {subDevices.length > 0 ? (
            <VStack w={"full"} spacing={4}>
              {subDevices.map((e, index) => (
                <VStack key={index} spacing={4}>
                  <Divider />
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    justify={"space-between"}
                    align={{ base: "flex-start", md: "center" }}
                    w={"full"}
                  >
                    <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
                      ModelId:
                    </Text>
                    <Select
                      placeholder="-AC model сонгоно уу-"
                      onChange={(q) => updateFormValue(q.target.value, index)}
                      multiple={false}
                      w={"60"}
                      fontSize={"14"}
                      fontWeight={"normal"}
                      _hover={{
                        borderColor: "#45A735",
                        borderWidth: 1,
                      }}
                      _focus={{
                        borderColor: "#45A735",
                        borderWidth: 1,
                      }}
                    >
                      {modelIdList.map((q, index) => (
                        <option key={index} value={q}>
                          {q}
                        </option>
                      ))}
                    </Select>
                  </Stack>
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    justify={"space-between"}
                    align={{ base: "flex-start", md: "center" }}
                    w={"full"}
                  >
                    <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
                      Temp:
                    </Text>
                    <HStack spacing={4} alignSelf={"center"}>
                      <HStack pos={"relative"}>
                        <Input
                          placeholder="-"
                          w={"20"}
                          bg={"#fff"}
                          fontWeight={"normal"}
                          fontSize={15}
                          color={"#000"}
                          _hover={{
                            borderColor: "#45A735",
                          }}
                          _focus={{
                            outline: "none",
                            borderColor: "#45A735",
                          }}
                          value={e.config.tempMin}
                          onChange={(q) => updateTempMin(q.target.value, index)}
                        />
                        <Text
                          color={"#000"}
                          fontSize={13}
                          fontWeight={"normal"}
                          pos={"absolute"}
                          top={-2}
                          bg={"#fff"}
                          px={"1"}
                        >
                          Min
                        </Text>
                      </HStack>
                      <Text>-</Text>
                      <HStack pos={"relative"}>
                        <Input
                          placeholder="-"
                          w={"20"}
                          bg={"#fff"}
                          fontWeight={"normal"}
                          fontSize={15}
                          color={"#000"}
                          _hover={{
                            borderColor: "#45A735",
                          }}
                          _focus={{
                            outline: "none",
                            borderColor: "#45A735",
                          }}
                          value={e.config.tempMax}
                          onChange={(q) => updateTempMax(q.target.value, index)}
                        />
                        <Text
                          color={"#000"}
                          fontSize={13}
                          fontWeight={"normal"}
                          pos={"absolute"}
                          top={-2}
                          bg={"#fff"}
                          px={"1"}
                        >
                          Max
                        </Text>
                      </HStack>
                    </HStack>
                  </Stack>
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    justify={"space-between"}
                    align={{ base: "flex-start", md: "center" }}
                    w={"full"}
                  >
                    <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
                      FanSpeed:
                    </Text>
                    <HStack spacing={4} alignSelf={"center"}>
                      <HStack pos={"relative"}>
                        <Input
                          placeholder="-"
                          w={"20"}
                          bg={"#fff"}
                          fontWeight={"normal"}
                          fontSize={15}
                          color={"#000"}
                          _hover={{
                            borderColor: "#45A735",
                          }}
                          _focus={{
                            outline: "none",
                            borderColor: "#45A735",
                          }}
                          value={e.config.fanSpeedMin}
                          onChange={(q) =>
                            updateSpeedMin(q.target.value, index)
                          }
                        />
                        <Text
                          color={"#000"}
                          fontSize={13}
                          fontWeight={"normal"}
                          pos={"absolute"}
                          top={-2}
                          bg={"#fff"}
                          px={"1"}
                        >
                          Min
                        </Text>
                      </HStack>
                      <Text>-</Text>
                      <HStack pos={"relative"}>
                        <Input
                          placeholder="-"
                          w={"20"}
                          bg={"#fff"}
                          fontWeight={"normal"}
                          fontSize={15}
                          color={"#000"}
                          _hover={{
                            borderColor: "#45A735",
                          }}
                          _focus={{
                            outline: "none",
                            borderColor: "#45A735",
                          }}
                          value={e.config.fanSpeedMax}
                          onChange={(q) =>
                            updateSpeedMax(q.target.value, index)
                          }
                        />
                        <Text
                          color={"#000"}
                          fontSize={13}
                          fontWeight={"normal"}
                          pos={"absolute"}
                          top={-2}
                          bg={"#fff"}
                          px={"1"}
                        >
                          Max
                        </Text>
                      </HStack>
                    </HStack>
                  </Stack>
                  <Button
                    size={"sm"}
                    px={"6"}
                    alignSelf={"flex-end"}
                    _focus={{
                      outline: "none",
                    }}
                    onClick={() => removeForm(index)}
                  >
                    <Icon
                      as={AiOutlineMinusCircle}
                      w={5}
                      h={5}
                      color={"#ff0000"}
                    />
                    <Text
                      color={"#000"}
                      fontWeight={"normal"}
                      fontSize={15}
                      ml={1}
                    >
                      remove
                    </Text>
                  </Button>
                </VStack>
              ))}
              <Button
                w={"full"}
                bg={"#45A735"}
                color={"#fff"}
                fontSize={15}
                fontWeight={"normal"}
                _hover={{
                  bg: "#5AB54B",
                }}
                _focus={{
                  outline: "none",
                }}
                onClick={addHandler}
              >
                Хадгалах
              </Button>
            </VStack>
          ) : null}
        </VStack>

        <VStack
          bg={"#fff"}
          w={"full"}
          rounded={"md"}
          shadow={"md"}
          p={"4"}
          spacing={0}
        >
          <Box
            w={"full"}
            bg={"#F8F8F8"}
            borderTopRightRadius={"md"}
            borderBottomRightRadius={"md"}
            borderLeftWidth={"medium"}
            borderColor={"#45A735"}
            py={"2"}
            px={{ base: "4", md: "6" }}
            mb={"4"}
            pos="relative"
          >
            <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
              Төхөөрөмжийн жагсаалт
            </Text>
            {selectData.length > 0 ? (
              <Flex
                p={2}
                w="max-content"
                color="white"
                bgGradient="linear(to-br, #2E931F, #45A735)"
                rounded="md"
                marginInline="auto"
                pos="absolute"
                right={6}
                top="-0.5rem"
                boxShadow="lg"
                cursor={"pointer"}
                onClick={() => onOpen()}
                _hover={{ animation: animation }}
              >
                <Icon as={FiTrash2} color="#fff" />
              </Flex>
            ) : null}
          </Box>
          {isLoading ? (
            <HStack spacing={"10"}>
              <Skeleton
                w={{ base: "xs", md: "2xs" }}
                h={"10"}
                borderRadius={"md"}
                startColor="blackAlpha.100"
                endColor="blackAlpha.300"
              />
              <Skeleton
                w={"2xs"}
                h={"10"}
                borderRadius={"md"}
                display={{ base: "none", md: "none", lg: "flex" }}
                startColor="blackAlpha.100"
                endColor="blackAlpha.300"
              />
              <Skeleton
                w={"2xs"}
                h={"10"}
                borderRadius={"md"}
                display={{ base: "none", md: "flex", lg: "flex" }}
                startColor="blackAlpha.100"
                endColor="blackAlpha.300"
              />
            </HStack>
          ) : deviceList.length > 0 ? (
            <Grid
              w={"full"}
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
              }}
              gap={6}
            >
              {deviceList.map((e, index) => (
                <VStack
                  key={index}
                  bg={"#fff"}
                  w={"full"}
                  rounded={"md"}
                  borderTopWidth={"medium"}
                  borderTopColor={"#45A735"}
                  borderLeftWidth={"thin"}
                  borderLeftColor={"#F5F5F5"}
                  borderRightWidth={"thin"}
                  borderRightColor={"#F5F5F5"}
                  borderBottomWidth={"thin"}
                  borderBottomColor={"#F5F5F5"}
                  p={"6"}
                >
                  <HStack w={"full"} justify={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      id:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e._id}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justify={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      model:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.model}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justify={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      type:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.type}
                    </Text>
                  </HStack>
                  <Divider />

                  <HStack w={"full"} justify={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      temperature:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.currentConfig.temp}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justify={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      mode:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.currentConfig.mode}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justify={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      fanSpeed:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.currentConfig.fanSpeed}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justify={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      swingV:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.currentConfig.swingV}
                    </Text>
                  </HStack>
                  <HStack w={"full"} justify={"space-between"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      swingH:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.currentConfig.swingH}
                    </Text>
                  </HStack>
                  <Checkbox
                    colorScheme="red"
                    size={"md"}
                    fontSize={14}
                    fontWeight={"medium"}
                    color={"#ff0000"}
                    value={selectData}
                    onChange={() => selectHandler(e._id)}
                    css={`
                      > span:first-of-type {
                        box-shadow: unset;
                      }
                    `}
                  >
                    Устгах
                  </Checkbox>
                </VStack>
              ))}
            </Grid>
          ) : (
            <VStack
              justifyContent="center"
              alignItems="center"
              color="gray.600"
              w="full"
            >
              <Icon as={FiInfo} w={7} h={7} />
              <Text fontSize={15}>Холбогдсон төхөөрөмж байхгүй байна.</Text>
            </VStack>
          )}
        </VStack>
      </Stack>

      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={onClose}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={deleteHandler}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default IrSubDevices;
