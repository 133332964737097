import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  HStack,
  Icon,
  IconButton,
  Select,
  SimpleGrid,
  SkeletonText,
  Stack,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { Line } from "react-chartjs-2";
import axios from "../../../axios";
import Cookies from "js-cookie";
import { FiInfo } from "react-icons/fi";
import { GrPowerReset } from "react-icons/gr";
import BackButton from "../../../components/button/BackButton";

const SwitchStateLog = () => {
  const location = useLocation();
  const [logData, setLogData] = useState([]);
  const [day, setDay] = useState("1");
  const [durationData, setDurationData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookies.get("accessToken");

  useEffect(() => {
    axios
      .post(
        "/v2/log/swith_log",
        {
          _id: location.state.e._id,
          day: day,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        // console.log(result.data.data);
        setLogData(result.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [day]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(
        "/v2/log/switch_duration",
        {
          _id: location.state.e._id,
          day: day,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        // console.log(result.data);
        setIsLoading(false);
        setDurationData(result.data.data);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
  }, [day]);

  const selectDate = (e) => {
    setDay(e.target.value);
  };

  const labelAdjusted = logData.map((e) => {
    return (
      e.time.toString().slice(5, 10) + "--" + e.time.toString().slice(11, 16)
    );
  });

  const data = {
    labels: labelAdjusted.map((e) => e.split("--")),
    datasets: [
      {
        fill: false,
        borderColor: "#45A735",
        backgroundColor: "#45A735",
        borderCapStyle: "butt",
        borderJoinStyle: "#45A735",
        pointBorderColor: "#45A735",
        pointBackgroundColor: "#45A735",
        borderWidth: 1,
        pointBorderWidth: 1,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: "#45A735",
        pointHoverBorderColor: "#45A735",
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: logData.map((data) => data.status),
        spanGaps: false,
        tension: 0.4,
        stepped: true,
        yAxisID: "y",
      },
    ],
  };

  const chartRef = useRef(null);
  const resetZoom = () => {
    if (logData.length > 1) {
      chartRef.current.resetZoom();
    }
  };

  const options = {
    spanGaps: true,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          mode: "x",
          pinch: {
            enabled: true,
          },
        },
        pan: {
          enabled: true,
          mode: "x",
        },
      },
    },

    scales: {
      x: {
        beginAtZero: false,
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: 6,
          color: "#707070",
        },
        grid: {
          borderDash: [3, 3],
          color: "#DBDBDB",
          drawBorder: false,
          drawTicks: false,
        },
      },
      y: {
        type: "category",
        labels: ["ON", "OFF", "unknown", "disconnected"],
        offset: true,
        position: "left",
        beginAtZero: false,
        grid: {
          borderDash: [3, 3],
          color: "#DBDBDB",
          drawTicks: false,
          drawBorder: false,
        },
        ticks: {
          mirror: true,
          labelOffset: -10,
          padding: 5,
          count: 3,
          color: "#707070",
        },
      },
    },
  };
  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "xs", md: "xl", lg: "7xl" }}
    >
      <HStack w={"full"} justify={"space-between"}>
        <BackButton />
        <Stack
          direction={{ base: "column", md: "row" }}
          justify={"center"}
          align={"center"}
        >
          <Select
            value={day}
            onChange={(e) => {
              selectDate(e);
            }}
            size="sm"
            maxW="40"
            variant="filled"
            rounded="md"
            bg={"#45A735"}
            color={"#fff"}
            boxShadow={"base"}
            cursor="pointer"
            _hover={{
              bg: "#45A735",
            }}
            _focus={{
              bg: "#45A735",
              color: "#000",
            }}
          >
            <option value="1">1 өдөр</option>
            <option value="7">7 хоног</option>
            <option value="30">1 сар</option>
            <option value="90">3 сар</option>
            <option value="180">6 сар</option>
            <option value="365">1 жил</option>
          </Select>
          <Tooltip label="Шинэчлэх" placement="right" hasArrow>
            <IconButton
              aria-label="Reset"
              icon={<GrPowerReset />}
              colorScheme="gray"
              onClick={resetZoom}
              w={{ base: "full", md: "min-content" }}
              size="sm"
              _focus={{
                outline: "none",
              }}
              disabled={logData.length > 0 ? false : true}
            />
          </Tooltip>
        </Stack>
      </HStack>
      <SimpleGrid w={"full"} columns={[1, 1, 2, 3, 3]} gap={"4"}>
        <HStack
          bg={"#fff"}
          w={"full"}
          p={"4"}
          borderTopWidth={"initial"}
          borderColor={"#45A735"}
          roundedBottom={"md"}
          shadow={"md"}
          align={"center"}
          justify={"space-between"}
        >
          <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
            Асаалттай:
          </Text>
          <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
            {isLoading ? (
              <SkeletonText w={"20"} noOfLines={1} skeletonHeight="2" />
            ) : durationData ? (
              durationData.uptime
            ) : (
              "-"
            )}
          </Text>
        </HStack>
        <HStack
          bg={"#fff"}
          w={"full"}
          p={"4"}
          borderTopWidth={"initial"}
          borderColor={"#45A735"}
          roundedBottom={"md"}
          shadow={"md"}
          align={"center"}
          justify={"space-between"}
        >
          <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
            Унтраалттай:
          </Text>
          <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
            {isLoading ? (
              <SkeletonText w={"20"} noOfLines={1} skeletonHeight="2" />
            ) : durationData ? (
              durationData.downtime
            ) : (
              "-"
            )}
          </Text>
        </HStack>
        <HStack
          bg={"#fff"}
          w={"full"}
          p={"4"}
          borderTopWidth={"initial"}
          borderColor={"#45A735"}
          roundedBottom={"md"}
          shadow={"md"}
          align={"center"}
          justify={"space-between"}
        >
          <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
            Холболтоос салсан:
          </Text>
          <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
            {isLoading ? (
              <SkeletonText w={"20"} noOfLines={1} skeletonHeight="2" />
            ) : durationData ? (
              durationData.disconnected
            ) : (
              "-"
            )}
          </Text>
        </HStack>
      </SimpleGrid>
      <Box bg={"#fff"} w={"full"} h={"xs"} p={"4"} rounded={"md"} shadow={"md"}>
        {logData.length > 1 ? (
          <Line data={data} options={options} ref={chartRef} />
        ) : (
          <VStack
            justifyContent="center"
            alignItems="center"
            color="gray.600"
            w="full"
            mt={"24"}
          >
            <Icon as={FiInfo} w={7} h={7} />
            <Text fontSize={15} fontWeight={"normal"}>
              Мэдээлэл олдсонгүй.
            </Text>
          </VStack>
        )}
      </Box>
    </VStack>
  );
};

export default SwitchStateLog;
