import React, { useEffect, useState } from "react";
import {
  VStack,
  Text,
  useToast,
  Grid,
  GridItem,
  HStack,
  Skeleton,
  Center,
  Divider,
  Flex,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "../../axios";
import { useHistory, useLocation } from "react-router-dom";
import BackButton from "../../components/button/BackButton";

const UserHouseList = () => {
  const location = useLocation();
  const history = useHistory();
  const toast = useToast();
  const id = "test-toast";
  const [house, setHouse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookies.get("accessToken");

  // --------------------- get User houses ---------------------
  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/user_houses", {
        params: {
          contractId: location.state.e.contractId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log(result.data.data);
        setHouse(result.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
        setIsLoading(false);
      });
    return () => {
      cancel = true;
    };
  }, []);

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      w={{ base: "full", md: "full", lg: "8xl" }}
      mb={"10"}
    >
      <BackButton />
      {isLoading ? (
        <HStack spacing={"10"}>
          <Skeleton
            w={{ base: "xs", md: "xs", lg: "2xs" }}
            h={"2xs"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            w={{ base: "xs", md: "xs", lg: "2xs" }}
            display={{ base: "none", md: "flex", lg: "flex" }}
            h={"2xs"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            display={{ base: "none", md: "none", lg: "flex" }}
            w={{ base: "xs", md: "xs", lg: "2xs" }}
            h={"2xs"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : (
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(5, 1fr)",
          }}
          gap={{ base: 4, md: 6 }}
        >
          {house.map((e) => (
            <GridItem
              key={e._id}
              w={{ base: "xs", md: "xs", lg: "2xs" }}
              bg="#fff"
              boxShadow={"base"}
              py={"8"}
              px={"10"}
              borderRadius={"lg"}
            >
              <Text color={"#000"} fontWeight={"medium"}>
                {e.name}
              </Text>
              <Divider />

              <Flex flexDirection={"row"} justify={"space-between"}>
                <Text mt={"4"} color={"#000"} fontSize={"14"}>
                  Төхөөрөмж:
                </Text>
                <Text
                  mt={"4"}
                  color={"#000"}
                  fontSize={"14"}
                  fontWeight={"medium"}
                >
                  {e.deviceNumber}
                </Text>
              </Flex>
              <Flex flexDirection={"row"} justify={"space-between"}>
                <Text color={"#000"} fontSize={"14"}>
                  Хуваалцсан тоо:
                </Text>
                <Text color={"#000"} fontSize={"14"} fontWeight={"medium"}>
                  {e.sharedNumber}
                </Text>
              </Flex>
              <Center
                bg={"#45A735"}
                py={1}
                mt={"1"}
                borderRadius={"md"}
                cursor="pointer"
                _hover={{
                  bg: "#5AB54B",
                }}
                onClick={() =>
                  history.push("/User/find/device-list", {
                    e,
                    username: location.state.username,
                  })
                }
              >
                <Text color={"#fff"} fontSize={"14"}>
                  Төхөөрөмж
                </Text>
              </Center>
              <Flex flexDirection={"row"} justify={"space-between"}>
                <Text mt={"2"} color={"#000"} fontSize={"14"}>
                  Багц автоматжуулалт:
                </Text>
                <Text
                  mt={"2"}
                  color={"#000"}
                  fontSize={"14"}
                  fontWeight={"medium"}
                >
                  {e.groupNumber}
                </Text>
              </Flex>
              <Flex flexDirection={"row"} justify={"space-between"}>
                <Text color={"#000"} fontSize={"14"}>
                  Автоматжуулалт:
                </Text>
                <Text color={"#000"} fontSize={"14"} fontWeight={"medium"}>
                  {e.automationNumber}
                </Text>
              </Flex>
              <Center
                bg={"#45A735"}
                py={1}
                mt={"1"}
                borderRadius={"md"}
                cursor="pointer"
                _hover={{
                  bg: "#5AB54B",
                }}
                onClick={() =>
                  history.push("/User/find/automation-list", {
                    e,
                    username: location.state.username,
                  })
                }
              >
                <Text color={"#fff"} fontSize={"14"}>
                  Автоматжуулалт
                </Text>
              </Center>

              <Flex flexDirection={"row"} justify={"space-between"}>
                <Text mt={"2"} color={"#000"} fontSize={"14"}>
                  Багц үйлдэл:
                </Text>
                <Text
                  mt={"2"}
                  color={"#000"}
                  fontSize={"14"}
                  fontWeight={"medium"}
                >
                  {e.tapToRunNumber}
                </Text>
              </Flex>
              <Center
                bg={"#45A735"}
                py={1}
                mt={"1"}
                borderRadius={"md"}
                cursor="pointer"
                _hover={{
                  bg: "#5AB54B",
                }}
                onClick={() =>
                  history.push("/User/find/ttr-list", {
                    e,
                    username: location.state.username,
                  })
                }
              >
                <Text color={"#fff"} fontSize={"14"}>
                  Багц үйлдэл
                </Text>
              </Center>
            </GridItem>
          ))}
        </Grid>
      )}
    </VStack>
  );
};

export default UserHouseList;
