import React, { useState } from "react";
import {
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import BackButton from "../../components/button/BackButton";
import Cookies from "js-cookie";
import axios from "../../axios";
import { useEffect } from "react";

const DeletedDevicesDetail = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const token = Cookies.get("accessToken");
  const location = useLocation();
  const day = location.state.day;
  const id = "toast-id";
  const toast = useToast();

  // ------------------ Get last deleted devices --------------------
  const getDeletedDevices = () => {
    if (day) {
      axios
        .get("/admin/deleted_devices", {
          params: {
            day: day,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result.data.data);
          if (result.data.data.length > 0) {
            setData(result.data.data.reverse());
          } else {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "error",
                description: "Мэдээлэл олдсонгүй!",
              });
            }
          }
        })
        .catch((err) => {
          // console.log(err);

          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getDeletedDevices();
  }, []);

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "full", md: "full", lg: "8xl" }}
    >
      <BackButton />
      {loading ? (
        <Center w="full" py={"64"}>
          <Spinner size="lg" color="#45a735" />
        </Center>
      ) : (
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          gap={{ base: 4, md: 6 }}
          alignItems={"center"}
        >
          {data.map((e, index) => (
            <GridItem
              key={index}
              bg={"#fff"}
              shadow={"md"}
              p={"6"}
              w={{ base: "xs", lg: "sm" }}
              rounded={"md"}
              borderTopWidth={"medium"}
              borderTopColor={"#45A735"}
              spacing={2}
            >
              <VStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    Устгасан админ:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.admin}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    Устгасан хугацаа:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.atTime.slice(0, 10)}
                    {" / "}
                    {e.atTime.slice(11, 19)}
                  </Text>
                </HStack>
                {/* <Divider /> */}
                <Flex
                  align={"center"}
                  w="full"
                  _before={{
                    content: '""',
                    borderBottom: "1px solid",
                    borderColor: "#45A735",
                    flexGrow: 0.3,
                    mr: { base: 4, md: 4 },
                  }}
                  _after={{
                    content: '""',
                    borderBottom: "1px solid",
                    borderColor: "#45A735",
                    flexGrow: 1.7,
                    ml: { base: 4, md: 4 },
                  }}
                >
                  <Text
                    color={"#000"}
                    fontSize={14}
                    fontWeight={"medium"}
                    letterSpacing={0.3}
                  >
                    Мэдээлэл
                  </Text>
                </Flex>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    clientId:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.data.clientId}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    deviceType:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.data.deviceType}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    contractId:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.data.contractId}
                  </Text>
                </HStack>
                <HStack
                  justifyContent={"space-between"}
                  w={"full"}
                  align={"flex-start"}
                >
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    deviceId:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                    maxW={"52"}
                  >
                    {e.data.deviceId}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    entityName:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.data.entityName}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    for:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.data.for}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    house:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.data.house}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    room:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.data.room ? e.data.room : "-"}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    isOriginal:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.data.isOriginal ? "true" : "false"}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    origin:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.data.origin ? e.data.origin : "-"}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    originalDeviceId:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.data.originalDeviceId ? e.data.originalDeviceId : "-"}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    address:
                  </Text>
                  <Text
                    fontSize={14}
                    color={e.data.status === "active" ? "blue" : "red"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.data.address ? e.data.address : "-"}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    status:
                  </Text>
                  <Text
                    fontSize={14}
                    color={e.data.status === "active" ? "blue" : "red"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.data.status}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    subDeviceCount:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.data.subDeviceCount}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    timeInterval:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.data.timeInterval}
                  </Text>
                </HStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    Холбогдсон цаг:
                  </Text>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.data.atTime.slice(0, 10)}
                    {" / "}
                    {e.data.atTime.slice(11, 19)}
                  </Text>
                </HStack>
              </VStack>
            </GridItem>
          ))}
        </Grid>
      )}
    </VStack>
  );
};

export default DeletedDevicesDetail;
