import React, { useState } from "react";
import {
  Button,
  Center,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "../../axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

const SysDevice = ({
  data,
  index,
  setTrigger,
  setShowModal,
  setVisible,
  setSelectedData,
}) => {
  const [btnSpinner, setBtnSpinner] = useState(false);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const deleteHandler = () => {
    setBtnSpinner(true);
    axios
      .delete("/v2/landin/delete_device_about", {
        data: {
          _id: data._id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        onClose();
        setTrigger(true);
        setBtnSpinner(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "success",
            description: "Амжилттай устлаа!",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        setBtnSpinner(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  // console.log("data--->", data);

  return (
    <HStack
      w={"full"}
      bg={index % 2 === 0 ? "#fff" : "#F5F5F5"}
      py={"3"}
      px={"1"}
      rounded={"md"}
    >
      <Center w={"60"}>
        <Text
          fontSize={13}
          fontWeight={data.visibleInLanding ? "medium" : "normal"}
          color={data.visibleInLanding ? "#45A735" : "#f00"}
        >
          {index + 1}
        </Text>
      </Center>
      <HStack w={"full"}>
        <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
          {data.deviceName.toString()}
        </Text>
      </HStack>
      <HStack w={"full"}>
        <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
          {data.category.join(", ")}
        </Text>
      </HStack>
      <HStack w={"full"}>
        <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
          {data.price.toLocaleString() + "₮"}
        </Text>
      </HStack>
      <HStack w={"full"}>
        <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
          {data?.stock ? data.stock + "ш" : "-"}

          {typeof data?.waiting === "number" && data.waiting !== 0 && (
            <Text as={"span"} fontWeight={"medium"} color={"#eb9e19"}>
              {" / " + data.waiting}
            </Text>
          )}
        </Text>
      </HStack>
      <Center w={"sm"}>
        <Menu closeOnSelect={false}>
          <MenuButton
            as={Button}
            h={"7"}
            variant={"outline"}
            fontSize={13}
            fontWeight={"medium"}
            color={"#000"}
          >
            Үйлдэл
          </MenuButton>
          <MenuList minWidth="240px">
            <MenuOptionGroup title="Үндсэн">
              <MenuItem
                fontSize={13}
                fontWeight={"medium"}
                color={"#000"}
                px={6}
                onClick={() =>
                  history.push("/system/add-device", { id: data._id })
                }
              >
                Засах
              </MenuItem>
              <MenuItem
                fontSize={13}
                fontWeight={"medium"}
                color={"#f00"}
                px={6}
                onClick={() => onOpen()}
              >
                Устгах
              </MenuItem>
            </MenuOptionGroup>
            <MenuDivider />
            <MenuOptionGroup title="Нэмэлт">
              <MenuItem
                fontSize={13}
                fontWeight={"medium"}
                color={"#000"}
                px={6}
                onClick={() => {
                  setSelectedData(data);
                  setShowModal(true);
                }}
              >
                Stock нэмэх
              </MenuItem>
              <MenuItem
                fontSize={13}
                fontWeight={"medium"}
                color={data.visibleInLanding ? "#f00" : "#45A735"}
                px={6}
                onClick={() => {
                  setSelectedData(data);
                  setVisible(true);
                }}
              >
                {data.visibleInLanding
                  ? "Хэрэглэгчээс нуух"
                  : "Хэрэглэгчид харуулах"}
              </MenuItem>
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Center>
      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={onClose}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={deleteHandler}
              disabled={btnSpinner}
            >
              {btnSpinner ? <Spinner color="#fff" mr={2} size="sm" /> : null}
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
};

export default SysDevice;
