import {
  Text,
  HStack,
  Grid,
  GridItem,
  VStack,
  Icon,
  Divider,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Button,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Input,
  InputGroup,
  InputLeftElement,
  ModalBody,
  Box,
  Skeleton,
} from "@chakra-ui/react";
import axios from "../../axios";
import React, { useEffect, useState } from "react";
import { FiInfo, FiUsers } from "react-icons/fi";
import { BiChip } from "react-icons/bi";
import { BsCalendar2Week } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import AddButton from "../../Images/AddButton.png";
import Cookies from "js-cookie";
import DashCard, { Count } from "../../components/card/DashCard";
import { FaUserCog } from "react-icons/fa";
import { MdOutlineTouchApp } from "react-icons/md";
import { RiRouterLine } from "react-icons/ri";
import { IoMdCheckmarkCircle } from "react-icons/io";
import HorizontalLine from "../../components/line/HorizontalLine";
import { TbSettingsAutomation } from "react-icons/tb";
import { IoIosReturnRight } from "react-icons/io";
import Unit from "../../components/card/Unit";
import { MdSensors } from "react-icons/md";
import { IoIosSwitch } from "react-icons/io";
import { IoMdSwitch } from "react-icons/io";
import AddedUsers from "../../components/card/AddedUsers";

const Dashboard = () => {
  const history = useHistory();
  const [feedback, setFeedback] = useState();

  const [lastUsers, setLastUsers] = useState([]);
  const [lastAddedUserLoading, setLastAddedUserLoading] = useState(false);
  const [lastUserSource, setLastUserSource] = useState(
    axios.CancelToken.source()
  );

  const [Detail, setDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [day, setDay] = useState(30);
  const [visible, setVisible] = useState(false);
  const token = Cookies.get("accessToken");
  const [deletedLoading, setDeletedLoading] = useState(false);
  const [cardsData, setCardsData] = useState(null);
  const [datas, setDatas] = useState();

  const [debouncedValue, setDebouncedValue] = useState(""); // Debounced value
  const [cancelSource, setCancelSource] = useState(axios.CancelToken.source());
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [detailLoading, setDetailLoading] = useState(false);

  const [detectedLoading, setDetectedLoading] = useState(false);
  const [detected, setDetected] = useState(null);
  const [detectedSource, setDetectedSource] = useState(
    axios.CancelToken.source()
  );

  const [minMaxLoading, setMinMaxLoading] = useState(false);
  const [minMax, setMinMax] = useState(null);
  const [minMaxSource, setMinMaxSource] = useState(axios.CancelToken.source());

  const fetchCardsData = () => {
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get(
        "/admin/dashboard",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
      .then((res) => {
        // console.log("res", res);
        if (res?.data && typeof res?.data === "object") setCardsData(res?.data);
      })
      .catch((err) => {
        // console.error(err);
      });
    return () => {
      cancel = true;
    };
  };

  // ------------------ Get feedback number --------------------
  useEffect(() => {
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get(
        "/admin/feedback_number",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
      .then((result) => {
        if (cancel) return;
        // console.log(result.data.data);
        setFeedback(result.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      cancel = true;
    };
  }, []);

  // ------------------ Get last added users --------------------
  const getLastAddedUsers = () => {
    const source = axios.CancelToken.source();
    setLastUserSource(source);
    setLastAddedUserLoading(true);
    axios
      .get("/admin/user_last_added", {
        params: {
          day: day,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        // console.log(result.data.data);
        setLastUsers(result.data.data);
      })
      .catch((err) => {
        // console.log(err);
        if (axios.isCancel(err)) {
          // console.log("Last added Users Canceled:", err?.message);
        }
      })
      .finally(() => {
        setLastAddedUserLoading(false);
      });
  };

  // ------------------ Get minMax  --------------------
  const getMinMax = () => {
    setMinMaxLoading(true);

    if (day) {
      const source = axios.CancelToken.source();
      setMinMaxSource(source);
      axios
        .get("/admin/average", {
          params: {
            day: day,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          // console.log("MIN, MAX, AVG --->", result.data.data);
          setMinMax(result.data.data);
        })
        .catch((err) => {
          // console.log(err);
          if (axios.isCancel(err)) {
            // console.log("Min, Max Canceled:", err?.message);
          }
        })
        .finally(() => {
          setMinMaxLoading(false);
        });
    }
  };

  // ------------------ Get detected  --------------------
  const getDetected = () => {
    setDetectedLoading(true);

    if (day) {
      const source = axios.CancelToken.source();
      setDetectedSource(source);
      axios
        .get("/admin/sensor", {
          params: {
            day: day,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          // console.log("DETECTED --->", result.data.data);
          setDetected(result.data.data);
        })
        .catch((err) => {
          // console.log(err);
          if (axios.isCancel(err)) {
            // console.log("Detected Canceled:", err?.message);
          }
        })
        .finally(() => {
          setDetectedLoading(false);
        });
    }
  };

  // ------------------ Get niit automation, newDevices  --------------------
  const getData = () => {
    setLoading(true);

    if (day) {
      const source = axios.CancelToken.source();
      setCancelSource(source);
      axios
        .get("/admin/dashboard_date", {
          params: {
            day: day,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          // console.log("data --->", result.data.data);
          setDatas(result.data.data);
        })
        .catch((err) => {
          // console.log(err);
          if (axios.isCancel(err)) {
            // console.log("Request canceled:", err?.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const DetailHandler = (clientId) => {
    setVisible(true);

    setDetailLoading(true);
    axios
      .get("/admin/device_details", {
        params: {
          clientId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        // console.log("device_detail ---->", result.data.data[0]);
        setDetail(result.data.data[0]);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setDetailLoading(false);
      });
  };

  const handleSearch = () => {
    if (isInitialLoad === false) {
      // console.log("cancel source", cancelSource);
      if (cancelSource) cancelSource.cancel("CANCEL WORKING!!!");
      if (detectedSource) detectedSource.cancel("CANCEL WORKING!!!");
      if (minMaxSource) minMaxSource.cancel("CANCEL WORKING!!!");
      if (lastUserSource) lastUserSource.cancel("CANCEL WORKING!!!");

      // // Create a new cancel token for the next requests
      // const newCancelSource = axios.CancelToken.source();
      // setCancelSource(newCancelSource); // Update state with the new cancel source
    }

    if (day) {
      getData();
      getLastAddedUsers();
      getDetected();
      getMinMax();
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(day); // Update the debounced value after delay
    }, 500); // 500ms delay (adjust as needed)

    return () => {
      clearTimeout(handler); // Cleanup on value change
    };
  }, [day]);

  // Function to fire when user stops typing
  useEffect(() => {
    if (debouncedValue) {
      // console.log("User stopped typing:", debouncedValue);
      // Call your function here
      handleSearch();
    }
  }, [debouncedValue]);

  useEffect(() => {
    fetchCardsData();
    handleSearch();
  }, []);

  // const detectedOptions = {
  //   // title: {
  //   //   text: "Мэдэрсэн",
  //   //   subtext: `(сүүлийн ${day ? day : " - "} хоног)`,
  //   //   left: "center",
  //   // },
  // tooltip: {
  //   trigger: "item",
  // },
  //   legend: {
  //     // orient: "horizontal",
  //     // left: "left",
  //     // top: true,
  //     // bottom: "bottom",
  // selected: {
  //   "Хөдөлгөөн": false, // Set the default selection state to false
  // },
  //   },
  //   series: [
  //     {
  //       // name: "Access From",
  //       type: "pie",
  //       radius: "50%",
  //       data: [
  //         { value: detected?.occupancy, name: "Хөдөлгөөн" },
  //         { value: detected?.contact, name: "Contact" },
  //         { value: detected?.humanDetector, name: "Бодгаль" },
  //         { value: detected?.smoke, name: "Утаа" },
  //         { value: detected?.water, name: "Ус" },
  //       ],
  //       emphasis: {
  //         itemStyle: {
  //           shadowBlur: 10,
  //           shadowOffsetX: 0,
  //           shadowColor: "rgba(0, 0, 0, 0.5)",
  //         },
  //       },
  //     },
  //   ],
  // };

  const detectedOptions = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      // data: ["Хөдөлгөөн", "Contact", "Бодгаль", "Утаа", "Ус"],
      selected: {
        Хөдөлгөөн: false,
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: [""],
      // data: ["Хөдөлгөөн", "Contact", "Бодгаль", "Утаа", "Ус"],
      axisTick: {
        alignWithLabel: true,
      },
    },
    yAxis: {
      type: "value",
    },

    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },

    series: [
      {
        name: "Хөдөлгөөн",
        type: "bar",
        // color: "#42d620",
        data: [detected?.occupancy],
        // data: [100],
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
      },

      {
        name: "Contact",
        type: "bar",
        // color: "#2078d6",
        data: [detected?.contact],
        // data: [34],
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
      },

      {
        name: "Бодгаль",
        type: "bar",
        // color: "#2078d6",
        data: [detected?.humanDetector],
        // data: [75],
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
      },

      {
        name: "Утаа",
        type: "bar",
        // color: "#2078d6",
        data: [detected?.smoke],
        // data: [10],
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
      },

      {
        name: "Ус",
        type: "bar",
        // color: "#2078d6",
        data: [detected?.water],
        // data: [55],
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
      },
    ],

    // tooltip: {
    //   trigger: "axis",
    //   axisPointer: {
    //     type: "shadow",
    //   },
    // },
    // grid: {
    //   top: "3%",
    //   left: "3%",
    //   right: "4%",
    //   bottom: "3%",
    //   containLabel: true,
    // },
    // xAxis: [
    //   {
    //     type: "category",
    //     data: ["Хөдөлгөөн", "Contact", "Бодгаль", "Утаа", "Ус"],
    //     axisTick: {
    //       alignWithLabel: true,
    //     },
    //   },
    // ],
    // yAxis: [
    //   {
    //     type: "value",
    //   },
    // ],
    // series: [
    //   {
    //     name: "Мэдэрсэн",
    //     type: "bar",
    //     barWidth: "60%",
    //     data: [
    //       detected?.occupancy,
    //       detected?.contact,
    //       detected?.humanDetector,
    //       detected?.smoke,
    //       detected?.water,
    //     ],
    //     showBackground: true,
    //     backgroundStyle: {
    //       color: "rgba(180, 180, 180, 0.1)",
    //     },
    //   },
    // ],
  };

  const detectedOptions2 = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      top: "3%",
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: ["Хөдөлгөөн", "Contact", "Бодгаль", "Утаа", "Ус"],
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        name: "Мэдэрсэн",
        type: "bar",
        barWidth: "60%",
        data: [
          detected?.occupancy,
          detected?.contact,
          detected?.humanDetector,
          detected?.smoke,
          detected?.water,
        ],
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.1)",
        },
      },
    ],
  };

  const detectedOptions3 = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      top: "3%",
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: ["Хөдөлгөөн", "Contact", "Бодгаль", "Утаа", "Ус"],
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        // name: "Мэдэрсэн",
        type: "line",
        barWidth: "60%",
        data: [
          detected?.occupancy,
          detected?.contact,
          detected?.humanDetector,
          detected?.smoke,
          detected?.water,
        ],
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.1)",
        },
      },
    ],
  };

  // const detectedOptions = {
  //   tooltip: {
  //     trigger: "axis",
  //   },
  //   legend: {
  //     data: ["Хөдөлгөөн", "Contact", "Бодгаль", "Утаа", "Ус"],
  //     selected: {
  //       Хөдөлгөөн: false,
  //     },
  //   },
  //   grid: {
  //     left: "3%",
  //     right: "4%",
  //     bottom: "3%",
  //     containLabel: true,
  //   },
  //   xAxis: {
  //     type: "category",
  //     boundaryGap: false,
  //     data: ["Хөдөлгөөн", "Contact", "Бодгаль", "Утаа", "Ус"],
  //   },

  //   yAxis: {
  //     type: "value",
  //   },

  //   series: [
  //     {
  //       name: "Хөдөлгөөн",
  //       type: "bar",
  //       // color: "#42d620",
  //       data: [detected?.occupancy],
  //       // data: [100],
  //     },

  //     {
  //       name: "Contact",
  //       type: "bar",
  //       // color: "#2078d6",
  //       data: [detected?.contact],
  //       // data: [34],
  //     },

  //     {
  //       name: "Бодгаль",
  //       type: "bar",
  //       // color: "#2078d6",
  //       data: [detected?.humanDetector],
  //       // data: [75],
  //     },

  //     {
  //       name: "Утаа",
  //       type: "bar",
  //       // color: "#2078d6",
  //       data: [detected?.smok],
  //       // data: [10],
  //     },

  //     {
  //       name: "Ус",
  //       type: "bar",
  //       // color: "#2078d6",
  //       data: [detected?.water],
  //       // data: [55],
  //     },
  //   ],

  // };

  // const avgOptions = {
  //   // title: {
  //   //   text: "Stacked Line",
  //   // },
  //   tooltip: {
  //     trigger: "axis",
  //   },
  //   legend: {
  //     data: ["Температур", "Чийгшил"],
  //   },
  // grid: {
  //   left: "3%",
  //   right: "4%",
  //   bottom: "3%",
  //   containLabel: true,
  // },
  //   // toolbox: {
  //   //   feature: {
  //   //     saveAsImage: {},
  //   //   },
  //   // },
  //   xAxis: {
  //     type: "category",
  //     boundaryGap: false,
  //     data: ["Бага", "Дундаж", "Их"],
  //   },

  //   yAxis: {
  //     type: "value",
  //   },

  //   series: [
  //     {
  //       name: "Температур",
  //       type: "line",
  //       color: "#42d620",
  //       data: [
  //         Number(minMax?.minTemp),
  //         Number(minMax?.avgTemp),
  //         Number(minMax?.maxTemp),
  //       ],
  //     },

  //     {
  //       name: "Чийгшил",
  //       type: "line",
  //       color: "#2078d6",
  //       data: [
  //         Number(minMax?.minHum),
  //         Number(minMax?.avgHum),
  //         Number(minMax?.maxHum),
  //       ],
  //     },
  //   ],
  // animationDurationUpdate: 1000, // Animation duration for updates
  // animationEasingUpdate: "linear", // Linear easing for smooth race effect
  // };

  return (
    <HStack w={"full"} spacing={"6"} mb={"10"}>
      <HStack
        w="full"
        justifyContent={"space-between"}
        gap={6}
        alignItems={"start"}
      >
        {/* ------------------- LEFT --------------------- */}
        <VStack w="83%" spacing={6}>
          <HStack w="full">
            {/* ------------------- Search day count  --------------------- */}
            <VStack
              w={"full"}
              alignItems={"start"}
              borderColor={"#45a735"}
              borderWidth={1}
              px={3}
              py={3}
              borderRadius={"lg"}
            >
              <Text fontSize={15} color={"#000"} fontWeight={"normal"}>
                Мэдээлэл хайх хоног
              </Text>

              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={BsCalendar2Week} color="gray.400" />}
                />
                <Input
                  type="number"
                  placeholder="Хоног"
                  onChange={(e) => {
                    setDay(e.target.value);
                    if (isInitialLoad) setIsInitialLoad(false);
                  }}
                  value={day}
                  maxLength={8}
                  _hover={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                  _focus={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                  w={["64", "full", "full", "full", "full"]}
                  bg={"#fff"}
                  fontSize={14}
                  boxShadow="sm"
                  rounded={"md"}
                />
              </InputGroup>
            </VStack>

            {/* ------------------- Deleted devices --------------------- */}
            <VStack
              transition="0.5s ease"
              bg={"#fff"}
              borderRadius={"xl"}
              px={4}
              py={"27.5px"}
              align={"center"}
              justify={"center"}
              borderColor={"#eee"}
              borderWidth={"thin"}
              _hover={{
                bg: "#fff",
                shadow: "md",
              }}
              spacing={1}
              onClick={() => {
                history.push("/dashboard/deleted-devices", {
                  day,
                });
              }}
              cursor={"pointer"}
              w="full"
            >
              <VStack w="full" align={"center"} justify={"center"}>
                {deletedLoading ? (
                  <>
                    <Skeleton w="full" h="19px" />
                    <Skeleton w="full" h="19px" />
                    <Skeleton w="full" h="19px" />
                  </>
                ) : (
                  <HStack>
                    <Text fontSize={15} color={"#000"} fontWeight={"normal"}>
                      Сүүлийн {day ? day : "-"} хоногт устгагдсан төхөөрөмжийн
                      мэдээлэл
                    </Text>

                    <Icon as={IoIosReturnRight} color={"#45a735"} />
                  </HStack>
                )}
              </VStack>
            </VStack>

            {/* --------------------- Added users ---------------------- */}
            <AddedUsers
              lastAddedUserLoading={lastAddedUserLoading}
              lastUsers={lastUsers}
              day={day}
            />
            {/* <Accordion
              allowMultiple
              borderColor={"#eee"}
              borderWidth={"thin"}
              borderRadius={"xl"}
              _hover={{
                bg: "#fff",
                borderRadius: "2xl",
              }}
              w="full"
              overflow={"hidden"}
            >
              <AccordionItem border="none" bg={"#fff"}>
                <AccordionButton
                  px={4}
                  py={6}
                  rounded={"md"}
                  _focus={{
                    border: "none",
                  }}
                  _hover={{
                    bg: "#fff",
                    rounded: "md",
                  }}
                >
                  <VStack align={"center"} spacing={1} w={"md"}>
                    <Text
                      fontSize={15}
                      color={"#000"}
                      fontWeight={"normal"}
                      align={"start"}
                      textAlign={"center"}
                    >
                      Сүүлийн {day ? day : "-"} хоногт нэмэгдсэн хэрэглэгчид
                    </Text>
                    {lastAddedUserLoading ? (
                      <Center w="full" h="26px">
                        <Skeleton h="4" w="30%" rounded={"md"} />
                      </Center>
                    ) : (
                      <Text fontSize={17} color={"#000"} fontWeight={"medium"}>
                        {lastUsers.length}
                      </Text>
                    )}
                  </VStack>
                  <AccordionIcon color={"#45A735"} alignSelf={"flex-start"} />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Divider mb={"4"} />
                  <Grid templateColumns={["repeat(1, 1fr)"]} gap={2} w={"full"}>
                    {lastUsers.map((e, index) => (
                      <GridItem
                        key={index}
                        bg={"#f9f9f9"}
                        py={2}
                        px={3}
                        rounded={"md"}
                      >
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Text
                            fontSize={15}
                            color={"#000"}
                            fontWeight={"normal"}
                          >
                            contractId:
                          </Text>
                          <Text
                            fontSize={15}
                            color={"#000"}
                            fontWeight={"medium"}
                          >
                            {e.username}
                          </Text>
                        </Stack>
                      </GridItem>
                    ))}
                  </Grid>
                </AccordionPanel>
              </AccordionItem>
            </Accordion> */}
          </HStack>

          {/* ------------------- Дээд 3 дөрвөлжин --------------------- */}
          <Grid
            transition="0.5s ease"
            templateColumns={[
              "repeat(1, 1fr)",
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
            ]}
            gap={{ base: 2, md: 4, lg: 8 }}
            w={"full"}
          >
            <DashCard
              title={"Нийт Хэрэглэгч"}
              value={cardsData?.allUsers}
              icon={FiUsers}
              bg="#429aff"
            />

            <DashCard
              title={"Үндсэн төхөөрөмжтэй хэрэглэгчид"}
              value={cardsData?.usersHaveDevice}
              icon={FaUserCog}
              bg="#fdb955"
            />

            <DashCard
              title={"Санал хүсэлт"}
              value={feedback}
              bg="#fff"
              color="#45a735"
              custom={{
                backgroundImage: AddButton,
                backgroundPosition: "right",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                borderBottomWidth: "medium",
                borderBottomColor: "#45A735",
                transitionDuration: "0.3s",
                cursor: "pointer",
                onClick: () => history.push("/dashboard/feedback"),
                _hover: {
                  transform: "scale(1.03)",
                  transitionTimingFunction: "ease-out",
                },
              }}
            />
          </Grid>

          {/* ------------------- Доод 4 дөрвөлжин --------------------- */}
          <Grid
            transition="0.5s ease"
            templateColumns={[
              "repeat(1, 1fr)",
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(4, 1fr)",
            ]}
            gap={{ base: 2, md: 4, lg: 8 }}
            w={"full"}
          >
            <DashCard
              title={"Нийт автоматжуулалт"}
              value={cardsData?.allAutomations}
              icon={TbSettingsAutomation}
              bg="#ca72d2"
            />
            <DashCard
              title={"Нийт төхөөрөмж"}
              value={cardsData?.allDevices}
              icon={RiRouterLine}
              bg="#40d9b7"
            />

            <DashCard
              title={"Нийт нэгж төхөөрөмж"}
              value={cardsData?.allSubDevices}
              icon={BiChip}
              bg="#f85d79"
            />

            <DashCard
              title={"Нийт гараар удирдсан"}
              value={cardsData?.switchCount}
              icon={MdOutlineTouchApp}
              bg="#75c938"
            />
          </Grid>

          {/* ------------------- 3 List --------------------- */}
          <Grid
            w="full"
            pt={2}
            templateColumns={[
              "repeat(1, 1fr)",
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
            ]}
            gap={8}
          >
            {/* -------------------- Min, max values ------------------ */}
            <GridItem
              w="full"
              maxHeight={"fit-content"}
              transition="0.5s ease"
              bg={"#fff"}
              borderRadius={"xl"}
              borderColor={"#eee"}
              borderWidth={"thin"}
              _hover={{
                bg: "#fff",
                shadow: "md",
              }}
              spacing={2}
              overflow={"hidden"}
            >
              {minMaxLoading ? (
                <VStack
                  w={"full"}
                  minH={"411px"}
                  maxH={"411px"}
                  spacing={3}
                  p={4}
                  alignItems={"center"}
                >
                  <Skeleton w="full" flexGrow={1} rounded={"lg"} />
                </VStack>
              ) : minMax ? (
                <VStack spacing={"0"}>
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    alignSelf={"start"}
                    px={4}
                    pt={4}
                  >
                    Температур{" "}
                    <span style={{ fontStyle: "italic", fontSize: 11 }}>
                      (сүүлийн {day ? day : "-"} хоног)
                    </span>{" "}
                    <span style={{ fontStyle: "italic", fontSize: 11 }}>
                      (дата:{" "}
                      {minMax?.count
                        ? Number(minMax?.count)?.toLocaleString()
                        : "-"}
                      )
                    </span>
                  </Text>

                  <HorizontalLine />
                  <VStack
                    w={"full"}
                    maxH={"sm"}
                    spacing={4}
                    px={4}
                    pb={4}
                    overflowY="auto"
                    sx={{
                      "&::-webkit-scrollbar": {
                        width: "2px",
                        borderRadius: "8px",
                        backgroundColor: `rgba(249, 249, 249)`,
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: `#03fc03`,
                        borderRadius: "8px",
                      },
                    }}
                  >
                    <Grid w="full" templateColumns={"repeat(1, 1fr)"} gap={"3"}>
                      <Unit title={"Их"} value={minMax?.maxTemp} unit={"°C"} />
                      <Unit
                        title={"Дундаж"}
                        value={minMax?.avgTemp}
                        unit={"°C"}
                      />
                      <Unit
                        title={"Бага"}
                        value={minMax?.minTemp}
                        unit={"°C"}
                      />
                    </Grid>
                  </VStack>

                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    alignSelf={"start"}
                    px={4}
                    pt={1}
                  >
                    Чийгшил{" "}
                    <span style={{ fontStyle: "italic", fontSize: 11 }}>
                      (сүүлийн {day ? day : "-"} хоног)
                    </span>
                  </Text>

                  <HorizontalLine />

                  <VStack
                    w={"full"}
                    maxH={"sm"}
                    spacing={4}
                    px={4}
                    pb={4}
                    overflowY="auto"
                    sx={{
                      "&::-webkit-scrollbar": {
                        width: "2px",
                        borderRadius: "8px",
                        backgroundColor: `rgba(249, 249, 249)`,
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: `#03fc03`,
                        borderRadius: "8px",
                      },
                    }}
                  >
                    <Grid w="full" templateColumns={"repeat(1, 1fr)"} gap={"3"}>
                      <Unit title={"Их"} value={minMax?.maxHum} unit={"%"} />
                      <Unit
                        title={"Дундаж"}
                        value={minMax?.avgHum}
                        unit={"%"}
                      />
                      <Unit title={"Бага"} value={minMax?.minHum} unit={"%"} />
                    </Grid>
                  </VStack>
                </VStack>
              ) : (
                <VStack
                  justifyContent="center"
                  alignItems="center"
                  color="gray.600"
                  w="full"
                  py={2}
                >
                  <Text
                    fontSize={14}
                    color={"#000"}
                    fontWeight={"medium"}
                    alignSelf={"start"}
                    px={4}
                    pt={2}
                  >
                    Их, бага, дундаж{" "}
                    <span style={{ fontStyle: "italic", fontSize: 11 }}>
                      (сүүлийн {day ? day : "-"} хоног)
                    </span>
                  </Text>

                  <HorizontalLine my={"0"} />

                  <VStack
                    justifyContent="center"
                    alignItems="center"
                    color="gray.600"
                    w="full"
                    py={2}
                  >
                    <Icon as={FiInfo} w={6} h={6} />
                    <Text fontSize={15}>Мэдээлэл олдсонгүй.</Text>
                  </VStack>
                </VStack>
              )}
            </GridItem>

            {/* -------------------- Smoke, water, occupancy detected ------------------ */}

            {/* <GridItem
              w="full"
              maxHeight={"fit-content"}
              transition="0.5s ease"
              bg={"#fff"}
              borderRadius={"xl"}
              borderColor={"#eee"}
              borderWidth={"thin"}
              _hover={{
                bg: "#fff",
                shadow: "md",
              }}
              spacing={2}
              overflow={"hidden"}
            >
              <Text
                fontSize={14}
                color={"#000"}
                fontWeight={"medium"}
                alignSelf={"start"}
                px={4}
                pt={4}
              >
                Мэдэрсэн{" "}
                <span style={{ fontStyle: "italic", fontSize: 11 }}>
                  (сүүлийн {day ? day : "-"} хоног)
                </span>
              </Text>

              <HorizontalLine />

              {detectedLoading ? (
                <VStack
                  w={"full"}
                  minH={"350px"}
                  maxH={"350px"}
                  spacing={3}
                  px={4}
                  pb={4}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Skeleton w="230px" h="230px" rounded={"full"} />
                </VStack>
              ) : detected ? (
                <Center
                  w="full"
                  alignItems={"center"}
                  justifyContent={"center"}
                  minH="350px"
                  maxH="350px"
                >
                  <Box w="90%" h="100%">
                    <ReactECharts
                      style={{ width: "100%", height: "350px" }}
                      option={detectedOptions3}
                    />
                  </Box>
                </Center>
              ) : (
                <VStack
                  justifyContent="center"
                  alignItems="center"
                  color="gray.600"
                  w="full"
                  py={10}
                >
                  <Icon as={FiInfo} w={6} h={6} />
                  <Text fontSize={15}>Мэдээлэл олдсонгүй.</Text>
                </VStack>
              )}
            </GridItem>

            <GridItem
              w="full"
              maxHeight={"fit-content"}
              transition="0.5s ease"
              bg={"#fff"}
              borderRadius={"xl"}
              borderColor={"#eee"}
              borderWidth={"thin"}
              _hover={{
                bg: "#fff",
                shadow: "md",
              }}
              spacing={2}
              overflow={"hidden"}
            >
              <Text
                fontSize={14}
                color={"#000"}
                fontWeight={"medium"}
                alignSelf={"start"}
                px={4}
                pt={4}
              >
                Мэдэрсэн{" "}
                <span style={{ fontStyle: "italic", fontSize: 11 }}>
                  (сүүлийн {day ? day : "-"} хоног)
                </span>
              </Text>

              <HorizontalLine />

              {detectedLoading ? (
                <VStack
                  w={"full"}
                  minH={"350px"}
                  maxH={"350px"}
                  spacing={3}
                  px={4}
                  pb={4}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Skeleton w="230px" h="230px" rounded={"full"} />
                </VStack>
              ) : detected ? (
                <Center
                  w="full"
                  alignItems={"center"}
                  justifyContent={"center"}
                  minH="350px"
                  maxH="350px"
                >
                  <Box w="90%" h="100%">
                    <ReactECharts
                      style={{ width: "100%", height: "350px" }}
                      option={detectedOptions}
                    />
                  </Box>
                </Center>
              ) : (
                <VStack
                  justifyContent="center"
                  alignItems="center"
                  color="gray.600"
                  w="full"
                  py={10}
                >
                  <Icon as={FiInfo} w={6} h={6} />
                  <Text fontSize={15}>Мэдээлэл олдсонгүй.</Text>
                </VStack>
              )}
            </GridItem> */}

            <GridItem
              w="full"
              maxHeight={"fit-content"}
              transition="0.5s ease"
              bg={"#fff"}
              borderRadius={"xl"}
              borderColor={"#eee"}
              borderWidth={"thin"}
              _hover={{
                bg: "#fff",
                shadow: "md",
              }}
              spacing={2}
              overflow={"hidden"}
            >
              <Text
                fontSize={14}
                color={"#000"}
                fontWeight={"medium"}
                alignSelf={"start"}
                px={4}
                pt={4}
              >
                Мэдэрсэн{" "}
                <span style={{ fontStyle: "italic", fontSize: 11 }}>
                  (сүүлийн {day ? day : "-"} хоног)
                </span>
              </Text>

              <HorizontalLine />

              {detectedLoading ? (
                <VStack
                  w={"full"}
                  minH={"350px"}
                  maxH={"350px"}
                  spacing={3}
                  px={4}
                  pb={4}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Skeleton w="230px" h="230px" rounded={"full"} />
                </VStack>
              ) : detected ? (
                <VStack
                  w={"full"}
                  maxH={"350px"}
                  spacing={4}
                  px={4}
                  pb={4}
                  overflowX={"hidden"}
                  overflowY="auto"
                  sx={{
                    "&::-webkit-scrollbar": {
                      width: "2px",
                      borderRadius: "8px",
                      backgroundColor: `rgba(249, 249, 249)`,
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: `#03fc03`,
                      borderRadius: "8px",
                    },
                  }}
                >
                  <Grid w="full" templateColumns={"repeat(1, 1fr)"} gap={"3"}>
                    <Unit title="Хөдөлгөөн" value={detected?.occupancy} />
                    <Unit title="Contact" value={detected?.contact} />
                    <Unit title="Бодгаль" value={detected?.humanDetector} />
                    <Unit title="Утаа" value={detected?.smoke} />
                    <Unit title="Ус" value={detected?.water} />
                  </Grid>
                </VStack>
              ) : (
                <VStack
                  justifyContent="center"
                  alignItems="center"
                  color="gray.600"
                  w="full"
                  py={10}
                >
                  <Icon as={FiInfo} w={6} h={6} />
                  <Text fontSize={15}>Мэдээлэл олдсонгүй.</Text>
                </VStack>
              )}
            </GridItem>
            {/* -------------------- Added devices ------------------ */}
            <GridItem
              w="full"
              transition="0.5s ease"
              bg={"#fff"}
              borderRadius={"xl"}
              borderColor={"#eee"}
              borderWidth={"thin"}
              _hover={{
                bg: "#fff",
                shadow: "md",
              }}
              spacing={2}
            >
              <Text
                fontSize={14}
                color={"#000"}
                fontWeight={"medium"}
                alignSelf={"start"}
                px={4}
                pt={4}
              >
                Холбогдсон төхөөрөмж{" "}
                <span style={{ fontStyle: "italic", fontSize: 11 }}>
                  (сүүлийн {day ? day : "-"} хоног)
                </span>
              </Text>

              <HorizontalLine />

              {loading ? (
                <VStack
                  w={"full"}
                  minH={"350px"}
                  maxH={"350px"}
                  spacing={3}
                  px={4}
                  pb={4}
                >
                  <Skeleton w="full" flexGrow={1} rounded={"lg"} />
                  <Skeleton w="full" flexGrow={1} rounded={"lg"} />
                  <Skeleton w="full" flexGrow={1} rounded={"lg"} />
                  <Skeleton w="full" flexGrow={1} rounded={"lg"} />
                </VStack>
              ) : datas?.newDevices?.length > 0 ? (
                <VStack
                  w={"full"}
                  maxH={"350px"}
                  spacing={4}
                  px={4}
                  pb={4}
                  overflowX={"hidden"}
                  overflowY="auto"
                  sx={{
                    "&::-webkit-scrollbar": {
                      width: "2px",
                      borderRadius: "8px",
                      backgroundColor: `rgba(249, 249, 249)`,
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: `#03fc03`,
                      borderRadius: "8px",
                    },
                  }}
                >
                  <Grid w="full" templateColumns={"repeat(1, 1fr)"} gap={"3"}>
                    {datas?.newDevices?.map((e, index) => (
                      <GridItem
                        key={Math.random()}
                        bg={"#f9f9f9"}
                        // w={["56", "56", "full", "xs", "xs"]}
                        w="full"
                        rounded={"lg"}
                        py={"2"}
                        px={"4"}
                        transitionDuration={"0.2s"}
                        transitionTimingFunction={"ease-out"}
                        _hover={{
                          transform: "scale(1.02)",
                        }}
                        cursor={"pointer"}
                        onClick={() => DetailHandler(e)}
                      >
                        <HStack justify={"space-between"}>
                          <Text
                            fontSize={"sm"}
                            color="#000"
                            fontWeight={"normal"}
                            alignSelf={"flex-start"}
                          >
                            clientId:
                          </Text>
                          <Text
                            fontSize={"sm"}
                            color="#000"
                            fontWeight={"medium"}
                            align={"end"}
                          >
                            {e}
                          </Text>
                        </HStack>
                      </GridItem>
                    ))}
                  </Grid>
                </VStack>
              ) : (
                <VStack
                  justifyContent="center"
                  alignItems="center"
                  color="gray.600"
                  w="full"
                  py={10}
                >
                  <Icon as={FiInfo} w={6} h={6} />
                  <Text fontSize={15}>Мэдээлэл олдсонгүй.</Text>
                </VStack>
              )}
            </GridItem>
          </Grid>
        </VStack>

        {/* ------------------- RIGHT --------------------- */}
        <VStack spacing={4} flexGrow={"1"} maxW={"20%"}>
          <Box
            w="full"
            css={{
              transform: "translateY(2px)",
            }}
          >
            <DashCard
              title={"Нийт унтраалга"}
              value={cardsData?.allSwitches}
              icon={IoMdSwitch}
              bg="#7442ff"
            />
          </Box>

          <Box w="full" mt={"10px"}>
            <DashCard
              title={"Нийт мэдрэгч"}
              value={cardsData?.allSensors}
              icon={MdSensors}
              bg="#cc3c9c"
            />
          </Box>

          {/* ------------------- Success automations --------------------- */}
          <Box
            w={"full"}
            boxShadow={"md"}
            borderRadius={"xl"}
            px={6}
            py={8}
            position={"relative"}
            zIndex={10}
            overflow={"hidden"}
          >
            <Box
              left="-15%"
              top="-40%"
              position={"absolute"}
              w={"400px"}
              h={"400px"}
              css={{
                background:
                  "linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)",
                animation: "anime 15s infinite ease",
                backgroundSize: "400% 400%",
              }}
              rounded={"full"}
            />
            <HStack justify={"space-between"}>
              <VStack alignItems={"center"} spacing={4}>
                <Icon
                  zIndex={10}
                  as={IoMdCheckmarkCircle}
                  fontSize={45}
                  color={"#fff"}
                />
                <Text
                  fontSize={"xx-large"}
                  color={"#fff"}
                  fontWeight={"semibold"}
                  css={{
                    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.05)",
                  }}
                  zIndex={10}
                >
                  <Count value={datas?.automationCount} />
                </Text>
                <Text
                  zIndex={10}
                  fontSize={"sm"}
                  color={"#fff"}
                  fontWeight={"normal"}
                  css={{
                    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.05)",
                  }}
                  textAlign={"center"}
                >
                  Сүүлийн {day || "-"} хоногт ажилласан автоматжуулалт
                </Text>
              </VStack>
            </HStack>
          </Box>
        </VStack>
      </HStack>

      {/* holbogdson tohooromj deer darahad garch ireh modal */}
      <Modal
        isCentered
        onClose={() => setVisible(false)}
        isOpen={visible}
        motionPreset="scale"
      >
        <ModalOverlay />
        <ModalContent w={["xs", "sm", "sm", "sm", "sm"]}>
          <ModalHeader>
            <Text fontSize={"16"} color="#000" fontWeight={"medium"}>
              Төхөөрөмжийн дэлгэрэнгүй
            </Text>
          </ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          <ModalBody>
            <VStack>
              {detailLoading ? (
                <VStack w="full" gap={"2.9px"}>
                  <Skeleton h="5" w="full" rounded={"lg"} />
                  <Skeleton h="5" w="full" rounded={"lg"} />
                  <Skeleton h="5" w="full" rounded={"lg"} />
                  <Skeleton h="5" w="full" rounded={"lg"} />
                  <Skeleton h="5" w="full" rounded={"lg"} />
                  <Skeleton h="5" w="full" rounded={"lg"} />
                  <Skeleton h="5" w="full" rounded={"lg"} />
                  <Skeleton h="5" w="full" rounded={"lg"} />
                  <Skeleton h="5" w="full" rounded={"lg"} />
                </VStack>
              ) : (
                <>
                  <HStack justify={"space-between"} w={"full"}>
                    <Text fontSize={"15"} color="#000" fontWeight={"normal"}>
                      clientId:
                    </Text>
                    <Text fontSize={"15"} color="#000" fontWeight={"medium"}>
                      {Detail.clientId}
                    </Text>
                  </HStack>
                  <HStack justify={"space-between"} w={"full"}>
                    <Text fontSize={"15"} color="#000" fontWeight={"normal"}>
                      contractId:
                    </Text>
                    <Text fontSize={"15"} color="#000" fontWeight={"medium"}>
                      {Detail.contractId}
                    </Text>
                  </HStack>
                  <HStack justify={"space-between"} w={"full"}>
                    <Text fontSize={"15"} color="#000" fontWeight={"normal"}>
                      deviceType:
                    </Text>
                    <Text fontSize={"15"} color="#000" fontWeight={"medium"}>
                      {Detail.deviceType}
                    </Text>
                  </HStack>
                  <HStack justify={"space-between"} w={"full"}>
                    <Text fontSize={"15"} color="#000" fontWeight={"normal"}>
                      entityName:
                    </Text>
                    <Text fontSize={"15"} color="#000" fontWeight={"medium"}>
                      {Detail.entityName}
                    </Text>
                  </HStack>
                  <HStack justify={"space-between"} w={"full"}>
                    <Text fontSize={"15"} color="#000" fontWeight={"normal"}>
                      for:
                    </Text>
                    <Text fontSize={"15"} color="#000" fontWeight={"medium"}>
                      {Detail.for}
                    </Text>
                  </HStack>
                  <HStack justify={"space-between"} w={"full"}>
                    <Text fontSize={"15"} color="#000" fontWeight={"normal"}>
                      house:
                    </Text>
                    <Text fontSize={"15"} color="#000" fontWeight={"medium"}>
                      {Detail.house}
                    </Text>
                  </HStack>
                  <HStack justify={"space-between"} w={"full"}>
                    <Text fontSize={"15"} color="#000" fontWeight={"normal"}>
                      room:
                    </Text>
                    <Text fontSize={"15"} color="#000" fontWeight={"medium"}>
                      {Detail.room ? Detail.room : "-"}
                    </Text>
                  </HStack>
                  <HStack justify={"space-between"} w={"full"}>
                    <Text fontSize={"15"} color="#000" fontWeight={"normal"}>
                      status:
                    </Text>
                    <Text
                      fontSize={"15"}
                      fontWeight={"medium"}
                      color={Detail.status === "active" ? "blue" : "#ff0000"}
                    >
                      {Detail.status}
                    </Text>
                  </HStack>
                  <HStack justify={"space-between"} w={"full"}>
                    <Text fontSize={"15"} color="#000" fontWeight={"normal"}>
                      id:
                    </Text>
                    <Text fontSize={"15"} color="#000" fontWeight={"medium"}>
                      {Detail._id}
                    </Text>
                  </HStack>
                </>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              bg={"#45A735"}
              px={"8"}
              _hover={{ bg: "#58B948" }}
              _focus={{
                outline: "none",
              }}
              onClick={() => setVisible(false)}
            >
              <Text fontSize={"15"} color="#fff" fontWeight={"normal"}>
                Хаах
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
};

export default Dashboard;
