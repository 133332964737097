import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  HStack,
  Icon,
  Input,
  keyframes,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import axios from "../../axios";
import Cookies from "js-cookie";
import { FiInfo, FiTrash2 } from "react-icons/fi";
import BackButton from "../../components/button/BackButton";

const ShareDeviceZlan = () => {
  const [shareList, setShareList] = useState([]);
  const [username, setUsername] = useState("");
  const [selectData, setSelectData] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const location = useLocation();
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "test";

  const { isOpen, onOpen, onClose } = useDisclosure();

  const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); }
  30% { transform: scale(1) rotate(-25deg); }
  60% { transform: scale(1) rotate(25deg); }
  100% { transform: scale(1) rotate(0); }
`;

  const animation = `${animationKeyframes} 0.8s ease`;

  useEffect(() => {
    if (location.state.e._id) {
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/admin/device_share", {
          params: {
            _id: location.state.e._id,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          // console.log(result.data.data);
          setShareList(result.data.data);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err);
        });
      return () => {
        cancel = true;
      };
    }
  }, [trigger]);

  const shareHandler = () => {
    if (username) {
      axios
        .post(
          "/admin/device_share",
          {
            _id: location.state.e._id,
            user: username,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "success",
              duration: 2000,
              position: "top",
              description: "Амжилттай",
            });
          }
          setTrigger(true);
          setUsername("");
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description:
                err.response.status === 400
                  ? "Бүртгэлгүй хэрэглэгч"
                  : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const selectHandler = (e) => {
    if (selectData.find((a) => a === e)) {
      setSelectData((prevState) => prevState.filter((r) => r !== e));
    } else {
      const select = [...selectData];
      const allSelect = select.concat(e);
      setSelectData(allSelect);
    }
  };

  const deleteHandler = () => {
    if (selectData.length > 0) {
      onClose();
      axios
        .delete("/admin/device_share", {
          data: {
            _id: location.state.e._id,
            user: selectData,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай устлаа!",
            });
          }
          setTrigger(true);
          setSelectData([]);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      w={{ base: "full", md: "full", lg: "6xl" }}
      mb={"10"}
    >
      {" "}
      <BackButton />
      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader fontSize={17} color={"#000"} fontWeight={"medium"}>
            Устгах уу?
          </ModalHeader>
          <ModalCloseButton
            _focus={{
              outline: "none",
            }}
          />
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              onClick={onClose}
              _focus={{
                outline: "none",
              }}
            >
              Үгүй
            </Button>
            <Button
              bg={"#ff0000"}
              color={"#fff"}
              px={"8"}
              onClick={deleteHandler}
              _hover={{
                bg: "#E30000",
              }}
              _focus={{
                outline: "none",
              }}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <VStack
        bg={"#fff"}
        rounded={"md"}
        shadow={"md"}
        p={"4"}
        borderTopWidth={"medium"}
        borderColor={"#45A735"}
      >
        <Flex
          align={"center"}
          w="full"
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 0.3,
            mr: { base: 4, md: 8 },
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 1.7,
            ml: { base: 4, md: 8 },
          }}
        >
          <Text
            color={"#000"}
            fontSize={15}
            fontWeight={"medium"}
            letterSpacing={0.3}
          >
            Төхөөрөмж хуваалцах
          </Text>
        </Flex>
        <Stack direction={{ base: "column", md: "row" }} w={"sm"}>
          <Input
            placeholder="Хэрэглэгчийн нэр"
            w={"full"}
            size={"md"}
            fontSize={15}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            _hover={{
              borderColor: "#45A735",
            }}
            _focus={{
              borderColor: "#45A735",
            }}
            onKeyPress={(e) => {
              if (username) {
                if (e.key === "Enter") {
                  shareHandler();
                }
              }
            }}
          />
          <Button
            bg={"#45A735"}
            rounded={"md"}
            w={"44"}
            cursor={"pointer"}
            fontSize={15}
            fontWeight={"normal"}
            color={"#fff"}
            _hover={{
              bg: "#5AB54B",
            }}
            _focus={{
              outline: "none",
            }}
            onClick={shareHandler}
            disabled={username ? false : true}
          >
            Хуваалцах
          </Button>
        </Stack>
      </VStack>
      <VStack
        bg={"#fff"}
        shadow={"md"}
        rounded={"md"}
        p={"4"}
        w={"full"}
        align={"flex-start"}
        spacing={4}
      >
        <Box
          bg={"#f9f9f9"}
          align={"flex-start"}
          py={"3"}
          px={"6"}
          w={"full"}
          pos={"relative"}
          borderLeftWidth={"medium"}
          borderColor={"#45A735"}
        >
          <Text color={"#000"} fontWeight={"medium"} fontSize={15}>
            Хуваалцсан хэрэглэгчдийн жагсаалт
          </Text>
          <Flex
            p={2}
            w="max-content"
            color="white"
            bgGradient="linear(to-br, #ff0000, #B50F0F)"
            rounded="md"
            marginInline="auto"
            pos="absolute"
            right={6}
            top="-0.4rem"
            shadow="lg"
            cursor={"pointer"}
            onClick={onOpen}
            _hover={{ animation: animation }}
          >
            <Icon as={FiTrash2} color="#fff" />
          </Flex>
        </Box>
        {shareList.length > 0 ? (
          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
            }}
            gap={{ base: 2, md: 4 }}
            w={"full"}
            p={"1"}
            maxH={"4xl"}
            overflowY="auto"
            sx={{
              "&::-webkit-scrollbar": {
                width: "6px",
                borderRadius: "8px",
                backgroundColor: `rgba(249, 249, 249)`,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: `rgba(69, 167, 53, 0.6)`,
                borderRadius: "8px",
              },
            }}
          >
            {shareList.map((e, index) => (
              <VStack
                key={index}
                bg={"#f9f9f9"}
                p={"4"}
                rounded={"md"}
                align={"flex-start"}
                shadow={"xs"}
              >
                <HStack justify={"space-between"} w={"full"}>
                  <Text fontSize={15} color={"#000"} fontWeight={"normal"}>
                    username:
                  </Text>
                  <Text fontSize={15} color={"#000"} fontWeight={"medium"}>
                    {e}
                  </Text>
                </HStack>
                <Checkbox
                  colorScheme="red"
                  size={"lg"}
                  value={selectData}
                  onChange={() => selectHandler(e)}
                  isChecked={selectData.find((a) => a === e) ? true : false}
                  css={`
                    > span:first-of-type {
                      box-shadow: unset;
                    }
                  `}
                >
                  <Text fontSize={14} color="#ff0000" fontWeight={"medium"}>
                    Устгах
                  </Text>
                </Checkbox>
              </VStack>
            ))}
          </Grid>
        ) : (
          <VStack
            justifyContent="center"
            alignItems="center"
            alignSelf={"center"}
            color="gray.600"
            w="full"
          >
            <Icon as={FiInfo} w={7} h={7} />
            <Text fontSize={15}>Мэдээлэл олдсонгүй.</Text>
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};

export default ShareDeviceZlan;
