import React, { useState } from "react";
import {
  Center,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import axios from "../../../axios";
import Cookies from "js-cookie";
import BackButton from "../../../components/button/BackButton";

const Command = () => {
  const [topic, setTopic] = useState("");
  const [command, setCommand] = useState("");
  const [detail, setDetail] = useState();
  const location = useLocation();
  const token = Cookies.get("accessToken");

  const commandHandler = () => {
    if (topic && command) {
      // console.log("click", command, "cmnd/DVES_E50B52_fb/" + topic);
      axios
        .post(
          "/admin/zbridge_command",
          {
            commandTopic: "cmnd/" + location.state.e.clientId + "_fb/" + topic,
            command: command,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log("command result ", result.data.data);
          setDetail(result.data.data);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "xs", md: "xl", lg: "8xl" }}
    >
      <BackButton />
      <Center
        py={"6"}
        px={"8"}
        borderRadius="md"
        bg={"#fff"}
        w={{ base: "xs", md: "sm" }}
        cursor="pointer"
        boxShadow={"md"}
      >
        <VStack w={"full"}>
          <VStack w={"full"} align={"flex-start"} spacing={0}>
            <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
              Topic
            </Text>
            <InputGroup
              _focus={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
            >
              <InputLeftAddon>
                <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                  cmnd/{location.state.e.clientId}_fb/
                </Text>
              </InputLeftAddon>
              <Input
                type={"text"}
                placeholder="Topic"
                onChange={(e) => setTopic(e.target.value)}
                value={topic}
                _hover={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
                _focus={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
                w={{ base: "full", md: "full" }}
                bg={"#fff"}
                fontSize={14}
                boxShadow="sm"
                rounded={"md"}
              />
            </InputGroup>
          </VStack>
          <VStack w={"full"} align={"flex-start"} spacing={0}>
            <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
              Command
            </Text>
            <Input
              type={"text"}
              placeholder="Command"
              value={command}
              onChange={(e) => setCommand(e.target.value)}
              _hover={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
              _focus={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
              w={{ base: "full", md: "full" }}
              bg={"#fff"}
              fontSize={14}
              boxShadow="sm"
              rounded={"md"}
              onKeyPress={(e) => {
                if (topic && command) {
                  if (e.key === "Enter") {
                    commandHandler();
                  }
                }
              }}
            />
          </VStack>
          <Center
            bg={"#45A735"}
            w={"full"}
            rounded={"md"}
            py={"2"}
            onClick={commandHandler}
          >
            <Text color={"#fff"} fontSize={14} fontWeight={"normal"}>
              Илгээх
            </Text>
          </Center>
        </VStack>
      </Center>

      {detail ? (
        <VStack
          py={"6"}
          px={"8"}
          borderRadius="md"
          bg={"#fff"}
          // w={{ base: "xs", md: "sm" }}
          cursor="pointer"
          boxShadow={"md"}
          align={"flex-start"}
        >
          <Text color={"#000"} fontWeight={"medium"} fontSize={15}>
            {Object.keys(detail)}
          </Text>
          <Text color={"#000"} fontWeight={"normal"} fontSize={15}>
            {JSON.stringify(detail)}
          </Text>
        </VStack>
      ) : null}
    </VStack>
  );
};

export default Command;
