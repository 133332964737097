import { Input, Text, Textarea, VStack } from "@chakra-ui/react";
import React from "react";

const CustomTextarea = ({
  placeholder,
  title,
  value,
  onChange,
  onKeyPress,
  readOnly,
}) => {
  return (
    <VStack w={"full"} align={"flex-start"} justify={"flex-start"} spacing={0}>
      {title && (
        <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
          {title + ":"}
        </Text>
      )}
      <Textarea
        readOnly={readOnly}
        type="text"
        w={"full"}
        bg={"#FCFCFC"}
        borderColor={"#EFEFEF"}
        placeholder={placeholder || ""}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        fontSize={14}
        fontWeight={"normal"}
        rounded={"md"}
        _hover={{
          borderColor: "#45A735",
          borderWidth: 1,
        }}
        _focus={{
          borderColor: "#45A735",
          borderWidth: 1,
        }}
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "6px",
            borderRadius: "8px",
            backgroundColor: `rgba(249, 249, 249)`,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: `rgba(69, 167, 53, 0.6)`,
            borderRadius: "8px",
          },
        }}
        onKeyPress={onKeyPress}
      />
    </VStack>
  );
};

export default CustomTextarea;
