import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import axios from "../../../axios";
import { useLocation } from "react-router-dom";
import { RiFileExcel2Line } from "react-icons/ri";
import { FiInfo } from "react-icons/fi";
import { ExportReactCSV } from "../../../components/export/ExportReactCSV";
import Cookies from "js-cookie";
import BackButton from "../../../components/button/BackButton";

const ZigbeeThActivityLog = () => {
  const location = useLocation();
  const [switchLog, setSwitchLog] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [date, setDate] = useState("1 өдөр");
  const [realDates, setRealDate] = useState({
    time: 86400000,
  });

  const [data, setData] = useState([]);
  const fileName = "VIOT";
  const token = Cookies.get("accessToken");

  // ----------------- zigbeeTH initial log --------------------
  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      // .get("/admin/device_logs_excel_sensor", {
      .get("/admin/chartdata_th", {
        params: {
          _id: location.state.e._id,
          time: realDates.time,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        // console.log(result.data.data);
        setSwitchLog(result.data.data);
        setData(
          result.data.data.length === 0
            ? "Хоосон байна"
            : result.data.data.map((e) => {
                return {
                  Date: e.time.slice(0, 10),
                  Time: e.time.slice(11, 19),
                  // Humidity: e.data.Humidity + "%",
                  // Temperature: e.data.Temperature + "°C",
                  Humidity: e.Humidity + "%",
                  Temperature: e.Temperature + "°C",
                };
              })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
  }, [date]);

  const selectDate = (e) => {
    setDate(e.target.value);
    setRealDate(
      e.target.value === "1 өдөр"
        ? { time: 86400000 }
        : e.target.value === "7 хоног"
        ? { time: 604800000 }
        : e.target.value === "1 сар"
        ? { time: 2629800000 }
        : e.target.value === "3 сар"
        ? { time: 7889400000 }
        : e.target.value === "6 сар"
        ? { time: 15778800000 }
        : e.target.value === "1 жил"
        ? { time: 31557600000 }
        : null
    );
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "xs", md: "xl", lg: "8xl" }}
    >
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader fontSize={"16"}>{date + "-н дата"}</ModalHeader>
          <ModalFooter>
            <Button onClick={onClose} px={"10"} mr={3}>
              Болих
            </Button>
            <Center px={"0"} onClick={onClose}>
              <ExportReactCSV csvData={data} fileName={fileName} />
            </Center>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <HStack
        w={{ base: "full", md: "full", lg: "4xl" }}
        justify={"space-between"}
      >
        <BackButton />

        <Stack direction={"row"} spacing={{ base: 2, md: 6 }}>
          <Select
            value={date}
            onChange={(e) => {
              selectDate(e);
            }}
            size="sm"
            maxW="40"
            variant="filled"
            rounded="md"
            bg={"#45A735"}
            color={"#fff"}
            boxShadow={"md"}
            cursor="pointer"
            _hover={{
              bg: "#45A735",
            }}
            _focus={{
              bg: "#45A735",
              color: "#000",
            }}
          >
            <option value="1 өдөр">1 өдөр</option>
            <option value="7 хоног">7 хоног</option>
            <option value="1 сар">1 сар</option>
            <option value="3 сар">3 сар</option>
            <option value="6 сар">6 сар</option>
            <option value="1 жил">1 жил</option>
          </Select>
          {switchLog.length > 0 ? (
            <Center
              py={"1"}
              px={"4"}
              borderRadius="md"
              bg={"#45A735"}
              cursor="pointer"
              boxShadow={"md"}
              onClick={onOpen}
            >
              <Icon as={RiFileExcel2Line} color={"#fff"} w={5} h={5} mr={"1"} />
              <Text fontSize={14} color={"#fff"} fontWeight={"normal"}>
                Export
              </Text>
            </Center>
          ) : null}
        </Stack>
      </HStack>

      {isLoading ? (
        <HStack spacing={"10"}>
          <Skeleton
            w={{ base: "72", md: "4xl" }}
            // w={"72"}
            h={{ base: "xs", md: "12" }}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : switchLog.length > 0 ? (
        <Box
          w={{ base: "80", md: "4xl" }}
          bg={"#fff"}
          boxShadow={"lg"}
          p={"4"}
          borderRadius={"lg"}
        >
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={"space-between"}
            w={"full"}
            bg={"#F8F8F8"}
            borderRadius={"md"}
            py={"2"}
            px={"6"}
            mb={"4"}
          >
            <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
              Төхөөрөмжийн ID:
            </Text>
            <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
              {location.state.e._id}
            </Text>
          </Stack>
          <TableContainer>
            <Table>
              <Thead bgGradient="linear(to-l, #45A735, #1ED600)">
                <Tr>
                  <Th
                    borderTopLeftRadius={"md"}
                    color={"#fff"}
                    fontSize={12}
                    fontWeight={"medium"}
                  >
                    Date
                  </Th>
                  <Th color={"#fff"} fontSize={12} fontWeight={"medium"}>
                    Time
                  </Th>
                  {/* <Th color={"#fff"} fontSize={12} fontWeight={"medium"}>
                    Humidity
                  </Th> */}
                  <Th
                    isNumeric
                    color={"#fff"}
                    fontSize={12}
                    fontWeight={"medium"}
                  >
                    Humidity
                  </Th>
                  <Th
                    isNumeric
                    color={"#fff"}
                    fontSize={12}
                    fontWeight={"medium"}
                    borderTopRightRadius={"md"}
                  >
                    Temperature
                  </Th>
                </Tr>
              </Thead>
              {switchLog.map((e, index) => (
                <Tbody key={index} bg={index % 2 === 0 ? "#fff" : "#FCFCFC"}>
                  <Tr>
                    <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                      {e.time.slice(0, 10)}
                    </Td>
                    <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                      {e.time.slice(11, 19)}
                    </Td>
                    <Td
                      isNumeric
                      color={"#000"}
                      fontSize={14}
                      fontWeight={"normal"}
                    >
                      {/* {e.disconnected === true ? e.data : e.Humidity + "%"} */}
                      {e.Humidity + "%"}
                    </Td>
                    <Td
                      isNumeric
                      color={"#000"}
                      fontSize={14}
                      fontWeight={"normal"}
                    >
                      {/* {e.disconnected === true ? e.data : e.Temperature + "°C"} */}
                      {e.Temperature + "°C"}
                    </Td>
                  </Tr>
                </Tbody>
              ))}
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <VStack
          justifyContent="center"
          alignItems="center"
          color="gray.600"
          w={{ base: "full", md: "full", lg: "4xl" }}
        >
          <Icon mt={"20"} as={FiInfo} w={7} h={7} />
          <Text fontSize={15}>Мэдээлэл олдсонгүй</Text>
        </VStack>
      )}
    </VStack>
  );
};

export default ZigbeeThActivityLog;
