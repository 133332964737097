import React, { useState } from "react";
import {
  HStack,
  Icon,
  Text,
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalFooter,
  SimpleGrid,
  VStack,
  Input,
  Button,
  useToast,
  Spinner,
  Image,
} from "@chakra-ui/react";
import { MdOutlineAddLocationAlt } from "react-icons/md";
import GoogleMapReact from "google-map-react";
import { GrLocation } from "react-icons/gr";
import axios from "../../axios";
import Cookies from "js-cookie";
import pin from "../../Images/pin.png";

const PinComponent = () => <Image src={pin} h={"7"} />;
// const PinComponent = ({ icon }) => <Icon as={icon} w={"5"} h={"5"} />;

const AddLocation = ({ setTrigger }) => {
  const [username, setUsername] = useState("");
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [modalZoom, setModalZoom] = useState(13);
  const [marker, setMarker] = useState([
    {
      lat: 47.91948034963807,
      lng: 106.91772834219846,
    },
  ]);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { isOpen, onOpen, onClose } = useDisclosure();

  const mapRef = React.useRef();
  const onMarkerDragEnd = (lat, lng) => {
    // console.log("lat long", lat, lng);

    // setMarker((prevState) => {
    //   return [
    //     // ...prevState,
    //     {
    //       lat: lat,
    //       lng: lng,
    //     },
    //   ];
    // });
    setMarker([
      {
        lat: lat,
        lng: lng,
      },
    ]);

    setLatitude(lat);
    setLongitude(lng);
  };

  const submitHandler = () => {
    if (username.length === 8 && address) {
      // Монгол, Улаанбаатар БГД 13-р хороо 1077-120тоот
      setIsDisable(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .post(
          "/v2/user_location/",
          {
            username: username,
            address: address,
            latitude: latitude.toString(),
            longitude: longitude.toString(),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
          { cancelToken: source.token }
        )
        .then((result) => {
          if (cancel) return;
          // console.log(result.data);
          setUsername("");
          setAddress("");
          setLongitude("");
          setLatitude("");
          onClose();
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
          setTrigger(true);
          setIsDisable(false);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: "Алдаа гарлаа!",
            });
          }
          setIsDisable(false);
        });
    }
  };

  return (
    <>
      <HStack
        transition={"ease-in-out .3s"}
        w={"full"}
        h={"12"}
        bg={"#45A735"}
        rounded={"md"}
        shadow={"md"}
        align={"center"}
        justify={"center"}
        onClick={() => onOpen()}
        cursor={"pointer"}
        _hover={{
          shadow: "lg",
        }}
      >
        <Icon as={MdOutlineAddLocationAlt} w={"5"} h={"5"} color={"#fff"} />
        <Text color={"#fff"} fontWeight={"normal"} fontSize={15}>
          Хаяг нэмэх
        </Text>
      </HStack>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="scale"
        size="3xl"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Хэрэглэгчийн байршил нэмэх</ModalHeader>
          <ModalBody>
            <VStack w={"full"}>
              <VStack w={"full"}>
                <SimpleGrid w={"full"} columns={[1, 1, 2, 2, 2]} gap={"4"}>
                  <VStack
                    align={"flex-start"}
                    justify={"flex-start"}
                    spacing={0}
                  >
                    <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
                      Утасны дугаар
                    </Text>
                    <Input
                      placeholder="-"
                      w={"full"}
                      bg={"#fff"}
                      fontSize={15}
                      fontWeight={"normal"}
                      color={"#000"}
                      maxLength={8}
                      type={"number"}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      _focus={{
                        outline: "none",
                        borderColor: "#45A735",
                      }}
                    />
                  </VStack>
                  <VStack
                    align={"flex-start"}
                    justify={"flex-start"}
                    spacing={0}
                  >
                    <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
                      Хаяг
                    </Text>
                    <Input
                      placeholder="-"
                      w={"full"}
                      bg={"#fff"}
                      fontSize={15}
                      fontWeight={"normal"}
                      color={"#000"}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      _focus={{
                        outline: "none",
                        borderColor: "#45A735",
                      }}
                    />
                  </VStack>
                  <VStack
                    align={"flex-start"}
                    justify={"flex-start"}
                    spacing={0}
                  >
                    <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
                      Уртраг
                    </Text>
                    <Input
                      placeholder="-"
                      w={"full"}
                      bg={"#fff"}
                      fontSize={15}
                      fontWeight={"normal"}
                      color={"#000"}
                      type={"number"}
                      value={longitude}
                      onChange={(e) => setLongitude(e.target.value)}
                      _focus={{
                        outline: "none",
                        borderColor: "#45A735",
                      }}
                    />
                  </VStack>
                  <VStack
                    align={"flex-start"}
                    justify={"flex-start"}
                    spacing={0}
                  >
                    <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
                      Өргөрөг
                    </Text>
                    <Input
                      placeholder="-"
                      w={"full"}
                      bg={"#fff"}
                      fontSize={15}
                      fontWeight={"normal"}
                      color={"#000"}
                      type={"number"}
                      value={latitude}
                      onChange={(e) => setLatitude(e.target.value)}
                      _focus={{
                        outline: "none",
                        borderColor: "#45A735",
                      }}
                    />
                  </VStack>
                </SimpleGrid>
              </VStack>
              <VStack
                w={"full"}
                h={"40vh"}
                bg={"#fff"}
                rounded={"md"}
                shadow={"md"}
                p={"2"}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyC0PZPzftfnrX4eu_O59XKZPYr7xeDHISg",
                  }}
                  defaultCenter={{
                    lat: 47.91948034963807,
                    lng: 106.91772834219846,
                  }}
                  zoom={modalZoom}
                  onChange={(e) => setModalZoom(e.zoom)}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map }) => {
                    mapRef.current = map;
                  }}
                  onClick={(e) => onMarkerDragEnd(e.lat, e.lng)}
                >
                  {modalZoom >= 13
                    ? marker.map((e, i) => (
                        <PinComponent
                          key={i}
                          lat={e.lat}
                          lng={e.lng}
                          icon={GrLocation}
                        />
                      ))
                    : null}
                </GoogleMapReact>
              </VStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              onClick={() => {
                onClose();
                setUsername("");
                setAddress("");
                setLongitude("");
                setLatitude("");
              }}
              _focus={{
                outline: "none",
              }}
            >
              Болих
            </Button>
            <Button
              colorScheme="green"
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={submitHandler}
              disabled={
                !isDisable && username.length === 8 && address ? false : true
              }
            >
              {isDisable ? <Spinner mr={"2"} size="sm" /> : null}
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddLocation;
