import React, { useEffect, useState } from "react";
import {
  Center,
  InputGroup,
  Input,
  InputLeftElement,
  VStack,
  Text,
  Flex,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Button,
  InputRightElement,
  Icon,
  Stack,
  Select,
} from "@chakra-ui/react";
import { FiUser } from "react-icons/fi";
import { CgLock } from "react-icons/cg";
import axios from "../../axios";
import Cookies from "js-cookie";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const AdminList = () => {
  const [adminList, setAdminList] = useState([]);
  const [adminRole, setAdminRole] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [adminId, setAdminId] = useState();
  const [adminUsername, setAdminUsername] = useState();
  const [trigger, setTrigger] = useState(false);
  const [show, setShow] = useState(false);
  const toast = useToast();
  const id = "test";
  const token = Cookies.get("accessToken");

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get(
        "/admin/admin_account",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
      .then((result) => {
        if (cancel) return;
        // console.log(result.data.data);
        setAdminList(result.data.data);
        setTrigger(false);
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      cancel = true;
    };
  }, [trigger]);

  const createHandler = () => {
    if (username && password && adminRole) {
      axios
        .post(
          "/admin/admin_account",
          {
            username: username,
            password: password,
            role: adminRole,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result);
          setTrigger(true);
          setUsername("");
          setPassword("");
          setAdminRole("");
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
        })
        .catch((err) => {
          // console.log(err.response);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    } else {
      if (!toast.isActive(id)) {
        toast({
          id,
          duration: 2000,
          position: "top",
          status: "error",
          description: "Мэдээлэл дутуу байна!",
        });
      }
    }
  };

  const deleteHandler = () => {
    onClose();
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .delete("/admin/admin_account", {
        data: {
          _id: adminId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log(result);
        setTrigger(true);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "success",
            description: "Амжилттай устлаа!",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });
    return () => {
      cancel = true;
    };
  };

  const deleteModal = (e) => {
    setAdminUsername(e.username);
    setAdminId(e._id);
    onOpen();
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      // bg={"red"}
      mb={"10"}
      w={{ base: "full", md: "2xl", lg: "6xl" }}
    >
      <VStack
        bg={"#fff"}
        boxShadow={"md"}
        rounded={"md"}
        spacing={4}
        p={"6"}
        w={{ base: "full", md: "2xl", lg: "3xl" }}
      >
        <Flex
          align={"center"}
          w="full"
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 0.2,
            mr: { base: 4, md: 8 },
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#45A735",
            flexGrow: 1.8,
            ml: { base: 4, md: 8 },
          }}
        >
          <Text
            color={"#000"}
            fontSize={15}
            fontWeight={"medium"}
            letterSpacing={0.3}
          >
            Админ бүртгэх
          </Text>
        </Flex>
        <Stack direction={["column", "column", "row"]} spacing={2} w="full">
          <InputGroup w="full">
            <InputLeftElement
              pointerEvents="none"
              children={<FiUser color="#45A735" />}
            />
            <Input
              variant="filled"
              type="text"
              placeholder="Нэр"
              color={"#000"}
              fontSize={15}
              fontWeight={"normal"}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              borderWidth={"thin"}
              _hover={{
                borderColor: "#45A735",
              }}
              _focus={{
                borderColor: "#45A735",
              }}
            />
          </InputGroup>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<CgLock color="#45A735" />}
            />
            <Input
              variant="filled"
              placeholder="Нууц үг"
              color={"#000"}
              fontSize={15}
              fontWeight={"normal"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={show ? "text" : "password"}
              borderWidth={"thin"}
              _hover={{
                borderColor: "#45A735",
              }}
              _focus={{
                borderColor: "#45A735",
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  createHandler();
                }
              }}
            />
            <InputRightElement>
              <Icon
                as={show ? AiOutlineEyeInvisible : AiOutlineEye}
                w={5}
                h={5}
                mr={"2"}
                color="#45A735"
                onClick={() => setShow(!show)}
                cursor={"pointer"}
              />
            </InputRightElement>
          </InputGroup>
          <Select
            variant="filled"
            placeholder="Хандах эрх сонгоно уу"
            onChange={(q) => setAdminRole(q.target.value)}
            multiple={false}
            fontSize={14}
            // bg={"#FCFCFC"}
            w={"full"}
            borderColor={"#EFEFEF"}
            borderWidth={1}
            cursor={"pointer"}
            _hover={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            _focus={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
          >
            {roleData.map((q, index) => (
              <option key={index} value={q._id}>
                {q.role}
              </option>
            ))}
          </Select>
          <Center
            bg={"#45A735"}
            rounded={"md"}
            py={"2"}
            px={"6"}
            w={{ base: "full", md: "sm" }}
            cursor={"pointer"}
            onClick={createHandler}
            _hover={{
              bg: "#5AB54B",
            }}
          >
            <Text fontSize={"15"} color="#fff" fontWeight={"normal"}>
              Бүртгэх
            </Text>
          </Center>
        </Stack>
      </VStack>

      <Flex
        align={"center"}
        w="full"
        _before={{
          content: '""',
          borderBottom: "1px solid",
          borderColor: "#45A735",
          flexGrow: 0.3,
          mr: { base: 4, md: 8 },
        }}
        _after={{
          content: '""',
          borderBottom: "1px solid",
          borderColor: "#45A735",
          flexGrow: 1.7,
          ml: { base: 4, md: 8 },
        }}
      >
        <Text
          color={"#000"}
          fontSize={15}
          fontWeight={"medium"}
          letterSpacing={0.3}
        >
          Админы жагсаалт
        </Text>
      </Flex>
      <TableContainer
        w={"full"}
        // w={{ base: "full", md: "xl", lg: "3xl" }}
        bg={"#fff"}
        boxShadow={"md"}
        rounded={"md"}
        spacing={4}
        p={{ base: "4", md: "6" }}
      >
        <Table variant="striped" colorScheme="gray" size="sm">
          <Thead>
            <Tr>
              <Th color={"#000"} fontSize={12} fontWeight={"medium"}>
                ID
              </Th>
              <Th color={"#000"} fontSize={12} fontWeight={"medium"}>
                Хэрэглэгчийн нэр
              </Th>
              <Th color={"#000"} fontSize={12} fontWeight={"medium"}>
                Хандах эрх
              </Th>
              <Th color={"#000"} fontSize={12} fontWeight={"medium"} isNumeric>
                Үйлдэл
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {adminList.map((e, index) => (
              <Tr key={index}>
                <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                  {index + 1}
                </Td>
                <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                  {e.username}
                </Td>
                <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                  {e.role}
                </Td>
                <Td isNumeric w={"28"}>
                  <Center
                    bg={"#ff0000"}
                    rounded={"md"}
                    py={"1.5"}
                    px={"6"}
                    w={"20"}
                    cursor={"pointer"}
                    onClick={() => deleteModal(e)}
                  >
                    <Text fontSize={"14"} color="#fff" fontWeight={"normal"}>
                      Устгах
                    </Text>
                  </Center>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          <ModalBody>{adminUsername}</ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} px={"8"} onClick={onClose}>
              Үгүй
            </Button>
            <Button colorScheme="red" px={"8"} onClick={deleteHandler}>
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

const roleData = [
  { role: "admin" },
  { role: "moderator" },
  { role: "tech" },
  { role: "finance" },
  { role: "techsub" },
  { role: "techmonitor" },
];
export default AdminList;
