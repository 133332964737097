import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  GridItem,
  HStack,
  Icon,
  Skeleton,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "../../../axios";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { FiInfo } from "react-icons/fi";
import BackButton from "../../../components/button/BackButton";

const TtrStateLogDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState([]);
  const location = useLocation();
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "test";

  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/user_taptorun_execute_detail", {
        params: {
          _id: location.state.id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log(result.data.actions);
        setDetail(result.data.actions);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
        setIsLoading(false);
      });
    return () => {
      cancel = true;
    };
  }, []);

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "xs", md: "xl", lg: "8xl" }}
    >
      <BackButton />
      {isLoading ? (
        <HStack spacing={"10"}>
          <Skeleton
            w={{ base: "xs", md: "xs" }}
            h={"12"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            w={{ base: "xs", md: "xs" }}
            display={{ base: "none", md: "flex" }}
            h={"12"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : detail.length > 0 ? (
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap={{ base: 4, md: 6 }}
        >
          {detail.map((e, index) => (
            <GridItem
              key={index}
              w={{ base: "xs", md: "xs", lg: "xs" }}
              bg={"#fff"}
              rounded={"md"}
              shadow={"md"}
              p={"4"}
              borderTopWidth={"medium"}
              borderTopColor={"#45A735"}
            >
              <VStack w={"full"}>
                <HStack w={"full"} justify={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    actionType:
                  </Text>
                  <Text
                    key={index}
                    color={"#000"}
                    fontSize={14}
                    fontWeight={"medium"}
                  >
                    {e.actionType}
                  </Text>
                </HStack>
                <Divider />
                <HStack
                  w={"full"}
                  justify={"space-between"}
                  align={"flex-start"}
                >
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    for:
                  </Text>
                  <Text
                    key={index}
                    color={"#000"}
                    fontSize={14}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.for ? e.for : e.name}
                  </Text>
                </HStack>
                <HStack
                  w={"full"}
                  justify={"space-between"}
                  align={"flex-start"}
                >
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    room:
                  </Text>
                  <Text
                    key={index}
                    color={"#000"}
                    fontSize={14}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.room ? e.room : "-"}
                  </Text>
                </HStack>
                <HStack w={"full"}>
                  <Divider />
                  <Text color={"#000"} fontSize={13} fontWeight={"medium"}>
                    Утга
                  </Text>
                  <Divider />
                </HStack>
                <HStack w={"full"} justify={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    type:
                  </Text>
                  <Text
                    key={index}
                    color={"#000"}
                    fontSize={14}
                    fontWeight={"medium"}
                  >
                    {e.type}
                  </Text>
                </HStack>
                <HStack w={"full"} justify={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    command:
                  </Text>
                  <Text
                    key={index}
                    color={"#000"}
                    fontSize={14}
                    fontWeight={"medium"}
                  >
                    {e.command}
                  </Text>
                </HStack>
                <HStack w={"full"} justify={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    success:
                  </Text>
                  <Text
                    key={index}
                    color={e.success ? "blue" : "#ff0000"}
                    fontSize={14}
                    fontWeight={"medium"}
                  >
                    {JSON.stringify(e.success)}
                  </Text>
                </HStack>
              </VStack>
            </GridItem>
          ))}
        </Grid>
      ) : (
        <VStack
          justifyContent="center"
          alignItems="center"
          color="gray.600"
          w="full"
        >
          <Icon mt={"20"} as={FiInfo} w={7} h={7} />
          <Text fontSize={15}>Мэдээлэл олдсонгүй</Text>
        </VStack>
      )}
    </VStack>
  );
};

export default TtrStateLogDetail;
