import { Box } from "@chakra-ui/react";
import React from "react";

function HorizontalLine({ my }) {
  return (
    <Box
      width={"full"}
      borderColor={"#80808040"}
      borderWidth={1}
      borderRightWidth={0}
      borderLeftWidth={0}
      borderBottomWidth={0}
      borderStyle={"dashed"}
      h="1.5"
      my={my ? my : 2}
    />
  );
}

export default HorizontalLine;
