// import { Box, HStack, Text, VStack } from "@chakra-ui/react";
// import React, { useEffect, useState } from "react";
// import CustomInput from "../../../components/input/CustomInput";

// const InputWithResult = ({ title, value, onChange, result }) => (
//   <HStack w="xl" align="flex-end" justify="space-between" spacing={6}>
//     <CustomInput title={title} value={value} onChange={onChange} />
//     <Box
//       display="flex"
//       bg="#FCFCFC"
//       borderColor="#EFEFEF"
//       borderWidth={1}
//       minW="64"
//       h={10}
//       alignItems="center"
//       justifyContent="end"
//       px="4"
//       borderRadius={4}
//     >
//       <Text fontSize={13} color="#000" fontWeight="normal">
//         {result || result === 0 ? result : "-"}
//       </Text>
//     </Box>
//   </HStack>
// );

// const Calculator = () => {
//   const [inputs, setInputs] = useState({
//     basicSalary: "",
//     XXYH: "",
//     AAH: "",
//     XXYHafter: "",
//     XXOAT: "",
//     salaryHand: "",
//   });

//   const [results, setResults] = useState({
//     basicSalary: 0,
//     XXYH: 0,
//     AAH: 0,
//     XXYHafter: 0,
//     XXOAT: 0,
//     salaryHand: 0,
//   });

//   const handleChange = (field) => (value) => {
//     // Reset all other fields on change
//     setInputs({
//       basicSalary: "",
//       XXYH: "",
//       AAH: "",
//       XXYHafter: "",
//       XXOAT: "",
//       salaryHand: "",
//       [field]: value,
//     });
//   };

//   useEffect(() => {
//     const basicSalary = parseFloat(inputs.basicSalary) || 0;
//     const XXYH = parseFloat(inputs.XXYH) || 0;
//     const AAH = parseFloat(inputs.AAH) || 0;
//     const XXYHafter = parseFloat(inputs.XXYHafter) || 0;
//     const XXOAT = parseFloat(inputs.XXOAT) || 0;
//     const salaryHand = parseFloat(inputs.salaryHand) || 0;

//     if (basicSalary) {
//       const XXYH = (basicSalary * 11.5) / 100;
//       const AAH = (basicSalary * 12.5) / 100;
//       const XXYHafter = basicSalary - XXYH;
//       const XXOAT = XXYHafter * 0.1;
//       const salaryHand = XXYHafter - XXOAT;

//       setResults({
//         basicSalary,
//         XXYH,
//         AAH,
//         XXYHafter,
//         XXOAT,
//         salaryHand,
//       });
//     } else if (XXYH) {
//       const basicSalary = (XXYH * 100) / 11.5;
//       const AAH = (basicSalary * 12.5) / 100;
//       const XXYHafter = basicSalary - XXYH;
//       const XXOAT = XXYHafter * 0.1;
//       const salaryHand = XXYHafter - XXOAT;

//       setResults({
//         basicSalary,
//         XXYH,
//         AAH,
//         XXYHafter,
//         XXOAT,
//         salaryHand,
//       });
//     } else if (AAH) {
//       const basicSalary = (AAH / 12.5) * 100;
//       const XXYH = (basicSalary * 11.5) / 100;
//       const XXYHafter = basicSalary - XXYH;
//       const XXOAT = XXYHafter * 0.1;
//       const salaryHand = XXYHafter - XXOAT;
//       setResults({
//         basicSalary,
//         XXYH,
//         AAH,
//         XXYHafter,
//         XXOAT,
//         salaryHand,
//       });
//     } else if (XXYHafter) {
//       const basicSalary = (XXYHafter / 88.5) * 100;
//       const XXYH = (basicSalary * 11.5) / 100;
//       const AAH = (basicSalary * 12.5) / 100;
//       const XXOAT = XXYHafter * 0.1;
//       const salaryHand = XXYHafter - XXOAT;
//       setResults({
//         basicSalary,
//         XXYH,
//         AAH,
//         XXYHafter,
//         XXOAT,
//         salaryHand,
//       });
//     } else if (XXOAT) {
//       const XXYHafter = (XXOAT / 10) * 100;
//       const salaryHand = XXYHafter - XXOAT;
//       const basicSalary = (XXYHafter / 88.5) * 100;
//       const XXYH = (basicSalary * 11.5) / 100;
//       const AAH = (basicSalary * 12.5) / 100;
//       setResults({
//         basicSalary,
//         XXYH,
//         AAH,
//         XXYHafter,
//         XXOAT,
//         salaryHand,
//       });
//     } else if (salaryHand) {
//       const XXYHafter = (salaryHand / 90) * 100;
//       const XXOAT = XXYHafter * 0.1;
//       const basicSalary = (XXYHafter / 88.5) * 100;
//       const XXYH = (basicSalary * 11.5) / 100;
//       const AAH = (basicSalary * 12.5) / 100;
//       setResults({
//         basicSalary,
//         XXYH,
//         AAH,
//         XXYHafter,
//         XXOAT,
//         salaryHand,
//       });
//     }
//   }, [inputs]);

//   return (
//     <VStack
//       align="flex-start"
//       spacing={4}
//       mb="10"
//       w={["xs", "full", "full", "full", "8xl"]}
//     >
//       <InputWithResult
//         title="Үндсэн цалин"
//         value={inputs.basicSalary}
//         onChange={handleChange("basicSalary")}
//         result={results.basicSalary}
//       />
//       <InputWithResult
//         title="Нийгмийн даатгалын шимтгэл ХХҮН"
//         value={inputs.XXYH}
//         onChange={handleChange("XXYH")}
//         result={results.XXYH}
//       />
//       <InputWithResult
//         title="Нийгмийн даатгалын шимтгэл ААН"
//         value={inputs.AAH}
//         onChange={handleChange("AAH")}
//         result={results.AAH}
//       />
//       <InputWithResult
//         title="Нийгмийн даатгал шимтгэгдсэний дараах"
//         value={inputs.XXYHafter}
//         onChange={handleChange("XXYHafter")}
//         result={results.XXYHafter}
//       />
//       <InputWithResult
//         title="ХХОАТ"
//         value={inputs.XXOAT}
//         onChange={handleChange("XXOAT")}
//         result={results.XXOAT}
//       />
//       <InputWithResult
//         title="Гарт олгох цалин"
//         value={inputs.salaryHand}
//         onChange={handleChange("salaryHand")}
//         result={results.salaryHand}
//       />
//     </VStack>
//   );
// };

// export default Calculator;

import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomInput from "../../../components/input/CustomInput";

const InputWithResult = ({ title, value, onChange, result }) => (
  <HStack w="xl" align="flex-end" justify="space-between" spacing={6}>
    <CustomInput title={title} value={value} onChange={onChange} />
    <Box
      display="flex"
      bg="#FCFCFC"
      borderColor="#EFEFEF"
      borderWidth={1}
      minW="64"
      h={10}
      alignItems="center"
      justifyContent="end"
      px="4"
      borderRadius={4}
    >
      <Text fontSize={13} color="#000" fontWeight="normal">
        {result || result === 0 ? result.toLocaleString() + "₮" : "-"}
      </Text>
    </Box>
  </HStack>
);

const calculateResults = ({
  basicSalary,
  XXYH,
  AAH,
  XXYHafter,
  XXOAT,
  salaryHand,
}) => {
  if (basicSalary) {
    XXYH = (basicSalary * 11.5) / 100;
    AAH = (basicSalary * 12.5) / 100;
    XXYHafter = basicSalary - XXYH;
    XXOAT = XXYHafter * 0.1;
    salaryHand = XXYHafter - XXOAT;
  } else if (XXYH) {
    basicSalary = (XXYH * 100) / 11.5;
    AAH = (basicSalary * 12.5) / 100;
    XXYHafter = basicSalary - XXYH;
    XXOAT = XXYHafter * 0.1;
    salaryHand = XXYHafter - XXOAT;
  } else if (AAH) {
    basicSalary = (AAH * 100) / 12.5;
    XXYH = (basicSalary * 11.5) / 100;
    XXYHafter = basicSalary - XXYH;
    XXOAT = XXYHafter * 0.1;
    salaryHand = XXYHafter - XXOAT;
  } else if (XXYHafter) {
    basicSalary = (XXYHafter * 100) / 88.5;
    XXYH = (basicSalary * 11.5) / 100;
    AAH = (basicSalary * 12.5) / 100;
    XXOAT = XXYHafter * 0.1;
    salaryHand = XXYHafter - XXOAT;
  } else if (XXOAT) {
    XXYHafter = XXOAT * 10;
    salaryHand = XXYHafter - XXOAT;
    basicSalary = (XXYHafter * 100) / 88.5;
    XXYH = (basicSalary * 11.5) / 100;
    AAH = (basicSalary * 12.5) / 100;
  } else if (salaryHand) {
    XXYHafter = salaryHand / 0.9;
    XXOAT = XXYHafter * 0.1;
    basicSalary = (XXYHafter * 100) / 88.5;
    XXYH = (basicSalary * 11.5) / 100;
    AAH = (basicSalary * 12.5) / 100;
  }

  return { basicSalary, XXYH, AAH, XXYHafter, XXOAT, salaryHand };
};

const Calculator = () => {
  const [inputs, setInputs] = useState({
    basicSalary: "",
    XXYH: "",
    AAH: "",
    XXYHafter: "",
    XXOAT: "",
    salaryHand: "",
  });

  const [results, setResults] = useState({
    basicSalary: 0,
    XXYH: 0,
    AAH: 0,
    XXYHafter: 0,
    XXOAT: 0,
    salaryHand: 0,
  });

  const handleChange = (field) => (value) => {
    setInputs({
      basicSalary: "",
      XXYH: "",
      AAH: "",
      XXYHafter: "",
      XXOAT: "",
      salaryHand: "",
      [field]: value,
    });
  };

  useEffect(() => {
    const parsedInputs = Object.fromEntries(
      Object.entries(inputs).map(([key, val]) => [key, parseFloat(val) || 0])
    );

    const calculatedResults = calculateResults(parsedInputs);
    setResults(calculatedResults);
  }, [inputs]);

  return (
    <VStack
      align="flex-start"
      spacing={4}
      mb="10"
      w={["xs", "full", "full", "full", "8xl"]}
    >
      <InputWithResult
        title="Үндсэн цалин"
        value={inputs.basicSalary}
        onChange={handleChange("basicSalary")}
        result={results.basicSalary}
      />
      <InputWithResult
        title="Нийгмийн даатгалын шимтгэл ХХҮН"
        value={inputs.XXYH}
        onChange={handleChange("XXYH")}
        result={results.XXYH}
      />
      <InputWithResult
        title="Нийгмийн даатгалын шимтгэл ААН"
        value={inputs.AAH}
        onChange={handleChange("AAH")}
        result={results.AAH}
      />
      <InputWithResult
        title="Нийгмийн даатгал шимтгэгдсэний дараах"
        value={inputs.XXYHafter}
        onChange={handleChange("XXYHafter")}
        result={results.XXYHafter}
      />
      <InputWithResult
        title="ХХОАТ"
        value={inputs.XXOAT}
        onChange={handleChange("XXOAT")}
        result={results.XXOAT}
      />
      <InputWithResult
        title="Гарт олгох цалин"
        value={inputs.salaryHand}
        onChange={handleChange("salaryHand")}
        result={results.salaryHand}
      />
    </VStack>
  );
};

export default Calculator;
