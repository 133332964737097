import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  HStack,
  Input,
  Select,
  Spinner,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "../../../axios";
import Cookies from "js-cookie";
import { useHistory, useLocation } from "react-router-dom";
import BackButton from "../../../components/button/BackButton";

const AddAQ = () => {
  const location = useLocation();
  const [phoneNo, setPhoneNo] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [houseList, setHouseList] = useState([]);
  const [selectHouse, setSelectHouse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const id = "test-toast";
  const history = useHistory();
  const token = Cookies.get("accessToken");

  // -------------- get House -------------------
  useEffect(() => {
    let source = axios.CancelToken.source();
    let cancel = false;
    if (phoneNo.length === 8) {
      axios
        .get("admin/device_houses", {
          params: { username: phoneNo },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          // console.log("house List", result.data.data);
          setHouseList(result.data.data);
        })
        .catch((err) => {
          // console.log("error", err);
        });
      return () => {
        cancel = true;
      };
    } else {
      setHouseList([]);
    }
  }, [phoneNo]);

  const handleSubmit = () => {
    if (
      location?.state?.data.clientId &&
      deviceName &&
      selectHouse &&
      phoneNo
    ) {
      setIsLoading(true);
      const data = {
        clientId: location?.state?.data.clientId,
        description: deviceName,
        houseId: selectHouse,
        username: phoneNo,
      };
      // console.log("submit", data);
      axios
        .post("/admin/air_quality_sensor", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log("Done ---", result);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "success",
              duration: 2000,
              position: "top",
              description: "Амжилттай",
            });
          }
          setIsLoading(false);
          history.push("/device/type/aq");
        })
        .catch((err) => {
          // console.log("error", err);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "error",
              duration: 2000,
              position: "top",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
          setIsLoading(false);
        });
    }
  };

  return (
    <VStack
      align={"flex-start"}
      w={{ base: "xs", md: "2xl", lg: "8xl" }}
      mb={"10"}
      spacing={4}
    >
      <BackButton />
      {location?.state?.data ? (
        <VStack
          bg={"#fff"}
          boxShadow={"md"}
          borderRadius={"md"}
          p={"4"}
          w={"md"}
        >
          <HStack
            w={"full"}
            bg={"#F3F3F3"}
            borderWidth={1}
            borderColor={"#EFEFEF"}
            borderRadius={"md"}
            py={"2"}
            px={"4"}
            justify={"space-between"}
          >
            <Text
              fontSize={14}
              color={"#000"}
              fontWeight={"normal"}
              alignSelf={"flex-start"}
            >
              clientId:
            </Text>
            <Text
              fontSize={14}
              color={"#000"}
              fontWeight={"medium"}
              align={"end"}
            >
              {location?.state?.data.clientId}
            </Text>
          </HStack>
          <Divider />
          <Input
            variant="filled"
            placeholder="Төхөөрөмжийн нэр"
            value={deviceName}
            onChange={(e) => setDeviceName(e.target.value)}
            bg={"#FCFCFC"}
            w={"full"}
            fontSize={14}
            borderColor={"#EFEFEF"}
            borderWidth={1}
            // type={"text"}
            _hover={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            _focus={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
          />
          <Stack
            w={"full"}
            direction={{ base: "column", md: "column", lg: "row" }}
          >
            <Input
              variant="filled"
              placeholder="Утасны дугаар"
              value={phoneNo}
              onChange={(e) => setPhoneNo(e.target.value)}
              bg={"#FCFCFC"}
              w={"full"}
              fontSize={14}
              borderColor={"#EFEFEF"}
              borderWidth={1}
              maxLength={8}
              type={"tel"}
              _hover={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
              _focus={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
            />

            <Select
              variant={"filled"}
              isDisabled={houseList.length > 0 ? false : true}
              placeholder="-Байшин сонгоно уу-"
              onChange={(e) => setSelectHouse(e.target.value)}
              multiple={false}
              fontSize={14}
              bg={"#FCFCFC"}
              w={"full"}
              borderColor={"#EFEFEF"}
              borderWidth={1}
              _hover={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
              _focus={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
            >
              {houseList.map((q, index) => (
                <option key={index} value={q._id}>
                  {q.name}
                </option>
              ))}
            </Select>
          </Stack>
          <Button
            w={"full"}
            bg={"#45A735"}
            _hover={{
              bg: "#5AB54B",
            }}
            _focus={{
              outline: "none",
            }}
            onClick={() => handleSubmit()}
            disabled={
              phoneNo.length === 8 && deviceName && selectHouse ? false : true
            }
          >
            {isLoading ? <Spinner mr={2} size="sm" color="#fff" /> : null}
            <Text color={"#fff"} fontWeight="normal">
              Хадгалах
            </Text>
          </Button>
        </VStack>
      ) : null}
    </VStack>
  );
};

export default AddAQ;
