import React, { useContext } from "react";
import { Flex, VStack, Stack } from "@chakra-ui/react";
import UserContext from "../UserContext";
import SideBar from "../components/sidebar/SideBar";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";

const Main = ({ children }) => {
  const context = useContext(UserContext);
  const location = useLocation();

  return (
    <Flex w={"100vw"} bg={"#F7FAFC"} h="100vh">
      <SideBar />
      <VStack w="full" spacing={"0"}>
        <Stack mx={"10"} w={"full"}>
          <Header />
        </Stack>
        <VStack
          w="full"
          h={"full"}
          overflowY="auto"
          sx={{
            "&::-webkit-scrollbar": {
              width: "10px",
              borderRadius: "8px",
              backgroundColor: `rgba(0, 0, 0, 0.05)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `rgba(0, 0, 0, 0.05)`,
              borderRadius: "8px",
            },
          }}
        >
          <Stack
            w={["xs", "2xl", "2xl", "4xl", "92%"]}
            mt={["1", "6", "6", "6", "8"]}
            mx={["2", "8", "8", "8", "16"]}
            alignSelf={["center", "center", "center", "center", "center"]}
          >
            {children}
          </Stack>
        </VStack>
      </VStack>
    </Flex>
  );
};

export default Main;

// <Grid
//   w={"full"}
//   h="100vh"
//   templateRows="repeat(11, 1fr)"
//   templateColumns="repeat(7, 1fr)"
//   gap={0}
//   bg={"#F7FAFC"}
// >
//   <GridItem rowSpan={11} colSpan={1}>
//     <SideBar />
//   </GridItem>
//   <GridItem colSpan={6} rowSpan={1}>
//     <Header />
//   </GridItem>
//   <GridItem
//     rowSpan={10}
//     colSpan={6}
//     mt={{
//       base: "1",
//       md: "6",
//       lg: "8",
//     }}
//     mx={{
//       base: "2",
//       md: "8",
//       lg: "16",
//     }}
//   >
//     {children}
//   </GridItem>
// </Grid>
