import { FiClock } from "react-icons/fi";
import {
  RiSecurePaymentLine,
  RiUserSearchLine,
  RiAdminLine,
  RiHome3Line,
  RiUserLine,
  RiUserFollowLine,
  RiUserLocationLine,
  RiUserVoiceLine,
  RiGuideLine,
  RiCalculatorLine,
} from "react-icons/ri";
import { BiChip, BiSearchAlt, BiImage } from "react-icons/bi";
import {
  MdOutlineEditNotifications,
  MdOutlineSettingsSystemDaydream,
} from "react-icons/md";
import { AiOutlineApi } from "react-icons/ai";

export const navData = [
  {
    title: "Хяналтын самбар",
    toLink: "/dashboard",
    icon: RiHome3Line,
  },
  {
    title: "Төхөөрөмж",
    toLink: "/device",
    icon: BiChip,
    subTitle: [
      {
        title: "Төхөөрөмж нэмэх",
        toLink: "/device/type",
        icon: BiChip,
      },
      {
        title: "Interval",
        toLink: "/device/interval",
        icon: FiClock,
      },
      {
        title: "Төхөөрөмж хайх",
        toLink: "/device/search",
        icon: BiSearchAlt,
      },
    ],
  },
  {
    title: "Хэрэглэгч",
    toLink: "/User",
    icon: RiUserLine,
    subTitle: [
      {
        title: "Хэрэглэгч хайх",
        toLink: "/User/find",
        icon: RiUserSearchLine,
      },
      {
        title: "Мэдэгдэл",
        toLink: "/User/notification",
        icon: MdOutlineEditNotifications,
      },
      {
        title: "Төлбөр мэдээлэл",
        toLink: "/User/analyze",
        icon: RiUserFollowLine,
      },
      {
        title: "Гомдол",
        toLink: "/User/complain",
        icon: RiUserVoiceLine,
      },
      {
        title: "Байршил",
        toLink: "/User/location",
        icon: RiUserLocationLine,
      },
    ],
  },
  {
    title: "Профайл",
    toLink: "/profile",
    icon: RiUserLine,
  },
  {
    title: "Баннер",
    toLink: "/banner",
    icon: BiImage,
  },
  {
    title: "Админы жагсаалт",
    toLink: "/admin-list",
    icon: RiAdminLine,
  },
  // {
  //   title: "RC/DC мэдэгдэл",
  //   toLink: "/notification",
  //   icon: MdOutlineNotificationsNone,
  // },
  {
    title: "Нэхэмжлэх",
    toLink: "/invoice",
    icon: RiSecurePaymentLine,
  },
  {
    title: "API service",
    toLink: "/api",
    icon: AiOutlineApi,
  },
  {
    title: "Систем",
    toLink: "/system",
    icon: MdOutlineSettingsSystemDaydream,
    subTitle: [
      {
        title: "Төхөөрөмж",
        toLink: "/system/device-list",
        icon: BiChip,
      },
      {
        title: "Зааварчилгаа",
        toLink: "/system/instruction",
        icon: RiGuideLine,
      },
      {
        title: "Тооцоолуур",
        toLink: "/system/calculator",
        icon: RiCalculatorLine,
      },
      {
        title: "Нэхэмжлэх",
        toLink: "/system/invoice",
        icon: RiCalculatorLine,
      },
    ],
  },
  {
    title: "login",
    toLink: "/login",
    icon: RiHome3Line,
  },
];
