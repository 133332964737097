import { GridItem, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import React, { memo } from "react";
import CountUp from "react-countup";

export const Count = memo(({ value }) => {
  return (
    <CountUp
      start={0}
      end={value}
      duration={1.5}
      formattingFn={(n) => n.toLocaleString()}
    />
  );
});

function DashCard({ title, value, icon, bg, custom, color }) {
  return (
    <GridItem
      w={"full"}
      boxShadow={"md"}
      borderRadius={"xl"}
      px={6}
      py={8}
      bg={bg}
      role="group"
      {...custom}
    >
      <HStack justify={"space-between"}>
        <VStack align={"start"} spacing={2}>
          <Text
            fontSize={"sm"}
            color={color || "#fff"}
            fontWeight={"normal"}
            css={{
              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.05)",
            }}
          >
            {title}
          </Text>

          <Text
            fontSize={"xx-large"}
            color={color || "#fff"}
            fontWeight={"semibold"}
            css={{
              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.05)",
            }}
          >
            <Count value={value} />
          </Text>
        </VStack>
        {icon ? (
          <Icon
            _groupHover={{
              animation: "gelatine 0.5s infinite",
            }}
            as={icon}
            fontSize={45}
            color={"#fff"}
          />
        ) : null}
      </HStack>
    </GridItem>
  );
}

export default DashCard;
