import React, { useState, useEffect } from "react";
import {
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  Input,
  Text,
  VStack,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Heading,
  Divider,
  InputGroup,
  InputRightElement,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  keyframes,
  Checkbox,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Tooltip,
  Box,
  Stack,
  Spinner,
  SkeletonText,
  IconButton,
  Radio,
  RadioGroup,
  Textarea,
  ModalCloseButton,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "../../axios";
import { FiEdit, FiInfo } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { GrPowerReset } from "react-icons/gr";

const CreateInvoice = () => {
  const [invoiceList, setInvoiceList] = useState([]);
  const [username, setUsername] = useState("");
  const [currentMounth, setCurrentMounth] = useState("");
  const [sinceMounth, setSinceMounth] = useState("");
  const [userData, setUserData] = useState();
  const [userDataLoading, setUserDataLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [zoom, setZoom] = useState(false);
  const [trigger, setTrigger] = useState(true);
  const [initialLoading, setInitialLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [count, setCount] = useState(1);
  const [invoiceId, setInvoiceId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [paid, setPaid] = useState(false);
  const [notPaid, setNotPaid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const id = "test";
  const token = Cookies.get("accessToken");
  const [limit, setLimit] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [value, setValue] = React.useState("1");
  const [showEbarimtModal, setShowEbarimtModal] = useState(false);
  const [ebarimtData, setEbarimtData] = useState(null);
  const [ebarimtIndex, setEbarimtIndex] = useState(null);

  const [filter, setFilter] = useState(false);
  const [invoiceFilterList, setInvoiceFilterList] = useState([]);
  const [usernameFilter, setUsernameFilter] = useState("");
  const [amountFilter, setAmountFilter] = useState([10, 1000000]);
  const [startDay, setStartDay] = useState("2022-10-27");
  const [endDay, setEndDay] = useState(
    moment().tz("Asia/Ulaanbaatar").format("YYYY-MM-DD")
  );
  const [paymentDescription, setPaymentDescription] = useState("");
  const [explainId, setExplainId] = useState("");
  const [explainSpinner, setExplainSpinner] = useState(false);
  const history = useHistory();
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  useEffect(() => {
    if (trigger) {
      setInitialLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get(
          "/admin/qpay_invoice_data",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
          { cancelToken: source.token }
        )
        .then((result) => {
          if (cancel) return;
          // console.log(result.data.data);
          const res = result.data.data.sort((a, b) =>
            a.paid > b.paid ? 1 : b.paid > a.paid ? -1 : 0
          );
          setInvoiceList(res);
          setPageCount(Math.ceil(result.data.count / 20));
          setTrigger(false);
          setInitialLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setInitialLoading(false);
        });
      return () => {
        cancel = true;
      };
    }
  }, [trigger]);

  const handlePageClick = (e) => {
    // console.log("select Page--", e.selected);
    setCurrentPage(e.selected);
    axios
      .get("/admin/qpay_invoice_data_more", {
        params: {
          page: e.selected + 1,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        // console.log(result.data);
        setInvoiceList(result.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const cancelHandler = () => {
    setCount((c) => c - 1);
    setOpenModal(false);
  };

  useEffect(() => {
    if (username.length > 7) {
      setUserDataLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get(
          "/admin/qpay_get_data",
          {
            params: {
              username: username,
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
          { cancelToken: source.token }
        )
        .then((result) => {
          if (cancel) return;
          // console.log(result.data.data);
          setUserData(result.data.data);
          setUserDataLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setUserDataLoading(false);
        });
      return () => {
        cancel = true;
      };
    } else {
      setUserData();
    }
  }, [username]);

  const currentYear = moment().format("YYYY");
  const createHandler = () => {
    if (value === "1") {
      if (username && amount) {
        setIsLoading(true);
        axios
          .post(
            "/admin/qpay_invoice",
            {
              username: username,
              amount: parseInt(amount),
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            // console.log(result.data);
            setCount((c) => c - 1);
            setOpenModal(false);
            setUsername("");
            setAmount("");
            setTrigger(true);
            if (!toast.isActive(id)) {
              toast({
                id,
                status: "success",
                duration: 2000,
                position: "top",
                description: "Амжилттай",
              });
            }
            setIsLoading(false);
          })
          .catch((err) => {
            // console.log(err);
            setCount((c) => c - 1);
            setOpenModal(false);
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "error",
                description: err.response.data.message
                  ? err.response.data.message
                  : "Алдаа гарлаа!",
              });
            }
            setIsLoading(false);
          });
      }
    } else if (value === "2") {
      if (username && amount && currentMounth && sinceMounth) {
        // console.log(
        //   "first",
        //   username,
        //   amount,
        //   currentYear + "-" + currentMounth + "-01",
        //   sinceMounth
        // );
        setIsLoading(true);
        axios
          .post(
            "/admin/qpay_invoice",
            {
              username: username,
              amount: parseInt(amount),
              startsAt: currentYear + "-" + currentMounth + "-01",
              months: sinceMounth,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            // console.log(result.data);
            setCount((c) => c - 1);
            setOpenModal(false);
            setUsername("");
            setAmount("");
            setCurrentMounth("");
            setSinceMounth("");
            setTrigger(true);
            if (!toast.isActive(id)) {
              toast({
                id,
                status: "success",
                duration: 2000,
                position: "top",
                description: "Амжилттай",
              });
            }
            setIsLoading(false);
          })
          .catch((err) => {
            // console.log(err);
            setCount((c) => c - 1);
            setOpenModal(false);
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "error",
                description: err.response.data.message
                  ? err.response.data.message
                  : "Алдаа гарлаа!",
              });
            }
            setIsLoading(false);
          });
      } else {
        setOpenModal(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: "Мэдээлэл дутуу байна!",
          });
        }
      }
    } else {
      setOpenModal(false);
      if (!toast.isActive(id)) {
        toast({
          id,
          duration: 2000,
          position: "top",
          status: "error",
          description: "Алдаа гарлаа!",
        });
      }
    }
  };

  const deleteModal = (e) => {
    // console.log(e)
    setInvoiceId(e);
    setShowDeleteModal(true);
  };

  const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); }
  30% { transform: scale(1) rotate(-25deg); }
  60% { transform: scale(1) rotate(25deg); }
  100% { transform: scale(1) rotate(0); }
`;

  const animation = `${animationKeyframes} 0.8s ease`;

  const deleteHandler = () => {
    setShowDeleteModal(false);
    axios
      .delete("/admin/qpay_invoice", {
        data: {
          _id: invoiceId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        // console.log(result);
        setTrigger(true);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "success",
            description: "Амжилттай устлаа!",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  const onChange = (val) => {
    setLimit(val);
  };

  const selectPaidHandler = (value) => {
    setPaid(value);
    if (value) {
      setNotPaid(false);
    }
  };

  const selectNotPaidHandler = (value) => {
    setNotPaid(value);
    if (value) {
      setPaid(false);
    }
  };

  const filterHandler = () => {
    setFilter(true);
    const data = {
      username: usernameFilter ? usernameFilter : "",
      maxAmount: amountFilter ? amountFilter[1] : 1000000,
      minAmount: amountFilter ? amountFilter[0] : 10,
      startsAt: startDay + "T00:00:00",
      endsAt: endDay + "T23:59:00",
      paid: paid ? paid : notPaid ? false : null,
    };
    // console.log("filter data", data);
    axios
      .post("/admin/qpay_invoice_filter", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        // console.log(result.data.data);
        setInvoiceFilterList(result.data.data.reverse());
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
        setIsLoading(false);
      });
  };

  const resetHandler = () => {
    setFilter(false);
    setInvoiceFilterList([]);
    setUsernameFilter("");
    setAmountFilter([10, 1000000]);
    setStartDay("2022-10-27");
    setEndDay(moment().tz("Asia/Ulaanbaatar").format("YYYY-MM-DD"));
    setPaid(false);
    setNotPaid(false);
  };

  const EbarimtGenerateHandler = (value, index) => {
    // if (value && value.paid && !value.ebarimtGenerated) {
    if (ebarimtData && ebarimtData.paid && !ebarimtData.ebarimtGenerated) {
      axios
        .post(
          "/admin/qpay_invoice_change_ebarimt_status",
          // { invoiceId: value._id },
          { invoiceId: ebarimtData._id },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            const clone = [...invoiceList];
            clone[ebarimtIndex].ebarimtGenerated = true;
            setInvoiceList(clone);
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            setShowEbarimtModal(false);
            setEbarimtData(null);
            setEbarimtIndex(null);
          }
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    } else {
      if (!toast.isActive(id)) {
        toast({
          id,
          duration: 2000,
          position: "top",
          status: "info",
          description: "И-баримт шивэгдсэн байна!",
        });
      }
    }
  };

  const explainHandler = () => {
    if (paymentDescription.length > 9) {
      setExplainSpinner(true);
      axios
        .put(
          "/admin/qpay_invoice_change_payment_status",
          {
            invoiceId: explainId,
            paymentDescription: paymentDescription,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result);
          onClose();
          setTrigger(true);
          setExplainSpinner(false);
          setPaymentDescription("");
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
        })
        .catch((err) => {
          // console.log(err.response);
          setExplainSpinner(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <VStack
      w={{ base: "xs", md: "2xl", lg: "8xl" }}
      mb={"10"}
      spacing={"6"}
      align={"flex-start"}
    >
      <VStack w={"full"} bg={"#fff"} p={"4"} rounded={"md"} shadow={"md"}>
        <Stack
          direction={{ base: "column", md: "row" }}
          w={"full"}
          align={"flex-start"}
          justify={"flex-start"}
        >
          <VStack>
            <VStack
              bg={"#fff"}
              rounded={"md"}
              p={"4"}
              w={{ base: "72", md: "xs", lg: "xs" }}
              transition="all 0.5s ease-out"
              height={
                // zoom
                // ?
                value === "2"
                  ? userData
                    ? "max-content"
                    : "80"
                  : userData
                  ? "max-content"
                  : "72"
                // : "14"
              }
              borderTopWidth={"medium"}
              borderTopColor={"#45A735"}
              borderLeftColor={"#eee"}
              borderLeftWidth={"thin"}
              borderRightColor={"#eee"}
              borderRightWidth={"thin"}
              borderBottomColor={"#eee"}
              borderBottomWidth={"thin"}
            >
              {/* <HStack
                w={"full"}
                // onClick={handleZoom} cursor={"pointer"}
              > */}
              <Flex
                align={"center"}
                w="full"
                _before={{
                  content: '""',
                  borderBottom: "1px solid",
                  borderColor: "#45A735",
                  flexGrow: 0.3,
                  mr: { base: 4, md: 4 },
                }}
                _after={{
                  content: '""',
                  borderBottom: "1px solid",
                  borderColor: "#45A735",
                  flexGrow: 1.7,
                  ml: { base: 4, md: 4 },
                }}
              >
                <Text
                  color={"#000"}
                  fontSize={15}
                  fontWeight={"medium"}
                  letterSpacing={0.3}
                >
                  Нэхэмжлэх үүсгэх
                </Text>
              </Flex>
              {/* <Icon
                  as={zoom ? IoIosArrowUp : IoIosArrowDown}
                  w={"5"}
                  h={"5"}
                  color={"#45A735"}
                />
              </HStack> */}

              {/* <Collapse
                className="addModel"
                in={isOpen}
                display={zoom ? "block" : "none"}
                animateOpacity
              > */}
              <VStack
                w={{ base: "72", md: "xs", lg: "xs" }}
                spacing={6}
                px={4}
                pt={2}
                pb={4}
              >
                <RadioGroup onChange={setValue} value={value} w={"full"}>
                  <Stack
                    direction="row"
                    w={"full"}
                    justify={"space-between"}
                    px={"6"}
                  >
                    <Radio
                      value="1"
                      colorScheme={"green"}
                      _focus={{
                        outline: "none",
                      }}
                    >
                      <Text fontSize={15} fontWeight={"normal"} color={"#000"}>
                        Сараар
                      </Text>
                    </Radio>
                    <Radio
                      value="2"
                      colorScheme={"green"}
                      _focus={{
                        outline: "none",
                      }}
                      isDisabled
                    >
                      <Text fontSize={15} fontWeight={"normal"} color={"#000"}>
                        Олон сараар
                      </Text>
                    </Radio>
                  </Stack>
                </RadioGroup>
                <VStack w={"full"} spacing={userData ? 4 : 3}>
                  <HStack pos={"relative"} w={"full"}>
                    <Input
                      placeholder="Хэрэглэгчийн нэр оруулна уу"
                      fontSize={"14"}
                      fontWeight={"normal"}
                      color={"#000"}
                      w={"full"}
                      _hover={{
                        borderColor: "#45A735",
                      }}
                      _focus={{
                        borderColor: "#45A735",
                      }}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <Center
                      pos={"absolute"}
                      top={-2.5}
                      left={-1}
                      bg={"#fff"}
                      px={"1"}
                    >
                      <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
                        Хэрэглэгчийн нэр
                      </Text>
                    </Center>
                  </HStack>

                  {userDataLoading ? (
                    <Spinner size="sm" color="#45A735" />
                  ) : userData ? (
                    <VStack w={"52"} spacing={2}>
                      <HStack justify={"space-between"} w={"full"}>
                        <Text
                          fontSize={14}
                          fontWeight={"normal"}
                          color={"#000"}
                        >
                          houses:
                        </Text>
                        <Text
                          fontSize={14}
                          fontWeight={"medium"}
                          color={"#000"}
                        >
                          {userData.houses}
                        </Text>
                      </HStack>
                      <HStack justify={"space-between"} w={"full"}>
                        <Text
                          fontSize={14}
                          fontWeight={"normal"}
                          color={"#000"}
                        >
                          devices:
                        </Text>
                        <Text
                          fontSize={14}
                          fontWeight={"medium"}
                          color={"#000"}
                        >
                          {userData.devices}
                        </Text>
                      </HStack>
                      <HStack justify={"space-between"} w={"full"}>
                        <Text
                          fontSize={14}
                          fontWeight={"normal"}
                          color={"#000"}
                        >
                          automations:
                        </Text>
                        <Text
                          fontSize={14}
                          fontWeight={"medium"}
                          color={"#000"}
                        >
                          {userData.automations}
                        </Text>
                      </HStack>
                      <HStack justify={"space-between"} w={"full"}>
                        <Text
                          fontSize={14}
                          fontWeight={"normal"}
                          color={"#000"}
                        >
                          taptoruns:
                        </Text>
                        <Text
                          fontSize={14}
                          fontWeight={"medium"}
                          color={"#000"}
                        >
                          {userData.taptoruns}
                        </Text>
                      </HStack>
                    </VStack>
                  ) : null}

                  {value === "2" ? (
                    <HStack>
                      <HStack pos={"relative"} w={"full"}>
                        <Input
                          placeholder="Сар оруулна уу"
                          fontSize={"14"}
                          fontWeight={"normal"}
                          color={"#000"}
                          w={"full"}
                          type={"number"}
                          _hover={{
                            borderColor: "#45A735",
                          }}
                          _focus={{
                            borderColor: "#45A735",
                          }}
                          value={currentMounth}
                          onChange={(e) => setCurrentMounth(e.target.value)}
                        />
                        <Center
                          pos={"absolute"}
                          top={-2.5}
                          left={-1}
                          bg={"#fff"}
                          px={"1"}
                        >
                          <Text
                            fontSize={13}
                            fontWeight={"normal"}
                            color={"#000"}
                          >
                            Сар
                          </Text>
                        </Center>
                      </HStack>
                      <HStack pos={"relative"} w={"full"}>
                        <Input
                          placeholder="Тухайн сараас хойш"
                          fontSize={"14"}
                          fontWeight={"normal"}
                          color={"#000"}
                          w={"full"}
                          type={"number"}
                          _hover={{
                            borderColor: "#45A735",
                          }}
                          _focus={{
                            borderColor: "#45A735",
                          }}
                          value={sinceMounth}
                          onChange={(e) => setSinceMounth(e.target.value)}
                        />
                        <Center
                          pos={"absolute"}
                          top={-2.5}
                          left={-1}
                          bg={"#fff"}
                          px={"1"}
                        >
                          <Text
                            fontSize={13}
                            fontWeight={"normal"}
                            color={"#000"}
                          >
                            Сараас хойш
                          </Text>
                        </Center>
                      </HStack>
                    </HStack>
                  ) : null}

                  <HStack pos={"relative"} w={"full"}>
                    <InputGroup>
                      <Input
                        placeholder="Төлбөрийн хэмжээ"
                        fontSize={"14"}
                        fontWeight={"normal"}
                        color={"#000"}
                        w={"full"}
                        _hover={{
                          borderColor: "#45A735",
                        }}
                        _focus={{
                          borderColor: "#45A735",
                        }}
                        type={"number"}
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        onKeyPress={(e) => {
                          if (username && amount) {
                            if (e.key === "Enter") {
                              setOpenModal(true);
                            }
                          }
                        }}
                      />

                      <InputRightElement
                        pointerEvents="none"
                        color="gray.400"
                        fontSize="13"
                        mr={"1"}
                        children={<Text>MNT</Text>}
                      />
                    </InputGroup>
                    <Center
                      pos={"absolute"}
                      top={-2.5}
                      left={-1}
                      bg={"#fff"}
                      px={"1"}
                    >
                      <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
                        Төлбөр
                      </Text>
                    </Center>
                  </HStack>

                  <Button
                    fontSize={15}
                    fontWeight={"normal"}
                    color={"#fff"}
                    bg={"#45A735"}
                    size={"md"}
                    w={"full"}
                    _hover={{
                      bg: "#5AB54B",
                    }}
                    _focus={{
                      outline: "none",
                    }}
                    disabled={username && amount ? false : true}
                    onClick={() => setOpenModal(true)}
                  >
                    Үүсгэх
                  </Button>
                </VStack>
              </VStack>
              {/* </Collapse> */}
            </VStack>
            <VStack
              w={{ base: "72", md: "xs", lg: "xs" }}
              borderColor={"#eee"}
              borderWidth={"thin"}
              rounded={"md"}
              align={"flex-start"}
              p={"4"}
            >
              <HStack w={"full"}>
                <Box
                  w={"full"}
                  borderColor={"#45A735"}
                  borderLeftWidth={"medium"}
                  bg={"#f8f8f8"}
                  py={"2"}
                  px={{ base: "4", md: "6" }}
                >
                  <Text fontSize={"15"} color="#000" fontWeight={"medium"}>
                    Шүүлтүүр
                  </Text>
                </Box>

                <Tooltip label="Шинэчлэх" placement="bottom" bg="#45A735">
                  <IconButton
                    aria-label="Reset"
                    icon={<GrPowerReset />}
                    bg={"#f8f8f8"}
                    _hover={{
                      bg: "#ECECEC",
                    }}
                    _focus={{
                      outline: "none",
                    }}
                    onClick={resetHandler}
                    w={{ base: "full", md: "min-content" }}
                    size="md"
                    // disabled={invoiceFilterList.length > 0 ? false : true}
                  />
                </Tooltip>
              </HStack>
              <Divider />
              <VStack w={"full"} align={"flex-start"} spacing={1}>
                <Text fontSize={"15"} color="#000" fontWeight={"medium"}>
                  Хэрэглэгчийн нэр
                </Text>
                <InputGroup>
                  <Input
                    placeholder="-"
                    w={"full"}
                    size={"sm"}
                    rounded={"md"}
                    fontSize={15}
                    fontWeight={"normal"}
                    _focus={{
                      borderColor: "#45A735",
                    }}
                    _hover={{ borderColor: "#45A735" }}
                    value={usernameFilter}
                    onChange={(e) => setUsernameFilter(e.target.value)}
                  />

                  <InputRightElement
                    pointerEvents="none"
                    height={"8"}
                    children={<Icon as={FiEdit} w={4} h={4} color="gray.400" />}
                  />
                </InputGroup>
              </VStack>
              <Divider />
              <VStack w={"full"} align={"flex-start"}>
                <Text fontSize={"15"} color="#000" fontWeight={"medium"}>
                  Төлбөрийн хэмжээ
                </Text>
                <RangeSlider
                  aria-label={["min", "max"]}
                  min={1000}
                  max={1000000}
                  colorScheme="green"
                  defaultValue={[1000, 1000000]}
                  onChange={onChange}
                  onChangeEnd={(val) => setAmountFilter(val)}
                  // onChangeEnd={(val) => console.log(val)}
                >
                  <RangeSliderTrack bg="#E7F8E5">
                    <RangeSliderFilledTrack bg="#45A735" />
                  </RangeSliderTrack>
                  <RangeSliderThumb
                    boxSize={4}
                    index={0}
                    borderColor={"green.300"}
                    borderWidth={"thin"}
                    _focus={{ outline: "none" }}
                  />
                  <RangeSliderThumb
                    boxSize={4}
                    index={1}
                    borderColor={"green.300"}
                    borderWidth={"thin"}
                    _focus={{ outline: "none" }}
                  />
                </RangeSlider>
                <Text fontSize={"14"} color="#000" fontWeight={"medium"}>
                  {/* 10₮ - 1,000,000₮ хооронд, */}
                  {limit ? limit[0].toLocaleString() : "1,000"}₮ -{" "}
                  {limit ? limit[1].toLocaleString() : "1,000,000"}₮ хооронд
                </Text>
              </VStack>
              <Divider />
              <VStack w={"full"} align={"flex-start"}>
                <Text fontSize={"15"} color="#000" fontWeight={"medium"}>
                  Хугацаа
                </Text>
                <HStack w={"full"}>
                  <Input
                    w={"full"}
                    size={"sm"}
                    rounded={"md"}
                    type={"date"}
                    fontSize={14}
                    fontWeight={"normal"}
                    _focus={{ outline: "none" }}
                    _hover={{
                      borderColor: "#45A735",
                    }}
                    value={startDay}
                    onChange={(e) => setStartDay(e.target.value)}
                  />
                  <Input
                    w={"full"}
                    size={"sm"}
                    rounded={"md"}
                    type={"date"}
                    fontSize={14}
                    fontWeight={"normal"}
                    _focus={{ outline: "none" }}
                    _hover={{
                      borderColor: "#45A735",
                    }}
                    value={endDay}
                    onChange={(e) => setEndDay(e.target.value)}
                  />
                </HStack>
              </VStack>
              <Divider />
              <VStack w={"full"} align={"flex-start"}>
                <Text fontSize={"15"} color="#000" fontWeight={"medium"}>
                  Төлөв
                </Text>
                <HStack
                  w={"full"}
                  justify={"space-between"}
                  align={"center"}
                  px={"10"}
                >
                  <Checkbox
                    colorScheme="green"
                    size={"lg"}
                    value={paid}
                    onChange={() => selectPaidHandler(!paid)}
                    isChecked={paid}
                    css={`
                      > span:first-of-type {
                        box-shadow: unset;
                      }
                      touch-action: none;
                    `}
                  >
                    <Text fontSize={"15"} color="#000" fontWeight={"normal"}>
                      Төлсөн
                    </Text>
                  </Checkbox>
                  <Checkbox
                    colorScheme="green"
                    size={"lg"}
                    value={notPaid}
                    onChange={() => selectNotPaidHandler(!notPaid)}
                    isChecked={notPaid}
                    css={`
                      > span:first-of-type {
                        box-shadow: unset;
                      }
                    `}
                  >
                    <Text fontSize={"15"} color="#000" fontWeight={"normal"}>
                      Төлөөгүй
                    </Text>
                  </Checkbox>
                </HStack>
              </VStack>
              <Divider />
              <Button
                w={"full"}
                bg={"#45A735"}
                color={"#fff"}
                size={"sm"}
                fontSize={15}
                fontWeight={"normal"}
                _hover={{
                  bg: "#5AB54B",
                }}
                _focus={{
                  outline: "none",
                }}
                onClick={filterHandler}
              >
                Хайх
              </Button>
            </VStack>
            <Button
              w={{ base: "72", md: "xs", lg: "xs" }}
              borderColor={"#eee"}
              borderWidth={"thin"}
              rounded={"md"}
              align={"flex-start"}
              // p={"4"}
              fontSize={15}
              fontWeight={"normal"}
              color={"#fff"}
              bg={"#E70000"}
              onClick={() => history.push("/invoice/restrict")}
              _focus={{
                outline: "none",
              }}
              _hover={{
                bg: "#EB2D2D",
              }}
            >
              Хязгаарлах
            </Button>
          </VStack>
          <VStack w={{ base: "full", md: "xs", lg: "6xl" }} spacing={4}>
            <TableContainer
              borderLeftWidth={"thin"}
              borderRightWidth={"thin"}
              rounded={"md"}
              w={"full"}
              borderColor={"#F4F4F4"}
            >
              <Table size="md" variant="simple">
                <Thead
                  // bgGradient="linear(to-l, #1ED600, #45A735,)"
                  bg={"#45A735"}
                >
                  <Tr>
                    {listTitle.map((e, index) => (
                      <Th
                        key={index}
                        color={"#fff"}
                        fontWeight={"medium"}
                        fontSize={14}
                        textTransform={"initial"}
                        isNumeric={index === 7 ? true : false}
                      >
                        {e.title}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                {initialLoading ? (
                  <Tbody>
                    <Tr>
                      <Td>
                        <SkeletonText noOfLines={1} />
                      </Td>
                      <Td>
                        <SkeletonText noOfLines={1} />
                      </Td>
                      <Td>
                        <SkeletonText noOfLines={1} />
                      </Td>
                      <Td>
                        <SkeletonText noOfLines={1} />
                      </Td>
                      <Td>
                        <SkeletonText noOfLines={1} />
                      </Td>
                      <Td>
                        <SkeletonText noOfLines={1} />
                      </Td>
                      <Td>
                        <SkeletonText noOfLines={1} />
                      </Td>
                      <Td isNumeric>
                        <SkeletonText noOfLines={1} />
                      </Td>
                    </Tr>
                  </Tbody>
                ) : invoiceList.length > 0 ? (
                  filter ? (
                    invoiceFilterList.map((e, index) => (
                      <Tbody key={index}>
                        <Tr>
                          <Td
                            color={"#000"}
                            fontWeight={"normal"}
                            fontSize={14}
                          >
                            {index + 1}
                          </Td>
                          <Td
                            color={"#000"}
                            fontWeight={"normal"}
                            fontSize={14}
                          >
                            {e.username}
                          </Td>
                          <Td
                            color={"#000"}
                            fontWeight={"normal"}
                            fontSize={14}
                          >
                            {e.amount.toLocaleString()} ₮
                          </Td>
                          <Td
                            color={"#000"}
                            fontWeight={"normal"}
                            fontSize={14}
                          >
                            {e.atTime ? e.atTime.slice(0, 10) : "-"}
                          </Td>
                          <Td
                            color={"#000"}
                            fontWeight={"normal"}
                            fontSize={14}
                          >
                            {e.invoiceDate}
                          </Td>
                          {/* <Td
                            color={"#45A735"}
                            fontWeight={"normal"}
                            fontSize={14}
                          >
                            Төлсөн
                          </Td> */}
                          {e.paid ? (
                            <Td
                              color={"#45A735"}
                              fontWeight={"normal"}
                              fontSize={14}
                            >
                              Төлсөн
                            </Td>
                          ) : (
                            <Td
                              color={"#ff0000"}
                              fontWeight={"normal"}
                              fontSize={14}
                              onClick={() => {
                                onOpen();
                                setExplainId(e._id);
                              }}
                              cursor={"pointer"}
                            >
                              Төлөөгүй
                            </Td>
                          )}
                          <Td
                            color={"#000"}
                            fontWeight={"normal"}
                            fontSize={14}
                          >
                            {e.admin}
                          </Td>
                          <Td
                            color={"#000"}
                            fontWeight={"normal"}
                            fontSize={14}
                            py={0}
                            isNumeric
                          >
                            {e.paid === false ? (
                              <Icon
                                color={"red"}
                                w={6}
                                h={6}
                                cursor="pointer"
                                as={MdDeleteOutline}
                                _hover={{ animation: animation }}
                                onClick={() => deleteModal(e._id)}
                              />
                            ) : (
                              "-"
                            )}
                          </Td>
                        </Tr>
                      </Tbody>
                    ))
                  ) : (
                    invoiceList.map((e, index) => (
                      <Tbody key={index}>
                        <Tr>
                          <Td
                            color={"#000"}
                            fontWeight={"normal"}
                            fontSize={13}
                          >
                            {index + 1}
                          </Td>
                          <Td
                            color={"#000"}
                            fontWeight={"normal"}
                            fontSize={13}
                          >
                            {e.username}
                          </Td>
                          <Td
                            color={"#000"}
                            fontWeight={"normal"}
                            fontSize={13}
                          >
                            {e.amount.toLocaleString() + "₮"}
                          </Td>
                          <Td
                            color={"#000"}
                            fontWeight={"normal"}
                            fontSize={13}
                          >
                            {e.atTime ? e.atTime.slice(0, 10) : "-"}
                          </Td>
                          <Td
                            color={"#000"}
                            fontWeight={"normal"}
                            fontSize={13}
                          >
                            {e.invoiceDate}
                          </Td>
                          {e.paid ? (
                            <Tooltip
                              hasArrow
                              label={
                                e.paymentDescription
                                  ? e.paymentDescription
                                  : "..."
                              }
                              bg="gray.300"
                              color="black"
                              textAlign={"center"}
                            >
                              <Td
                                color={"#45A735"}
                                fontWeight={"normal"}
                                fontSize={13}
                                cursor={"pointer"}
                              >
                                Төлсөн
                              </Td>
                            </Tooltip>
                          ) : (
                            <Td
                              color={"#ff0000"}
                              fontWeight={"normal"}
                              fontSize={13}
                              onClick={() => {
                                onOpen();
                                setExplainId(e._id);
                              }}
                              cursor={"pointer"}
                            >
                              Төлөөгүй
                            </Td>
                          )}
                          <Td
                            color={"#000"}
                            fontWeight={"normal"}
                            fontSize={13}
                          >
                            {e.admin}
                          </Td>
                          <Td
                            color={"#000"}
                            fontWeight={"normal"}
                            fontSize={13}
                            py={0}
                            isNumeric
                          >
                            {e.paid === false ? (
                              <Icon
                                color={"red"}
                                w={6}
                                h={6}
                                cursor="pointer"
                                as={MdDeleteOutline}
                                _hover={{ animation: animation }}
                                onClick={() => deleteModal(e._id)}
                              />
                            ) : e.organizationId ? (
                              e.organizationId === "nothing" ? (
                                "-"
                              ) : (
                                <Button
                                  transition={"ease-in-out .3s"}
                                  size="sm"
                                  bg={
                                    e.ebarimtGenerated ? "#6BB55F" : "#EA5151"
                                  }
                                  px={"1.5"}
                                  rounded={"sm"}
                                  borderWidth={"thin"}
                                  borderColor={
                                    e.ebarimtGenerated ? "#45A735" : "#ff0000"
                                  }
                                  onClick={() => {
                                    if (e.ebarimtGenerated) {
                                      EbarimtGenerateHandler();
                                    } else {
                                      setEbarimtData(e);
                                      setEbarimtIndex(index);
                                      setShowEbarimtModal(true);
                                    }
                                  }}
                                  _focus={{
                                    outline: "none",
                                  }}
                                  _hover={{
                                    transform: "scale(1.15)",
                                    shadow: "md",
                                  }}
                                  cursor={
                                    e.ebarimtGenerated ? "default" : "pointer"
                                  }
                                >
                                  <Text
                                    fontSize={13}
                                    fontWeight={"medium"}
                                    color={"#fff"}
                                  >
                                    {e.organizationId}
                                  </Text>
                                </Button>
                              )
                            ) : (
                              "-"
                            )}
                          </Td>
                        </Tr>
                      </Tbody>
                    ))
                  )
                ) : (
                  <VStack
                    justifyContent="center"
                    alignItems="center"
                    color="gray.600"
                    w={{ base: "72", md: "xs", lg: "5xl" }}
                    pt={{ base: "6", md: "12", lg: "24" }}
                    spacing={1}
                    pos={{ base: "initial", md: "absolute" }}
                  >
                    <Icon as={FiInfo} w={7} h={7} />
                    <Text fontSize={15} fontWeight={"normal"}>
                      Мэдээлэл олдсонгүй.
                    </Text>
                  </VStack>
                )}
              </Table>
            </TableContainer>

            {invoiceList.length > 0 ? (
              <ReactPaginate
                previousLabel={"< Prev"}
                nextLabel={"Next >"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                // forcePage={currentPage}
                onPageChange={handlePageClick}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            ) : null}
          </VStack>
        </Stack>
      </VStack>
      <Modal
        isCentered
        onClose={!openModal}
        isOpen={openModal}
        motionPreset="scale"
        transition="all 0.5s ease-out"
      >
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>
            <Heading as="h3" size="md">
              Нэхэмжлэх үүсгэх
            </Heading>
          </ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody>
            <VStack w={"full"} px={"2"}>
              <HStack w={"full"} justify={"space-between"}>
                <Text color={"#000"} fontWeight={"normal"} fontSize={15}>
                  Гэрээний дугаар:
                </Text>
                <Text color={"#000"} fontWeight={"medium"} fontSize={15}>
                  {username}
                </Text>
              </HStack>
              <HStack w={"full"} justify={"space-between"}>
                <Text color={"#000"} fontWeight={"normal"} fontSize={15}>
                  Төлбөрийн хэмжээ:
                </Text>
                <Text color={"#000"} fontWeight={"medium"} fontSize={15}>
                  {amount.toLocaleString() + " ₮"}
                </Text>
              </HStack>
            </VStack>
            {count % 2 === 0 ? null : (
              <HStack
                w={"full"}
                align={"flex-end"}
                justify={"flex-end"}
                spacing={0}
                my={"3"}
              >
                <Button
                  colorScheme="gray"
                  mr={3}
                  px={"8"}
                  fontSize={15}
                  fontWeight={"normal"}
                  _focus={{
                    outline: "none",
                  }}
                  onClick={() => setOpenModal(false)}
                >
                  Үгүй
                </Button>
                <Button
                  bg="#45A735"
                  color={"#fff"}
                  px={"8"}
                  fontSize={15}
                  fontWeight={"normal"}
                  _hover={{
                    bg: "#5AB54B",
                  }}
                  _focus={{
                    outline: "none",
                  }}
                  onClick={() => setCount((c) => c + 1)}
                >
                  Илгээх
                </Button>
              </HStack>
            )}
          </ModalBody>
          {count % 2 === 0 ? (
            <>
              <Divider mt={"2"} />
              <ModalFooter transition="all 0.5s ease-out">
                <VStack w={"full"} align={"flex-start"} spacing={10}>
                  <Heading as="h3" size="md">
                    Итгэлтэй байна уу?
                  </Heading>

                  <HStack
                    w={"full"}
                    align={"flex-end"}
                    justify={"flex-end"}
                    spacing={0}
                  >
                    <Button
                      colorScheme="gray"
                      mr={3}
                      px={"8"}
                      fontSize={15}
                      fontWeight={"normal"}
                      //   _hover={{
                      //     bg: "#5AB54B",
                      //   }}
                      _focus={{
                        outline: "none",
                      }}
                      onClick={cancelHandler}
                    >
                      Үгүй
                    </Button>
                    <Button
                      bg="#45A735"
                      color={"#fff"}
                      px={"8"}
                      fontSize={15}
                      fontWeight={"normal"}
                      _hover={{
                        bg: "#5AB54B",
                      }}
                      _focus={{
                        outline: "none",
                      }}
                      onClick={createHandler}
                      disabled={username && amount && !isLoading ? false : true}
                    >
                      {isLoading ? <Spinner mr={2} size="sm" /> : null}
                      Тийм
                    </Button>
                  </HStack>
                </VStack>
              </ModalFooter>
            </>
          ) : null}
        </ModalContent>
      </Modal>

      <Modal
        isCentered
        onClose={!showDeleteModal}
        isOpen={showDeleteModal}
        motionPreset="scale"
      >
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              onClick={() => setShowDeleteModal(false)}
              _focus={{
                outline: "none",
              }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={deleteHandler}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Тайлбар бичнэ үү!</ModalHeader>
          <ModalCloseButton
            _focus={{
              outline: "none",
            }}
          />
          <ModalBody>
            <Textarea
              placeholder="-"
              height={"44"}
              bg={"#fff"}
              fontSize={15}
              fontWeight={"normal"}
              borderColor={"#f2f2f2"}
              value={paymentDescription}
              onChange={(e) => setPaymentDescription(e.target.value)}
              _focus={{
                outline: "none",
                borderColor: "#45A735",
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              onClick={() => onClose()}
              _focus={{
                outline: "none",
              }}
            >
              Болих
            </Button>
            <Button
              colorScheme="green"
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={explainHandler}
              disabled={
                !explainSpinner && paymentDescription.length > 9 ? false : true
              }
            >
              {explainSpinner ? <Spinner mr={"2"} size="sm" /> : null}
              Илгээх
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isCentered
        onClose={!showEbarimtModal}
        isOpen={showEbarimtModal}
        motionPreset="scale"
      >
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Итгэлтэй байна уу?</ModalHeader>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              onClick={() => setShowEbarimtModal(false)}
              _focus={{
                outline: "none",
              }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="green"
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={EbarimtGenerateHandler}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

const listTitle = [
  { title: "№" },
  { title: "Хэрэглэгчийн нэр" },
  { title: "Төлбөрийн хэмжээ" },
  { title: "Үүссэн хугацаа" },
  { title: "Хугацаа" },
  { title: "Төлөв" },
  { title: "Илгээсэн админ" },
  { title: "..." },
];
export default CreateInvoice;
