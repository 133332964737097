import React, { useState } from "react";
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import CustomInput from "../../../../components/input/CustomInput";
import CustomTextarea from "../../../../components/input/CustomTextarea";
import axios from "../../../../axios";
import Cookies from "js-cookie";
import { generatePDF } from "../create/GeneratePDF";

const InvoiceModal = ({ data, showModal, setShowModal, setTrigger }) => {
  const [changeStatus, setChangeStatus] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");
  // console.log("InvoiceModal --->", data);

  const submitHandler = () => {
    if (data) {
      setIsDisabled(true);
      axios
        .put(
          "/v2/device_invoice/pay",
          {
            _id: data.invoiceData?._id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result);
          setTrigger(true);
          setShowModal(false);
          setChangeStatus(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
        })
        .catch((err) => {
          // console.log(err.response);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        })
        .finally(() => setIsDisabled(false));
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      size={"sm"}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      {changeStatus ? (
        <ModalContent>
          <ModalHeader color={"#000"} fontSize={17} fontWeight={"medium"}>
            Итгэлтэй байна уу?
          </ModalHeader>
          <ModalBody>
            <VStack>
              <Text as="i" color={"#000"} fontSize={13} fontWeight={"normal"}>
                <Text
                  as="span"
                  color={"#45A735"}
                  fontSize={13}
                  fontWeight={"medium"}
                >
                  "Төлөгдсөн болгох"
                </Text>{" "}
                товчийг дарснаар буцаах боломжгүй болохыг анхаарна уу!
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter gap={2}>
            <Button
              w={!data?.invoiceData?.isPaid ? "full" : "50%"}
              color={"#000"}
              fontWeight={"medium"}
              fontSize={13}
              onClick={() => setChangeStatus(false)}
            >
              Болих
            </Button>
            <Button
              w={"full"}
              bg={"#45A735"}
              color={"#fff"}
              fontWeight={"medium"}
              fontSize={13}
              _hover={{
                bg: "#3E9330",
              }}
              _disabled={{
                bg: "#8cd97f",
              }}
              _focus={{
                outline: "none",
              }}
              onClick={submitHandler}
              isDisabled={isDisabled}
            >
              {isDisabled ? <Spinner color="#fff" mr={2} size="sm" /> : null}
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      ) : (
        <ModalContent>
          <ModalHeader color={"#000"} fontSize={17} fontWeight={"medium"}>
            Нэхэмжлэх - {data?.invoiceNo}
          </ModalHeader>
          <ModalBody>
            <VStack>
              <CustomInput
                readOnly={true}
                title={"Төлөгчийн нэр"}
                value={data?.orgName || "-"}
              />
              <HStack w={"full"}>
                <CustomInput
                  readOnly={true}
                  title={"Регистрийн дугаар"}
                  value={data?.orgId || "-"}
                />
                <CustomInput
                  readOnly={true}
                  title={"Утас"}
                  value={data?.orgPhone || "-"}
                />
              </HStack>
              <CustomTextarea
                readOnly={true}
                title={"Хаяг"}
                value={data?.orgAddress || "-"}
              />
              <HStack w={"full"}>
                <CustomInput
                  readOnly={true}
                  title={"Нийт үнэ"}
                  value={data?.amount.toLocaleString() + "₮" || "-"}
                />
                <CustomInput
                  readOnly={true}
                  title={"НӨАТ"}
                  value={data?.tax.toLocaleString() + "₮" || "-"}
                />
              </HStack>
              <CustomInput
                readOnly={true}
                title={"Нэмэхжлэх үүссэн хугацаа"}
                value={data?.invoiceData?.time || "-"}
              />
              <CustomInput
                readOnly={true}
                title={"Үүсгэсэн админ"}
                value={data?.invoiceData?.admin || "-"}
              />
              <CustomInput
                readOnly={true}
                title={"Төлөв"}
                value={
                  data?.invoiceData?.isPaid ? "Төлөгдсөн" : "Төлөгдөөгүй" || "-"
                }
              />
              {data?.invoiceData?.isPaid ? (
                <CustomInput
                  readOnly={true}
                  title={"Нэхэмжлэх төлөгдсөн хугацаа"}
                  value={data?.invoiceData?.paidAt || "-"}
                />
              ) : null}
            </VStack>
          </ModalBody>
          <ModalFooter gap={2}>
            {data?.invoiceData?.isPaid ? (
              <HStack w={"full"}>
                <Button
                  w={"full"}
                  color={"#000"}
                  fontWeight={"medium"}
                  fontSize={13}
                  onClick={() => setShowModal(false)}
                >
                  Хаах
                </Button>
                <Button
                  variant="outline"
                  w={"full"}
                  fontSize={13}
                  color={"#000"}
                  fontWeight={"medium"}
                  onClick={() =>
                    generatePDF({
                      invoice: true,
                      tax: data?.tax > 0 ? true : false,
                      deviceData: data?.devices,
                      footerData: data?.additionalInfo,
                      orgInfo: {
                        number: data?.number || "",
                        orgName: data?.orgName || "",
                        orgId: data?.orgId || "",
                        orgAddress: data?.orgAddress || "",
                        orgPhone: data?.orgPhone || "",
                        time: data?.time || "",
                        admin: data?.admin || "",
                        amount: data?.amount || 0,
                        tax: data?.tax || 0,
                      },
                      invoiceNo: data?.invoiceData?.number || "",
                    })
                  }
                >
                  Export
                </Button>
              </HStack>
            ) : (
              <VStack w={"full"}>
                <Button
                  variant="outline"
                  w={"full"}
                  fontSize={13}
                  color={"#000"}
                  fontWeight={"medium"}
                  onClick={() =>
                    generatePDF({
                      invoice: true,
                      tax: data?.tax > 0 ? true : false,
                      deviceData: data?.devices,
                      footerData: data?.additionalInfo,
                      orgInfo: {
                        number: data?.number || "",
                        orgName: data?.orgName || "",
                        orgId: data?.orgId || "",
                        orgAddress: data?.orgAddress || "",
                        orgPhone: data?.orgPhone || "",
                        time: data?.time || "",
                        admin: data?.admin || "",
                        amount: data?.amount || 0,
                        tax: data?.tax || 0,
                      },
                      invoiceNo: data?.invoiceData?.number || "",
                    })
                  }
                >
                  Export
                </Button>
                <HStack w={"full"}>
                  <Button
                    w={!data?.invoiceData?.isPaid ? "full" : "50%"}
                    color={"#000"}
                    fontWeight={"medium"}
                    fontSize={13}
                    onClick={() => setShowModal(false)}
                  >
                    Хаах
                  </Button>
                  {!data?.invoiceData?.isPaid ? (
                    <Button
                      w={"full"}
                      bg={"#45A735"}
                      color={"#fff"}
                      fontWeight={"medium"}
                      fontSize={13}
                      _hover={{
                        bg: "#3E9330",
                      }}
                      _disabled={{
                        bg: "#8cd97f",
                      }}
                      _focus={{
                        outline: "none",
                      }}
                      onClick={() => setChangeStatus(true)}
                      isDisabled={isDisabled}
                    >
                      Төлөгдсөн болгох
                    </Button>
                  ) : null}
                </HStack>
              </VStack>
            )}
          </ModalFooter>
        </ModalContent>
      )}
    </Modal>
  );
};

export default InvoiceModal;
