import { GridItem, HStack, Text } from "@chakra-ui/react";
import React from "react";

function Unit({ title, value, unit }) {
  return (
    <GridItem bg={"#f9f9f9"} rounded={"lg"} py={"2"} px={"4"}>
      <HStack justify={"space-between"}>
        <Text
          fontSize={"sm"}
          color="#000"
          fontWeight={"normal"}
          alignSelf={"flex-start"}
        >
          {title}:
        </Text>
        <Text fontSize={"sm"} color="#000" fontWeight={"medium"} align={"end"}>
          {Number(value)?.toLocaleString()}
          <span style={{ fontSize: 14, color: "#15c545", marginLeft: "2px" }}>
            {unit}
          </span>
        </Text>
      </HStack>
    </GridItem>
  );
}

export default Unit;
