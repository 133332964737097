import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "../../../axios";
import { FiEdit, FiInfo, FiPhone } from "react-icons/fi";
import { AiOutlineMinusCircle } from "react-icons/ai";
import BackButton from "../../../components/button/BackButton";

const Ir = () => {
  const [deviceList, setDeviceList] = useState([]);
  const [modelIdList, setModelIdList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [trigger, setTrigger] = useState(true);
  const history = useHistory();
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [delClient, setDelClient] = useState("");

  useEffect(() => {
    if (trigger) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/admin/ircontroller_create", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          // console.log("Ir List ---", result.data.data);
          setDeviceList(
            result.data.data.map((e, index) => {
              return {
                clientId: e.clientId,
                deviceId: e.deviceId,
                contractId: "",
                houseList: [],
                houseId: "",
                for: "",
                subDevices: [],
              };
            })
          );
          setTrigger(false);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setTrigger(false);
          setIsLoading(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });

      return () => {
        cancel = true;
      };
    }
  }, [trigger]);

  // ------------------------- get Air condition modelId ------------------------------
  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/ircontroller_create_models", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log("AC modelId", result.data.data);
        setModelIdList(result.data.data);
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });

    return () => {
      cancel = true;
    };
  }, []);

  // ------------------------ set ContractId -----------------------------
  const handleChange = (a, index) => {
    let source = axios.CancelToken.source();
    let cancel = false;
    // --------------------------- get Houses ---------------------------------
    const clone = [...deviceList];
    clone[index].contractId = a;
    if (a.split("").length > 7) {
      axios
        .get("admin/device_houses", {
          params: { username: a },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          // console.log(result.data.data);
          const houses = [...deviceList];
          houses[index].houseList = result.data.data;
          setDeviceList(houses);
        })
        .catch((err) => {
          // console.log("error", err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });

      setDeviceList(clone);
      return () => {
        cancel = true;
      };
    } else {
      const houses = [...deviceList];
      houses[index].houseList = [];
      setDeviceList(houses);
    }
  };

  // --- get Select ---
  const handleSelect = (value, index) => {
    // console.log("select house", value, index);
    const house = [...deviceList];
    house[index].houseId = value;
    setDeviceList(house);
  };

  const nameHandler = (value, index) => {
    const deviceName = [...deviceList];
    deviceName[index].for = value;
    setDeviceList(deviceName);
  };

  const handleSubmit = (value, index) => {
    if (value) {
      const data = {
        clientId: value.clientId,
        username: value.contractId,
        houseId: value.houseId,
        deviceId: value.deviceId,
        for: value.for,
        subDevices: value.subDevices,
      };
      // console.log("data ---->", data);
      axios
        .post("/admin/ircontroller_create", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result);
          setTrigger(true);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
        })
        .catch((err) => {
          // console.log(err.response);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    } else {
      if (!toast.isActive(id)) {
        toast({
          id,
          duration: 2000,
          position: "top",
          status: "error",
          description: "Талбарыг гүйцэд бөглөнө үү!",
        });
      }
    }
  };

  // -------------------------- create SubDevices -------------------------
  const AddForm = (index) => {
    const list = [...deviceList];
    const sub = [
      ...deviceList[index].subDevices,
      {
        model: "",
        type: "AC",
        config: {
          tempMin: "",
          tempMax: "",
          fanSpeedMax: "",
          fanSpeedMin: "",
        },
      },
    ];
    list[index].subDevices = sub;
    setDeviceList(list);
  };

  // ----------------------------- update SubDevices -----------------------------------
  const updateFormValue = (value, index, i) => {
    // console.log("update form value", value, index, i, e);
    const devices = [...deviceList];
    devices[index].subDevices[i].model = value;
    setDeviceList(devices);
  };
  const updateTempMin = (value, index, i) => {
    // console.log("update tempMin", value, index);
    const devices = [...deviceList];
    devices[index].subDevices[i].config.tempMin = parseInt(value);
    setDeviceList(devices);
  };
  const updateTempMax = (value, index, i) => {
    // console.log("update tempMax", value, index);
    const devices = [...deviceList];
    devices[index].subDevices[i].config.tempMax = parseInt(value);
    setDeviceList(devices);
  };
  const updateSpeedMin = (value, index, i) => {
    // console.log("update tempMin", value, index);
    const devices = [...deviceList];
    devices[index].subDevices[i].config.fanSpeedMin = value;
    setDeviceList(devices);
  };
  const updateSpeedMax = (value, index, i) => {
    // console.log("update tempMax", value, index);
    const devices = [...deviceList];
    devices[index].subDevices[i].config.fanSpeedMax = value;
    setDeviceList(devices);
  };

  // ----------------------------- remove SubDevices -----------------------------------
  const removeForm = (index, i) => {
    // console.log(index, i);
    const removeSub = [...deviceList];
    removeSub[index].subDevices.splice(i, 1);
    setDeviceList(removeSub);
  };

  const deleteHandler = () => {
    if (delClient) {
      onClose();
      axios
        .delete("/admin/delete_uncreated_ir", {
          data: {
            clientId: delClient,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result);
          setTrigger(true);
          setDelClient("");
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай устлаа!",
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "full", md: "full", lg: "7xl" }}
    >
      <BackButton />
      {isLoading ? (
        <HStack spacing={"10"}>
          <Skeleton
            w={{ base: "xs", md: "2xs" }}
            h={"10"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            w={"2xs"}
            h={"10"}
            borderRadius={"md"}
            display={{ base: "none", md: "none", lg: "flex" }}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            w={"2xs"}
            h={"10"}
            borderRadius={"md"}
            display={{ base: "none", md: "flex", lg: "flex" }}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : deviceList.length > 0 ? (
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          gap={6}
        >
          {deviceList.map((e, index) => (
            <VStack
              key={index}
              w={{ base: "xs", md: "md" }}
              bg={"#fff"}
              shadow={"md"}
              p={"4"}
              rounded={"md"}
              borderTopWidth={"medium"}
              borderTopColor={"#45A735"}
            >
              <Flex
                align={"center"}
                w="full"
                _before={{
                  content: '""',
                  borderBottom: "1px solid",
                  borderColor: "#45A735",
                  flexGrow: 0.3,
                  mr: { base: 4, md: 8 },
                }}
                _after={{
                  content: '""',
                  borderBottom: "1px solid",
                  borderColor: "#45A735",
                  flexGrow: 1.7,
                  ml: { base: 4, md: 8 },
                }}
              >
                <Text
                  color={"#000"}
                  fontSize={15}
                  fontWeight={"medium"}
                  letterSpacing={0.3}
                >
                  IR controller
                </Text>
              </Flex>
              <HStack
                w={"full"}
                bg={"#EFEFEF"}
                borderWidth={1}
                borderColor={"#EFEFEF"}
                borderRadius={"md"}
                py={"2"}
                px={"4"}
                justify={"space-between"}
              >
                <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                  clientId:
                </Text>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  {e.clientId}
                </Text>
              </HStack>
              <Stack direction={{ base: "column", md: "row" }} w={"full"}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Icon as={FiPhone} color="#45A735" />}
                  />
                  <Input
                    variant="filled"
                    placeholder="Утасны дугаар"
                    value={e.contractId}
                    onChange={(a) => handleChange(a.target.value, index)}
                    bg={"#FCFCFC"}
                    borderColor={"#EFEFEF"}
                    borderWidth={1}
                    maxLength={8}
                    fontSize={"14"}
                    fontWeight={"normal"}
                    type={"tel"}
                    w={"full"}
                    _hover={{
                      borderColor: "#45A735",
                      borderWidth: 1,
                    }}
                    _focus={{
                      borderColor: "#45A735",
                      borderWidth: 1,
                    }}
                  />
                </InputGroup>

                <Select
                  isDisabled={
                    e.contractId.length > 7 && e.houseList.length > 0
                      ? false
                      : true
                  }
                  placeholder="-Байшин сонгоно уу-"
                  onChange={(q) => handleSelect(q.target.value, index)}
                  multiple={false}
                  w={"full"}
                  fontSize={"14"}
                  fontWeight={"normal"}
                  _hover={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                  _focus={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                >
                  {e.houseList.map((q, index) => (
                    <option key={index} value={q._id}>
                      {q.name}
                    </option>
                  ))}
                </Select>
              </Stack>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={FiEdit} color="#45A735" />}
                />
                <Input
                  variant="filled"
                  placeholder="Төхөөрөмжинд нэр өгөх"
                  value={e.for}
                  onChange={(a) => nameHandler(a.target.value, index)}
                  bg={"#FCFCFC"}
                  borderColor={"#EFEFEF"}
                  borderWidth={1}
                  fontSize={"14"}
                  maxLength={30}
                  fontWeight={"normal"}
                  type={"text"}
                  w={"full"}
                  _hover={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                  _focus={{
                    borderColor: "#45A735",
                    borderWidth: 1,
                  }}
                />
              </InputGroup>
              <Button
                w={"full"}
                bg={"#ddd"}
                color={"#000"}
                fontSize={15}
                fontWeight={"normal"}
                _focus={{
                  outline: "none",
                }}
                onClick={() => AddForm(index)}
              >
                Air condition нэмэх
              </Button>
              {e.subDevices.map((subAC, i) => (
                // {subDevices
                //   .filter((a) => a.index === index)
                //   .map((subAC, i) => (
                <VStack
                  key={i}
                  w={"full"}
                  borderColor={"#EFEFEF"}
                  borderWidth={"thin"}
                  p={"4"}
                  rounded={"md"}
                  spacing={4}
                >
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    justify={"space-between"}
                    align={{ base: "flex-start", md: "center" }}
                    w={"full"}
                  >
                    <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
                      ModelId:
                    </Text>
                    <Select
                      placeholder="-AC model сонгоно уу-"
                      onChange={(q) =>
                        updateFormValue(q.target.value, index, i)
                      }
                      multiple={false}
                      w={"60"}
                      fontSize={"14"}
                      fontWeight={"normal"}
                      _hover={{
                        borderColor: "#45A735",
                        borderWidth: 1,
                      }}
                      _focus={{
                        borderColor: "#45A735",
                        borderWidth: 1,
                      }}
                    >
                      {modelIdList.map((q, index) => (
                        <option key={index} value={q}>
                          {q}
                        </option>
                      ))}
                    </Select>
                  </Stack>
                  <Divider />
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    justify={"space-between"}
                    align={{ base: "flex-start", md: "center" }}
                    w={"full"}
                  >
                    <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
                      Temp:
                    </Text>
                    <HStack spacing={4} alignSelf={"center"}>
                      <HStack pos={"relative"}>
                        <Input
                          placeholder="-"
                          w={"20"}
                          bg={"#fff"}
                          fontWeight={"normal"}
                          fontSize={15}
                          color={"#000"}
                          _hover={{
                            borderColor: "#45A735",
                          }}
                          _focus={{
                            outline: "none",
                            borderColor: "#45A735",
                          }}
                          value={subAC.config.tempMin}
                          onChange={(q) =>
                            updateTempMin(q.target.value, index, i)
                          }
                        />
                        <Text
                          color={"#000"}
                          fontSize={13}
                          fontWeight={"normal"}
                          pos={"absolute"}
                          top={-2}
                          bg={"#fff"}
                          px={"1"}
                        >
                          Min
                        </Text>
                      </HStack>
                      <Text>-</Text>
                      <HStack pos={"relative"}>
                        <Input
                          placeholder="-"
                          w={"20"}
                          bg={"#fff"}
                          fontWeight={"normal"}
                          fontSize={15}
                          color={"#000"}
                          _hover={{
                            borderColor: "#45A735",
                          }}
                          _focus={{
                            outline: "none",
                            borderColor: "#45A735",
                          }}
                          value={subAC.config.tempMax}
                          onChange={(q) =>
                            updateTempMax(q.target.value, index, i)
                          }
                        />
                        <Text
                          color={"#000"}
                          fontSize={13}
                          fontWeight={"normal"}
                          pos={"absolute"}
                          top={-2}
                          bg={"#fff"}
                          px={"1"}
                        >
                          Max
                        </Text>
                      </HStack>
                    </HStack>
                  </Stack>
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    justify={"space-between"}
                    align={{ base: "flex-start", md: "center" }}
                    w={"full"}
                  >
                    <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
                      FanSpeed:
                    </Text>
                    <HStack spacing={4} alignSelf={"center"}>
                      <HStack pos={"relative"}>
                        <Input
                          placeholder="-"
                          w={"20"}
                          bg={"#fff"}
                          fontWeight={"normal"}
                          fontSize={15}
                          color={"#000"}
                          _hover={{
                            borderColor: "#45A735",
                          }}
                          _focus={{
                            outline: "none",
                            borderColor: "#45A735",
                          }}
                          value={subAC.config.fanSpeedMin}
                          onChange={(q) =>
                            updateSpeedMin(q.target.value, index, i)
                          }
                        />
                        <Text
                          color={"#000"}
                          fontSize={13}
                          fontWeight={"normal"}
                          pos={"absolute"}
                          top={-2}
                          bg={"#fff"}
                          px={"1"}
                        >
                          Min
                        </Text>
                      </HStack>
                      <Text>-</Text>
                      <HStack pos={"relative"}>
                        <Input
                          placeholder="-"
                          w={"20"}
                          bg={"#fff"}
                          fontWeight={"normal"}
                          fontSize={15}
                          color={"#000"}
                          _hover={{
                            borderColor: "#45A735",
                          }}
                          _focus={{
                            outline: "none",
                            borderColor: "#45A735",
                          }}
                          value={subAC.config.fanSpeedMax}
                          onChange={(q) =>
                            updateSpeedMax(q.target.value, index, i)
                          }
                        />
                        <Text
                          color={"#000"}
                          fontSize={13}
                          fontWeight={"normal"}
                          pos={"absolute"}
                          top={-2}
                          bg={"#fff"}
                          px={"1"}
                        >
                          Max
                        </Text>
                      </HStack>
                    </HStack>
                  </Stack>
                  <Button
                    size={"sm"}
                    px={"6"}
                    alignSelf={"flex-end"}
                    _focus={{
                      outline: "none",
                    }}
                    onClick={() => removeForm(index, i)}
                  >
                    <Icon
                      as={AiOutlineMinusCircle}
                      w={5}
                      h={5}
                      color={"#ff0000"}
                    />
                    <Text
                      color={"#000"}
                      fontWeight={"normal"}
                      fontSize={15}
                      ml={1}
                    >
                      remove
                    </Text>
                  </Button>
                </VStack>
              ))}
              <HStack w={"full"}>
                <Button
                  bg={"#ff0000"}
                  w={"full"}
                  color={"#fff"}
                  fontSize={15}
                  fontWeight={"normal"}
                  _hover={{
                    bg: "#E20000",
                  }}
                  _focus={{
                    outline: "none",
                  }}
                  onClick={() => {
                    onOpen();
                    setDelClient(e.clientId);
                  }}
                >
                  Устгах
                </Button>
                <Button
                  bg={"#45A735"}
                  w={"full"}
                  color={"#fff"}
                  fontSize={15}
                  fontWeight={"normal"}
                  _hover={{
                    bg: "#5AB54B",
                  }}
                  _focus={{
                    outline: "none",
                  }}
                  onClick={() => handleSubmit(e, index)}
                  disabled={e.for && e.contractId && e.houseId ? false : true}
                >
                  Хадгалах
                </Button>
              </HStack>
            </VStack>
          ))}
        </Grid>
      ) : (
        <VStack
          justifyContent="center"
          alignItems="center"
          color="gray.600"
          w="full"
          pt={"52"}
        >
          <Icon as={FiInfo} w={7} h={7} />
          <Text fontSize={15}>Холбогдсон төхөөрөмж байхгүй байна.</Text>
        </VStack>
      )}

      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          <ModalBody>
            <Text color={"#000"} fontWeight={"normal"} fontSize={15}>
              {delClient}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={onClose}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={deleteHandler}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Ir;
