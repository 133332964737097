import React from "react";
import { Text, VStack } from "@chakra-ui/react";

const Instruction = () => {
  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "xs", md: "xl", lg: "8xl" }}
    >
      <Text>Зааварчилгаа</Text>
    </VStack>
  );
};

export default Instruction;
