import React, { useEffect, useState } from "react";
import {
  useToast,
  HStack,
  VStack,
  Skeleton,
  Text,
  Grid,
  GridItem,
  Divider,
  Switch,
  Image,
  Center,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  ModalFooter,
  Icon,
  ButtonGroup,
  IconButton,
  Input,
  Spinner,
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import { useHistory, useLocation } from "react-router-dom";
import axios from "../../axios";
import delIcon from "../../Images/delete.png";
import { FiEdit, FiInfo } from "react-icons/fi";
import { MdCheck, MdClose } from "react-icons/md";
import BackButton from "../../components/button/BackButton";

const UserDeviceList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [clientId, setClientId] = useState("");
  const [trigger, setTrigger] = useState(false);
  const [entity, setEntity] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [deviceIndex, setDeviceIndex] = useState("");
  const [originalModal, setOriginalModal] = useState(false);
  const [originalLoading, setOriginalLoading] = useState(false);
  const [originalUser, setOriginalUser] = useState([]);
  const [irData, setIrData] = useState({});
  const [irDeleteModal, setIrDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const token = Cookies.get("accessToken");
  const location = useLocation();
  const history = useHistory();
  const toast = useToast();
  const id = "test-id";

  const { isOpen, onOpen, onClose } = useDisclosure();

  // ----------------- get Device List --------------------
  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/admin/user_devices_new", {
        params: {
          houseId: location.state.e._id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log("device List =-==", result.data.data);
        setDeviceList(result.data.data);
        setIsLoading(false);
        setTrigger(false);
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
        setTrigger(false);
        setIsLoading(false);
      });
    return () => {
      cancel = true;
    };
  }, [trigger]);

  // ----------------- DElETE Device --------------------
  const deleteHandler = (value) => {
    // console.log("DElETE data ---", value);
    if (value.clientId) {
      setIrDeleteModal(false);
      axios
        .delete("/admin/delete_ir", {
          data: {
            clientId: value.clientId,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result);
          setTrigger(true);
          setIrData({});
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай устлаа!",
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    } else {
      // console.log(deleteData);
      const url =
        deleteData.deviceType === "newG4"
          ? "/v2/new_g4/"
          : "/admin/user_devices";
      axios
        .delete(url, {
          data: {
            clientId: value,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result);
          setTrigger(true);
          setClientId("");
          onClose();
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай устлаа!",
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const delModal = (e) => {
    // console.log("delModal", e);
    if (e.deviceType === "ir") {
      setIrData(e);
      setClientId("");
      setIrDeleteModal(true);
    } else {
      setClientId(e.clientId);
      setDeleteData(e);
      setIrData({});
      onOpen();
    }
  };

  const entityHandler = (id) => {
    if (entity) {
      setIsEditing(false);
      axios
        .post(
          "/admin/edit_entityname",
          {
            _id: id,
            entityName: entity,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result);
          setEntity("");
          setTrigger(true);
          if (!toast.isActive(id)) {
            toast({
              id,
              status: "success",
              duration: 2000,
              position: "top",
              description: "Амжилттай",
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const originalHandler = (id) => {
    // console.log("originalHandler", id);
    setOriginalModal(true);
    setOriginalLoading(true);
    axios
      .post(
        "/admin/get_shared_devices",
        {
          _id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        // console.log(result.data.data);
        setOriginalUser(result.data.data);
        setOriginalLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setOriginalLoading(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "xs", md: "full", lg: "8xl" }}
    >
      <BackButton />
      <Modal
        isCentered
        onClose={!irDeleteModal}
        isOpen={irDeleteModal}
        motionPreset="scale"
      >
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalCloseButton
            _focus={{ outline: "none" }}
            onClick={() => setIrDeleteModal(false)}
          />
          <ModalBody>{irData.clientId}</ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              _focus={{ outline: "none" }}
              onClick={() => setIrDeleteModal(false)}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              px={"8"}
              _focus={{ outline: "none" }}
              onClick={() => deleteHandler(irData)}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          <ModalBody>{clientId}</ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              _focus={{ outline: "none" }}
              onClick={onClose}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              px={"8"}
              _focus={{ outline: "none" }}
              onClick={() => deleteHandler(clientId)}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isLoading ? (
        <HStack spacing={"10"}>
          <Skeleton
            w={{ base: "80", md: "72", lg: "72" }}
            h={"xs"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            display={{ base: "none", md: "flex", lg: "flex" }}
            w={{ base: "80", md: "72", lg: "72" }}
            h={"xs"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            display={{ base: "none", md: "none", lg: "flex" }}
            w={{ base: "80", md: "72", lg: "72" }}
            h={"xs"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            display={{ base: "none", md: "none", lg: "flex" }}
            w={{ base: "80", md: "72", lg: "72" }}
            h={"xs"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : deviceList.length > 0 ? (
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap={{ base: 4, md: 6 }}
        >
          {deviceList.map((e, index) => (
            <GridItem
              key={e._id}
              w="xs"
              bg="#fff"
              boxShadow={"base"}
              py={"8"}
              px={"10"}
              borderRadius={"lg"}
              borderTopWidth={"medium"}
              borderColor={"#45A735"}
            >
              <VStack
                key={index}
                bg={"#fff"}
                borderRadius={"md"}
                align={"flex-start"}
                spacing={2}
              >
                <Stack w="full">
                  <HStack justify={"space-between"}>
                    <HStack align={"flex-start"}>
                      <Text fontSize={"sm"} color="#000" fontWeight={"normal"}>
                        clientId:
                      </Text>
                      <Text fontSize={"sm"} color="#000" fontWeight={"medium"}>
                        {e.clientId}
                      </Text>
                    </HStack>
                    {e.isOriginal === false ? null : (
                      <Image
                        boxSize="20px"
                        objectFit="contain"
                        color={"red"}
                        src={delIcon}
                        alt="delete"
                        onClick={() => delModal(e)}
                        cursor="pointer"
                        alignSelf={"flex-start"}
                      />
                    )}
                  </HStack>
                  <Divider />
                </Stack>
                <Stack w="full">
                  <HStack align={"flex-start"}>
                    <Text fontSize={"sm"} color="#000" fontWeight={"normal"}>
                      for:
                    </Text>
                    <Text fontSize={"sm"} color="#000" fontWeight={"medium"}>
                      {e.for}
                    </Text>
                  </HStack>
                  {e.deviceType === "switch" ||
                  e.deviceType === "RGB" ||
                  e.deviceType === "knx_switch" ||
                  e.deviceType === "zbSwitch" ||
                  e.deviceType === "Switchsc" ||
                  e.deviceType === "WLED" ? (
                    <HStack justify={"space-between"} align={"flex-start"}>
                      <HStack>
                        <Text
                          fontSize={"sm"}
                          color="#000"
                          fontWeight={"normal"}
                        >
                          status:
                        </Text>
                        <Text
                          fontSize={"sm"}
                          color={e.status === "active" ? "blue" : "#ff0000"}
                          fontWeight={"medium"}
                        >
                          {e.status}
                        </Text>
                      </HStack>
                      {e.status === "inActive" ? null : e.deviceType ===
                        "WLED" ? (
                        <HStack>
                          <Box
                            w={"5"}
                            h={"5"}
                            bg={e.data.color}
                            rounded={"full"}
                          />
                          <Switch
                            id="isDisabled"
                            isReadOnly
                            size={"md"}
                            colorScheme={"blue"}
                            // defaultChecked
                            isChecked={e.data.state === "on" ? true : false}
                          />
                        </HStack>
                      ) : (
                        <Switch
                          id="isDisabled"
                          isReadOnly
                          size={"md"}
                          colorScheme={"blue"}
                          // defaultChecked
                          isChecked={
                            e.deviceType === "knx_switch"
                              ? e.data === "false"
                                ? false
                                : true
                              : e.deviceType === "zbSwitch" ||
                                e.deviceType === "Switchsc"
                              ? e.data === "OFF"
                                ? false
                                : true
                              : e.switchStatus === "OFF"
                              ? false
                              : true
                          }
                        />
                      )}
                    </HStack>
                  ) : e.deviceType === "th" ||
                    e.deviceType === "zigbeeTh" ||
                    e.deviceType === "powr" ||
                    e.deviceType === "ir" ||
                    e.deviceType === "lth" ||
                    e.deviceType === "smtc" ||
                    e.deviceType === "lgt" ||
                    e.deviceType === "Co2" ||
                    e.deviceType === "So2" ||
                    e.deviceType === "No2" ||
                    e.deviceType === "Dust" ||
                    e.deviceType === "zbAqi" ? (
                    <HStack justify={"space-between"} align={"flex-start"}>
                      <HStack>
                        <Text
                          fontSize={"sm"}
                          color="#000"
                          fontWeight={"normal"}
                        >
                          status:
                        </Text>
                        <Text
                          fontSize={"sm"}
                          color={e.status === "active" ? "blue" : "#ff0000"}
                          fontWeight={"medium"}
                        >
                          {e.status}
                        </Text>
                      </HStack>
                      {e.status === "active" ? (
                        <HStack>
                          {e.data.Temperature ? (
                            <Text
                              fontSize={"sm"}
                              color={"#000"}
                              fontWeight={"medium"}
                            >
                              {e.data.Temperature}°C
                            </Text>
                          ) : e.data.temperature ? (
                            <Text
                              fontSize={"sm"}
                              color={"#000"}
                              fontWeight={"medium"}
                            >
                              {e.data.temperature}°C
                            </Text>
                          ) : null}
                          {e.data.Humidity ? (
                            <Text
                              fontSize={"sm"}
                              color={"#000"}
                              fontWeight={"medium"}
                            >
                              {e.data.Humidity}%
                            </Text>
                          ) : e.data.humidity ? (
                            <Text
                              fontSize={"sm"}
                              color={"#000"}
                              fontWeight={"medium"}
                            >
                              {e.data.humidity}%
                            </Text>
                          ) : null}
                          {e.data.ec ? (
                            <Text
                              fontSize={"sm"}
                              color={"#000"}
                              fontWeight={"medium"}
                            >
                              ec: {e.data.ec}
                            </Text>
                          ) : null}
                          {e.data.illumination ? (
                            <Text
                              fontSize={"sm"}
                              color={"#000"}
                              fontWeight={"medium"}
                            >
                              {e.data.illumination} LUX
                            </Text>
                          ) : null}
                          {e.data.Co2 || e.data.Co2 === 0 ? (
                            <Text
                              fontSize={"sm"}
                              color={"#000"}
                              fontWeight={"medium"}
                            >
                              {e.data.Co2}ppm
                            </Text>
                          ) : null}
                          {e.data.data || e.data.data === 0 ? (
                            <Text
                              fontSize={"sm"}
                              color={"#000"}
                              fontWeight={"medium"}
                            >
                              {e.data.data}
                              {e.deviceType === "Dust" ? "μg/m³" : "ppm"}
                            </Text>
                          ) : null}
                        </HStack>
                      ) : null}
                    </HStack>
                  ) : e.deviceType === "aqs" ? (
                    <HStack justify={"space-between"} align={"flex-start"}>
                      <HStack>
                        <Text
                          fontSize={"sm"}
                          color="#000"
                          fontWeight={"normal"}
                        >
                          status:
                        </Text>
                        <Text
                          fontSize={"sm"}
                          color={e.status === "active" ? "blue" : "#ff0000"}
                          fontWeight={"medium"}
                        >
                          {e.status}
                        </Text>
                      </HStack>
                      {e.status === "active" ? (
                        <HStack>
                          {e.data.Temperature ? (
                            <Text
                              fontSize={"sm"}
                              color={"#000"}
                              fontWeight={"medium"}
                            >
                              {e.data.Temperature}°C
                            </Text>
                          ) : null}
                          {e.data.CO2 ? (
                            <Text
                              fontSize={"sm"}
                              color={"#000"}
                              fontWeight={"medium"}
                            >
                              {e.data.CO2} ppm
                            </Text>
                          ) : null}
                        </HStack>
                      ) : null}
                    </HStack>
                  ) : e.deviceType === "co" ? (
                    <HStack justify={"space-between"} align={"flex-start"}>
                      <HStack>
                        <Text
                          fontSize={"sm"}
                          color="#000"
                          fontWeight={"normal"}
                        >
                          status:
                        </Text>
                        <Text
                          fontSize={"sm"}
                          color={e.status === "active" ? "blue" : "#ff0000"}
                          fontWeight={"medium"}
                        >
                          {e.status}
                        </Text>
                      </HStack>
                      {e.data.CarbonDioxide ? (
                        <Text
                          fontSize={"sm"}
                          color={"#000"}
                          fontWeight={"medium"}
                        >
                          {e.data.CarbonDioxide} ppm
                        </Text>
                      ) : null}
                    </HStack>
                  ) : e.deviceType === "zbridge" ||
                    e.deviceType === "Curtain" ? (
                    <HStack align={"flex-start"}>
                      <Text fontSize={"sm"} color="#000" fontWeight={"normal"}>
                        status:
                      </Text>
                      <Text
                        fontSize={"sm"}
                        color={e.status === "active" ? "blue" : "#ff0000"}
                        fontWeight={"medium"}
                      >
                        {e.status}
                      </Text>
                    </HStack>
                  ) : e.deviceType === "Contact" ||
                    e.deviceType === "Water" ||
                    e.deviceType === "Smoke" ||
                    e.deviceType === "Occupancy" ? (
                    <HStack justify={"space-between"} align={"flex-start"}>
                      <HStack>
                        <Text
                          fontSize={"sm"}
                          color="#000"
                          fontWeight={"normal"}
                        >
                          status:
                        </Text>
                        <Text
                          fontSize={"sm"}
                          color={e.status === "active" ? "blue" : "#ff0000"}
                          fontWeight={"medium"}
                        >
                          {e.status}
                        </Text>
                      </HStack>
                      {e.status === "inActive" ? null : e.deviceType ===
                        "Smoke" ? null : e.data ? (
                        <Text
                          fontSize={"sm"}
                          color={"#000"}
                          fontWeight={"medium"}
                        >
                          {e.data}
                        </Text>
                      ) : null}
                    </HStack>
                  ) : null}
                  <HStack align={"flex-start"}>
                    <Text fontSize={"sm"} color="#000" fontWeight={"normal"}>
                      username:
                    </Text>
                    <Text fontSize={"sm"} color="#000" fontWeight={"medium"}>
                      {location.state && location.state.username
                        ? location.state.username
                        : "-"}
                    </Text>
                  </HStack>
                  <HStack align={"flex-start"}>
                    <Text fontSize={"sm"} color="#000" fontWeight={"normal"}>
                      contractId:
                    </Text>
                    <Text fontSize={"sm"} color="#000" fontWeight={"medium"}>
                      {e.contractId}
                    </Text>
                  </HStack>
                  <HStack align={"flex-start"}>
                    <Text fontSize={"sm"} color="#000" fontWeight={"normal"}>
                      deviceType:
                    </Text>
                    <Text fontSize={"sm"} color="#000" fontWeight={"medium"}>
                      {e.deviceType}
                    </Text>
                  </HStack>
                  <HStack align={"center"}>
                    <Text fontSize={"sm"} color="#000" fontWeight={"normal"}>
                      entityName:
                    </Text>
                    {e.deviceType === "Co2" ||
                    e.deviceType === "So2" ||
                    e.deviceType === "No2" ||
                    e.deviceType === "Dust" ? (
                      e.isOriginal === true ? (
                        isEditing && index === deviceIndex ? (
                          <HStack>
                            <Input
                              placeholder={e.entityName}
                              type={"text"}
                              fontSize="sm"
                              color="#000"
                              fontWeight={"medium"}
                              size={"xs"}
                              rounded={"md"}
                              _hover={{
                                borderColor: "#45A735",
                              }}
                              _focus={{
                                outline: "none",
                                borderColor: "#45A735",
                              }}
                              value={entity}
                              onChange={(a) => setEntity(a.target.value)}
                            />
                            <ButtonGroup justifyContent="center">
                              <IconButton
                                size="xs"
                                _focus={{
                                  outline: "none",
                                }}
                                icon={<MdCheck />}
                                onClick={() => entityHandler(e._id)}
                                disabled={entity ? false : true}
                              />
                              <IconButton
                                size="xs"
                                _focus={{
                                  outline: "none",
                                }}
                                icon={<MdClose />}
                                onClick={() => setIsEditing(false)}
                              />
                            </ButtonGroup>
                          </HStack>
                        ) : (
                          <HStack>
                            <Text
                              fontSize={"sm"}
                              color="#000"
                              fontWeight={"medium"}
                            >
                              {e.entityName}
                            </Text>
                            <IconButton
                              size="xs"
                              _focus={{
                                outline: "none",
                              }}
                              icon={<FiEdit />}
                              onClick={() => {
                                setIsEditing(true);
                                setDeviceIndex(index);
                              }}
                            />
                          </HStack>
                        )
                      ) : (
                        <Text
                          fontSize={"sm"}
                          color="#000"
                          fontWeight={"medium"}
                        >
                          {e.entityName}
                        </Text>
                      )
                    ) : (
                      <Text fontSize={"sm"} color="#000" fontWeight={"medium"}>
                        {e.entityName}
                      </Text>
                    )}
                  </HStack>
                  <HStack align={"flex-start"}>
                    <Text fontSize={"sm"} color="#000" fontWeight={"normal"}>
                      house:
                    </Text>
                    <Text fontSize={"sm"} color="#000" fontWeight={"medium"}>
                      {e.house}
                    </Text>
                  </HStack>
                  <HStack align={"flex-start"}>
                    <Text fontSize={"sm"} color="#000" fontWeight={"normal"}>
                      room:
                    </Text>
                    <Text fontSize={"sm"} color="#000" fontWeight={"medium"}>
                      {e.room ? e.room : "-"}
                    </Text>
                  </HStack>
                  <HStack align={"flex-start"}>
                    <Text fontSize={"sm"} color="#000" fontWeight={"normal"}>
                      id:
                    </Text>
                    <Text fontSize={"sm"} color="#000" fontWeight={"medium"}>
                      {e._id}
                    </Text>
                  </HStack>
                  <HStack>
                    <Text fontSize={"sm"} color="#000" fontWeight={"normal"}>
                      isOriginal:
                    </Text>
                    <Text fontSize={"sm"} color="#000" fontWeight={"medium"}>
                      {e.isOriginal === true ? "Yes" : "No"}
                    </Text>
                  </HStack>
                  {e.isOriginal === true ? (
                    <HStack>
                      <Text fontSize={"sm"} color="#000" fontWeight={"normal"}>
                        shared:
                      </Text>
                      <Text fontSize={"sm"} color="#000" fontWeight={"medium"}>
                        {e.sharedCount}
                      </Text>
                    </HStack>
                  ) : null}
                  <HStack align={"flex-start"}>
                    <Text fontSize={"sm"} color="#000" fontWeight={"normal"}>
                      Цаг:
                    </Text>
                    <Text fontSize={"sm"} color="#000" fontWeight={"medium"}>
                      {e.atTime
                        ? e.atTime.slice(0, 10) + " / " + e.atTime.slice(11, 19)
                        : "-"}
                    </Text>
                  </HStack>
                </Stack>

                {/* <Divider /> */}
                {e.isOriginal === true ? (
                  <HStack justify={"space-between"} w="full">
                    <Center
                      bg={"#45A735"}
                      py={1}
                      w={"32"}
                      borderRadius={"md"}
                      cursor="pointer"
                      _hover={{
                        bg: "#5AB54B",
                      }}
                      onClick={() => {
                        e.deviceType === "zigbeeTh" ||
                        e.deviceType === "th" ||
                        e.deviceType === "smtc" ||
                        e.deviceType === "lgt" ||
                        e.deviceType === "lth" ||
                        e.deviceType === "co" ||
                        e.deviceType === "Co2" ||
                        e.deviceType === "So2" ||
                        e.deviceType === "No2" ||
                        e.deviceType === "Dust"
                          ? history.push("/User/find/sensor-state-log", {
                              e,
                              contractId: location.state.contractId,
                            })
                          : history.push("/User/find/switch-state-log", {
                              e,
                              contractId: location.state.contractId,
                            });
                      }}
                    >
                      <Text color={"#fff"} fontSize={"13"}>
                        State Log
                      </Text>
                    </Center>
                    {e.deviceType === "zbridge" ? (
                      <Center
                        bg={"#45A735"}
                        py={1}
                        w={"32"}
                        borderRadius={"md"}
                        cursor="pointer"
                        _hover={{
                          bg: "#5AB54B",
                        }}
                        onClick={() =>
                          history.push("/User/find/command", {
                            e,
                            contractId: location.state.contractId,
                          })
                        }
                      >
                        <Text color={"#fff"} fontSize={"13"}>
                          Command
                        </Text>
                      </Center>
                    ) : (
                      <Center
                        bg={"#45A735"}
                        py={1}
                        w={"32"}
                        borderRadius={"md"}
                        cursor="pointer"
                        _hover={{
                          bg: "#5AB54B",
                        }}
                        onClick={() => {
                          e.deviceType === "zigbeeTh" || e.deviceType === "th"
                            ? history.push("/User/find/zigbeeTh-activity-log", {
                                e,
                                contractId: location.state.contractId,
                              })
                            : history.push("/User/find/switch-activity-log", {
                                e,
                                contractId: location.state.contractId,
                              });
                        }}
                      >
                        <Text color={"#fff"} fontSize={"13"}>
                          Activity Log
                        </Text>
                      </Center>
                    )}
                  </HStack>
                ) : null}
                {e.deviceType === "th" ||
                e.deviceType === "zigbeeTh" ||
                e.deviceType === "zbAqi" ||
                e.deviceType === "lth" ||
                e.deviceType === "smtc" ||
                e.deviceType === "lgt" ||
                e.deviceType === "Co2" ||
                e.deviceType === "So2" ||
                e.deviceType === "No2" ||
                e.deviceType === "Dust" ? (
                  <Center
                    bg={"#45A735"}
                    py={1}
                    w={"full"}
                    borderRadius={"md"}
                    cursor="pointer"
                    _hover={{
                      bg: "#5AB54B",
                    }}
                    onClick={() => {
                      e.deviceType === "Co2" ||
                      e.deviceType === "So2" ||
                      e.deviceType === "No2" ||
                      e.deviceType === "Dust"
                        ? history.push("/User/find/Zlan-chart", {
                            e,
                            contractId: location.state.contractId,
                          })
                        : e.deviceType === "lgt"
                        ? history.push("/User/find/LoraLgt-chart", {
                            e,
                            contractId: location.state.contractId,
                          })
                        : e.deviceType === "lth"
                        ? history.push("/User/find/LoraTh-chart", {
                            e,
                            contractId: location.state.contractId,
                          })
                        : e.deviceType === "smtc"
                        ? history.push("/User/find/LoraSmtc-chart", {
                            e,
                            contractId: location.state.contractId,
                          })
                        : e.deviceType === "zbAqi"
                        ? history.push("/User/find/zbAqi-chart", {
                            e,
                            contractId: location.state.contractId,
                          })
                        : history.push("/User/find/chart", {
                            e,
                            contractId: location.state.contractId,
                          });
                    }}
                  >
                    <Text color={"#fff"} fontSize={"13"}>
                      Chart
                    </Text>
                  </Center>
                ) : null}
                <Center
                  bg={"#45A735"}
                  py={1}
                  w={"full"}
                  borderRadius={"md"}
                  cursor="pointer"
                  _hover={{
                    bg: "#5AB54B",
                  }}
                  onClick={() => {
                    e.isOriginal
                      ? e.deviceType === "Co2" ||
                        e.deviceType === "So2" ||
                        e.deviceType === "No2" ||
                        e.deviceType === "Dust"
                        ? history.push("/User/find/share-device-zlan", {
                            e,
                            contractId: location.state.contractId,
                          })
                        : history.push("/User/find/share-device", {
                            e,
                            contractId: location.state.contractId,
                          })
                      : originalHandler(e._id);
                  }}
                >
                  <Text color={"#fff"} fontSize={"13"}>
                    Share
                  </Text>
                </Center>
                {e.deviceType === "newG4" ? (
                  <Center
                    bg={"#45A735"}
                    py={1}
                    w={"full"}
                    borderRadius={"md"}
                    cursor="pointer"
                    _hover={{
                      bg: "#5AB54B",
                    }}
                    onClick={() =>
                      history.push("/User/find/g4-detail", {
                        data: e,
                      })
                    }
                  >
                    <Text color={"#fff"} fontSize={"13"}>
                      Дэлгэрэнгүй
                    </Text>
                  </Center>
                ) : null}
                {e.deviceType === "ir" ? (
                  <Center
                    bg={"#45A735"}
                    py={1}
                    w={"full"}
                    borderRadius={"md"}
                    cursor="pointer"
                    _hover={{
                      bg: "#5AB54B",
                    }}
                    onClick={() =>
                      history.push("/User/find/ir-detail", {
                        e,
                        contractId: location.state.contractId,
                      })
                    }
                  >
                    <Text color={"#fff"} fontSize={"13"}>
                      Add SubDevice
                    </Text>
                  </Center>
                ) : null}
              </VStack>
            </GridItem>
          ))}
        </Grid>
      ) : (
        <VStack
          justifyContent="center"
          alignItems="center"
          color="gray.600"
          w="full"
        >
          <Icon mt={"20"} as={FiInfo} w={7} h={7} />
          <Text fontSize={15}>Мэдээлэл олдсонгүй</Text>
        </VStack>
      )}
      {/* ------------------------ Original Modal ---------------------------- */}
      <Modal
        isCentered
        onClose={!originalModal}
        isOpen={originalModal}
        motionPreset="scale"
      >
        <ModalOverlay />
        <ModalContent w={{ base: "xs", md: "sm" }}>
          <ModalHeader>Төхөөрөмж эзэмшигч</ModalHeader>
          <ModalCloseButton
            _focus={{ outline: "none" }}
            onClick={() => setOriginalModal(false)}
          />
          <ModalBody w={"full"} alignItems={"center"} justifyContent={"center"}>
            {originalLoading ? (
              <Spinner size="md" color="green" ml={"40"} />
            ) : (
              originalUser.map((e, index) => (
                <VStack key={index}>
                  <HStack justify={"space-between"} w={"full"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      contractId:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.contractId}
                    </Text>
                  </HStack>
                  <Divider />
                  <HStack justify={"space-between"} w={"full"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      for:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.for}
                    </Text>
                  </HStack>
                  <HStack justify={"space-between"} w={"full"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      house:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.house}
                    </Text>
                  </HStack>
                  <HStack justify={"space-between"} w={"full"}>
                    <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                      isOriginal:
                    </Text>
                    <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                      {e.isOriginal ? "true" : "false"}
                    </Text>
                  </HStack>
                </VStack>
              ))
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              px={"8"}
              _focus={{ outline: "none" }}
              onClick={() => setOriginalModal(false)}
            >
              Хаах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* ------------------------ Original Modal ---------------------------- */}
    </VStack>
  );
};

export default UserDeviceList;
