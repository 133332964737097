import React from "react";
import {
  Divider,
  Flex,
  Grid,
  HStack,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import BackButton from "../../components/button/BackButton";

const AutomationDetail = () => {
  const location = useLocation();
  const color = useColorModeValue("green.200", "green.700");

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "full", md: "xl", lg: "8xl" }}
    >
      {" "}
      <BackButton />
      <VStack
        boxShadow={"base"}
        borderRadius={"md"}
        p={{ base: "2", md: "6" }}
        w={{ base: "full", md: "3xl", lg: "6xl" }}
        bg={"#fff"}
        spacing={{ base: 2, md: 4 }}
      >
        <VStack w={"full"}>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={"space-between"}
            w="full"
            borderWidth={1}
            borderColor={"#EFEFEF"}
            borderRadius={"md"}
            py={"2"}
            px={"6"}
            bg={"#F8F8F8"}
          >
            <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
              Автоматжуулалтын нэр:
            </Text>
            <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
              {location.state.e.name}
            </Text>
          </Stack>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={"space-between"}
            w="full"
            borderWidth={1}
            borderColor={"#EFEFEF"}
            borderRadius={"md"}
            py={"2"}
            px={"6"}
            bg={"#F8F8F8"}
          >
            <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
              Үүссэн цаг:
            </Text>
            <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
              {location.state.e.atTime.slice(0, 10) + " / "}
              {location.state.e.atTime.slice(11, 19)}
            </Text>
          </Stack>
        </VStack>
        <Flex
          align={"center"}
          w="full"
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: color,
            flexGrow: 0.3,
            mr: { base: 4, md: 8 },
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: color,
            flexGrow: 1.7,
            ml: { base: 4, md: 8 },
          }}
        >
          <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
            Нөхцөл
          </Text>
        </Flex>
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          gap={4}
          alignItems={"flex-start"}
          w={"full"}
        >
          {location.state.e.triggers.map((e, index) =>
            e.automationTriggerType === "time" ? (
              <VStack
                key={index}
                bg={"#F8F8F8"}
                borderRadius={"lg"}
                w={"full"}
                py={"4"}
                px={"6"}
                spacing={1}
              >
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    Төрөл:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e.automationTriggerType}
                  </Text>
                </HStack>
                <Divider />
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    Цаг:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e.condition}
                  </Text>
                </HStack>
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    Өдөр:
                  </Text>
                  <Text
                    color={"#000"}
                    fontSize={14}
                    fontWeight={"medium"}
                    align={"end"}
                  >
                    {e.days.join(", ")}
                  </Text>
                </HStack>
              </VStack>
            ) : e.automationTriggerType === "state" ? (
              <VStack
                key={index}
                bg={"#F8F8F8"}
                borderRadius={"lg"}
                w={"full"}
                py={"4"}
                px={"6"}
                spacing={1}
              >
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    Төрөл:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e.automationTriggerType}
                  </Text>
                </HStack>
                <Divider />
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    ID:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e._id}
                  </Text>
                </HStack>
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    Нэр:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e.device}
                  </Text>
                </HStack>
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    Утга:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e.condition}
                  </Text>
                </HStack>
              </VStack>
            ) : e.automationTriggerType === "sun" ? (
              <VStack
                key={index}
                bg={"#F8F8F8"}
                borderRadius={"lg"}
                w={"full"}
                py={"4"}
                px={"6"}
                spacing={1}
              >
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    Төрөл:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e.automationTriggerType}
                  </Text>
                </HStack>
                <Divider />
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    Утга:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e.condition}
                  </Text>
                </HStack>
              </VStack>
            ) : e.automationTriggerType === "temperature" ||
              e.automationTriggerType === "humidity" ? (
              <VStack
                key={index}
                bg={"#F8F8F8"}
                borderRadius={"lg"}
                w={"full"}
                py={"4"}
                px={"6"}
                spacing={1}
              >
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    Төрөл:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e.automationTriggerType}
                  </Text>
                </HStack>
                <Divider />
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    ID:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e._id}
                  </Text>
                </HStack>
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    Нэр:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e.device}
                  </Text>
                </HStack>
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    Утга:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e.compareType} {e.condition}
                  </Text>
                </HStack>
              </VStack>
            ) : e.automationTriggerType === "co" ? (
              <VStack
                key={index}
                bg={"#F8F8F8"}
                borderRadius={"lg"}
                w={"full"}
                py={"4"}
                px={"6"}
                spacing={1}
              >
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    Төрөл:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e.automationTriggerType}
                  </Text>
                </HStack>
                <Divider />
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    ID:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e._id}
                  </Text>
                </HStack>
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    Нэр:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e.device}
                  </Text>
                </HStack>
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    Утга:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e.condition}
                  </Text>
                </HStack>
              </VStack>
            ) : e.automationTriggerType === "Smoke" ? (
              <VStack
                key={index}
                bg={"#F8F8F8"}
                borderRadius={"lg"}
                w={"full"}
                py={"4"}
                px={"6"}
                spacing={1}
              >
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    Төрөл:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e.automationTriggerType}
                  </Text>
                </HStack>
                <Divider />
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    ID:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e._id}
                  </Text>
                </HStack>
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    Нэр:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e.device}
                  </Text>
                </HStack>
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text color={"#000"} fontSize={14} fontWeight={"normal"}>
                    Утга:
                  </Text>
                  <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                    {e.condition === 1 ? "Утаа мэдрэх үед" : "-"}
                  </Text>
                </HStack>
              </VStack>
            ) : null
          )}
        </Grid>
        <VStack
          borderWidth={1}
          borderColor={"#EFEFEF"}
          w="full"
          borderRadius={"md"}
          bg={"#fff"}
          p={{ base: "2", md: "6" }}
          spacing={{ base: 2, md: 4 }}
        >
          <Flex
            align={"center"}
            w="full"
            _before={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: color,
              flexGrow: 0.3,
              mr: { base: 4, md: 8 },
            }}
            _after={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: color,
              flexGrow: 1.7,
              ml: { base: 4, md: 8 },
            }}
          >
            <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
              Үйлдэл
            </Text>
          </Flex>
          <TableContainer w={{ base: "72", md: "full" }}>
            <Table>
              <Thead bg={"#F8F8F8"}>
                <Tr>
                  <Th color={"#000"} fontSize={14} fontWeight={"medium"}>
                    ID
                  </Th>
                  <Th color={"#000"} fontSize={12} fontWeight={"medium"}>
                    Нэр
                  </Th>
                  <Th
                    color={"#000"}
                    fontSize={12}
                    fontWeight={"medium"}
                    isNumeric
                  >
                    комманд
                  </Th>
                </Tr>
              </Thead>
              {location.state.e.actions.map((e, index) => (
                <Tbody key={index} bg={index % 2 == 0 ? "white" : "#FCFCFC"}>
                  {e.actionType === "switch" ||
                  e.actionType === "knx_switch" ||
                  e.actionType === "Switchsc" ||
                  e.actionType === "zbSwitch" ||
                  e.actionType === "Curtain" ? (
                    <Tr>
                      <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                        {e._id}
                      </Td>
                      <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                        {e.device}
                      </Td>
                      <Td
                        color={"#000"}
                        fontSize={14}
                        fontWeight={"normal"}
                        isNumeric
                      >
                        {e.command}
                      </Td>
                    </Tr>
                  ) : e.actionType === "delay" ? (
                    <Tr>
                      <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                        -
                      </Td>
                      <Td color={"#000"} fontSize={14} fontWeight={"normal"}>
                        {e.actionType}
                      </Td>
                      <Td
                        color={"#000"}
                        fontSize={14}
                        fontWeight={"normal"}
                        isNumeric
                        align="right"
                      >
                        {e.time}&nbsp;цаг&nbsp;{e.minute}&nbsp;минут&nbsp;
                        {e.second}&nbsp;секунт
                      </Td>
                    </Tr>
                  ) : null}
                </Tbody>
              ))}
            </Table>
          </TableContainer>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default AutomationDetail;
