import React, { useEffect, useState, useRef } from "react";
import {
  Flex,
  Spinner,
  IconButton,
  Tooltip,
  useBreakpointValue,
  VStack,
  Text,
  Icon,
  Center,
  Input,
  Button,
  Stack,
} from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import { Chart } from "chart.js";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import Cookies from "js-cookie";
import axios from "../../axios";
import { FiInfo } from "react-icons/fi";
import { GrPowerReset } from "react-icons/gr";
import moment from "moment-timezone";
import BackButton from "../button/BackButton";

Chart.register(zoomPlugin);
const ThChart = () => {
  let location = useLocation();
  let history = useHistory();
  const [datas, setDatas] = useState([]);
  const [spin, setSpin] = useState(false);
  const [startDay, setStartDay] = useState(
    moment().tz("Asia/Ulaanbaatar").format("YYYY-MM-DD")
  );
  const [endDay, setEndDay] = useState(
    moment().tz("Asia/Ulaanbaatar").format("YYYY-MM-DD")
  );
  const token = Cookies.get("accessToken");

  const variant = useBreakpointValue({ base: false, md: true });

  //Chart data, option set
  const labelAdjusted = datas.map((el) => {
    return (
      el.time.toString().slice(5, 10) + "--" + el.time.toString().slice(11, 16)
    );
  });

  const data = {
    labels: labelAdjusted.map((el) => el.split("--")),
    datasets: [
      {
        label: "Температур",
        fill: false,
        borderColor: "#ff0000",
        backgroundColor: "#ff0000",
        borderCapStyle: "butt",
        borderJoinStyle: "#ff0000",
        pointBorderColor: "#ff0000",
        pointBackgroundColor: "#ff0000",
        borderWidth: 1,
        pointBorderWidth: 1,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: "#ff0000",
        pointHoverBorderColor: "#ff0000",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        data: datas.map((el) => el.temperature),
        spanGaps: false,
        tension: 0.4,
      },

      {
        label: "Чийгшил",
        fill: false,
        borderColor: "#0074D9",
        backgroundColor: "#0074D9",
        borderCapStyle: "butt",
        borderJoinStyle: "#0074D9",
        pointBorderColor: "#0074D9",
        pointBackgroundColor: "#0074D9",
        borderWidth: 1,
        pointBorderWidth: 1,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: "#0074D9",
        pointHoverBorderColor: "#0074D9",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        data: datas.map((el) => el.humidity),
        spanGaps: false,
        tension: 0.4,
      },
    ],
  };

  const options = {
    spanGaps: true,
    animation: variant,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: false,
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: 6,
          callback: function (val, index) {
            return index != 0 ? this.getLabelForValue(val) : "";
          },
          color: "#707070",
        },
        grid: {
          // borderDash: [3, 3],
          color: "#DBDBDB",
          drawBorder: false,
          drawTicks: false,
        },
      },
      y: {
        display: variant,
        beginAtZero: false,
        grid: {
          borderDash: [3, 3],
          color: "#DBDBDB",
          drawTicks: false,
          drawBorder: false,
        },
        ticks: {
          // maxTicksLimit: 3,
          display: true,
          mirror: true,
          labelOffset: -10,
          padding: 5,
          count: 3,
          color: "#707070",
          callback: function (val, index) {
            return index === 0
              ? "Min " + this.getLabelForValue(val)
              : index === 1
              ? "Avg " + this.getLabelForValue(val)
              : "Max " + this.getLabelForValue(val);
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const def1 =
              context.dataset.label === "Чийгшил"
                ? "Чийгшил: "
                : context.dataset.label === "Температур"
                ? "Температур: "
                : null;

            const def2 =
              context.dataset.label === "Чийгшил"
                ? "%"
                : context.dataset.label === "Температур"
                ? "°C"
                : null;

            return def1 + context.raw + def2;
          },
        },
      },
      title: {
        display: true,
      },
      legend: {
        display: true,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          color: "#707070",
        },
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          mode: "x",
          pinch: {
            enabled: true,
          },
        },
        pan: {
          enabled: true,
          mode: "x",
        },
      },
      chartAreaBorder: {
        borderColor: "#DBDBDB",
        borderWidth: 1,
      },
    },
  };

  const chartAreaBorder = {
    id: "chartAreaBorder",
    beforeDatasetsDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { top, bottom, left, right, width, height },
      } = chart;
      ctx.save();
      ctx.beginPath();
      ctx.strokeStyle = options.borderColor;
      ctx.lineWidth = options.borderWidth;
      ctx.moveTo(right, top);
      ctx.lineTo(right, bottom);
      ctx.closePath();
      ctx.stroke();
    },
  };

  //Reset zoom chart usage
  const chartRef = useRef(null);
  const resetZoom = () => {
    if (datas.length > 0) {
      chartRef.current.resetZoom();
    }
  };

  useEffect(() => {
    if (location.state.e._id) {
      let source = axios.CancelToken.source();
      let cancel = false;
      setSpin(true);
      axios
        .get("/v2/log/chart_data", {
          params: {
            _id: location.state.e._id,
            startsAtDate: startDay + "T00:00:00",
            endsAtDate: endDay + "T23:59:00",
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          // console.log(result.data.data);
          // setDatas(result.data.data.reverse());
          setDatas(result.data.data.reverse());
          setSpin(false);
        })
        .catch((err) => {
          // console.log(err);
          setSpin(false);
        });
      return () => {
        cancel = true;
      };
    }
  }, []);

  const dayHandler = () => {
    if (startDay && endDay) {
      setSpin(true);
      axios
        .get("/v2/log/chart_data", {
          params: {
            _id: location.state.e._id,
            startsAtDate: startDay + "T00:00:00",
            endsAtDate: endDay + "T23:59:00",
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result.data.data);
          setDatas(result.data.data.reverse());
          setSpin(false);
        })
        .catch((err) => {
          // console.log(err);
          setSpin(false);
        });
    }
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      // bg={"red"}
      mb={"10"}
      // w={{ base: "xs", md: "xl", lg: "8xl" }}
      w={{ base: "full", md: "full", lg: "8xl" }}
    >
      <BackButton />
      <Flex
        flexDir="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        w={"full"}
        maxH="36rem"
        minH={96}
        px={{ base: 4, md: 10 }}
        py={{ base: 6, md: 6 }}
        rounded="md"
        boxShadow="rgba(99, 99, 99, 0.1) 0px 1px 6px 2px"
        bg={"#FFF"}
      >
        <Stack
          direction={{ base: "column", md: "row" }}
          justify={"space-between"}
          alignItems="center"
          w={"full"}
        >
          <Stack
            direction={{ base: "column", md: "row" }}
            w={{ base: "full", md: "min-content" }}
            align={"flex-end"}
          >
            <VStack spacing={0} w={"full"} align={"flex-start"}>
              <Text color={"#000"} fontSize={14} fontWeight="normal">
                Эхлэх
              </Text>
              <Input
                type={"date"}
                value={startDay}
                onChange={(e) => setStartDay(e.target.value)}
                bg={"#FCFCFC"}
                borderColor={"#EFEFEF"}
                borderWidth={1}
                w={"full"}
                size={"sm"}
                rounded={"md"}
                fontSize={14}
                _hover={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
                _focus={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
              />
            </VStack>
            <VStack spacing={0} w={"full"} align={"flex-start"}>
              <Text color={"#000"} fontSize={14} fontWeight="normal">
                Дуусах
              </Text>
              <Input
                type={"date"}
                value={endDay}
                onChange={(e) => setEndDay(e.target.value)}
                bg={"#FCFCFC"}
                borderColor={"#EFEFEF"}
                borderWidth={1}
                w={"full"}
                size={"sm"}
                rounded={"md"}
                fontSize={14}
                _hover={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
                _focus={{
                  borderColor: "#45A735",
                  borderWidth: 1,
                }}
              />
            </VStack>
            <Button
              w={"full"}
              size={"sm"}
              bg={"#45A735"}
              _hover={{
                bg: "#5AB54B",
              }}
              onClick={dayHandler}
              _focus={{
                outline: "none",
              }}
            >
              <Text color={"#fff"} fontSize={14} fontWeight="normal">
                Харах
              </Text>
            </Button>
          </Stack>
          <Tooltip label="Шинэчлэх" placement="right" hasArrow>
            <IconButton
              aria-label="Reset"
              icon={<GrPowerReset />}
              colorScheme="gray"
              onClick={resetZoom}
              w={{ base: "full", md: "min-content" }}
              size="sm"
              _focus={{ outline: "none" }}
              disabled={datas.length > 0 ? false : true}
            />
          </Tooltip>
        </Stack>

        {spin ? (
          <Center w={"full"}>
            <Spinner size="lg" color="green" mt={{ base: "20", md: "32" }} />
          </Center>
        ) : datas.length > 0 &&
          datas.some((e) => e.temperature != null || e.humidity != null) ? (
          <Line
            data={data}
            options={options}
            ref={chartRef}
            plugins={[chartAreaBorder]}
            style={{
              maxHeight: "26rem",
            }}
          />
        ) : (
          <VStack
            justifyContent="center"
            alignItems="center"
            color="gray.600"
            w="full"
          >
            <Icon mt={"20"} as={FiInfo} w={7} h={7} />
            <Text fontSize={15}>Мэдээлэл олдсонгүй</Text>
          </VStack>
        )}
      </Flex>
    </VStack>
  );
};

export default ThChart;

// import React, { useEffect, useState, useRef } from "react";
// import {
//   Flex,
//   Spinner,
//   IconButton,
//   Tooltip,
//   useBreakpointValue,
//   VStack,
//   Text,
//   Icon,
//   Center,
//   Input,
//   Button,
//   Stack,
// } from "@chakra-ui/react";
// import { useHistory, useLocation } from "react-router-dom";
// import Cookies from "js-cookie";
// import axios from "../../axios";
// import { FiInfo } from "react-icons/fi";
// import { GrPowerReset } from "react-icons/gr";
// import moment from "moment-timezone";
// import BackButton from "../button/BackButton";
// import ReactECharts from "echarts-for-react";

// const ThChart = () => {
//   let location = useLocation();
//   let history = useHistory();
//   const [datas, setDatas] = useState([]);
//   const [spin, setSpin] = useState(false);
//   const [startDay, setStartDay] = useState(
//     moment().tz("Asia/Ulaanbaatar").format("YYYY-MM-DD")
//   );
//   const [endDay, setEndDay] = useState(
//     moment().tz("Asia/Ulaanbaatar").format("YYYY-MM-DD")
//   );
//   const token = Cookies.get("accessToken");

//   //Reset zoom chart usage
//   const chartRef = useRef(null);
//   // const resetZoom = () => {
//   //   if (datas.length > 0) {
//   //     chartRef.current.resetZoom();
//   //   }
//   // };

//   useEffect(() => {
//     if (location.state.e._id) {
//       let source = axios.CancelToken.source();
//       let cancel = false;
//       setSpin(true);
//       axios
//         .get("/v2/log/chart_data", {
//           params: {
//             _id: location.state.e._id,
//             startsAtDate: startDay + "T00:00:00",
//             endsAtDate: endDay + "T23:59:00",
//           },
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//           cancelToken: source.token,
//         })
//         .then((result) => {
//           if (cancel) return;
//           // console.log(result.data.data);
//           // setDatas(result.data.data.reverse());
//           setDatas(result.data.data.reverse());
//           setSpin(false);
//         })
//         .catch((err) => {
//           // console.log(err);
//           setSpin(false);
//         });
//       return () => {
//         cancel = true;
//       };
//     }
//   }, []);

//   const dayHandler = () => {
//     if (startDay && endDay) {
//       setSpin(true);
//       axios
//         .get("/v2/log/chart_data", {
//           params: {
//             _id: location.state.e._id,
//             startsAtDate: startDay + "T00:00:00",
//             endsAtDate: endDay + "T23:59:00",
//           },
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         })
//         .then((result) => {
//           // console.log(result.data.data);
//           setDatas(result.data.data.reverse());
//           setSpin(false);
//         })
//         .catch((err) => {
//           // console.log(err);
//           setSpin(false);
//         });
//     }
//   };

//   const temperatures = datas.map((data) => data.temperature);
//   const humidities = datas.map((data) => data.humidity);

//   const maxTemperature = Math.max(...temperatures);
//   const minTemperature = Math.min(...temperatures);
//   const avgTemperature =
//     temperatures.reduce((acc, val) => acc + val, 0) / temperatures.length;

//   const maxHumidity = Math.max(...humidities);
//   const minHumidity = Math.min(...humidities);
//   const avgHumidity =
//     humidities.reduce((acc, val) => acc + val, 0) / humidities.length;

//   return (
//     <VStack
//       align={"flex-start"}
//       spacing={"6"}
//       // bg={"red"}
//       mb={"10"}
//       // w={{ base: "xs", md: "xl", lg: "8xl" }}
//       w={{ base: "full", md: "full", lg: "8xl" }}
//     >
//       <BackButton />
//       <Flex
//         flexDir="column"
//         justifyContent="flex-start"
//         alignItems="flex-start"
//         w={"full"}
//         maxH="36rem"
//         minH={96}
//         px={{ base: 4, md: 10 }}
//         py={{ base: 6, md: 6 }}
//         rounded="md"
//         boxShadow="rgba(99, 99, 99, 0.1) 0px 1px 6px 2px"
//         bg={"#FFF"}
//       >
//         <Stack
//           direction={{ base: "column", md: "row" }}
//           justify={"space-between"}
//           alignItems="center"
//           w={"full"}
//         >
//           <Stack
//             direction={{ base: "column", md: "row" }}
//             w={{ base: "full", md: "min-content" }}
//             align={"flex-end"}
//           >
//             <VStack spacing={0} w={"full"} align={"flex-start"}>
//               <Text color={"#000"} fontSize={14} fontWeight="normal">
//                 Эхлэх
//               </Text>
//               <Input
//                 type={"date"}
//                 value={startDay}
//                 onChange={(e) => setStartDay(e.target.value)}
//                 bg={"#FCFCFC"}
//                 borderColor={"#EFEFEF"}
//                 borderWidth={1}
//                 w={"full"}
//                 size={"sm"}
//                 rounded={"md"}
//                 fontSize={14}
//                 _hover={{
//                   borderColor: "#45A735",
//                   borderWidth: 1,
//                 }}
//                 _focus={{
//                   borderColor: "#45A735",
//                   borderWidth: 1,
//                 }}
//               />
//             </VStack>
//             <VStack spacing={0} w={"full"} align={"flex-start"}>
//               <Text color={"#000"} fontSize={14} fontWeight="normal">
//                 Дуусах
//               </Text>
//               <Input
//                 type={"date"}
//                 value={endDay}
//                 onChange={(e) => setEndDay(e.target.value)}
//                 bg={"#FCFCFC"}
//                 borderColor={"#EFEFEF"}
//                 borderWidth={1}
//                 w={"full"}
//                 size={"sm"}
//                 rounded={"md"}
//                 fontSize={14}
//                 _hover={{
//                   borderColor: "#45A735",
//                   borderWidth: 1,
//                 }}
//                 _focus={{
//                   borderColor: "#45A735",
//                   borderWidth: 1,
//                 }}
//               />
//             </VStack>
//             <Button
//               w={"full"}
//               size={"sm"}
//               bg={"#45A735"}
//               _hover={{
//                 bg: "#5AB54B",
//               }}
//               onClick={dayHandler}
//               _focus={{
//                 outline: "none",
//               }}
//             >
//               <Text color={"#fff"} fontSize={14} fontWeight="normal">
//                 Харах
//               </Text>
//             </Button>
//           </Stack>
//           {/* <Tooltip label="Шинэчлэх" placement="right" hasArrow>
//             <IconButton
//               aria-label="Reset"
//               icon={<GrPowerReset />}
//               colorScheme="gray"
//               onClick={resetZoom}
//               w={{ base: "full", md: "min-content" }}
//               size="sm"
//               _focus={{ outline: "none" }}
//               disabled={datas.length > 0 ? false : true}
//             />
//           </Tooltip> */}
//         </Stack>

//         {spin ? (
//           <Center w={"full"}>
//             <Spinner size="lg" color="green" mt={{ base: "20", md: "32" }} />
//           </Center>
//         ) : datas.length > 0 &&
//           datas.some((e) => e.temperature != null || e.humidity != null) ? (
//           // <div ref={chartRef} style={{ width: "100%", height: "400px" }}></div>
//           <ReactECharts
//             style={{ width: "100%", height: "400px" }}
//             option={{
//               title: {
//                 text: "TH chart",
//                 left: "1%",
//               },
//               legend: {
//                 data: ["Temperature", "Humidity"],
//               },
//               // tooltip: {
//               //   trigger: "axis",
//               //   formatter: '{b}km : {c}°C',
//               //   axisPointer: {
//               //     type: "cross",
//               //   },
//               // },
//               tooltip: {
//                 trigger: "axis",
//                 formatter: function (params) {
//                   var tooltipText = params[0].axisValueLabel + "<br/>";
//                   params.forEach(function (item) {
//                     tooltipText +=
//                       item.seriesName +
//                       ": " +
//                       item.value +
//                       (item.seriesName === "Humidity" ? "%" : "°C") +
//                       "<br/>";
//                   });
//                   return tooltipText;
//                 },
//                 axisPointer: {
//                   type: "cross",
//                 },
//               },

//               grid: {
//                 left: "5%",
//                 right: "15%",
//                 bottom: "10%",
//                 containLabel: true,
//               },
//               xAxis: {
//                 // type: "category",
//                 // boundaryGap: false,
//                 data: datas.map(
//                   (data) =>
//                     data.time.toString().slice(5, 10) +
//                     "/" +
//                     data.time.toString().slice(11, 16)
//                 ),
//               },
//               yAxis: {
//                 type: "value",
//                 position: "left",
//                 alignTicks: true,
//                 axisLine: {
//                   show: true,
//                 },
//                 axisLabel: {
//                   formatter: function (value) {
//                     return (
//                       value +
//                       "°C\nMax: " +
//                       maxTemperature +
//                       "°C\nMin: " +
//                       minTemperature +
//                       "°C\nAvg: " +
//                       avgTemperature +
//                       "°C"
//                     );
//                   },
//                 },
//               },
//               // yAxis: {
//               //   type: "value",
//               //   position: "left",
//               //   alignTicks: true,
//               //   axisLine: {
//               //     show: true,
//               //   },
//               //   axisLabel: {
//               //     formatter: "{value} °C",
//               //   },
//               // },
//               toolbox: {
//                 right: 10,
//                 feature: {
//                   restore: {},
//                   saveAsImage: {},
//                 },
//               },
//               dataZoom: [
//                 {
//                   startValue: "2014-06-01",
//                 },
//                 {
//                   type: "inside",
//                 },
//               ],
//               visualMap: [
//                 {
//                   top: 50,
//                   right: 10,
//                   seriesIndex: 0, // associate with the first series (temperature)
//                   pieces: [
//                     {
//                       gt: 0,
//                       lte: 20,
//                       color: "#00A2FF",
//                     },
//                     {
//                       gt: 20,
//                       lte: 24,
//                       color: "#45A735",
//                     },
//                     {
//                       gt: 24,
//                       lte: 100,
//                       color: "#ff0000",
//                     },
//                   ],
//                   outOfRange: {
//                     color: "#999",
//                   },
//                 },
//                 {
//                   top: 50,
//                   right: 10,
//                   seriesIndex: 1, // associate with the second series (humidity)
//                   pieces: [
//                     {
//                       gt: 0,
//                       lte: 20,
//                       color: "#008000",
//                     },
//                     {
//                       gt: 20,
//                       lte: 24,
//                       color: "#FFFF00",
//                     },
//                     {
//                       gt: 24,
//                       lte: 100,
//                       color: "#00A2FF",
//                     },
//                   ],
//                   outOfRange: {
//                     color: "#999",
//                   },
//                 },
//               ],
//               series: [
//                 {
//                   name: "Temperature",
//                   type: "line",
//                   stack: "Total",
//                   showSymbol: false,
//                   data: datas.map((data) => data.temperature),
//                   markLine: {
//                     silent: true,
//                     lineStyle: {
//                       color: "#333",
//                     },
//                     // data: [
//                     //   {
//                     //     yAxis: 10,
//                     //   },
//                     //   {
//                     //     yAxis: 50,
//                     //   },
//                     //   {
//                     //     yAxis: 100,
//                     //   },
//                     // ],
//                   },
//                 },
//                 {
//                   name: "Humidity",
//                   type: "line",
//                   stack: "Total",
//                   showSymbol: false,
//                   type: "line", // Assuming line chart for humidity, you can change it if needed
//                   data: datas.map((data) => data.humidity), // Assuming you have a 'humidity' property in your data
//                   markLine: {
//                     silent: true,
//                     lineStyle: {
//                       color: "#000",
//                     },
//                     // Add your markLine data for humidity if needed
//                   },
//                 },
//               ],
//             }}
//           />
//         ) : (
//           <VStack
//             justifyContent="center"
//             alignItems="center"
//             color="gray.600"
//             w="full"
//           >
//             <Icon mt={"20"} as={FiInfo} w={7} h={7} />
//             <Text fontSize={15}>Мэдээлэл олдсонгүй</Text>
//           </VStack>
//         )}
//       </Flex>
//     </VStack>
//   );
// };

// export default ThChart;
