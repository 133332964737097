import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Select,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "../../../axios";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import BackButton from "../../../components/button/BackButton";

const AddmA = () => {
  const history = useHistory();
  const [clientId, setClientId] = useState("");
  const [contractId, setContractId] = useState("");
  const [house, setHouse] = useState([]);
  const [selectHouse, setSelectHouse] = useState();
  const [selectAi, setSelectAi] = useState([]);
  const [ai, setAi] = useState({
    AI1: "",
    AI2: "",
    AI3: "",
    AI4: "",
    AI5: "",
    AI6: "",
    AI7: "",
    AI8: "",
  });
  const [data, setData] = useState();
  const toast = useToast();
  const id = "test-toast";
  const token = Cookies.get("accessToken");

  useEffect(() => {
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get(
        "/admin/zlan_types",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
      .then((result) => {
        // console.log(result.data.data);
        setData(result.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  const handleClientId = (value) => {
    // console.log(value);
    setClientId(value);
  };

  const handleContractId = (value) => {
    setContractId(value);
    // --- get House ---
    if (value.length === 8) {
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("admin/device_houses", {
          params: { username: value },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          // console.log(result.data.data);
          setHouse(result.data.data);
        })
        .catch((err) => {
          // console.log("error", err);
        });
      return () => {
        cancel = true;
      };
    } else {
      setHouse([]);
    }
  };

  const handleHouse = (value) => {
    // console.log("house", value);
    setSelectHouse(value);
  };

  const incSelect = () => {
    setSelectAi((prevState) => {
      return [...prevState, ""];
    });
  };

  const handleSelect = (e, i) => {
    // console.log(e);
    const SelectData = { ...ai };
    i === 0
      ? (SelectData.AI1 = e)
      : i === 1
      ? (SelectData.AI2 = e)
      : i === 2
      ? (SelectData.AI3 = e)
      : i === 3
      ? (SelectData.AI4 = e)
      : i === 4
      ? (SelectData.AI5 = e)
      : i === 5
      ? (SelectData.AI6 = e)
      : i === 6
      ? (SelectData.AI7 = e)
      : (SelectData.AI8 = e);
    setAi(SelectData);
  };

  const handleSubmit = () => {
    const saveData = {
      clientId: clientId,
      contractId: contractId,
      modules: ai,
      house: {
        name: selectHouse,
        _id: house.find((e) => e.name === selectHouse)._id,
      },
    };
    Object.keys(saveData.modules).forEach((key) => {
      if (saveData.modules[key] === "") {
        delete saveData.modules[key];
      }
    });
    // console.log("save", saveData);
    axios
      .post("/admin/zlan_device", saveData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        // console.log(result);
        if (!toast.isActive(id)) {
          toast({
            id,
            status: "success",
            duration: 2000,
            position: "top",
            description: "Амжилттай",
          });
        }
        history.push("dashboard");
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            status: "error",
            duration: 2000,
            position: "top",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "full", md: "full", lg: "8xl" }}
    >
      <BackButton />

      <VStack
        w={{ base: "full", md: "md" }}
        spacing={"2"}
        bg={"#fff"}
        rounded={"lg"}
        py={"4"}
        px={"8"}
        boxShadow={"md"}
      >
        <Stack
          direction={{ base: "column", md: "row" }}
          justify={"space-between"}
          w={"full"}
          spacing={{ base: 0, md: 2 }}
        >
          <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
            clientId:
          </Text>
          <Input
            variant="filled"
            placeholder="client ID"
            bg={"#FCFCFC"}
            w={"64"}
            fontSize={14}
            borderColor={"#EFEFEF"}
            borderWidth={1}
            _hover={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            _focus={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            value={clientId}
            onChange={(a) => handleClientId(a.target.value)}
          />
        </Stack>
        <Stack
          direction={{ base: "column", md: "row" }}
          justify={"space-between"}
          w={"full"}
          spacing={{ base: 0, md: 2 }}
        >
          <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
            contractId:
          </Text>
          <Input
            variant="filled"
            placeholder="contract ID"
            bg={"#FCFCFC"}
            w={"64"}
            borderColor={"#EFEFEF"}
            borderWidth={1}
            fontSize={14}
            maxLength={8}
            type={"tel"}
            _hover={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            _focus={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            value={contractId}
            onChange={(a) => handleContractId(a.target.value)}
          />
        </Stack>
        <Stack
          direction={{ base: "column", md: "row" }}
          justify={"space-between"}
          w={"full"}
          spacing={{ base: 0, md: 2 }}
        >
          <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
            house:
          </Text>
          <Select
            w={"64"}
            fontSize={14}
            placeholder="-Байшин сонгоно уу-"
            disabled={house.length > 0 ? false : true}
            onChange={(a) => handleHouse(a.target.value)}
            multiple={false}
            _hover={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
            _focus={{
              borderColor: "#45A735",
              borderWidth: 1,
            }}
          >
            {house.map((e, index) => (
              <option key={index}>{e.name}</option>
            ))}
          </Select>
        </Stack>
        {selectAi.map((e, index) => (
          <Stack
            direction={{ base: "column", md: "row" }}
            key={index}
            justify={"space-between"}
            w={"full"}
            spacing={{ base: 0, md: 2 }}
          >
            <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
              AI{index + 1}:
            </Text>
            <Select
              w={"64"}
              fontSize={14}
              placeholder="-"
              onChange={(a) => handleSelect(a.target.value, index)}
              multiple={false}
              _hover={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
              _focus={{
                borderColor: "#45A735",
                borderWidth: 1,
              }}
            >
              {data.map((e, index) => (
                <option key={index}>{e}</option>
              ))}
            </Select>
          </Stack>
        ))}
        <Stack
          direction={{ base: "column", md: "row" }}
          justify={"space-between"}
          w={"full"}
        >
          <Button
            bg={"#45A735"}
            rounded={"md"}
            w={{ base: "full", md: "52" }}
            py={"2"}
            px={"8"}
            disabled={selectAi.length > 7 ? true : false}
            _hover={{
              bg: "#5AB54B",
            }}
            _focus={{
              outline: "none",
            }}
            onClick={() => incSelect()}
          >
            <Text fontSize={"sm"} color={"#fff"} fontWeight={"normal"}>
              Хөл нэмэх
            </Text>
          </Button>
          <Button
            bg={"#45A735"}
            rounded={"md"}
            w={{ base: "full", md: "52" }}
            py={"2"}
            px={"8"}
            disabled={
              clientId.length > 11 && contractId.length > 7 && selectHouse
                ? false
                : true
            }
            _hover={{
              bg: "#5AB54B",
            }}
            _focus={{
              outline: "none",
            }}
            onClick={() => handleSubmit()}
          >
            <Text fontSize={"sm"} color={"#fff"} fontWeight={"normal"}>
              Хадгалах
            </Text>
          </Button>
        </Stack>
      </VStack>
    </VStack>
  );
};

export default AddmA;
