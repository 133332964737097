import React from "react";
import { HStack, Text } from "@chakra-ui/react";

const SummaryRow = ({ label, value }) => {
  return (
    <HStack
      w={"full"}
      bg={"#FCFCFC"}
      borderColor={"#EFEFEF"}
      borderWidth={1}
      borderRadius={4}
      px={5}
      py={2}
      align={"center"}
      justify={"space-between"}
      gap={4}
    >
      <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
        {label}
      </Text>
      <Text color={"#000"} fontSize={13} fontWeight={"medium"}>
        {value || "-"}
      </Text>
    </HStack>
  );
};

export default SummaryRow;
