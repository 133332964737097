import React, { useEffect, useState } from "react";
import {
  Button,
  Center,
  Divider,
  Grid,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Switch,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "../../axios";
import Cookies from "js-cookie";
import { IoIosStats } from "react-icons/io";
import { useHistory, useLocation } from "react-router-dom";
import { FiInfo } from "react-icons/fi";
import { BiChevronDown } from "react-icons/bi";
import { GrUpdate } from "react-icons/gr";
import BackButton from "../../components/button/BackButton";

const UserGroupDetail = () => {
  const location = useLocation();
  const history = useHistory();
  const toast = useToast();
  const id = "test-toast";
  const [isLoading, setIsLoading] = useState(false);
  const [automatioData, setAutomationData] = useState([]);
  const token = Cookies.get("accessToken");

  // -------------- get Group Detail ----------------
  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("admin/user_automation_group", {
        params: {
          groupId: location.state.e._id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        // console.log(result.data.data);
        setAutomationData(result.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.message
              ? err.response.data.message
              : "Алдаа гарлаа!",
          });
        }
        setIsLoading(false);
      });
    return () => {
      cancel = true;
    };
  }, []);

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      mb={"10"}
      w={{ base: "full", md: "full", lg: "8xl" }}
    >
      {" "}
      <BackButton />
      {isLoading ? (
        <HStack spacing={"10"}>
          <Skeleton
            w={{ base: "80", md: "xs", lg: "sm" }}
            h={"32"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            display={{ base: "none", md: "flex", lg: "flex" }}
            w={{ base: "80", md: "xs", lg: "sm" }}
            h={"32"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
          <Skeleton
            display={{ base: "none", md: "none", lg: "flex" }}
            w={{ base: "80", md: "xs", lg: "sm" }}
            h={"32"}
            borderRadius={"md"}
            startColor="blackAlpha.100"
            endColor="blackAlpha.300"
          />
        </HStack>
      ) : automatioData.length > 0 ? (
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          gap={{ base: 4, md: 6 }}
        >
          {automatioData.map((e, index) => (
            <VStack
              key={e._id}
              w={"full"}
              bg="#fff"
              boxShadow={"base"}
              borderRadius={"lg"}
              pb={"4"}
              py={"6"}
              px={"8"}
              spacing={4}
            >
              <VStack
                w={"full"}
                // py={"6"}
                // px={"8"}
                cursor="pointer"
                onClick={() =>
                  history.push("/User/find/automation-detail", {
                    e,
                    contractId: location.state.contractId,
                  })
                }
              >
                <HStack justify={"space-between"} w="full">
                  <Text fontSize={"sm"} color="#000" fontWeight={"normal"}>
                    Автоматжуулалтын нэр:
                  </Text>
                  <Text fontSize={"sm"} color="#000" fontWeight={"medium"}>
                    {e.name}
                  </Text>
                </HStack>
                <Divider />
                <HStack justify={"space-between"} w="full">
                  <Text fontSize={"sm"} color="#000" fontWeight={"normal"}>
                    Төлөв:
                  </Text>
                  <Switch
                    id="isDisabled"
                    isReadOnly
                    size={"md"}
                    colorScheme={"blue"}
                    // defaultChecked
                    isChecked={e.isActive === false ? false : true}
                  />
                </HStack>
              </VStack>
              <Menu>
                {({ isOpen }) => (
                  <>
                    <MenuButton
                      isActive={isOpen}
                      as={Button}
                      rightIcon={<BiChevronDown />}
                      w={{ base: "full", md: "72", lg: "80" }}
                      bg={"#fff"}
                      color={"#45A735"}
                      borderWidth={1}
                      borderColor={"#eee"}
                      _expanded={{ bg: "#5AB54B", color: "#fff" }}
                      _hover={{
                        bg: "#5AB54B",
                        color: "#fff",
                      }}
                      textAlign="left"
                      fontWeight={"medium"}
                      fontSize={"sm"}
                    >
                      Log харах
                    </MenuButton>
                    <MenuList w={{ base: "full", md: "72", lg: "80" }}>
                      <MenuItem
                        icon={<IoIosStats />}
                        fontSize={"sm"}
                        color="#000"
                        fontWeight={"normal"}
                        onClick={() =>
                          history.push("/User/find/automation-status-log", {
                            e,
                            contractId: location.state.contractId,
                          })
                        }
                      >
                        Status Log
                      </MenuItem>
                      <MenuItem
                        icon={<GrUpdate />}
                        fontSize={"sm"}
                        color="#000"
                        fontWeight={"normal"}
                        onClick={() =>
                          history.push("/User/find/automation-update-log", {
                            e,
                            contractId: location.state.contractId,
                          })
                        }
                      >
                        Update Log
                      </MenuItem>
                    </MenuList>
                  </>
                )}
              </Menu>
            </VStack>
          ))}
        </Grid>
      ) : (
        <VStack
          justifyContent="center"
          alignItems="center"
          color="gray.600"
          w="full"
        >
          <Icon mt={"20"} as={FiInfo} w={7} h={7} />
          <Text fontSize={15}>Мэдээлэл олдсонгүй</Text>
        </VStack>
      )}
    </VStack>
  );
};

export default UserGroupDetail;
