import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Text,
} from "@chakra-ui/react";
import AllUser from "./AllUser";
import SelectUser from "./SelectUser";
import SendSms from "./SendSms";

const SendNotification = ({ setTrigger }) => {
  return (
    <Accordion w={{ base: "xs", md: "2xl" }} allowToggle>
      {btnData.map((e, i) => (
        <AccordionItem
          key={i}
          border="none"
          w={"full"}
          mt={i === 0 ? "0" : "4"}
        >
          {({ isExpanded }) => (
            <>
              <AccordionButton
                transition={"ease-in-out .3s"}
                w={"full"}
                h={"14"}
                bg={"#fff"}
                rounded={"md"}
                shadow={"md"}
                px={10}
                justifyContent={"space-between"}
                _hover={{
                  shadow: "lg",
                }}
                _focus={{ border: "none" }}
                m={0}
                pos={"relative"}
              >
                <Box
                  transition={"ease-in-out 1s"}
                  w={1}
                  h={10}
                  bg={"#45A735"}
                  pos={"absolute"}
                  left={0}
                  roundedRight={"md"}
                />
                <HStack textAlign={"left"} w={"full"} spacing={4}>
                  <Text
                    fontSize={15}
                    fontWeight={"normal"}
                    color={"#000"}
                    alignSelf={"center"}
                  >
                    {e.title}
                  </Text>
                </HStack>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel w={"full"} pb={2}>
                {e.title === "Бүх хэрэглэгчид" ? (
                  <AllUser setTrigger={setTrigger} />
                ) : e.title === "Хэрэглэгч сонгох" ? (
                  <SelectUser setTrigger={setTrigger} />
                ) : e.title === "Хэрэглэгч рүү мессеж илгээх" ? (
                  <SendSms setTrigger={setTrigger} />
                ) : null}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

const btnData = [
  {
    title: "Бүх хэрэглэгчид",
  },
  {
    title: "Хэрэглэгч сонгох",
  },
  {
    title: "Хэрэглэгч рүү мессеж илгээх",
  },
];

export default SendNotification;
