import {
  Center,
  Divider,
  Flex,
  HStack,
  Icon,
  Image,
  Stack,
  Text,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Avatar,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { HiLogout } from "react-icons/hi";
import logoLight from "../../Images/logo.png";
import UserContext from "../../UserContext";
import { navData } from "./SideBarData";
import Cookies from "js-cookie";

const SideBar = () => {
  const context = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  const username = Cookies.get("username");
  const role = Cookies.get("role");

  return (
    <Flex
      transition="all .8s ease-in-out"
      bg={"#fff"}
      my={"2"}
      ml={"2"}
      rounded={"lg"}
      boxShadow={"lg"}
      w={"2xs"}
      zIndex={"banner"}
      display={["none", "none", "none", "none", "flex"]}
      overflowY="auto"
      sx={{
        "&::-webkit-scrollbar": {
          width: "2px",
          borderRadius: "8px",
          backgroundColor: `rgba(249, 249, 249)`,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: `rgba(69, 167, 53, 0.6)`,
          borderRadius: "8px",
        },
      }}
    >
      <VStack align={"center"} mx={"3"} justify={"space-between"} h={"full"}>
        <Stack spacing={2}>
          <HStack
            h="10"
            alignItems={"center"}
            mx="2"
            mt={"3"}
            mb={"1"}
            onClick={() => history.push("/dashboard")}
            cursor={"pointer"}
          >
            <Image src={logoLight} objectFit="contain" />
          </HStack>
          <Divider />
          <Accordion allowMultiple w="100%">
            <Stack spacing={1}>
              {navData.map((e, index) =>
                (role === "techsub" && e.toLink === "/invoice") ||
                (role === "techsub" && e.toLink === "/api") ||
                (role === "techsub" && e.toLink === "/User/find") ||
                (role === "techsub" && e.toLink === "/dashboard") ||
                (role === "techsub" && e.toLink === "/system/device-list") ||
                (role === "techmonitor" && e.toLink === "/dashboard") ||
                (role === "techmonitor" && e.toLink === "/api") ||
                (role === "techmonitor" && e.toLink === "/invoice") ||
                (role === "techmonitor" &&
                  e.toLink === "/system/device-list") ? null : e.subTitle ? (
                  <AccordionItem key={index} border="none">
                    <AccordionButton
                      _focus={{ border: "none" }}
                      justifyContent={"space-between"}
                      _hover={{
                        backgroundColor: "#F7F7F7",
                        borderRadius: 10,
                      }}
                      // as={NavLink}
                      // to={e.toLink}
                      m={0}
                    >
                      <HStack align="center" cursor="pointer">
                        <Center
                          bg={
                            location.pathname.split("/")[1] ===
                            e.toLink.split("/")[1]
                              ? "#45A735"
                              : "#fff"
                          }
                          boxShadow={"base"}
                          borderRadius={"lg"}
                          p={2}
                        >
                          <Icon
                            fontSize="16"
                            color={
                              location.pathname.split("/")[1] ===
                              e.toLink.split("/")[1]
                                ? "#fff"
                                : "#45A735"
                            }
                            as={e.icon}
                          />
                        </Center>
                        <Text
                          w={"full"}
                          color={
                            location.pathname.split("/")[1] ===
                            e.toLink.split("/")[1]
                              ? "#000"
                              : "#A0A0A0"
                          }
                          fontSize={13}
                          fontWeight={
                            location.pathname.split("/")[1] ===
                            e.toLink.split("/")[1]
                              ? "medium"
                              : "normal"
                          }
                          ml={"2"}
                        >
                          {e.title}
                        </Text>
                      </HStack>
                      <AccordionIcon
                        color={
                          location.pathname.split("/")[1] ===
                          e.toLink.split("/")[1]
                            ? "#000"
                            : "#A0A0A0"
                        }
                      />
                    </AccordionButton>
                    <AccordionPanel py={0} my={2}>
                      <VStack align="center" cursor="pointer" ml={2}>
                        {e.subTitle.map((e, i) =>
                          (role === "techsub" &&
                            e.toLink === "/device/interval") ||
                          (role === "techmonitor" &&
                            e.toLink === "/User/notification") ||
                          (role === "techmonitor" &&
                            e.toLink === "/User/analyze") ||
                          (role === "techmonitor" &&
                            e.toLink === "/User/complain") ||
                          (role === "techmonitor" &&
                            e.toLink === "/device/interval") ? null : (
                            <HStack
                              key={i}
                              as={NavLink}
                              to={e.toLink}
                              alignSelf="flex-start"
                              align={"center"}
                              w={"100%"}
                              p={2}
                              // mt={e.title === "Interval" ? 4 : 0}
                              transition="all 0.3s"
                              _hover={{
                                backgroundColor: "#F7F7F7",
                                borderRadius: 10,
                              }}
                            >
                              <Icon
                                fontSize="16"
                                color={
                                  location.pathname.split("/")[2] ===
                                  e.toLink.split("/")[2]
                                    ? "#45A735"
                                    : "#A0A0A0"
                                }
                                as={e.icon}
                              />
                              <Text
                                w={"full"}
                                color={
                                  location.pathname.split("/")[2] ===
                                  e.toLink.split("/")[2]
                                    ? "#000"
                                    : "#A0A0A0"
                                }
                                fontSize={13}
                                fontWeight={
                                  location.pathname.split("/")[2] ===
                                  e.toLink.split("/")[2]
                                    ? "medium"
                                    : "normal"
                                }
                              >
                                {e.title}
                              </Text>
                            </HStack>
                          )
                        )}
                      </VStack>
                    </AccordionPanel>
                  </AccordionItem>
                ) : e.toLink === "/admin-list" ||
                  e.toLink === "/notification" ||
                  e.toLink === "/profile" ||
                  e.toLink === "/User/notification" ||
                  e.toLink === "/banner" ||
                  e.toLink === "/login" ? null : (
                  <NavLink
                    className={"navLink"}
                    key={index}
                    to={e.toLink}
                    style={{
                      textDecoration: "none",
                      alignSelf: "center",
                      width: "100%",
                      padding: 8,
                      paddingLeft: 16,
                    }}
                    activeStyle={{
                      backgroundColor: "#fff",
                      borderRadius: 10,
                    }}
                  >
                    <HStack align="center" cursor="pointer">
                      <Center
                        bg={
                          location.pathname.split("/")[1] ===
                          e.toLink.split("/")[1]
                            ? "#45A735"
                            : "#fff"
                        }
                        boxShadow={"base"}
                        borderRadius={"lg"}
                        p={2}
                      >
                        <Icon
                          fontSize="16"
                          color={
                            location.pathname.split("/")[1] ===
                            e.toLink.split("/")[1]
                              ? "#fff"
                              : "#45A735"
                          }
                          as={e.icon}
                        />
                      </Center>
                      <Text
                        w={"full"}
                        color={
                          location.pathname.split("/")[1] ===
                          e.toLink.split("/")[1]
                            ? "#000"
                            : "#A0A0A0"
                        }
                        fontSize={13}
                        fontWeight={
                          location.pathname.split("/")[1] ===
                          e.toLink.split("/")[1]
                            ? "medium"
                            : "normal"
                        }
                        ml={"2"}
                      >
                        {e.title}
                      </Text>
                    </HStack>
                  </NavLink>
                )
              )}
            </Stack>
          </Accordion>
        </Stack>
        <Stack pb={"4"} align={"center"} width={"100%"}>
          {navData.map((e, index) =>
            role !== "techsub" &&
            role !== "techmonitor" &&
            e.toLink === "/notification" ? (
              <Flex
                key={index}
                cursor="pointer"
                width={"90%"}
                color={"#F7F7F7"}
                as={NavLink}
                to={e.toLink}
                style={{
                  textDecoration: "none",
                  alignSelf: "center",
                  width: "90%",
                  padding: 8,
                }}
                _hover={{
                  backgroundColor: "#F7F7F7",
                  borderRadius: 10,
                }}
              >
                <HStack align="center" cursor="pointer">
                  <Center
                    bg={
                      location.pathname.split("/")[1] === e.toLink.split("/")[1]
                        ? "#45A735"
                        : "#fff"
                    }
                    boxShadow={"base"}
                    borderRadius={"lg"}
                    p={2}
                  >
                    <Icon
                      fontSize="16"
                      color={
                        location.pathname.split("/")[1] ===
                        e.toLink.split("/")[1]
                          ? "#fff"
                          : "#45A735"
                      }
                      as={e.icon}
                    />
                  </Center>
                  <Text
                    w={"full"}
                    color={
                      location.pathname.split("/")[1] === e.toLink.split("/")[1]
                        ? "#000"
                        : "#A0A0A0"
                    }
                    fontSize={"sm"}
                    fontWeight={
                      location.pathname.split("/")[1] === e.toLink.split("/")[1]
                        ? "medium"
                        : "normal"
                    }
                    ml={"2"}
                  >
                    {e.title}
                  </Text>
                </HStack>
              </Flex>
            ) : null
          )}
          <Divider />
          <Flex
            alignItems={"center"}
            align={"center"}
            justify={"center"}
            width={"100%"}
            px={"3"}
            _hover={{
              backgroundColor: "#F7F7F7",
              borderRadius: 10,
            }}
          >
            <Menu w={"full"}>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    py={2}
                    w={"full"}
                    transition="all 0.3s"
                    _focus={{ boxShadow: "none" }}
                  >
                    <HStack w={"full"} justifyContent={"space-between"}>
                      <HStack>
                        <Avatar size={"sm"} borderRadius={"lg"} />
                        <VStack
                          display={{ base: "none", md: "flex" }}
                          alignItems="flex-start"
                          spacing={0}
                          ml="2"
                        >
                          <Text
                            fontSize={14}
                            color={"#000"}
                            fontWeight={"medium"}
                          >
                            {username}
                          </Text>
                          <Text
                            fontSize={12}
                            color="#9A9A9A"
                            fontWeight={"medium"}
                            textTransform={"capitalize"}
                          >
                            {role ? role : "-"}
                          </Text>
                        </VStack>
                      </HStack>
                      <Box display={{ base: "none", md: "flex" }}>
                        {isOpen ? <FiChevronDown /> : <FiChevronUp />}
                      </Box>
                    </HStack>
                  </MenuButton>
                  <MenuList bg={"white"} borderColor={"gray.200"}>
                    {navData.map((e, index) =>
                      e.toLink === "/profile" ||
                      (role !== "techsub" &&
                        role !== "techmonitor" &&
                        e.toLink === "/banner") ||
                      (role !== "techsub" &&
                        role !== "techmonitor" &&
                        e.toLink === "/admin-list") ? (
                        <MenuItem
                          key={index}
                          as={NavLink}
                          to={e.toLink}
                          _focus={{
                            backgroundColor: "#fff",
                            borderRadius: 10,
                          }}
                          _active={{
                            backgroundColor: "#fff",
                            borderRadius: 10,
                          }}
                        >
                          <Center borderRadius={"lg"} p={2} bg={"#F5F5F5"}>
                            <Icon fontSize="16" color={"#45A735"} as={e.icon} />
                          </Center>
                          <Text
                            color={"#000"}
                            fontSize={"sm"}
                            fontWeight="normal"
                            ml={"2"}
                          >
                            {e.title}
                          </Text>
                        </MenuItem>
                      ) : null
                    )}
                    <MenuDivider />
                    <MenuItem
                      _focus={{
                        backgroundColor: "#fff",
                        borderRadius: 10,
                      }}
                      _active={{
                        backgroundColor: "#fff",
                        borderRadius: 10,
                      }}
                      onClick={() => context.logoutHandler()}
                    >
                      <Center borderRadius={"lg"} p={2} bg={"#F5F5F5"}>
                        <Icon fontSize="16" color={"#45A735"} as={HiLogout} />
                      </Center>
                      <Text
                        color={"#ff0000"}
                        fontSize={"sm"}
                        fontWeight="medium"
                        ml={"2"}
                      >
                        Гарах
                      </Text>
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </Flex>
        </Stack>
      </VStack>
    </Flex>
  );
};

export default SideBar;
