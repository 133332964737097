import React from "react";
import { Center, Text, Stack, VStack } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import BackButton from "../../../components/button/BackButton";

const ZlanType = () => {
  const history = useHistory();

  return (
    <VStack
      align={"flex-start"}
      spacing={"6"}
      // bg={"red"}
      mb={"10"}
      w={{ base: "xs", md: "xl", lg: "8xl" }}
    >
      <BackButton />
      <Stack
        direction={{ base: "column", md: "row" }}
        align={"flex-start"}
        spacing={"6"}
        w={"full"}
      >
        <Center
          w={"60"}
          boxShadow={"base"}
          bg={"#45A735"}
          rounded={"md"}
          flexDirection={"column"}
          _hover={{
            transform: "scale(1.02)",
          }}
          cursor={"pointer"}
          onClick={() => history.push("/device/type/zlan/add-zlan-ma")}
        >
          <Text fontSize={"xs"} fontWeight={"normal"} p={2} color={"#fff"}>
            Төрөл
          </Text>

          <Center w={"60"} bg={"#fff"} px={6} py={10} rounded={"md"}>
            <Text fontSize={"md"} fontWeight={"medium"} color={"#000"}>
              4-20mA
            </Text>
          </Center>
        </Center>
        <Center
          w={"60"}
          boxShadow={"base"}
          bg={"#45A735"}
          rounded={"md"}
          flexDirection={"column"}
          _hover={{
            transform: "scale(1.02)",
          }}
          cursor={"pointer"}
          onClick={() => history.push("/device/type/zlan/add-zlan-rs")}
        >
          <Text fontSize={"xs"} fontWeight={"normal"} p={2} color={"#fff"}>
            Төрөл
          </Text>

          <Center w={"60"} bg={"#fff"} px={6} py={10} rounded={"md"}>
            <Text fontSize={"md"} fontWeight={"medium"} color={"#000"}>
              RS485
            </Text>
          </Center>
        </Center>
      </Stack>
    </VStack>
  );
};

export default ZlanType;
